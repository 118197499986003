const HOST = 'https://api.4gives.me';
// const HOST = 'https://app4gives-sandbox.herokuapp.com';
const SERVER = `${HOST}/api/v1`;

const URL = {
    SERVER: SERVER,
    LOGIN: `${SERVER}/rest-auth/login/`,
    NEW_PASSWORD: `${SERVER}/users/user/password-reset/`,
    CHANGE_PASSWORD: `${SERVER}/rest-auth/password/change/`,
    REGISTRATION: `${SERVER}/users/user/register/`,
    NOTIFICATIONS: `${SERVER}/notifications/register-device/`,
    FORGOT_PASSWORD: `${SERVER}/users/user/forgot-password/`,
    USER: {
        PROFILE: `${SERVER}/users/user/`,
        NUMBER: `${SERVER}/users/user/number/`,
        SMS_VERIFY: `${SERVER}/users/user/number/confirm/`,
        VERIFY: `verify/`,
        API_KEYS: `${SERVER}/users/user/api_keys/`,
        MERCHANT_PROFILE: `${SERVER}/users/user/merchant_profile/`,
        RESEND_VERIF: `${SERVER}/users/user/resend-email-validation/`,
    },
    TRANSACTIONS: {
        TRANSACTIONS_BASE: `${SERVER}/transactions`,
        TRANSACTIONS_ACTIVITY: `${SERVER}/transactions/activities`,
        TRANSACTIONS_BUY: `${SERVER}/transactions/buying`,
        TRANSACTIONS_SELL: `${SERVER}/transactions/selling`,
        ACCOUNT_ACTIVATE: `${SERVER}/transactions/source`,
        PAYMENT_SOURCE: `${SERVER}/transactions/source`,
        COMPUTATION: `${SERVER}/transactions/installment/compute/`,
        SERVICE_TYPES: `${SERVER}/transactions/payment_type`,
        CATEGORY: `${SERVER}/transactions/category`,
        CREATE: `${SERVER}/transactions/create`,
        GCASH_CREATE: `${SERVER}/transactions/payment/gcash/`,
        ON_DEMAND_PAYMENT: `${SERVER}/transactions/payment/on_demand/`,
        OTP_SEND: `${SERVER}/transactions/otp/send/`
    },
    OTP: {
        SEND: `${SERVER}/users/user/number/`,
        VERIFY: `${SERVER}/transactions/otp/verify/`,
    },
    PROPOSALS: {
        PROPOSAL: `${SERVER}/transactions/proposal/`,//should accept int id
        REFERENCE_GENERATE: `${SERVER}/transactions/proposal/reference/`,
        PROPOSAL_ACCEPT: `${SERVER}/transactions/proposal/accept`,
        PROPOSALS_SELL: `${SERVER}/transactions/proposal/sell`,
        PROPOSALS_BUY: `${SERVER}/transactions/proposal/buy`,
        PROPOSAL_SELL: `${SERVER}/transactions/proposal/create/sell`,
        PROPOSAL_BUY: `${SERVER}/transactions/proposal/create/buy`,
        GET_PROPOSAL_LINK: `${SERVER}/transactions/proposal/link/`,
        PROPOSALS_BUY_ACCEPTED: `${SERVER}/transactions/proposal/buy/accepted`,
        PROPOSALS_BUY_WAITING: `${SERVER}/transactions/proposal/buy/waiting`,
        PROPOSALS_SELL_ACCEPTED: `${SERVER}/transactions/proposal/sell/accepted`,
        PROPOSALS_SELL_WAITING: `${SERVER}/transactions/proposal/sell/waiting`,
    },
    PAYMAYA: {
        PAYMAYA_CARDS: `${SERVER}/transactions/paymaya/cards`,
        PAYMAYA_ACCOUNT: `${SERVER}/transactions/paymaya/account`
    },
    EVENTS: {
        ACTIVITIES: `${HOST}/events/activities/`,
        TRANSACTIONS: `${HOST}/events/transactions/`,
        APPLICATION: `${HOST}/events/application/`
    }
}

export default URL;