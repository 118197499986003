import React from 'react';
import { Container } from 'react-grid-system';
import { AppBar, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);


export const LandingPage: React.FC = () => 
    <div className="home-page">
        <Container>

        </Container>
    </div>