import React, { useCallback, useState, useEffect, useRef } from 'react';
import {  Row, Col} from 'react-grid-system'
import { TextInput, TextContactInput, TextContactLoginInput, TextLoginInput } from '../../components/Input'
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from '../../hooks';
import './style.scss';
import { Button, ErrorDialog, OTPDialog, SuccessDialog } from '../../components';
import { Alert, AlertTitle } from '@material-ui/lab';
import { User } from '../../models';
import Auth from '../../auth';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {  RadioGroup, FormControlLabel, Radio, MenuItem, Select, Card, CardContent, Divider, Dialog, DialogContent, DialogTitle, Grid, CardActions } from '@material-ui/core';
import { useContext } from 'react';
import {Container} from 'reactstrap';
import { useFormFiles } from '../../hooks';
import { filter } from 'lodash';
import { cities } from '../../assets/cities';
import { provinces } from '../../assets/provinces';
import { textSpanIntersection } from 'typescript';
import { banks } from '../../assets/banks';

const avatarDefault = require('../../assets/images/avatar-default.png');
const close = require('../../assets/images/close.png');

export const ProfilePage: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const { user, source, setUser } = useContext(User.Context);
    const [isEditAvatar, setIsEditAvatar] = useState(false);
    const inputFile = useRef<any | null>(null) 
    const [gender, setGender] = useState('M');
    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [cardActivated, setCardActivated] = useState(false);
    const [street, setStreet] = useState<string>('');
    const [street2, setStreet2] = useState<string>('');
    const [barangay, setBarangay] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [zip, setZip] = useState<string>('');
    const [contact, setContact] = useState<string>('');
    const {files, handleFiles} = useFormFiles({
        avatar: '',
    })

    const [editBankAccountNumber, setEditBankAccountNumber] = useState( user && user.bankAccountNumber ? user.bankAccountNumber : null);
    const [editBankCode, setEditBankCode] = useState(user && user.bankCode ? user.bankCode : null );

    const location = useLocation();

    const [openMobileChange, setOpenMobileChange] = useState(false);

    const [openOTP, setOpenOTP] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    const [openChangePass, setOpenChangePass] = useState(false);
    const [newPass1, setNewPass1] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const history = useHistory();

    useEffect(() => {
        if(User.userData){
            User.get((userData) => {
                setUser(userData);
                console.log(userData)
            });
            console.log(User.userData);   
        }
    }, [User])

    const openFile = () => {
        inputFile.current.click()
    }

    useEffect(() => {
        if(files.avatar !== ''){
            Auth.updateAvatar(
                files.avatar,
                (response)=> {
                    console.log(response)
                    setIsEditAvatar(false);
                    setUser(response)
                    setLoadingOverlay(false)
                },
                (e) => {
                    console.log(e)
                    setOpenErrorDialog(true)
                    setErrorDialogMessage(e.profile_picture)
                    //ERROR profile_picture
                    setIsEditAvatar(false);
                    setLoadingOverlay(false)
                }
            )
        }
    }, [files])

    useEffect(() => {
        if(user){
            setLoadingOverlay(true)
            console.log(user)
            setFirstName(user.first_name ? user.first_name : '');
            setMiddleName(user.middle_name ? user.middle_name : '' );
            setLastName(user.last_name ? user.last_name : '');
            setStreet(user.street ? user.street : '');
            setStreet2(user.street2 ? user.street2 : '');
            setBarangay(user.barangay ? user.barangay: '');
            setCity(user.city ? user.city : '');
            setRegion(user.region ? user.region : '');
            setZip(user.zip_code ? user.zip_code  : '');
            setContact(user.contact_number ? user.contact_number.slice(3) : '');
            setLoadingOverlay(false)
        }
        
    },[user])

    useEffect(() => {
        if(source){
            const isInstall = source.map((data:any) => {
                if(data.installmentAuthorized){
                  return true
                }else{
                  return false
                }
              })
            console.log(isInstall)
            const test =  isInstall.some((val: any) => {
                if(val === true){
                    return true
                }
            })
            setCardActivated(test)
        }
    },[source])

    const handleAvatarChange = (e: any) => {
        setLoadingOverlay(true)
        if(e.target.files){
            handleFiles(e)
        }
        
    }

    const saveProfile = () => {
        setLoadingOverlay(true)
        Auth.updateProfilePage(
            firstName,
            middleName,
            lastName,
            gender,
            street,
            street2,
            region,
            city,
            barangay,
            zip,
            (response) => {
                console.log(response)
                setLoadingOverlay(false)
                setLoadingOverlay(false)
                setOpenDialog(true)
                setDialogMessage("Profile has been updated.");
            },
            (e)=> {
                setLoadingOverlay(false)
                setErrorDialogMessage(e.error ? e.error : "We have encountered an error. Please try again in a few minutes")
                setOpenErrorDialog(true)
                console.log(e)
            }
        )
    }

    const changePassword = () => {
        setLoadingOverlay(true)
        if(user){
            Auth.forgotPassword(
                user.email,
                (response) => {
                    setLoadingOverlay(false)
                    setOpenDialog(true)
                    setDialogMessage("Your request for a password change has been sent! Please check your email.")
                },
                (e) => {
                    setLoadingOverlay(false)
                    setErrorDialogMessage(e.error ? e.error : "We have encountered an error. Please try again in a few minutes")
                    setOpenErrorDialog(true)
                    console.log(e)
                }
            )
        }
        
    }

    const changeMobile = () => {
        setLoadingOverlay(true)
        Auth.updateNumber(
            contact,
            (resp) => {
                console.log(resp)
                setLoadingOverlay(false)
                setOpenDialog(true)
                setOpenMobileChange(false)
                setOpenOTP(true)
                setDialogMessage("Mobile Number has been updated!");
            },
            (e) => {
                setLoadingOverlay(false)
                setErrorDialogMessage(e.error ? e.error : "We have encountered an error. Please try again in a few minutes")
                setOpenErrorDialog(true)
                console.log(e)
            }
        )
    }

    const resendEmailVerif = () => {
        setLoadingOverlay(true)
        if(user){
            Auth.resendVerifEmail(
                user.email,
                (resp) => {
                    console.log(resp)
                    setOpenDialog(true)
                    setLoadingOverlay(false)
                    setDialogMessage("A verification email has been sent.");
                },
                (e) => {
                    console.log(e)
                    setLoadingOverlay(false)
                    setErrorDialogMessage(e.new_password2 ? e.new_password2 : e.new_password1 ? e.new_password1 : "We have encountered an error. Please try again in a few minutes")
                    setOpenErrorDialog(true)
                }
            )
        }
        
    }

    const changePass = () => {
        setLoadingOverlay(true)
        Auth.changePassword(
            newPass1,
            newPass2,
            (resp) => {
                console.log(resp)
                setDialogMessage("Your password has been updated successfully")
                setOpenChangePass(false)
                setOpenDialog(true)
                
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setErrorDialogMessage(e.new_password2)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
            }
        )
    }

    const updateBank = () => {
        setLoadingOverlay(true)
        Auth.accUpgrade.updateBankDetails(
            editBankCode,
            editBankAccountNumber,
            (resp) => {
                console.log(resp)
                setDialogMessage("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                setOpenDialog(true)
                User.get((userdata: any) => {
                    setUser(userdata)
                })
                setLoadingOverlay(false)
            },
            (e) => {
                setErrorDialogMessage(e.detail)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
             
            }
        )
    }


    return(
        <div className="page-content profile-page">
            <Container>
                <div className="profile-page-container fg-container">
                    <Row>
                        <Col xs={12} sm={8}>
                        <Card>
                            <CardContent>
                            <h1 className="page-title">Profile</h1>
                            <Row align="center" justify="center">
                                <div className='profilePicture'>
                                    <img className="avatar" src={user && user.profile_picture ? user.profile_picture : avatarDefault} />
                                    <div className='profilePictureUploadWrapper' onClick={openFile}>
                                        <CameraAltIcon style={{fill:'#FF873A', width: 15}} />
                                        <input type='file' id='file' name='avatar' ref={inputFile} onChange={(e) => { if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    handleAvatarChange(e)
                                                                }
                                                            }}} style={{display: 'none'}}/>
                                    </div>
                                </div>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                
                                <Col sm={6} md={3}>
                                    <span>First Name: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="first_name"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        type='text'
                                        value={firstName}
                                        placeholder='First Name'
                                    />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Middle Name: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="middle_name"
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        type='text'
                                        value={middleName}
                                        placeholder='Middle Name (Optional)'
                                    />
                                </Col>
                            
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Last Name: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="last_name"
                                        onChange={(e) => setLastName(e.target.value)}
                                        type='text'
                                        value={lastName}
                                        placeholder='Last Name'
                                    />
                                </Col>
                            
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Gender: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={(e) => setGender(e.target.value)}>
                                        <FormControlLabel value="M" control={<Radio />} label="Male" />
                                        <FormControlLabel value="F" control={<Radio />} label="Female" />
                                    </RadioGroup>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Street Address: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="street"
                                        onChange={(e) => setStreet(e.target.value)}
                                        type='text'
                                        value={street}
                                        placeholder='Street Address'
                                    />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Street Address 2: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="street2"
                                        onChange={(e) => setStreet2(e.target.value)}
                                        type='text'
                                        value={street2}
                                        placeholder='Street Address 2'
                                    />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Province: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="region"
                                        value={region}
                                        style={{width: '100%'}}
                                        displayEmpty
                                        onChange={(e: any)=>{console.log(e.target.value); setRegion(e.target.value)}}
                                    >
                                        <MenuItem value="" disabled>
                                            <i>Select Province...</i>
                                        </MenuItem>
                                        {provinces.map((prov : any) => (
                                            <MenuItem key={prov.name} value={prov.name}>{prov.name}</MenuItem>
                                        ))
                                        }
                                        
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>City: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="city"
                                        value={city}
                                        style={{width: '100%'}}
                                        displayEmpty
                                        onChange={(e: any)=>{console.log(e.target.value); setCity(e.target.value)}}
                                    >
                                        <MenuItem value="" disabled>
                                            <i>Select City...</i>
                                        </MenuItem>
                                        {filter(cities, {province: region}).map((city : any) => (
                                            <MenuItem key={city.name} value={city.name}>{city.name}</MenuItem>
                                        ))
                                        }
                                        
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Barangay: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="barangay"
                                        onChange={(e) => setBarangay(e.target.value)}
                                        type='text'
                                        value={barangay}
                                        placeholder='Barangay'
                                    />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Zip Code: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextInput
                                        name="zip_code"
                                        onChange={(e) => {
                                          
                                            if(e.target.value.match(/^\d+$/) && e.target.value.length < 6){
                                                setZip(e.target.value)
                                            }else if(e.target.value === ''){
                                                setZip(e.target.value)
                                            }
                                        }}
                                        type='text'
                                        value={zip}
                                        placeholder='Zip Code'
                                    />
                                </Col>
                            </Row>
                            {/**
                             *<Row style={{paddingTop: 20}} align="center" justify="center">
                                <Col sm={6} md={3}>
                                    <span>Contact Number: </span>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextContactInput
                                        name="contact_number"
                                        onChange={(e) => setContact(e.target.value)}
                                        type='text'
                                        value={contact}
                                    />
                                </Col>
                            </Row>
                             * 
                             */}
                            
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="profile-page-button">
                                        
                                        <Button 
                                            color='primary'
                                            onClick={saveProfile}
                                            className='fg-acountactivate-button'
                                        >
                                            Update Profile
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            </CardContent>
                        </Card>
                        </Col>
                        <Col xs={12}sm={4}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardContent>
                                            <h1 className="page-title">Permission Levels</h1>
                                            <div style={{paddingLeft: '50px'}}>
                                                {location.pathname.includes('buyer') && (
                                                    <>
                                                        <Row align="start" justify="start">
                                                            <label className="container-check">Buyer Authorized
                                                                <input type="checkbox" checked={user ? user.buyAuthorized : false} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </Row>
                                                        <Row align="start" justify="start">
                                                            <label className="container-check">Installment Ready
                                                                <input type="checkbox" checked={cardActivated} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </Row>
                                                    </>
                                                )}
                                                {location.pathname.includes('seller') && (
                                                    <Row align="start" justify="start">
                                                        <label className="container-check">Seller Authorized
                                                            <input type="checkbox" checked={user ? user.sellAuthorized : false} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </Row>
                                                )}
                                                
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                            
                            {location.pathname.includes('seller') && (
                                <>
                                <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                    <Divider />
                                </div>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardContent>
                                                <h1 className="page-title">Permission Levels</h1>
                                                <div style={{paddingLeft: '0px'}}>
                                                    <Grid container direction="column" spacing={3} alignItems="center" justify="center">
                                                        <Grid container item direction="row" alignItems="center">
                                                            <Grid item xs={12}>
                                                                <h6 style={{color: '#FF873A'}} id="demo-simple-select-autowidth-label">Bank:</h6>
                                                            </Grid>
                                                            <Grid item>
                                                                <Select
                                                                    id="demo-simple-select-autowidth"
                                                                    name="bankCode"
                                                                    value={editBankCode}
                                                                    autoWidth
                                                                    displayEmpty
                                                                    onChange={(e: any)=>{setEditBankCode(e.target.value)}}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        <em>Choose your bank</em>
                                                                    </MenuItem>
                                                                    {banks.map((bank : any) => (
                                                                        <MenuItem key={bank.id} value={bank.value}>{bank.label}</MenuItem>
                                                                    ))
                                                                    }
                                                                    
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item direction="row" alignItems="center">
                                                            <Grid item xs={12}>
                                                                <h6 style={{color: '#FF873A'}}>Account Number:</h6>
                                                            </Grid>
                                                            <Grid item>
                                                                <TextInput 
                                                                    name='bankAccountNumber'
                                                                    onChange={(e) => {setEditBankAccountNumber(e.target.value)}}
                                                                    type='number'
                                                                    value={editBankAccountNumber}
                                                                    placeholder='Account Number'
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                   
                                                </div>
                                            </CardContent>
                                            <CardActions style={{textAlign:'center'}}>
                                                <Row align="center" style={{paddingBottom: '20px', margin: 'auto', width: '150px'}} justify="center">
                                                    <Button 
                                                        color='primary'
                                                        onClick={updateBank}
                                                        className='fg-acountactivate-button-security'
                                                    >
                                                        Save
                                                    </Button>
                                                </Row>
                                            </CardActions>
                                        </Card>
                                    </Col>
                                </Row>
                                </>
                            )}
                            
                            <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                <Divider />
                            </div>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardContent>
                                            <h1 className="page-title">Security Settings</h1>
                                            <div style={{paddingLeft: '20px'}}>
                                                {!user?.emailConfirmed && (
                                                    <Row align="center" style={{paddingBottom: '20px', width: '244.22'}} justify="center">
                                                        <Button 
                                                            color='primary'
                                                            onClick={resendEmailVerif}
                                                            className='fg-acountactivate-button-security'
                                                        >
                                                            Resend Email Verification
                                                        </Button>
                                                    </Row>
                                                )}
                                                
                                                <Row align="center" style={{paddingBottom: '20px', width: '244.22'}} justify="center">
                                                    <Button 
                                                        color='primary'
                                                        onClick={() =>setOpenMobileChange(true)}
                                                        className='fg-acountactivate-button-security'
                                                    >
                                                        Change Mobile Number
                                                    </Button>
                                                </Row>
                                                <Row align="center" style={{paddingBottom: '20px', width: '244.22'}} justify="center">
                                                    <Button 
                                                        color='primary'
                                                        onClick={() => setOpenChangePass(true)}
                                                        className='fg-acountactivate-button-security'
                                                    >
                                                        Change Password
                                                    </Button>
                                                </Row>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                            
                            
                        </Col>
                    </Row>
                    
                </div>
            </Container>


            <Dialog 
                open={openChangePass} 
                fullWidth={true} 
                maxWidth={'sm'} 
                onClose={() =>{setOpenChangePass(false);}} 
                PaperProps={{
                    style: {
                        backgroundColor: '#FF873A', 
                        border: "1px solid #FF873A",
                        borderRadius: '20px'
                    },
                }}
            >
                <DialogTitle>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={() =>{setOpenChangePass(false);}} 
                    />
                    
                    <div style={{paddingTop: 10, color: "white", textAlign:'center'}}>
                        Change Password
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Container>
                            <div style={{padding: 20, backgroundColor: '#FF873A'}}>
                            <Grid style={{paddingTop: 25}} container item direction="column" spacing={2} alignItems="center" justify="center">
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={4} style={{color: 'white'}}>
                                        New Password:
                                    </Grid>
                                    <Grid xs={6} item alignItems="center" justify="center">
                                        <TextLoginInput 
                                            name="contact"
                                            value={newPass1 || ''}
                                            type='password'
                                            placeholder="New Password"
                                            onChange={(e:any) => setNewPass1(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={4} style={{color: 'white'}}>
                                        Confirm Password:
                                    </Grid>
                                    <Grid xs={6} item alignItems="center" justify="center">
                                        <TextLoginInput 
                                            name="contact"
                                            value={newPass2 || ''}
                                            type='password'
                                            placeholder="Confirm Password"
                                            onChange={(e:any) => setNewPass2(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{padding: 20}}>

                                </div>
                                <Grid container item alignItems="center" justify="center">
                                    <Grid item>
                                        <Button 
                                            disabled={contact === '' || contact.length > 10 || contact.length < 10}
                                            color='login-secondary'
                                            onClick={changePass}
                                            className='fg-acountactivate-button'
                                        >
                                            Update Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </div>
                        
                    </Container>
                </DialogContent>
            </Dialog>


            <Dialog 
                open={openMobileChange} 
                fullWidth={true} 
                maxWidth={'sm'} 
                onClose={() =>{setOpenMobileChange(false);}} 
                PaperProps={{
                    style: {
                        backgroundColor: '#FF873A', 
                        border: "1px solid #FF873A",
                        borderRadius: '20px'
                    },
                }}
            >
                <DialogTitle style={{backgroundColor: '#FF873A'}}>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={() =>{setOpenMobileChange(false);}} 
                    />
                    
                    <div style={{paddingTop: 10, color: "white", textAlign:'center'}}>
                        Update Mobile Number
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Container>
                    
                        <div style={{padding: 20, backgroundColor: '#FF873A'}}>
                            <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                <Grid item xs={4} style={{color: 'white'}}>
                                    Mobile Number:
                                </Grid>
                                <Grid xs={6} item alignItems="center" justify="center">
                                    <TextContactLoginInput 
                                        name="contact"
                                        value={contact || ''}
                                        type='text'
                                        placeholder="Mobile Number"
                                        onChange={(e:any) => setContact(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{padding: 20}}>

                            </div>
                            <Grid container item alignItems="center" justify="center">
                                <Grid item>
                                    <Button 
                                        disabled={contact === '' || contact.length > 10 || contact.length < 10}
                                        color='login-secondary'
                                        onClick={changeMobile}
                                        className='fg-acountactivate-button'
                                    >
                                        Update Number
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                       
                        
                    </Container>
                </DialogContent>
            </Dialog>
            <OTPDialog openDialog={openOTP} setOpenDialog={(e) => {setOpenOTP(e)}} onFinish={() => console.log("PINISH")}/>
            <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={dialogMessage} />
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
        </div>
    );
}