import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from '../Header';
import ActionBar from '../ActionBar';
import '../../assets/styles/global.scss';
import './style.scss';
import routes from '../../routes/routes';
import { User } from '../../models';
import {
    AccountActivation,
    AccountUpgrade,
    ApiKeysPage,
    CreateOffer,
    CreateSale,
    MyAccount,
    MerchantAccount,
    BuyerAccount,
    ProfilePage,
    Purchase,
    SalesHistory,
    VaultCards,
    VaultCreditCardCreate,
    ProposalTransaction,
    ProposalCreate,
    InstallmentUpgradeForm
} from '../../pages';
import Backdrop from '@material-ui/core/Backdrop';
import BounceLoader from "react-spinners/BounceLoader";
import useClickOutside from '../../hooks/useClickOutside';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import Auth from '../../auth';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CreateIcon from '@material-ui/icons/Create';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import {  SwipeableDrawer, Grid, ListItemIcon, Divider, MenuItem, MenuList, Typography, AppBar, Button, IconButton, Toolbar, useMediaQuery, Card, Badge, Popover } from '@material-ui/core';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useQuery } from '../../hooks';
import firebase from 'firebase';
import NotificationBell from '../NotificationBell';
import { Alert, Container } from 'reactstrap';
import Documentation from '../../pages/Documentation';
import HelmetMetaData from '../../components/HelmetMetaData';
import { useIdleTimer } from 'react-idle-timer';
import { IdleLogoutDialog } from '../../components';
import { NavBar } from '../NavBar';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import URL from '../../services/url';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ReactDOM from 'react-dom';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


const logo = require('../../assets/images/4giveslogo.png');
const avatarDefault = require('../../assets/images/avatar-default.png');
const svgLogo = require('../../assets/images/logo_vector.svg');
const whiteSellerLogo = require('../../assets/images/4giveslogo_white_seller.svg')
const whiteBuyerLogo = require('../../assets/images/4giveslogo_white_buyer.svg')
const logout = require('../../assets/images/logout.png');
const settings = require('../../assets/images/settings.png');
const sell = require('../../assets/images/sell.png');
const buy = require('../../assets/images/purchase.png');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1000,
      color: '#fff',
    },
    grow:{
        flexGrow: 1,
    },
    menuLabel: {
        color: '#FF873A'
    },
    menuLabelWhite: {
        color: 'white'
    },
    appBarLabel: {
        color: '#fff',
        textAlign: 'center',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    paper: {
        background: '#fff',
    },
    user:{
        cursor: 'pointer',
    }
  }),
);

const defaultProps = {
    color: 'secondary' as 'secondary',
    children: <NotificationsIcon style={{fill: 'white', fontSize: '30px'}}/>,
  };
  

const Admin: React.FC = () => {

    
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { user, source, setUser, setSource } = useContext(User.Context);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [profileMenu, setProfileMenu] = useState<boolean>(false);
    const [fcmToken, setFCMToken] = useState<any>(null)
    const location = useLocation();
    const matches = useMediaQuery('(max-width: 1240px)')

    const [notifCount, setCount] = useState(0);
    const [hasIncomingCount, setHasIncomingCount] = useState(0);

    const [incomingSellerProposalsCount, setIncomingSellerProposalsCount] = useState<any>(0)
    const [hasIncomingBuyerProposals, setHasIncomingBuyerProposals] = useState<any>(false)

    const [openIdle, setOpenIdle] = useState(false);

    const [notifMenu, setNotifMenu] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchorMenu, setAnchorMenu] = React.useState<HTMLButtonElement | null>(null);
    const openMenu = Boolean(anchorMenu);
    const idMenu = openMenu ? 'simple-popover-menu' : undefined;

    const handleOnIdle = (event: any) => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        console.log(getRemainingTime())
        console.log(getElapsedTime());
        setOpenIdle(true)
    }

    const handleOnActive = (event: any) => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
        console.log(getElapsedTime());
    }

    const handleOnAction = (event: any) => {
        console.log('user did something', event)
        console.log(getRemainingTime() / 1000 )
        console.log(getElapsedTime()/ 1000);
    }


    const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
        timeout: 300000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    const alertNotifsRef = useRef<any | null>(null) 


    const [visible, setVisible] = useState<any | null>({
        emailNotConfirmed: false,
        sellNotAuthorized: false,
        pendingApplication: false,
        pendingInstallment: false,
        hasNoSource: false,
        rejects: {
            
        },
       
        initVerif: false,
    })

    const [rejectVisible, setRejectVisible] = useState<any | null>({
        rejects: {
            buy_authorize_rejected: null,
            sell_authorize_rejected: null,
            installment_authorize_rejected: null,
            installment_sourceID: null,
        }
        
    })
    const query = useQuery();


    const toggleMenu = useCallback(() => {
        setProfileMenu(!profileMenu);
    }, []);
  
    const userContainer = useRef<HTMLDivElement>(null);

    useClickOutside(userContainer, () => {
        setProfileMenu(false);
    });

    const [hasSubscribed, setHasSubscribed] = useState(false);

    const subscribe = async () => {
        if(user) {
            setHasSubscribed(true)
            const events = new EventSource(URL.EVENTS.APPLICATION+user.id);
            console.log("ADMIN SUBSCRIBING")
            console.log(InstallmentUpgradeForm)
            events.onmessage = (event) => {
              switch (event.type) {
                case 'message':
                  const data = JSON.parse(event.data);
                  console.log(data)
                   /*const updateActivities = [...activities]
                  updateActivities.push(data)
                  console.log(updateActivities);
                  setActivities(updateActivities);
                  */
                 if(data.event === "application" && data.action === "re-render"){
                    User.get((userData) => {
                    
                            User.getSource((cardData) => {
                                setSource(cardData.data.results)
                                setUser(userData);
                                console.log(cardData)
                                setCount(0)
                                
                                    updateAlertMessages(userData, cardData.data.results)
                                    updateRejectMessages()
                                
                                
                                /*
                                if(location.pathname.includes('buyer') && !userData.buyAuthorized){
                                    history.push('/app/seller/vault-cards?no_card=true');
                                }else if(location.pathname.includes('seller') && !userData.sellAuthorized){
                                    history.push('/app/buyer/account-upgrade?send_documents=true')
                                }*/
                                
                                
                                setLoadingOverlay(false)
                            })
                       
                    });
                 }else{
                    events.close()
                 }
                 
                  break;
              }
            };
    
            events.onerror = (e) => {
                console.log(e)
                events.close()
            }
            if(!(location.pathname.includes('/app'))){
                console.log('CLOSING')
                events.close();
            }
            return () => {
                events.close();
              };
        }
        
      };


    useEffect(() => {
        if(fcmToken !== null) {
            Auth.authDevice(
                fcmToken,
                (resp) => {
                    console.log(resp)
                },
                (e) => {
                    console.log(e)
                }
            )
        }
    },[fcmToken])

    useEffect(() => {
        console.log('Fetch User fired');
        setLoadingOverlay(true)
        
        User.get((userData) => {
            
            User.getSource((cardData) => {
                setSource(cardData.data.results)
                setUser(userData);

                
                
                /**
                 * 
                 * if(location.pathname.includes('buyer') && !userData.buyAuthorized){
                    history.push('/app/seller/vault-cards?no_card=true');
                }else if(location.pathname.includes('seller') && !userData.sellAuthorized){
                    history.push('/app/buyer/account-upgrade?send_documents=true')
                }
                 */
                
                setLoadingOverlay(false)
            })
           
        });
        console.log('Fetch success');
        firebaseInit();
    }, []);

    useEffect(() => {
        if(user){
            
            subscribe()
            
            
        }
        
    },[user])

    useEffect(() => {
        if(user && source && !hasSubscribed){
            setCount(0)
            updateAlertMessages(user, source)
            
        }
    },[user, source])

    useEffect(() => {
        if(user && visible && !hasSubscribed){
            updateRejectMessages();
        }
    },[visible.initVerif])

    const updateAlertMessages = (user: any, source: any) => {
        if(user && source){
            let count6 = 0
            Auth.getProposalsSell(
                '',
                (response) => {
                    console.log(response.results)
                    setIncomingSellerProposalsCount(response.count)
                    
                    Auth.getProposalsBuy(
                        '',
                        (resp) => {
                            console.log(response.results)
                            setHasIncomingBuyerProposals(resp.count > 0)
                            if(location.pathname.includes('buyer')) {
                                console.log(resp.count > 0 ? 'HAS PENDING INCOMING' : 'HAS PENDING NONE')
                                setHasIncomingCount(resp.count > 0 ? 1 : 0)
                            }
                            //setLoadingOverlay(false)
                        },
                        (e) => {
                            console.log(e)
                        }
                    );
                    //setLoadingOverlay(false)
                },
                (e) => {
                    console.log(e)
                }
            );
            let [falseInstallment] = source.filter((resp: any) => resp.installmentAuthorized === false)
            console.log('HAS PENDING INSTALLMENT', location.pathname.includes('buyer') ? falseInstallment !== undefined ? (falseInstallment && falseInstallment !== undefined) && !falseInstallment.Cardstatement ? 0 : 1 : 0 : 0)
            let count1 = user.emailConfirmed ? 0 : 1
            let count2 = location.pathname.includes('seller') ? user.sellAuthorized ? 0 : 1 : 0
            let count3 = location.pathname.includes('seller') ? user.sellAuthorized ? 0 : (user.IDBack && user.IDFront) && user.Bankproof ? 1 : 0 : 0
            let count4 = location.pathname.includes('buyer') ? falseInstallment !== undefined ? (falseInstallment && falseInstallment !== undefined) && !falseInstallment.Cardstatement ? 0 : 1 : 0 : 0
            let count5 = location.pathname.includes('buyer') ? source.length > 0 ? 0: 1 : 0
            console.log(count1 + count2 + count3 + count4 + count5+ count6)
            setCount(count1 + count2 + count3 + count4 + count5+ count6)
            console.log(source)
            console.log(falseInstallment)
            setVisible({
                emailNotConfirmed: user.emailConfirmed ? false : true,
                sellNotAuthorized: user.sellAuthorized ? false: true,
                pendingApplication: user.sellAuthorized ? false : (user.IDBack && user.IDFront) && user.Bankproof,
                pendingInstallment: falseInstallment !== undefined ? !falseInstallment.Cardstatement ? false : true : false,
                hasNoSource: source.length > 0 ? false: true,
                initVerif: true,
            })
            
            
        }
    }

    useEffect(() => {
        console.log(notifCount)
    },[notifCount])
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorMenu(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorMenu(null)
    };

    const updateRejectMessages = () => {
        if(user && visible){
            if (user.rejection_notes.length > 0) {
                console.log(user.rejection_notes.length > 0)
                let rejects: any = {};
                let count = 0
                user.rejection_notes.map((reject: any) => {
                    console.log(reject)
                    rejects[reject.type] = reject.note
                    count++
                })
                user.rejection_notes.filter((resp: any) => resp.payment_source !== null).map((reject: any) => {
                    rejects.installment_sourceID = reject.payment_source
                })
                setCount(notifCount + count)
                console.log(rejects)
                setRejectVisible({
                    rejects
                })
                /*
                setVisible({
                    ...visible,
                    rejects
                })*/
            }
        }
    }

    useEffect(() => {
        console.log(visible)
    },[visible])

    const onDismiss = (e: any) => {
        setVisible({
            ...visible,
            [e]: ![e]
        })
    }

    const onDismissString = (e: any ) =>{
        setRejectVisible({
            rejects: {
                ...rejectVisible.rejects,
                [e]: null
            }
            
        })
    }

    const firebaseInit = () => {
        if(!firebase.apps.length){
            firebase.initializeApp({
                apiKey: "AIzaSyCBZOX8tCbLWcclMU87dJZ3kqZqudzJDsk",
                authDomain: "gives-1468d.firebaseapp.com",
                databaseURL: "https://gives-1468d.firebaseio.com",
                projectId: "gives-1468d",
                storageBucket: "gives-1468d.appspot.com",
                messagingSenderId: "738586613773",
                appId: "1:738586613773:web:b47b51a781558ce7f41d44",
                measurementId: "G-F9DHD98882"
            }) ? getToken() : console.log("Firebase failed to initialize");
        }
    }

    const getToken = async () => {
        try{
            const messaging = firebase.messaging();
            let fcmToken = null
            let permissionGranted = false;
            await messaging
                .requestPermission()
                .then(function () {
                    console.log("FCM permission granted");
                    permissionGranted = true;
                })
                    .catch(function (err) {
                    console.log("Unable to get FCM permission. ", err);
                });
            if(permissionGranted){
                console.log("permission granted");
                await messaging.getToken()
                .then(function(token){
                    console.log("Fcm received: ", token);
                    fcmToken = token
                })
                .catch(function(err) {
                    console.log("Error ", err);
                })

                setFCMToken(fcmToken);

            }else{
                console.log("permission not granted");
            }
        } catch(error) {
            console.log('Failed: Firebase User Unauthorized '+error)
        }
    }

    useEffect(() => {
        
        if(alertNotifsRef && user){
            if(alertNotifsRef.current){
                var childrenCount = alertNotifsRef.current.innerHTML;
                let count = (childrenCount.match(/<div/g) || []).length;
                console.log(count)
            }
            
        }
        
    },[alertNotifsRef, user])
  



    if(user){
        return (
            <>
           {location !== undefined && (
            <HelmetMetaData
                title={
                location.pathname === "/app/buyer/account-upgrade" ? "Merchant Upgrade" : ""+
                location.pathname === "/app/seller/account-upgrade" ? "Merchant Upgrade" : ""+
                location.pathname === "/app/buyer/vault-cards" ? "Payment Sources" : ""+
                location.pathname === "/app/seller/vault-cards" ? "Payment Sources" : ""+
                location.pathname === "/app/seller/create-sale" ? "Sell" : ""+
                location.pathname === "/app/buyer/create-offer" ? "Buy" : ""+
                location.pathname === "/verify" ? "Email Verification" : ""+
                location.pathname === "/app/my-account" ? "Dashboard" : ""+
                location.pathname === "/app/buyer/my-account" ? "Buyer Dashboard" : ""+
                location.pathname === "/app/seller/my-account" ? "Seller Dashboard" : ""+
                location.pathname === "/app/seller/api-keys" ? "API Keys" : ""+
                location.pathname === "/app/buyer/documentation" ? "Documnetation" : ""+
                location.pathname === "/app/seller/documentation" ? "Documnetation" : ""+
                location.pathname === "/app/buyer/profile-page" ? "Profile Page" : ""+
                location.pathname === "/app/seller/profile-page" ? "Profile Page" : ""+
                location.pathname === "/login" ? "Login" : ""+
                location.pathname === "/login?page=registration-page" ? "Sign Up" : ""+
                location.pathname === "/web-pay" ? "Payment" : ""+
                location.pathname === "/password-reset" ? "Password Reset" : ""+
                location.pathname.includes('transaction') ? "Transaction Details" : ""+
                location.pathname.includes('proposal') ? "Proposal Details" : ""+
                
                " | 4Gives"
                }
            />
            )}
            <div className="page-wrapper">
                {loadingOverlay ?
                    <Backdrop className={classes.backdrop} open={loadingOverlay} >
                        <BounceLoader color={"#FF873A"} />
                    </Backdrop>
                : null}
                
               
                
                    
                
                <AppBar position="fixed" style={{ background: '#FF873A', boxShadow: 'none'}}>
                    <Toolbar>
                        {matches &&(
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={2}>
                                    <IconButton edge="start" onClick={() => setOpenDrawer(true)} className={classes.menuButton} color="inherit" aria-label="menu">
                                        <MenuIcon style={{fill: '#fff'}}/>
                                    </IconButton>
                                </Grid>
                                <Grid item container justify="center" xs={8}>
                                    <img src={location.pathname.includes('buyer') ? whiteBuyerLogo : location.pathname.includes('seller') ? whiteSellerLogo : svgLogo} style={{width: '150px', height: 'auto', cursor: 'pointer', padding: 10, textAlign: 'center'}} onClick={() => {window.location.href = window.location.origin + (location.pathname.includes("buyer") ? '/app/buyer/my-account' : location.pathname.includes("seller") ? '/app/seller/my-account' : '/')}}className="logo"/>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton style={{float: 'right'}} onClick={() => setNotifMenu(true)}>
                                        <Badge badgeContent={notifCount + hasIncomingCount} {...defaultProps}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        
                        )}
                        
                        {!matches && (
                            <>
                            <img src={location.pathname.includes('buyer') ? whiteBuyerLogo : location.pathname.includes('seller') ? whiteSellerLogo : svgLogo} style={{width: '150px', height: 'auto', cursor: 'pointer', padding: 10, textAlign: 'center'}} onClick={() => {window.location.href = window.location.origin + (location.pathname.includes("buyer") ? '/app/buyer/my-account' : location.pathname.includes("seller") ? '/app/seller/my-account' : '/')}} className="logo"/>
                            <div>
                                <NavBar />
                            </div>
                            </>
                        )}
                        
                        <div className={classes.grow} />
                        {
                            /**<NotificationBell user={user} source={source}/> */
                        }
                        
                        {user && !matches && (
                            <div style={{padding: '0px 10px'}}>
                            <IconButton aria-describedby={id} onClick={handleClick}>
                                <Badge  badgeContent={notifCount + hasIncomingCount} {...defaultProps}/>
                            </IconButton>

                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <div ref={alertNotifsRef} style={{padding: '20px', overflow: 'auto', height: '100%', width:'32vw'}} className="alert-notif-container">
                                    <Typography style={{textAlign: 'center', fontWeight: 'bold'}}>Notifications</Typography>
                                    {location.pathname.includes('buyer') && (
                                    <Alert color="warning" className="alert-notif"  style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.pendingInstallment} >
                                        <div onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards`); handleClose()}}><p style={{fontWeight: 'bold'}}>Pending Installment Application:</p> You have a pending application to use for installment. Please wait for 48 hours.</div>
                                    </Alert>
                                    )}
                                    {location.pathname.includes('seller') && (
                                    <Alert color="warning" className="alert-notif"   style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.pendingApplication}>
                                        <div onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`); handleClose()}}><p style={{fontWeight: 'bold'}}>Pending Merchant Application:</p> You have a pending application to be a seller. Please wait for 48 hours.</div>
                                    </Alert>
                                    )}
                                    <Alert color="warning" className="alert-notif"   style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',  marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.emailNotConfirmed} >
                                        <div onClick={() => {history.push(`/verify`); handleClose()}}> <p style={{fontWeight: 'bold'}}>Email Not Verified:</p> Please verify your email.</div>
                                    </Alert>
                                    {location.pathname.includes('seller') && (
                                    <Alert color="warning" className="alert-notif"   style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={!user.IDFront && visible.sellNotAuthorized} >
                                        <div onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`); handleClose()}}><p style={{fontWeight: 'bold'}}>Upgrade Your Account:</p>  Please upgrade your account to be a seller.</div>
                                    </Alert>
                                    )}
                                    {location.pathname.includes('buyer') && (
                                    <Alert color="warning" className="alert-notif"   style={{ cursor: 'pointer',borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.hasNoSource} >
                                        <div onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards`); handleClose()}}><p style={{fontWeight: 'bold'}}>No Card Found:</p>  Please input your card to transact to 4Gives. </div>
                                    </Alert>
                                    )}
                                    {location.pathname.includes('buyer') && (
                                        <Alert color="warning" className="alert-notif"  style={{ cursor: 'pointer',borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={hasIncomingBuyerProposals} >
                                            <div onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/create-offer`); handleClose()}}><p style={{fontWeight: 'bold'}}>Note:</p>  Pending proposals will expire in 48 hours</div>
                                        </Alert>
                                    )}
                                    

                                    {rejectVisible.rejects.buy_authorize_rejected && (
                                        <Alert color="warning" className="alert-notif"   style={{borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',  marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={rejectVisible.rejects.buy_authorize_rejected !== '' && rejectVisible.rejects.buy_authorize_rejected} >
                                            <p style={{fontWeight: 'bold'}}>Buyer Authorization Rejection Notice:</p> {rejectVisible.rejects.buy_authorize_rejected}
                                        </Alert>
                                    )}
                                    {rejectVisible.rejects.sell_authorize_rejected && (
                                        <Alert color="warning" className="alert-notif"   style={{borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',marginBottom: '5px', cursor:'pointer', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}}  isOpen={rejectVisible.rejects.sell_authorize_rejected !== '' && rejectVisible.rejects.sell_authorize_rejected} >
                                            <div onClick={()=> {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade?is-rejected=true`); handleClose()}}><p style={{fontWeight: 'bold'}}>Seller Authorization Rejection Notice:</p> {rejectVisible.rejects.sell_authorize_rejected}</div>
                                        </Alert>
                                    )}
                                    {rejectVisible.rejects.installment_authorize_rejected && (
                                        <Alert color="warning" className="alert-notif"   style={{borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', cursor:'pointer', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}}  isOpen={rejectVisible.rejects.installment_authorize_rejected !== '' && rejectVisible.rejects.installment_authorize_rejected} >
                                            <div onClick={()=> {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards?rejected-id=${rejectVisible.rejects.installment_sourceID}`); handleClose()}}><p style={{fontWeight: 'bold'}}>Installment Authorization Rejection Notice:</p> {rejectVisible.rejects.installment_authorize_rejected}</div>
                                        </Alert>
                                    )}
                                </div>
                                
                            </Popover>
                            </div>
                        )}

                        {/*user && !matches && (
                            <div className={classes.user} ref={userContainer}>
                                
                                <Typography className={classes.appBarLabel} variant="body1" onClick={() => toggleMenu()}>
                                    <img className="profilePictureDesktop" src={user.profile_picture ? `${user.profile_picture}` :`${avatarDefault}`} />
                                
                                   {//user.username +"(DEV)    " +user.email
                                   }
                                   {user.email}
                                </Typography>
                                <div className={`user-menu-container ${profileMenu && 'user-menu-container-open'}`}>
                                    <ul className="user_menu">
                                        
                                        <li onClick={()=>{history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/profile-page`)}}>
                                            <img src={settings} />
                                            <span>Edit Profile</span>
                                        </li>
                                        {/** 
                                        <li onClick={()=>{history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/documentation`)}}>
                                            <img src={settings} />
                                            <span>Docu</span>
                                        </li>
                                        {location.pathname.includes('seller') ?(
                                    
                                        <li onClick={() => history.push(location.pathname.includes('seller') && (user && !user.buyAuthorized) ? '/app/seller/vault-cards' :  '/app/buyer/my-account')}>
                                        
                                            <img src={buy} />
                                            <span>Buyer Mode</span>
                                            
                                        </li>
                                               
                                        ): location.pathname.includes('buyer') ? (
                                        
                                            <li onClick={() => history.push(location.pathname.includes('buyer') && (user && !user.sellAuthorized) ? 
                                                
                                                '/app/buyer/account-upgrade'
                                                : 
                                                '/app/seller/my-account' 
                                            )}>
                                                <img src={sell} />
                                                <span>Seller Mode</span>
                                                
                                            </li>
                                        ): null}
                                        <li onClick={() => {
                                            Auth.logout();
                                            console.log('loging out');
                                            setUser(null)
                                            window.location.href = window.location.origin+`/login${location.pathname.includes('buyer') ? '?applying_for=buyer' : location.pathname.includes('seller') ? '?applying_for=merchant' : ''}`
                                        } }>
                                            <img src={logout} />
                                            <span>Logout</span>
                                        </li>
    
                                        </ul>
                                </div>
                            </div>
                        )
    
                        */}
                        
                        {user && !matches && (
                            <div className={classes.user}>
                                <Typography className={classes.appBarLabel} variant="body1" onClick={handleClickProfile}>
                                        <img className="profilePictureDesktop" src={user.profile_picture ? `${user.profile_picture}` :`${avatarDefault}`} />
                                    
                                    {//user.username +"(DEV)    " +user.email
                                    }
                                    {user.email}
                                </Typography>
                                <Popover
                                    id={idMenu}
                                    open={openMenu}
                                    anchorEl={anchorMenu}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                >
                                    <div style={{ width: '322px'}}>
                                        <MenuList>
                                            
                                            {location.pathname.includes('seller') ?(
                                                <MenuItem onClick={() => {history.push(location.pathname.includes('seller') && (user && !user.buyAuthorized) ? '/app/seller/vault-cards' :  '/app/buyer/my-account'); handleClose()}}>
                                                    <ListItemIcon style={{minWidth: '50px'}}>
                                                        <div className="menuIcon">
                                                            <ShoppingCartIcon fontSize="default" style={{fill:'#FF873A', margin: 'auto'}}/>
                                                        </div>
                                                    </ListItemIcon>
                                                    <Typography className={classes.menuLabel} variant="body1">Buyer Mode</Typography>
                                                </MenuItem>
                                            ): location.pathname.includes('buyer') ? (
                                                <MenuItem onClick={() => {history.push(location.pathname.includes('buyer') && (user && !user.sellAuthorized) ? 
                                                
                                                    '/app/buyer/account-upgrade'
                                                    : 
                                                    '/app/seller/my-account' 
                                                ); handleClose()}}>
                                                    <ListItemIcon style={{minWidth: '50px'}}>
                                                        <div className="menuIcon">
                                                            <MonetizationOnIcon fontSize="default" style={{fill:'#FF873A', margin: 'auto'}}/>
                                                        </div>
                                                    </ListItemIcon>
                                                    <Typography className={classes.menuLabel} variant="body1">Seller Mode</Typography>
                                                </MenuItem>
                                            ):null}
                                            <Divider />
                                            <MenuItem onClick={()=>{history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/profile-page`); handleClose()}}>
                                                <ListItemIcon style={{minWidth: '50px'}}>
                                                    <div className="menuIcon">
                                                        <AccountBoxIcon fontSize="default" style={{fill:'#FF873A', margin: 'auto'}}/>
                                                    </div>
                                                </ListItemIcon>
                                                <Typography className={classes.menuLabel} variant="body1">Edit Profile</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                Auth.logout();
                                                console.log('loging out');
                                                setUser(null)
                                                window.location.href = window.location.origin+`/login${location.pathname.includes('buyer') ? '?applying_for=buyer' : location.pathname.includes('seller') ? '?applying_for=merchant' : ''}`;
                                            } }>
                                                <ListItemIcon style={{minWidth: '50px'}}>
                                                    <div className="menuIcon">
                                                        <ExitToAppIcon fontSize="default" style={{fill:'#FF873A', margin: 'auto'}}/>
                                                    </div>
                                                </ListItemIcon>
                                                <Typography className={classes.menuLabel} variant="body1">Logout</Typography>
                                            </MenuItem>
                                        </MenuList>
                                    </div>
                                </Popover>
                            </div>
                        )
    
                        }
                        
                    </Toolbar>
                </AppBar>
                {/***
                 * <ActionBar />
                 */}

                <SwipeableDrawer 
                    anchor={'right'} 
                    classes={{paper: classes.paper}} 
                    disableSwipeToOpen={true}
                    open={notifMenu} onOpen={() => setNotifMenu(true)} onClose={() => {setNotifMenu(false)}}
                >
                    <div className="drawernav">
                        <div style={{padding: '0px', overflow: 'auto', height: '100%'}}>
                            <Typography style={{textAlign: 'center', fontWeight: 'bold'}}>Notifications</Typography>
                           
                                    {location.pathname.includes('buyer') && (
                                    <Alert color="warning" className="alert-notif"  style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.pendingInstallment} >
                                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards`)}><p style={{fontWeight: 'bold'}}>Pending Installment Application:</p> You have a pending application to use for installment. Please wait for 48 hours.</div>
                                    </Alert>
                                    )}
                                    {location.pathname.includes('seller') && (
                                    <Alert color="warning" className="alert-notif"   style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.pendingApplication}>
                                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`)}><p style={{fontWeight: 'bold'}}>Pending Merchant Application:</p> You have a pending application to be a seller. Please wait for 48 hours.</div>
                                    </Alert>
                                    )}
                                    <Alert color="warning" className="alert-notif"   style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',  marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.emailNotConfirmed} >
                                        <div onClick={() => history.push(`/verify`)}> <p style={{fontWeight: 'bold'}}>Email Not Verified:</p> Please verify your email.</div>
                                    </Alert>
                                    {location.pathname.includes('seller') && (
                                    <Alert color="warning" className="alert-notif"   style={{cursor: 'pointer', borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={!user.IDFront && visible.sellNotAuthorized} >
                                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`)}><p style={{fontWeight: 'bold'}}>Upgrade Your Account:</p>  Please upgrade your account to be a seller.</div>
                                    </Alert>
                                    )}
                                    {location.pathname.includes('buyer') && (
                                    <Alert color="warning" className="alert-notif"   style={{ cursor: 'pointer',borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.hasNoSource} >
                                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards`)}><p style={{fontWeight: 'bold'}}>No Card Found:</p>  Please input your card to transact to 4Gives. </div>
                                    </Alert>
                                    )}
                                    {location.pathname.includes('buyer') && (
                                        <Alert color="warning" className="alert-notif"  style={{ cursor: 'pointer',borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={hasIncomingBuyerProposals} >
                                            <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/create-offer`)}><p style={{fontWeight: 'bold'}}>Note:</p>  Pending proposals will expire in 48 hours</div>
                                        </Alert>
                                    )}
                                    

                                    {rejectVisible.rejects.buy_authorize_rejected && (
                                        <Alert color="warning" className="alert-notif"   style={{borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',  marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={rejectVisible.rejects.buy_authorize_rejected !== '' && rejectVisible.rejects.buy_authorize_rejected} >
                                            <p style={{fontWeight: 'bold'}}>Buyer Authorization Rejection Notice:</p> {rejectVisible.rejects.buy_authorize_rejected}
                                        </Alert>
                                    )}
                                    {rejectVisible.rejects.sell_authorize_rejected && (
                                        <Alert color="warning" className="alert-notif"   style={{borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px',marginBottom: '5px', cursor:'pointer', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}}  isOpen={rejectVisible.rejects.sell_authorize_rejected !== '' && rejectVisible.rejects.sell_authorize_rejected} >
                                            <div onClick={()=> history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade?is-rejected=true`)}><p style={{fontWeight: 'bold'}}>Seller Authorization Rejection Notice:</p> {rejectVisible.rejects.sell_authorize_rejected}</div>
                                        </Alert>
                                    )}
                                    {rejectVisible.rejects.installment_authorize_rejected && (
                                        <Alert color="warning" className="alert-notif"   style={{borderTop: '1px solid #ececec', borderBottom: '1px solid #ececec', backgroundColor: 'white', borderLeft: '0px', borderRight:'0px', marginBottom: '5px', cursor:'pointer', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}}  isOpen={rejectVisible.rejects.installment_authorize_rejected !== '' && rejectVisible.rejects.installment_authorize_rejected} >
                                            <div onClick={()=> history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards?rejected-id=${rejectVisible.rejects.installment_sourceID}`)}><p style={{fontWeight: 'bold'}}>Installment Authorization Rejection Notice:</p> {rejectVisible.rejects.installment_authorize_rejected}</div>
                                        </Alert>
                                    )}
                        </div>
                    </div>
                </SwipeableDrawer>
                
                
                <SwipeableDrawer 
                    anchor={'left'} 
                    classes={{paper: classes.paper}} 
                    disableSwipeToOpen={true}
                    open={openDrawer} onOpen={() => setOpenDrawer(true)} onClose={() => {setOpenDrawer(false)}}>
                    <div className="drawernav">
                        <Grid container justify="center" spacing={2}>
                            { user && 
                                <Grid container spacing={1}>
                                    <Grid xs={12} item justify="center" style={{ textAlign: 'center' }}>
                                        <div className='profilePicture' 
                                            style={{ backgroundImage: user.profile_picture ? `url(${user.profile_picture})` :`url(${avatarDefault})`, 
                                            backgroundColor: '#f47121'}}
                                        />
                                    </Grid>
                                    <Grid xs={12} item justify="center" style={{ textAlign: 'center' }}>
                                        <Grid item>
                                            <span className="profileSummary">{user.email}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            
                            <Grid xs={12} item style={{ textAlign: 'center' }}>
                                <img src={logo} style={{maxWidth: '150px', margin: 'auto', paddingTop: '10px'}} />
                            </Grid>
                            <Divider />
                            <Grid xs={12}  item>
                                <MenuList>
                                    <MenuItem onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/my-account`); setOpenDrawer(false)}}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <HomeIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Home</Typography>
                                    </MenuItem>
                                    {location.pathname.includes('buyer') && (
                                        <MenuItem onClick={() => {history.push(`${user && (user.buyAuthorized && source.length > 0) ? (user.emailConfirmed) ? '/app/buyer/create-offer' : '/verify?require_code=true' : '/app/buyer/vault-cards?no_card=true'}`); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <ListIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Transactions</Typography>
                                        </MenuItem>
                                    )}
                                    {location.pathname.includes('seller') && (
                                        <MenuItem onClick={() => {history.push(`${user && user.sellAuthorized ? (user.emailConfirmed) ? '/app/seller/create-sale' : '/verify?require_code=true' : '/app/seller/account-upgrade?send_documents=true'}`); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <ListIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Transactions</Typography>
                                        </MenuItem>
                                    )}
                                    {location.pathname.includes('buyer') && (
                                        <MenuItem onClick={() => {history.push(`/app/buyer/vault-cards`); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <CreditCardIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Source</Typography>
                                        </MenuItem>
                                    )}
                                    {user && (user.sellAuthorized && location.pathname.includes('seller')) && (
                                        <MenuItem onClick={() => {history.push("/app/seller/api-keys"); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <SettingsEthernetIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">API Keys</Typography>
                                        </MenuItem>
                                    )}
                                    {/*location.pathname.includes('seller') && (
                                        <MenuItem onClick={() => {history.push(`/app/seller/account-upgrade`); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <ArrowUpwardIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Upgrade Account</Typography>
                                        </MenuItem>
                                    )*/}
                                    
                                    <Divider />
                                    <MenuItem onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/profile-page`); setOpenDrawer(false)}}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <CreateIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Edit Profile</Typography>
                                    </MenuItem>
                                    {/*user && (user.sellAuthorized && location.pathname.includes('seller')) && (
                                        <MenuItem onClick={() => {history.push("/app/seller/api-keys"); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <SettingsEthernetIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">API Keys</Typography>
                                        </MenuItem>
                                    )*/}
                                    {location.pathname.includes('seller') ? (
                                        <MenuItem onClick={() => {history.push(location.pathname.includes('seller') && (user && user.sellAuthorized) ? '/app/seller/vault-cards' :  '/app/buyer/my-account'); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <ShoppingCartIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Buyer Mode</Typography>
                                        </MenuItem>
                                    ): (
                                        <MenuItem onClick={() => {history.push(location.pathname.includes('buyer') && (user && user.buyAuthorized) ? 
                            
                                        '/app/buyer/account-upgrade'
                                        : 
                                        '/app/seller/my-account' ); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <MonetizationOnIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Seller Mode</Typography>
                                        </MenuItem>
                                    )}
                                    {/*location.pathname.includes('seller') && (
                                        <MenuItem onClick={() => {history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/documentation`); setOpenDrawer(false)}}>
                                            <ListItemIcon style={{minWidth: '28px'}}>
                                                <div className="menuIcon">
                                                    <AssignmentIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                                </div>
                                            </ListItemIcon>
                                            <Typography className={classes.menuLabel} variant="body1">Documentation</Typography>
                                        </MenuItem>
                                    )*/}

                                    <MenuItem onClick={() => {
                                        Auth.logout();
                                        console.log('loging out');
                                        window.location.href = window.location.origin+`/login${location.pathname.includes('buyer') ? '?applying_for=buyer' : location.pathname.includes('seller') ? '?applying_for=merchant' :  ''}`
                                    }}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <ExitToAppIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Logout</Typography>
                                    </MenuItem>
                                    
                                </MenuList>
                                <span style={{ fontWeight: 'bold', fontSize: '16px', color: "#FF873A" }}></span>
                            </Grid>
                        </Grid>
                    </div>
                </SwipeableDrawer>
                <div className="container-notifs" style={{display: 'none'}}>
                <Container style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', padding: '5px 20px' }}>
                   
                    <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: '225px',borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.pendingInstallment} toggle={() => onDismiss("pendingInstallment")}>
                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`)}><p style={{fontWeight: 'bold'}}>Pending Installment Application:</p> You have a pending application to use for installment. Please wait for 48 hours.</div>
                    </Alert>
                    <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: '225px',borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.pendingApplication} toggle={() => onDismiss("pendingApplication")}>
                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`)}><p style={{fontWeight: 'bold'}}>Pending Merchant Application:</p> You have a pending application to be a seller. Please wait for 48 hours.</div>
                    </Alert>
                    <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: '225px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.emailNotConfirmed} toggle={() => onDismiss("emailNotConfirmed")}>
                        <div onClick={() => history.push(`/verify`)}> <p style={{fontWeight: 'bold'}}>Email Not Verified:</p> Please verify your email.</div>
                    </Alert>
                    {location.pathname.includes('seller') && (
                    <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: '225px',borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={!user.IDFront && visible.sellNotAuthorized} toggle={() => onDismiss("sellNotAuthorized")}>
                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade`)}><p style={{fontWeight: 'bold'}}>Upgrade Your Account:</p>  Please upgrade your account to be a seller.</div>
                    </Alert>
                    )}
                    {location.pathname.includes('buyer') && (
                    <Alert color="warning"  style={{ cursor: 'pointer',border: '2px solid #FF873A',width: '225px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={visible.hasNoSource} toggle={() => onDismiss("hasNoSource")}>
                        <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards`)}><p style={{fontWeight: 'bold'}}>No Card Found:</p>  Please input your card to transact to 4Gives. </div>
                    </Alert>
                    )}
                    {location.pathname.includes('buyer') && (
                        <Alert color="warning"  style={{ cursor: 'pointer',border: '2px solid #FF873A',width: '225px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={hasIncomingBuyerProposals} toggle={() => setHasIncomingBuyerProposals(!hasIncomingBuyerProposals)}>
                            <div onClick={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/create-offer`)}><p style={{fontWeight: 'bold'}}>Note:</p>  Pending proposals will expire in 48 hours</div>
                        </Alert>
                    )}
                    

                    {rejectVisible.rejects.buy_authorize_rejected && (
                        <Alert color="warning" style={{border: '2px solid #FF873A',width: '225px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}} isOpen={rejectVisible.rejects.buy_authorize_rejected !== '' && rejectVisible.rejects.buy_authorize_rejected} toggle={() => onDismissString("buy_authorize_rejected")}>
                            <p style={{fontWeight: 'bold'}}>Buyer Authorization Rejection Notice:</p> {rejectVisible.rejects.buy_authorize_rejected}
                        </Alert>
                    )}
                    {rejectVisible.rejects.sell_authorize_rejected && (
                        <Alert color="warning" style={{border: '2px solid #FF873A', width: '225px',borderRadius: '20px', marginBottom: '5px', cursor:'pointer', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}}  isOpen={rejectVisible.rejects.sell_authorize_rejected !== '' && rejectVisible.rejects.sell_authorize_rejected} toggle={() => onDismissString("sell_authorize_rejected")}>
                            <div onClick={()=> history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/account-upgrade?is-rejected=true`)}><p style={{fontWeight: 'bold'}}>Seller Authorization Rejection Notice:</p> {rejectVisible.rejects.sell_authorize_rejected}</div>
                        </Alert>
                    )}
                    {rejectVisible.rejects.installment_authorize_rejected && (
                        <Alert color="warning" style={{border: '2px solid #FF873A', width: '225px',borderRadius: '20px', marginBottom: '5px', cursor:'pointer', paddingRight: '20px', margin: '6px', flex: 4, flexGrow: 1}}  isOpen={rejectVisible.rejects.installment_authorize_rejected !== '' && rejectVisible.rejects.installment_authorize_rejected} toggle={() => onDismissString("installment_authorize_rejected")}>
                            <div onClick={()=> history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller':''}/vault-cards?rejected-id=${rejectVisible.rejects.installment_sourceID}`)}><p style={{fontWeight: 'bold'}}>Installment Authorization Rejection Notice:</p> {rejectVisible.rejects.installment_authorize_rejected}</div>
                        </Alert>
                    )}
                   
                </Container>
                </div>
                <Switch>
                    <Route
                        exact
                        path="/app/buyer"
                        render={() => 
                            
                            <Redirect to="/app/buyer/my-account" /> 
                            
                        }
                    />
                    <Route
                        exact
                        path="/app/seller"
                        render={() => 
                            
                            <Redirect to="/app/seller/my-account" /> 
                            
                        }
                    />
                    <Route
                        exact
                        path="/app"
                        render={() => 
                            {
                                if(user && user.sellAuthorized){
                                    return(
                                        <Redirect to="/app/seller/my-account" /> 
                                    )
                                }else if(user && user.buyAuthorized){
                                    return(
                                        <Redirect to="/app/buyer/my-account" /> 
                                    )
                                }else{
                                    return(
                                        <Redirect to="/" /> 
                                    )
                                }
                            
                            }
                        }
                    />
                    <Route exact path="/app/my-account"  render={(props: any) => <MyAccount {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/buyer/my-account" exact render={(props: any) => <BuyerAccount {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/seller/my-account" exact render={(props: any) => <MerchantAccount {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/transact-history" exact render={(props: any) => <MyAccount {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/account-activation" exact render={(props: any) => <AccountActivation {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/account-upgrade" exact render={(props: any) => <AccountUpgrade {...props} user={user} setUser={setUser} setLoadingOverlay={setLoadingOverlay}/>} />
                    {/*** 
                    <Route path="/app/buyer/account-upgrade" exact render={(props: any) => <AccountUpgrade {...props} user={user} setUser={setUser} setLoadingOverlay={setLoadingOverlay}/>} />
                    */}
                    <Route path="/app/:type/account-upgrade" exact render={(props: any) => <AccountUpgrade {...props} user={user} setUser={setUser} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/vault-creditcard" exact render={(props: any) => <VaultCards {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/create-sale" exact render={(props: any) => <CreateSale {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/create-offer" exact render={(props: any) => <CreateOffer {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/seller/create-sale" exact render={(props: any) => <CreateSale {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/seller/:type/:id" exact render={(props: any) => <ProposalTransaction {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/buyer/create-offer"  exact render={(props: any) => <CreateOffer {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/buyer/:type/:id" exact render={(props: any) => <ProposalTransaction {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/buyer/vault-cards" exact render={(props: any) => <VaultCards {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/seller/vault-cards" exact render={(props: any) => <VaultCards {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/buyer/profile-page" exact render={(props: any) => <ProfilePage {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/seller/profile-page" exact render={(props: any) => <ProfilePage {...props} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/api-keys" exact render={(props: any) => <ApiKeysPage {...props}  user={user} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/seller/api-keys" exact render={(props: any) => <ApiKeysPage {...props}  user={user} setLoadingOverlay={setLoadingOverlay}/>} />
                    <Route path="/app/documentation" exact render={(props: any) => <Documentation {...props} user={user} setLoadingOverlay={setLoadingOverlay} />} />
                    <Route path="/app/buyer/documentation" exact render={(props: any) => <Documentation {...props} user={user} setLoadingOverlay={setLoadingOverlay} />} />
                    <Route path="/app/seller/documentation" exact render={(props: any) => <Documentation {...props} user={user} setLoadingOverlay={setLoadingOverlay} />} />
                    <Route exact path="/app/:category/upgrade-card"  render={(props: any) => <InstallmentUpgradeForm {...props} setLoadingOverlay={setLoadingOverlay} />} />
                    <Route path="/app/:type/proposal-create" exact render={(props: any) => <ProposalCreate {...props} user={user} setLoadingOverlay={setLoadingOverlay} />} />
                    { /*routes.private.map((page, index) =>{
                        
                        console.log(page.component);
                        
                        return(
                        <Route path={page.url} component={page.component} key={`route_${index}`}/>
                    )
                        })*/}
                </Switch>
                <IdleLogoutDialog openDialog={openIdle} />
            </div>
            </>
        );
    }
    else{
        return(
            <div></div>
        )
    }
    
}

export default Admin;