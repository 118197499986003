import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, Grid, makeStyles, MenuItem, Select, Container } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/Link';
import DoneIcon from '@material-ui/icons/Done';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './style.scss';

const success = require('../../assets/images/4gives_additional icons-03.png');
const close = require('../../assets/images/close.png');

export const SuccessDialog: React.FC<{
    openDialog: boolean,
    setOpenDialog: (val: boolean) => void,
    onClose?: () => void,
    dialogMessage: string | null,
    link?: any | null,
    reference?: any | null,
}> = ({openDialog, setOpenDialog, dialogMessage, link, reference, onClose}) => {
    const [copied, setCopied] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false)
 
    return (
        <Dialog 
            open={openDialog} 
            fullWidth={true} 
            maxWidth={link !== undefined ? 'sm' : 'xs'} 
            
            PaperProps={{
                style: {
                    backgroundColor: '#f7723', 
                    border: "1px solid #FF873A",
                    borderRadius: '8px'
                },
            }}
        >
            <DialogTitle style={{color: 'green',backgroundColor: '#FF873A' ,textAlign: "center"}}>
                <img
                    src={close}
                    style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                    onClick={() =>{setOpenDialog(false); 
                        if(onClose !== undefined) {
                            onClose()
                        }
                        setCopied(false)
                    }} 
                />
                {/*** 
                <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                    <Grid item>
                        <img src={success} style={{width: '50px', height: '50px'}}/>
                    </Grid>
                    
                </Grid>
                */}
                <div style={{paddingTop: 10, color: "white", fontWeight: 'bold'}}>
                    Success
                </div>
                    
                
            </DialogTitle>
            <Container className="dialog-container" style={{backgroundColor: '#f7723'}}>
                    <div style={{padding: 20, textAlign: 'center', marginTop: '-30px', backgroundColor: '#f7723'}}>
                        
                        <Grid container item direction="row" alignContent="center" justify="center" style={{backgroundColor: '#f7723'}}>
                            <Grid item style={{textAlign: 'center', padding: '15px', color: 'white'}}>
                                {dialogMessage}
                            </Grid>
                        </Grid>
                        {(link && reference) &&(
                            <>
                            <div style={{textAlign: 'center', padding: '15px', color: 'white'}}>
                                Please copy and send this link to a user
                            </div>
                            <div className="link-copy">
                                <CopyToClipboard text={link} onCopy={() => setLinkCopied(true)}>
                                    <Grid container item direction="row" alignContent="center" justify="center">
                                        <Grid item>
                                            <div className="text" style={{color: 'white'}} >
                                                {link}{!linkCopied ? (<LinkIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CopyToClipboard>
                            </div>
                            <div style={{textAlign: 'center', padding: '15px', color: 'white'}}>
                                Or you can just send the reference code to a user
                            </div>
                            <div className="link-copy">
                                <CopyToClipboard text={reference} onCopy={() => setCopied(true)}>
                                    <Grid container item direction="row" alignContent="center" justify="center">
                                        <Grid item>
                                            <div className="text" style={{color: 'white'}} >
                                                {reference}{!copied ? (<LinkIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CopyToClipboard>
                            </div>
                            </>
                        )}
                    </div>
                
            </Container>
        </Dialog>
    );
}
    