import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {  Row, Col} from 'react-grid-system'
import { FileUpload, TextCardExpiryInput, TextInput, TextLoginCardExpiryInput, TextLoginInput} from '../../components/Input'
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Button, ErrorDialog, SuccessDialog } from '../../components';
import { useForm, useFormFiles, useQuery } from '../../hooks';
import { User } from '../../models';
import Auth from '../../auth';
import './style.scss'
import {  Button as MuiButton, Divider, makeStyles, Dialog, DialogTitle, Grid, CardContent, Card, TableHead, Table, TableBody, TableCell, TableContainer, TableRow, MenuItem, Select, IconButton, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import { toBase64 } from '../../functions';
import {Alert, Container} from 'reactstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPixel from 'react-facebook-pixel';

const mascot = require('../../assets/images/4gives_card_person.png')
const close = require('../../assets/images/close.png');

const useStyles = makeStyles({
    root: {
      borderColor: '#FF873A',
    },
   
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
      paddingTop: 15, 
      alignSelf: 'center', 
      justifySelf: 'center', 
      color: 'white',
      backgroundColor: '#FF873A'
    },
   
  });

export const VaultCards: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
    setTest?: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const {user, source, setSource} = useContext<any>(User.Context)
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)
    const cardStatementRef = useRef<any | null>(null) 
    const query = useQuery();
    /*const { values , handleChange } = useForm({
        cardNumber: '',
        expMonth: '',
        expYear: '',
        CVC: '',
        cardCutoff: '',
        cardLimit: ''
    });
    */

    const openCardStatement = () => {
        console.log('openID')
        cardStatementRef.current.click()
    }

    const [values, setValues] = useState({
        cardNumber: '',
        expMonth: '',
        expYear: '',
        CVC: '',
        cardCutoff: '',
        cardLimit: ''
    })


    const [activateSource, setActivateSource] = useState<any | null>(null)
    const [editCardstatement, setEditCardstatement] = useState<any | null>(null);
    const [editCardLimit, setEditCardLimit] = useState<any | null>(null);

    const [agreement, setAgreement] = useState(false);

    const [imageChanged, setImageChanged] = useState(false);

    const [delCard, setDelCard] = useState<any | null>(null)
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const history = useHistory();
    const {files, handleFiles} = useFormFiles({
        Cardstatement: '',
    });
    
    const [imgFiles, setImgFiles] = useState({
        Cardstatement: ''
    })

    const [previewCardStatement, setPreviewCardStatement] = useState('')

    const [errorCardNumber, setErrorCardNumber] = useState('')
    
    useEffect(() => {
        if(source){
            console.log(source)
        }
    },[source])

    useEffect(() => {
        User.getSource(
            (resp: any) => {
                setSource(resp.data.results)
            }
        )
        if(query.has('add-card') && query.get('add-card')){
            setOpen(true)
        }
        if(query.has('rejected-id')){
            Auth.getSource(
                query.get('rejected-id'),
                (resp) => {
                    console.log(resp)
                    setActivateSource(resp)
                },
                (e) => {
                    console.log(e)
                }
            )
        }
    },[])

    useEffect(() => {
        if(activateSource){
            console.log(activateSource)
            
            setEditCardstatement(activateSource.Cardstatement)
            setEditCardLimit(activateSource.cardLimit ? activateSource.cardLimit : 0)
        }
    },[activateSource])

   

    const handleFileChange = async (e: any) => {
        console.log(e)
        console.log(e.target.name)
        console.log(toBase64(e.target.files[0]))
        setImgFiles({
            ...imgFiles,
            [e.target.name]: await toBase64(e.target.files[0])
        })
    }
    


    const addPaymayaCard = useCallback(() => {
        setLoadingOverlay(true)
        //Auth.paymayaInit();
        Auth.tokenize_card(
            values.cardNumber,
            values.expMonth,
            '20'+values.expYear,
            values.CVC,
            (r) => {
                Auth.addCard(
                    r.paymentTokenId,
                    query.has('pid') ? query.get('pid') : null,
                    (resp) => {
                        ReactPixel.track('Add Payment Info')
                        console.log('card added successfully');
                        console.log(resp);
                        window.location.replace(resp.paymaya_response.verificationUrl)
                        //setDialogMsg('Your card has been added successfully!');
                        setOpen(false);
                        //setOpenSuccess(true);
                        setLoadingOverlay(false)
                    },
                    (e) => {
                        console.log(e);
                        setError(e.error);
                        setOpenErrorDialog(true)
                        setLoadingOverlay(false)
                    }
                )
                
            },
            (e) => {
                console.log(e);
                setError(e.message);
                let [cardNumb] = e.parameters.filter((resp: any) => resp.field === 'card.number') 
                if(cardNumb !== undefined){
                    setErrorCardNumber(cardNumb.description)
                }
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
            }
        )

    },[
        values.cardNumber,
        values.CVC,
        values.expMonth,
        values.expYear,
    ])

    function numberWithCommas(x: any) {
        return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    function checkCard(string: string) {
        var matches = string.replace(/[0-9_-]/g, '');
        console.log(matches.toLowerCase());
        
        
        return matches.toLowerCase();
    }


    const handleClose = () => {
        setOpen(false);
        setValues({
            cardNumber: '',
            expMonth: '',
            expYear: '',
            CVC: '',
            cardCutoff: '',
            cardLimit: ''
        })
    }

    const previewHandler = (e: any) => {
        handleFileChange(e)
        setPreviewCardStatement(URL.createObjectURL(e.target.files[0]))
    }

    const refreshSources = () => {
        User.getSource(
            (resp) => {
                if(resp.data.count > 0){
                    setSource(resp.data.results)
                }else{
                    history.push('/login?page=add-card-page&applying_for=buyer')
                }
                
            }
        )
    }

    const activateAccount = () => {
        setLoadingOverlay(true)
        if(parseInt(values.cardLimit) <= 0){
            setError('Card Limit must not be 0.')
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
        }else{

            
            Auth.activateAcc(
                activateSource.id,
                activateSource.reference_id,
                imgFiles.Cardstatement,
                '0',
                values.cardLimit,
                (response) => {
                    console.log(response)
                    setDialogMsg("Card Activation Request sent! Please allow up to 48 hours for verification.")
                    setOpenSuccess(true);
                    setLoadingOverlay(false)
                    refreshSources()
                },
                (e) => {
                    if(e.Cardstatement !== undefined){
                        setError(e.Cardstatement[0])
                    }else if(e.cardLimit !== undefined){
                        setError(e.cardLimit[0])
                    }
                    
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
            )
        }
    }

    const resubmitDocuments = () => {
        setLoadingOverlay(true)
        if(!imageChanged){
            setError("Please upload a different card statement.")
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
        }else{
            Auth.activateAcc(
                activateSource.id,
                activateSource.reference_id,
                imgFiles.Cardstatement,
                '0',
                editCardLimit,
                (response) => {
                    console.log(response)
                    setDialogMsg("Card Activation Request has been resubmitted! Please allow up to 48 hours for verification.")
                    setOpenSuccess(true);
                    setLoadingOverlay(false)
                    refreshSources()
                },
                (e) => {
                    setError(e)
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
            )
        }
    }

    const deleteCard = () =>{
        setLoadingOverlay(true)
        Auth.deleteCard(
            delCard.id,
            (resp) => {
                if(resp.error !== undefined){
                    setError(resp.error)
                    setDelCard(null)
                    setOpenDeleteConfirm(false)
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    refreshSources()
                }else{
                    setDialogMsg("Card has been removed.")
                    ReactPixel.trackCustom('Card Deleted.')
                    setDelCard(null)
                    setOpenDeleteConfirm(false)
                    setOpenSuccess(true);
                    setLoadingOverlay(false)
                    refreshSources()
                }
            },
            (e) => {
                setError(e)
                setDelCard(null)
                setOpenDeleteConfirm(false)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )
    }

    return(
        <>
        
            <div className="page-content vaultCards">
            <Container>
                    {query.has("no_cards") && query.get("no_cards") === "true" && (
                        <Alert color="warning">
                            Info: Please add a card to start buying from merchants.
                        </Alert>
                    )}
                    
                    <div className="vault-card-container fg-container">
                        <h1 className="page-title" style={{textAlign: 'center'}}>Payment Methods</h1>
                        {/*** */}
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        
                                        <TableCell>
                                            Status
                                        </TableCell>
                                        <TableCell>
                                            Card Limit
                                        </TableCell>
                                       
                                        <TableCell>
                                            Available Spend Limit
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {source.length > 0 ? source.map((card: any) => 
                                        <TableRow key={card.id}>
                                            <TableCell>
                                                {card.type + ' - '+card.name}
                                            </TableCell>
                                          
                                            <TableCell>
                                                {card.installmentAuthorized ? 'Installment Authorized' : (card.Cardstatement && card.cardLimit) && !card.is_rejected ? 'Pending Application' : card.is_rejected ? 'Rejected' : 'Not Authorized'} 
                                            </TableCell>
                                            <TableCell>
                                                {card.cardLimit ? numberWithCommas(card.cardLimit) : 'PHP 0'}
                                            </TableCell>
                                            
                                        
                                            <TableCell>
                                                {card.available_spend_limit ? numberWithCommas(card.available_spend_limit) : 'PHP 0'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {!card.installmentAuthorized ? 
                                                (
                                                <>
                                                    <Button 
                                                        color='primary'
                                                        onClick={() => {
                                                            //setActivateSource(card)
                                                            history.push(`/app${location.pathname.includes('buyer') ? '/buyer' : location.pathname.includes('seller') ? '/seller' : '' }/upgrade-card?id=${card.id}`)
                                                        }}
                                                    >
                                                        {card.Cardstatement || card.cardLimit ? 'Resubmit' : 'Upgrade'}
                                                    </Button>
                                                </>
                                                )
                                                :  ''}

                                                <IconButton  onClick={() => {setDelCard(card); setOpenDeleteConfirm(true)}}>
                                                    <DeleteIcon style={{fill: 'red'}} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ): 
                                    <>
                                        <TableRow>
                                            <TableCell align="center" colSpan={5}>
                                                <img className="image-mascot" src={mascot} /><br />
                                                <div style={{fontSize: '18px', paddingTop: '5px', textAlign:'center', color:'#FF873A', fontWeight: 500}}>No payment methods found</div> <br />
                                                <Button 
                                                    color='primary'
                                                    onClick={() => {
                                                        setOpen(true)
                                                    }}
                                                >
                                                    Add Card
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/***
                         * <Grid container item direction="row" spacing={1} alignItems="flex-start" justify="flex-start">
                            {source ? 
                                source.map((card: any) => 
                                <Grid item  key={card.id}>
                                    <div className="vault-card">
                                        <div className="logo-container">
                                            <img className="logo" />
                                        </div>
                                        <div  className="card-name">
                                            <span className="card-name-text">{card.name}</span>
                                        </div>
                                        <div  className="card-details">
                                            {card.installmentAuthorized ? (
                                                <div className="card-authorized">
                                                    <CheckCircleIcon fontSize="small" fill="green"  />
                                                    <span className="card-details-text">Installment Authorized</span>
                                                </div>
                                            ):(
                                                <div className="card-unauthorized">
                                                    <CancelIcon fontSize="small" fill="red"/>
                                                    <span className="card-details-text">Installment Not Authorized</span>
                                                </div>
                                            )}
                                            
                                            <p className="card-details-text">Card Cutoff Day: {card.cardCutoff}</p>
                                            <p className="card-details-text">Card Limit: {card.cardLimit ? numberWithCommas(card.cardLimit) : ""}</p >
                                            <p className="card-details-text">Spend Limit: {card.cardLimit ? numberWithCommas(card.spendLimit) : ""}</p>
    
                                        </div>
                                        
                                        <div className={checkCard(card.name)}>
                                            <img className="card-image" />
                                        </div>
                                        
                                    </div>
                                    {!card.installmentAuthorized && (
                                        <div className="upgrade-button" onClick={() => history.push(`/app/account-activation?sid=${card.id}`)}>
                                            <span style={{color: '#FF873A', fontWeight: 'bold', fontSize: '15px'}}>Upgrade Card?</span>
                                        </div>
                                    )}
                                    
                                    
                                </Grid>
                                )
                           
                            
                            :null}
                            <Grid item justify="center">
                                <div className="add-card" onClick={() => setOpen(true)}>
                                    <span className="add-card-text"><AddIcon fontSize="large" fill='#FF873A'/>Add Card</span>
                                    
                                </div>
                                
                                
                            </Grid>
                        </Grid>
                         * 
                         */}
                        
                        <Button 
                            color='primary'
                            onClick={() => {
                                history.push(`/app${location.pathname.includes('buyer') ? '/buyer' : location.pathname.includes('seller') ? '/seller' : '' }/vault-cards?add-card=true`)
                                setOpen(true)
                            }}
                            className="vault-card-button"
                        >
                            Add Card
                        </Button>
                
                    </div>    
                    
                </Container>
            </div>

            <Dialog
                className={classes.dialogPaper} 
                open={delCard && openDeleteConfirm} 
                maxWidth={'xs'}
                fullWidth
                PaperProps={{style: {
                    backgroundColor: '#FF873A',
                    border: "1px solid #FF873A",
                    borderRadius: '10px'
                }}}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <span style={{color: 'white', fontWeight: 'bold', fontSize: '16px'}}>Confirm Delete Action</span>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={()=>{setDelCard(null); setOpenDeleteConfirm(false); }}/>
                </DialogTitle>
                <DialogContent style={{textAlign:'center', padding: '20px 0px', color: 'white'}}>
                    
                    Are you sure you wish to delete <span style={{color: 'white', fontWeight: 'bold'}}>{delCard ? delCard.type + ' - '+delCard.name : ''}</span>?
                </DialogContent>
                <DialogActions>
                    <MuiButton variant="contained" onClick={deleteCard} style={{color: 'white', background:'green', borderColor: 'white'}}>
                        <span style={{color: 'white'}}>Yes</span>
                    </MuiButton>
                    <MuiButton variant="contained" style={{color: 'white', background:'red'}}>
                        <span style={{color: 'white'}}>No</span>
                    </MuiButton>
                </DialogActions>
            </Dialog>


            <Dialog
                className={classes.dialogPaper} 
                open={activateSource} 
                maxWidth={'sm'}
                fullWidth
                PaperProps={{style: {
                    backgroundColor: '#fee2b3',
                    border: "1px solid #fee2b3",
                    borderRadius: '10px'
                }}}
                onClose={() => {setActivateSource(null); setPreviewCardStatement('');setImgFiles({Cardstatement: ''})}}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <span style={{color: '#FF873A', fontWeight: 'bold'}}>Activate {activateSource && activateSource.type+' - '+activateSource.name} Card</span>
                    <img
                        src='https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png'
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={()=>{setActivateSource(null); setPreviewCardStatement(''); setImgFiles({Cardstatement: ''})}}/>
                </DialogTitle>
                <div style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                    <div style={{padding: 25}}>
                            <Row>
                                <Col sm={12} md={12}>
                                    {activateSource  && activateSource.Cardstatement ? (
                                        <div className="form-container">
                                            <h6>Credit Card Statement for {activateSource && activateSource.type+' - '+activateSource.name}</h6>
                                            <p>Send your latest credit card statement. Please upload a clear copy showing your Full Name, Credit Cart Number and Credit Limit. (Valid formats: JPG, PNG, PDF)</p>
                                            {editCardstatement !== null && (
                                                    <Grid container item direction="row" alignItems="center" justify="center">
                                                        <div className="image-container">
                                                            
                                                            <img className="image" src={editCardstatement ? editCardstatement : ''}/>
                                                            
                                                            <div onClick={openCardStatement} className='fg-uploadbutton'>
                                                                UPLOAD
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    )}
                                            <div className="fileupload-hidden">
                                                    {/***
                                                     *   <FileUpload
                                                        name='IDBack'
                                                        onChange={handleFiles}
                                                        ref={idBackRef}
                                                        placeholder='Back ID'
                                                    />
                                                     */}
                                                  
                                                    <input 
                                                        type="file" 
                                                        ref={cardStatementRef} 
                                                        name="Cardstatement" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            previewHandler(e); 
                                                            if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setError("File must be below 2MB.")
                                                                }else{
                                                                    setEditCardstatement(URL.createObjectURL(e.target.files[0]))
                                                                    setImageChanged(true)
                                                                }
                                                            }
                                                        }
                                                        } 
                                                    />
                                            </div>
                                        </div>
                                    ):(
                                        <div className="form-container">
                                            <h6>Credit Card Statement for {activateSource && activateSource.type+' - '+activateSource.name}</h6>
                                            <p>Send your latest credit card statement. Please upload a clear copy showing your Full Name, Credit Cart Number and Credit Limit. (Valid formats: JPG, PNG, PDF)</p>
                                            
                                            {previewCardStatement !== '' && (
                                                <div className="image-container">
                                                            
                                                    <img className="image" src={previewCardStatement !== '' ? previewCardStatement : ''}/>
                                                    
                                                </div>
                                            )}
                                            
                                            <div className="fileupload">
                                                <FileUpload
                                                    name='Cardstatement'
                                                    onChange={(e) => {
                                                        previewHandler(e)
                                                    }}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                    {/**
                                     * 
                                     * <Row>
                                        <div className="form-container">
                                            <h6>Monthly Cut Off</h6>
                                            <TextInput 
                                                name='cardCutoff'
                                                onChange={(e) => setValues({...values, cardCutoff: e.target.value})}
                                                type='text'
                                                value={values.cardCutoff}
                                                placeholder='Monthly Cut Off'
                                            />
                                        </div>
                                    </Row>
                                     * 
                                     */}
                                    
                                    
                                    
                            </Row>
                            <Row style={{justifyContent: 'center'}}>
                                <div className="form-container" style={{paddingTop: '20px', textAlign: 'center'}}>
                                    <h6>Card Limit</h6>
                                    <TextInput 
                                        name='cardLimit'
                                        onChange={(e) => {
                                            if(editCardLimit){
                                                setEditCardLimit(e.target.value)
                                            }else{
                                                setValues({...values, cardLimit: e.target.value});
                                            }
                                        }}
                                        type='number'
                                        value={editCardLimit ? editCardLimit : values.cardLimit}
                                        placeholder='Card Limit'
                                    />
                                </div>
                            </Row>
                            <Row align="center" justify="start"  style={{justifyContent: 'center'}}>
                                <div className="form-container" style={{ textAlign: 'center'}}>
                                    <Col xs={2}>
                                
                                        <Checkbox
                                            checked={agreement}
                                            onChange={() => setAgreement(!agreement)}
                                            name="checkedB"
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" style={{fill: '#464646'}} />}
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" style={{fill: '#FF873A'}} />
                                            }
                                        />
                                    </Col>
                                    <Col xs={8} style={{color: '#464646', textAlign:'left'}}>
                                
                                        
                                            I have read and accept the <Link style={{color: '#0072ff', textDecoration: 'underline'}} to={`/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'merchant' : ''}/installment-agreement`}>privacy policy</Link>.
                                        
                                    </Col>
                                </div>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="activate-account-button">
                                        
                                        <Button 
                                            color='primary'
                                            onClick={activateSource && (activateSource.Cardstatement || activateSource.cardLimit) ? resubmitDocuments : activateAccount}
                                            className='fg-acountactivate-button'
                                        >
                                           {activateSource && (activateSource.Cardstatement || activateSource.cardLimit) ? 'Resubmit Documents' : ' Send Activation Request'}
                                        </Button>
                                       
                                        <p>
                                            Allow up to 48 hours for verification
                                        </p>

                                        
                                    </div>
                                </Col>
                            </Row>
                    </div>
                </div>
            </Dialog>
           
            <Dialog 
                className={classes.dialogPaper} 
                open={open} 
                maxWidth={'sm'}
                fullWidth
                PaperProps={{style: {
                    backgroundColor: '#FF873A',
                    border: "1px solid #FF873A",
                    borderRadius: '10px'
                }}}
                onClose={handleClose}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <span style={{color: 'white', fontWeight: 'bold'}}>Add Card</span>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={handleClose}/>
                </DialogTitle>
                    <div style={{padding: 25}}>
                        <Grid container item direction="column" spacing={2} justify="center" alignItems="center">
                            <Grid container item direction="row" spacing={1} alignItems="center">
                                <Grid item xs={12} sm={5}>
                                    <span style={{color: 'white', fontWeight: 'bold'}}>Card Number:</span>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <TextLoginInput 
                                        name="cardNumber"
                                        onChange={(e: any) => {
                                            if(e.target.value.length < 17){
                                                setValues({...values, cardNumber: e.target.value})
                                            }
                                            if(errorCardNumber !== ''){
                                                setErrorCardNumber('');
                                            }
                                            
                                        }}
                                        type='number'
                                        value={values.cardNumber}
                                        placeholder='Enter Card Number'

                                    />
                                </Grid>
                            </Grid>
                            {errorCardNumber !== '' && (
                            <Grid item>
                                
                                    <p className='errorCardNumber' style={{margin:'-8px 0', backgroundColor: '#ff4c4c', color: 'white', border: '1px solid red', borderRadius: '10px', padding: '5px', fontSize: '13px'}}>
                                        { errorCardNumber}
                                    </p>
                                
                            </Grid>
                            )}
                            <Grid container item direction="row" spacing={1} alignItems="center">
                                <Grid item xs={12} sm={5}>
                                    <span style={{color: 'white', fontWeight: 'bold'}}>Card Expiry:</span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                 
                                    <TextLoginCardExpiryInput 
                                        expName1='expMonth'
                                        expName2='expYear'
                                        expValue1={values.expMonth}
                                        expValue2={values.expYear}
                                        expOnChange1={(e: any) => setValues({...values, expMonth: e.target.value})}
                                        expOnChange2={(e: any) => setValues({...values, expYear: e.target.value})}
                                        name="addCardExpiry"
                                        type='text'
                                        value={values.expMonth}
                                        className={"expMonthYear"}
                                        onChange={(e) => {
                                            console.log('')
                                            
                                        }}
                                        placeholder='Card Expiry'
                                    />
                                </Grid>
                            </Grid>
                            {/***
                             * 
                             * 
                            
                            <Grid container item direction="row" spacing={1} alignItems="center">
                                <Grid item xs={12} sm={5}>
                                    <span style={{color: '#FF873A', fontWeight: 'bold'}}>Expiry Month:</span>
                                </Grid>
                                <Grid item>
                                 
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="expMonth"
                                        value={values.expMonth || ''}
                                        style={{width: '100%'}}
                                        
                                        onChange={(e: any) => setValues({...values, expMonth: e.target.value})}
                                        displayEmpty
                                        autoWidth
                                        defaultValue=""
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Choose Expiry Month...</em>
                                        </MenuItem>
                                      
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                        
                                        
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}  alignItems="center">
                                <Grid item xs={12} sm={5}>
                                    <span style={{color: '#FF873A', fontWeight: 'bold'}}>Expiry Year:</span>
                                </Grid>
                                <Grid item>
                                <Select
                                        id="demo-simple-select-autowidth"
                                        name="expYear"
                                        value={values.expYear || ''}
                                        style={{width: '100%'}}
                                        
                                        onChange={(e: any) => setValues({...values, expYear: e.target.value})}
                                        displayEmpty
                                        autoWidth
                                        defaultValue=""
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Choose Expiry Year...</em>
                                        </MenuItem>
                                      
                                        <MenuItem value={'2021'}>2021</MenuItem>
                                        <MenuItem value={'2022'}>2022</MenuItem>
                                        <MenuItem value={'2023'}>2023</MenuItem>
                                        <MenuItem value={'2024'}>2024</MenuItem>
                                        <MenuItem value={'2025'}>2025</MenuItem>
                                        <MenuItem value={'2026'}>2026</MenuItem>
                                        <MenuItem value={'2027'}>2027</MenuItem>
                                        <MenuItem value={'2028'}>2028</MenuItem>
                                        
                                        
                                        
                                    </Select>
                                </Grid>
                            </Grid>
                             */}
                            <Grid container item direction="row" spacing={1}  alignItems="center">
                                <Grid item xs={12} sm={5}>
                                    <span style={{color: 'white', fontWeight: 'bold'}}>Card CVC:</span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextLoginInput 
                                        name="CVC"
                                        onChange={(e: any) => {
                                            if(e.target.value.length < 5){
                                                setValues({...values, CVC: e.target.value})
                                            }
                                            
                                        }}
                                        type='number'
                                        value={values.CVC}
                                        length={3}
                                        placeholder='Enter CVC'

                                    />
                                </Grid> 
                            </Grid>
                           
                            
                            <Grid container item direction="row" spacing={1} justify="center" alignItems="center">
                                <Grid item>
                                    <Button 
                                        color='login-secondary'
                                        disabled={values.CVC.length !== 3 || values.cardNumber.length !== 16 || values.expMonth === '' || values.expYear === ''}
                                        onClick={() => {
                                            addPaymayaCard();
                                        }}
                                        className="vault-card-button"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                
            </Dialog>
            
            <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg} onClose={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller' : ''}/vault-cards`)}/>
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </>
    )
}


