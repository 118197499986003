import React, {useState} from 'react';
import {
    Divider,
    Grid,
    MenuItem,
    MenuList,
    Paper
} from '@material-ui/core';
import {Container} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { CodeBlock } from 'react-code-blocks';
import './style.scss';

//const htmlFileTest = require('../../assets/html/api_+')

const Documentation = () => {

    const [htmlFile, setHTMLFile] = useState<string>('');

    axios.get('https://4gives-pages.s3.ap-southeast-1.amazonaws.com/api_docu.html', 
        {
        headers: {
           'Content-Type': 'application/json'
        } 
    })
    .then(function (response: any) {
      setHTMLFile(response.data)
    })
    .catch(function(error: any) {
      console.log(error)

    })

    return(
        <div className="page-content documentation-page">
            <div className="documentation-page-container fg-container">
                <Grid container direction="row">
                    <Grid container direction="column" alignItems='center' justify="flex-start" item xs={3}>
                        
                            <h4 style={{padding: 20,color: '#FF873A'}}>4Gives Documentation
                            <div >
                        <Divider/>
                        </div></h4>
                        
                       
                        <ul className="navigation">
                            <li>
                            <NavLink to={'/app/documentation'} className='menuItem'>
                                
                                
                                <span>4Gives Payment Integration</span>
                            </NavLink>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={9}>
                    <div className="documentation" dangerouslySetInnerHTML={{__html: htmlFile}}>
                        {/** 
                        <Grid container xs={12} sm={12} spacing={2} item direction="column" justify="center" alignItems="center">
                            <Grid direction="row" container item>
                                <Grid item xs={12}>
                                    <h4 style={{margin: 0, textAlign: 'center'}}>Prerequisites</h4>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ul>
                                    <li>Sell Authorized 4Gives Account</li>
                                    <li>API Secret Key</li>
                                    <li>Webhook URL</li>
                                </ul>
                            </Grid>
                        </Grid>
                        <Divider />
                        <div style={{padding: '20px'}}>
                        <Grid container xs={12} sm={12} spacing={1} direction="column" justify="center" alignItems="center" item>
                                <Grid container direction="row" alignItems="center" spacing={2} item>
                                    <Grid item xs={6}>
                                        <h6 style={{margin: 0, textAlign:'center'}}>Base URL:</h6>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p style={{fontSize: '14px', textAlign: 'center'}}>https://api.4gives.me</p>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} item>
                                    <Grid item xs={6}>
                                        <h6 style={{margin: 0, textAlign:'center'}}>Endpoint:</h6>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p style={{fontSize: '14px', textAlign: 'center'}}>payment</p>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} item>
                                    <Grid item xs={6}>
                                        <h6 style={{margin: 0, textAlign:'center'}}>Request:</h6>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p style={{fontSize: '14px', textAlign: 'center'}}>for authentication</p>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} item>
                                    <Grid item xs={6}>
                                        <h6 style={{margin: 0, textAlign:'center'}}>Headers:</h6>
                                    </Grid>
                                    <Grid container xs={6} item direction="column" >
                                        <Grid item>
                                            <p style={{fontSize: '14px', textAlign: 'center'}}>{`Content-Type: application/json`}</p>
                                        </Grid>
                                        <Grid item>
                                            <p style={{fontSize: '14px', textAlign: 'center'}}>{`Api-key: Basic <secret key>`}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{padding: '20px'}}></div>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item xs={2}>
                                e.g.
                            </Grid>
                            <Grid item xs={10}>
                                <CodeBlock 
                                    text={`const header = {
                                        'Content-Type': 'application/json',
                                        'Api-key': 'Basic <SECRET KEY>',
                                    }`}
                                    language={'javascript'}
                                    showLineNumbers={true}
                                />
                            </Grid>
                        </Grid>
                        <div style={{padding: '20px'}}></div>

                        <Grid container xs={12} sm={12} spacing={1} direction="column" justify="center" alignItems="center" item>
                            <Grid container direction="row" spacing={2} item>
                                <Grid item xs={12}>
                                    <h3 style={{margin: 0, textAlign: 'center'}}>Body:</h3>
                                    <Divider />
                                </Grid>
                                
                            </Grid>
                            <Divider />
                            <Grid container direction="row" spacing={2} item>
                                <Grid item xs={8}>
                                    <h6 style={{margin: 0, textAlign:'center'}}>Required Inputs:</h6>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                            <Grid container direction="column" alignItems="center" justify="center" spacing={2} item>
                                <Grid item xs={12}>
                                    - name (string)
                                </Grid>
                                <Grid item xs={12}>
                                    - amount (int)
                                </Grid>
                                <Grid item xs={12}>
                                    - success_redirect_url (string)
                                </Grid>
                                <Grid item xs={12}>
                                    - failure_redirect_url (string)
                                </Grid>
                                <Grid item xs={12}>
                                    - reference (string)(unique)
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{padding: '20px'}}></div>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item xs={2}>
                                    e.g.
                                </Grid>
                                <Grid item xs={10}>
                                    <CodeBlock 
                                        text={`const body = {
                                            'amount': 1000,
                                            'success_redirect_url': 'http://success-url.com',
                                            'failure_redirect_url': 'http://failed-url.com',
                                            'name': 'Transaction Name',
                                            'reference': 'qnZkP031j'
                                        }`}
                                        language={'javascript'}
                                        showLineNumbers={true}
                                    />
                                </Grid>
                            </Grid>
                        <div style={{padding: '20px'}}></div>
                        <Grid container xs={12} sm={12} spacing={1} direction="column" justify="center" alignItems="center" item>
                            <Grid container direction="row" spacing={2} item>
                                <Grid item xs={12}>
                                    <h3 style={{margin: 0, textAlign: 'center'}}>Response:</h3>
                                    <Divider />
                                </Grid>
                                
                            </Grid>
                            <Divider />
                            <Grid container direction="row" spacing={2} item>
                                <Grid item xs={12}>
                                    <h6 style={{margin: 0, textAlign:'center'}}>Http status - 200</h6>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <div style={{padding: '20px'}}></div>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item xs={2}>
                                e.g. for reponse
                            </Grid>
                            
                            <Grid item xs={10}>
                                <CodeBlock 
                                    text={`{
                                        'redirectUrl': 'https://4gives.me/web-pay?id=<UNIQUE TRANSACTION ID>'
                                    }`}
                                    language={'javascript'}
                                    showLineNumbers={true}
                                />
                            </Grid>
                        </Grid>
                        <div style={{padding: '20px'}}></div>
                        <Grid container xs={12} sm={12} spacing={1} direction="column" justify="center" alignItems="center" item>
                            
                            <Divider />
                            <Grid container direction="row" spacing={2} item>
                                <Grid item xs={6}>
                                    <h6 style={{margin: 0, textAlign:'center'}}>Invalid API Key type:</h6>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{fontSize: '14px'}}>should use secret key and not public key</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} item>
                                <Grid item xs={6}>
                                    <h6 style={{margin: 0, textAlign:'center'}}>Invalid authentication header:</h6>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{fontSize: '14px'}}>either invalid “Api-key” value format or invalid secret key</p>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{fontSize: '14px'}}>Transaction with this reference already exists</p>
                            </Grid>
                            
                        </Grid>
                        */}
                    </div>
                    </Grid>
                </Grid>
                
            </div>
        </div>
    )
}

export default Documentation;
