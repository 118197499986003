import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useLocation } from 'react-router';
import Auth from '../auth';

const PrivateRoute: React.FC<RouteProps | any> =
  ({ component: Component, ...rest }) => {
    const location = useLocation();
    if (!Auth.isAuthenticated() || !Component) {
      return (<Redirect to={`${location.pathname.includes('buyer') ? '/login?page=login-page&applying_for=buyer' : location.pathname.includes('seller') ? '/login?page=login-page&applying_for=merchant' : '/'}`} />);
    }
    
    return (
      <Route
        {...rest}
        render={(props: RouteComponentProps<{}>) => <Component {...props} />}
      />
    );
  };

export default PrivateRoute;
