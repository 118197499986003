import React, {useEffect, useState} from 'react';
import { Col, Row } from 'react-grid-system';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, TextField, Theme, Toolbar, Typography, InputAdornment, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import createBrowserHistory from 'history/createBrowserHistory';
import { Button as CustomButton } from '../../components/Button';
import Select from '@material-ui/core/Select';
import iosBadge from '../../assets/images/ios-badge.svg';
import googleBadge from '../../assets/images/google-play-badge.svg';
import purchaseIcon from '../../assets/images/fork_screen icons/04.png';
import merchantIcon from '../../assets/images/fork_screen icons/01.png';
import Auth from '../../auth';

const logo = require('../../assets/images/4giveslogo.png');
const logoWhite = require('../../assets/images/4giveslogo_white.png');
const pic = require('../../assets/images/pic2-min.png');
const offer = require('../../assets/images/create_offer.png');
const sale = require('../../assets/images/create_sale.png');
const gives = require('../../assets/images/4giveslogoorange.png');
const purchase = require('../../assets/images/purchase.png')


const useStyles = makeStyles((theme) => ({
  selectInput: {
      minWidth: 300,
      maxWidth: 300,
  },
  multilineColor: {
    color: '#FF873A',
    fontSize: '20px',
    width: '130px'
  },
  multilineColorMobile: {
    color: '#FF873A',
    fontSize: '16px',
    width: '70px'
  },
  select: {
    '&:before': {
        borderColor: 'rgba(255, 133, 51, 0.42)',
    },
    '&:after': {
        borderColor: '#FF873A',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `1px solid #FF873A`
    },
    
  },
  selectIcon: {
      fill: '#ececec',
  },
  labelForm: {
    color: '#FF873A',
    margin: '0 auto',
  },
  btnBorderColor: {
    border:"none",
    outline:"none",
    borderColor: '#FF873A',
    borderWidth: '0px',
    padding: '10px',
    color: '#FF873A',
    backgroundColor: '#fee2b3',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    '&:active': {
      outline: 'none',
    },
  },
  btnBorderColorSelected: {
    border:"none",
    outline:"none",
    borderColor: '#FF873A',
    borderWidth: '0px',
    padding: '10px',
    color: 'white',
    backgroundColor: '#FF873A',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    '&:active': {
      outline: 'none',
    },
  },
  labelColor: {
    color: '#FF873A',
  },
  cssFocused: {
    color: '#FF873A',
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid rgba(255, 133, 51, 0.42)'
    },
    '&:after': {
        borderBottom: `2px solid #FF873A`
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid #FF873A`
    }
  },
}));

export const Content: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const [amount, setAmount] = useState<string | null>('2000')
    const [month, setMonth] = useState(3);
    const [perMonth, setPerMonth] = useState<any | null>(null)
    const [isError, setIsError] = useState(false)
    const [interest, setInterest] = useState(0);
    const handleAmountChange = (str: string) => {
      if(/^[0-9]*$/.test(str)){
        setAmount(str)
        setIsError(false)
      }else{
        setIsError(true)
      }
    }

    useEffect(() => {
      if((amount !== '' && amount !== '0') && month > 0){
        computeInstallment(amount, month)
      }
    },[amount, month])

    const computeInstallment = (amount: string | null, month: number) => {
      Auth.installmentCompute(
        amount,
        month,
        (resp) => {
          console.log(resp)
          setPerMonth(resp.data.monthly)
          setInterest(resp.data.interest)
        },
        (e) => {
          console.log(e)
        }
      )
    }
    
      return(
        <>
        <div className="content">
          <Container >
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid container item sm={4} lg={6}>
                <Grid container item direction="row" alignItems="center" spacing={1} justify="center">
                  <Grid item>
                    <Typography variant={!matches ? "h6" : "h4"} style={{color: '#FF873A', fontWeight: 'bold', textAlign: 'center'}}>
                        Easy Pay in Installments
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row" alignItems="center" spacing={1} justify="center">
                  <Grid item>
                    <Typography variant={"body1"} style={{color: "black", padding:'30px'}}>
                        Pay in installment, fast and easy.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row" alignItems="center" spacing={1} justify="center" style={{paddingBottom: '10px'}}>
                    <Grid item >
                      {/**8
                       * <div className="content-btn-pic" onClick={() => history.push('/buyer')}>
                        <img src={purchaseIcon} style={{height: '100%', objectFit:'contain', width: '300px', margin: 'auto'}} />
                        <Typography  style={{color: '#FF873A', textAlign:'center', fontWeight: 'bold', paddingTop: '30px'}}>
                          I want to create a payment with installments
                        </Typography>
                      </div>
                       */}
                      <CustomButton 
                          color='primary'
                          onClick={() => history.push('/buyer')}
                          className='fg-home'
                      >
                          I am a buyer
                      </CustomButton>
                      
                    </Grid>
                    <Grid item >
                      {/***
                       * 
                       *  <div className="content-btn-pic" onClick={() => history.push('/merchant')}>
                        <img src={merchantIcon} style={{height: '100%', objectFit:'contain', textAlign: 'center',  width: '300px', margin: 'auto'}} />
                        <Typography  style={{color: '#FF873A', textAlign:'center', fontWeight: 'bold', paddingTop: '30px'}}>
                          I want to be a merchant that can offer installment payments
                        </Typography>
                      </div>
                       * 
                       */}
                     <CustomButton 
                          color='primary'
                          onClick={() => history.push('/merchant')}
                          className='fg-home'
                      >
                          I am a seller
                      </CustomButton>
                      
                    </Grid>
                  
                </Grid>
              </Grid>
              <Grid item sm={6} lg={5}>
                  <div className="content-pic">
                    <img src={pic} style={{height: '100%', objectFit:'cover', width: matches ? '30vw' : '200px', margin: 'auto'}} />
                  </div>
                  
              </Grid>
            </Grid>
          </Container>
          <div style={{background: '#FF873A', padding: '40px'}}>
            <Container>
              <Grid container item direction="column" spacing={2} alignItems="center" justify="flex-start">
                <Grid item >
                  <Typography variant={!matches ? "h6" : "h4"} style={{color: 'white',  fontWeight: 500}}>
                    About 4Gives
                  </Typography>
                  
                </Grid>
                <Grid item>
                <Typography variant={!matches ? "body2" : "body1"} style={{color: 'white',  lineHeight: 2}}>
                    Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                  </Typography>
                </Grid>
                
              </Grid>
            </Container>
          </div>

          <div style={{padding: '30px'}}>
            <Container>
              <Typography variant={!matches ? "h6" : "h4"} style={{color: '#FF873A', paddingTop: 20, fontWeight: 500, textAlign: 'center', paddingBottom: '30px'}}>
                How it Works
              </Typography>
              <Grid container item direction="row"  justify="space-evenly" spacing={matches ? 9 : 2}>
                <Grid item alignContent="center" sm={3} >
                  <div className="buttons">
                    <img src={gives}/>
                    <div className="button-label">
                      Lorem
                    </div>
                    <div style={{color: 'black'}}>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relyi
                    </div>
                  </div>
                  
                </Grid>
                <Grid item alignItems="center" sm={3} >
                  <div className="buttons">
                    <img src={offer}/>
                    <div className="button-label">
                    Lorem
                    </div>
                    <div style={{color: 'black'}}>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relyi
                    </div>
                  </div>
                </Grid>
                <Grid item alignItems="center" sm={3} >
                  <div className="buttons">
                    <img src={sale}/>
                    <div className="button-label">
                    Lorem
                    </div>
                    <div style={{color: 'black'}}>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relyi
                    </div>
                  </div>
                </Grid>
                <Grid item alignItems="center" sm={3} >
                  <div className="buttons">
                    <img src={purchase}/>
                    <div className="button-label">
                    Lorem
                    </div>
                    <div style={{color: 'black'}}>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relyi
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>

       

        </>
      )
}

export default Content;