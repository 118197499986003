import { Container, Grid, Divider, CardActions, Card, CardContent, makeStyles } from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Auth from "../../auth";
import { Pagination } from '@material-ui/lab';
import URL from '../../services/url';
import { ProposalDetails } from "../../components";

const mascot = require('../../assets/images/4gives_mascot.png');

const useStyles = makeStyles({
    root: {
        borderColor: '#FF873A'
    },
    indicator: {
        backgroundColor: "#FF873A",
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
        paddingTop: 15, 
        alignSelf: 'center', 
        justifySelf: 'center', 
        color: '#FF873A'
    },
    select: {
        '&:before': {
            borderColor: '#FF873A',
        },
        '&:after': {
            borderColor: '#FF873A',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid #FF873A`
        }
    },
    
    selectIcon: {
        fill: '#ececec',
    },
    labelForm: {
        color: '#fffff',
    },
    cardEntry: {
      cursor: 'pointer',
      
    },
    btn: {
        color: 'white',
        background: '#FF873A',
        borderColor: '#FF873A',
        '&:hover': {
            backgroundColor: '#FF873A',
          },
    },
    btnOutlined: {
        color: '#FF873A',
        borderColor: '#FF873A',
    },
    step: {
        color: '#FF873A !important',
        "& $completed": {
          color: "lightgreen !important"
        },
        "& $active": {
          color: "pink !important"
        },
        "& $disabled": {
          color: "red !important"
        }
    },
    divider: {
        backgroundColor: '#FF873A',
        border: "1px solid #FF873A",
    },
    active: {}, //needed so that the &$active tag works
    completed: {},
    checkedIcon: {
      backgroundColor: '#FF873A',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FF873A',
      },
    },
  });


export const RecentActivity: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
    user?: any | null;
    source?: any | null;
    setOpenDialog?: any | null;
    setDialogMessage?: any | null;
    type?: any | null;
    setOpenErrorDialog: (e: any) => void;
    setErrorMessage: (e: any) => void;
}> = ({setLoadingOverlay, source, user, setOpenDialog, setDialogMessage, type, setOpenErrorDialog, setErrorMessage}) => {
    const classes = useStyles();
    const location = useLocation();
    const [args, setArgs] = useState(`?page_size=5&type=${type}`);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [activities, setActivities] = useState<any | null>(null);
    const [buyer, setBuyer] = useState<any | null>(null);
    const [merchant, setMerchant] = useState<any | null>(null);

    
    const [details, setDetails] = useState<any | null>(null);
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if(value !== page){
            setLoadingOverlay(true)
            setPage(value);
            if(value === 1){
                
                setArgs(`?page_size=5&type=${type}`);
            }else{
                setLoadingOverlay(true)
                setArgs(`?page_size=5&type=${type}&page=` + value)
            }
        }
        
    }

    const handleClose = () => {
        setModal(!modal)
        setDetails(null);
    }

    const subscribe = async () => {
        const events = new EventSource(URL.EVENTS.ACTIVITIES+user.id);
        console.log("SUBSCRIBING")
        events.onmessage = (event) => {
          switch (event.type) {
            case 'message':
              const data = JSON.parse(event.data);
              console.log(data)
               /*const updateActivities = [...activities]
              updateActivities.push(data)
              console.log(updateActivities);
              setActivities(updateActivities);
              */
             if(data.event === "activities" && data.action === "re-render"){
                Auth.getRecentActivities(
                    args,
                    (response) => {
                        console.log(response)
                        setActivities(response.results)
                        setCount(response.count)
                    },
                    (e) => {
                        console.log(e)
                    }
                )
             }else{
                events.close()
             }
             
              break;
          }
        };

        events.onerror = (e) => {
            console.log(e)
            events.close()
        }
        if(!(location.pathname.includes('my-account'))){
            console.log('CLOSING')
            events.close();
            }
        return () => {
            events.close();
          };
      };

    useEffect(() => {
        if(details && modal){
            setLoadingOverlay(false)
        }
    },[details, modal])

    const openProposal = (id: any, isProposal: boolean) => {
        setLoadingOverlay(true)
        let result : any = {}
        
        if(isProposal){
            Auth.getProposal(
                id,
                (response) => {
                    console.log(response)
                    result = response
                    if(result.merchant === user.id){
                        result.type = "SELL"
                        setMerchant(user)
                        if(result.buyer){
                            Auth.getUser(
                                result.buyer,
                                (userResp) => {
                                    setBuyer(userResp)
                                    setModal(true)
                                    setDetails(result)
                                },
                                (e) => {
                                    console.log(e)
                                    setErrorMessage(e)
                                    setOpenErrorDialog(true)
                                }
                            )
                        }else{
                            setModal(true)
                            setDetails(result)
                        }
                        
                    }else{
                        result.type = "BUY"
                        setBuyer(user)
                        if(result.merchant){
                            Auth.getUser(
                                result.merchant,
                                (userResp) => {
                                    setMerchant(userResp)
                                    setModal(true)
                                    setDetails(result)
                                },
                                (e) => {
                                    console.log(e)
                                    setErrorMessage(e)
                                    setOpenErrorDialog(true)
                                }
                            )
                        }else{
                            setModal(true)
                            setDetails(result)
                        }
                        
                    }
                },
                (e) => {
                    console.log(e)
                    setErrorMessage(e)
                    setOpenErrorDialog(true)
                }
            )
        }else{
            Auth.getTransaction(
                id,
                (response) => {
                    console.log(response)
                    result = response
                    if(result.merchant === user.id){
                        result.type = "SELL"
                        setMerchant(user)
                        if(result.buyer){
                            Auth.getUser(
                                result.buyer,
                                (userResp) => {
                                    setBuyer(userResp)
                                    setModal(true)
                                    setDetails(result)
                                },
                                (e) => {
                                    console.log(e)
                                    setErrorMessage(e)
                                    setOpenErrorDialog(true)
                                }
                            )
                        }else{
                            setModal(true)
                            setDetails(result)
                        }
                        
                    }else{
                        result.type = "BUY"
                        setBuyer(user)
                        if(result.merchant){
                            Auth.getUser(
                                result.merchant,
                                (userResp) => {
                                    setMerchant(userResp)
                                    setModal(true)
                                    setDetails(result)
                                },
                                (e) => {
                                    console.log(e)
                                    setErrorMessage(e)
                                    setOpenErrorDialog(true)
                                }
                            )
                        }else{
                            setModal(true)
                            setDetails(result)
                        }
                        
                    }
                },
                (e) => {
                    console.log(e)
                    setErrorMessage(e)
                    setOpenErrorDialog(true)
                }
            )
        }
        console.log(id)
    }

    useEffect(() => {
        Auth.getRecentActivities(
            args,
            (response) => {
                console.log(response.results)
                setActivities(response.results)
                setCount(response.count)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
            }
        )
    },[args])

    useEffect(() => {
        if(activities === null){
            subscribe()
            Auth.getRecentActivities(
                args,
                (response) => {
                    console.log(response)
                    setActivities(response.results)
                    setCount(response.count)
                },
                (e) => {
                    console.log(e)
                }
            )
        }
    },[])

    

    return (

        <>
        <div style={{paddingTop: 20}}>
            <Card raised={true}>
                <CardContent style={{height: '100%'}}>
                    <div style={{padding: '10px 10px', cursor: 'pointer'}}>
                        <h5>Recent Activities</h5>
                    </div>
                    {activities && activities.length > 0 ? (
                        <>
                        {activities.map((result: any) => 

                            
                            <div 
                                key={result.id}
                                className={(result.proposal_id === null || result.transaction_id === null) ? 'activity-entry' : 'activity-entry-clickable'}
                                onClick={() => 
                                result.proposal_id ? 
                                    ((result.transaction_type === "SELL" ) )? 
                                        //console.log("TESTING")
                                        user.sellAuthorized ?(
                                            user.emailConfirmed ? 
                                                //openProposal(result.proposal_id, true) 
                                                history.push(`/app/seller/proposal/${result.proposal_id}`)
                                            :(
                                                setOpenErrorDialog(true),
                                                setErrorMessage('Please confirm your email before proceeding.')
                                            )
                                        ):(
                                            history.push('/app/seller/account-upgrade?send_documents=true')
                                        )
                                    :
                                    (result.transaction_type === "BUY") ? 
                                    //console.log("YOU IS BUYER")
                                        user.buyAuthorized ? (
                                            user.emailConfirmed ? 
                                                //openProposal(result.proposal_id, true) 
                                                history.push(`/app/buyer/proposal/${result.proposal_id}`)
                                            :(
                                                setOpenErrorDialog(true),
                                                setErrorMessage('Please confirm your email before proceeding.')
                                            )
                                        ):(
                                            history.push('/app/buyer/vault-cards')
                                        )                                        
                                    : null
                                    //console.log(result)
                                    //console.log('redirect')
                                    
                                : 
                                result.transaction_id ? (
                                    openProposal(result.transaction_id, false),
                                    history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller' : ''}/transaction/${result.transaction_id}`)
                                ):
                                    console.log("Proposal has been processed")
                                } 
                            >
                                <Grid container item direction="column" onClick={() => console.log(result)} spacing={1} alignItems="flex-start" justify="space-around">
                                    <Grid item className={"label-date"} >
                                        {moment(result.created).format("MMMM D, YYYY h:mm A")}
                                    </Grid>
                                    <Grid item className={"importantLabel"}>
                                        {result.message}
                                    </Grid>
                                    <Grid item className={"label-ref"}>
                                        Reference: {result.proposal_ref_number ? result.proposal_ref_number : result.transaction_ref_number}
                                    </Grid>
                                </Grid>
                                <div style={{padding: '10px 0px'}}>
                                    <Divider variant="fullWidth" classes={{root: classes.divider}}/>
                                </div>
                            </div>
                         
                        )}
                    
                        </>
                    ):(
                        <Container style={{paddingTop: 20}}>
                            <Grid container item direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <img className="image-mascot" src={mascot}/>
                                </Grid>
                                <Grid item className={"importantLabel"}>
                                    <h5 style={{fontStyle: "italic"}}>No Recent Activities....</h5>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                   
                </CardContent>
                <CardActions>
                    <Pagination count={Math.ceil(count/5)} page={page} shape="rounded" onChange={handlePageChange} />
                </CardActions>
            </Card>
        </div>
        {details && (
            <ProposalDetails modal={modal} setModal={handleClose} result={details} buyer={buyer} merchant={merchant} cards={source} />
        )}
        </>
    )
}