import React, { useState, useCallback, useContext, useEffect, useRef, useMemo } from 'react';
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Button, TextInput, TextLoginInput, TextContactLoginInput, SuccessDialog, ErrorDialog, IdleLogoutDialog } from '../../components';
import Icon from '../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, useFormFiles, useQuery } from '../../hooks';
import Auth from '../../auth';
import { User } from '../../models';
import './style2.scss';
import logo from '../../assets/images/4giveslogo_white.png';
import sellerLogoWhite from '../../assets/images/4giveslogo_white_seller.svg';
import buyerLogoWhite from '../../assets/images/4giveslogo_white_buyer.svg';
import buyerLogo from '../../assets/images/4giveslogo.png';
import sellerLogo from '../../assets/images/4giveslogo_seller.svg';
import merch from '../../assets/images/merch1.jpg';
import { RadioGroup, FormControlLabel, Radio, MenuItem, Select, Backdrop, FormControl, Grid, Typography, useMediaQuery, useTheme, Container, Checkbox, NativeSelect, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { filter } from 'lodash';
import { provinces } from '../../assets/provinces';
import { cities } from '../../assets/cities';
import BounceLoader from 'react-spinners/BounceLoader';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Col, Row } from 'react-grid-system';
import { FileUpload, FileUploadLogin, TextLoginCardExpiryInput, TextLoginCardNumberInput } from '../../components/Input';
import { banks } from '../../assets/banks';
import purchaseIcon from '../../assets/images/fork_screen icons/Buyer.png';
import merchantIcon from '../../assets/images/fork_screen icons/Seller.png';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MyURL from '../../services/url';
import ReactGA from 'react-ga';
import { Alert } from 'reactstrap';
import ReactPixel from 'react-facebook-pixel';
import { useIdleTimer } from 'react-idle-timer';
import OtpInput from 'react-otp-input';

interface IRegErrorMsg {
    username: [string];
    email: [string];
    password1: [string];
    password2: [string];
}

interface IProfileErrorMsg {
    first_name: [string];
    last_name: [string];
    street: [string];
    contact_number: [string];
    street2: [string];
    barangay: [string];
    city: [string];
    region: [string];
    zip_code: [string];
}

interface ILogErrorMsg {
    non_field_errors: [string];
    email: [string];
    password: [string];
}


const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    backdrop: {
        zIndex: 1000,
        color: '#fff',
      },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    labelForm: {
        color: '#fffff',
    },
    multilineColor:{
        color:'#ececec',
        fontSize: '18px',
        fontWeight: 300,
    },
    select: {
        '&:before': {
            borderColor: '#ececec',
        },
        '&:after': {
            borderColor: '#ececec',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid #ececec`
        }
    },
    selectIcon: {
        fill: '#ececec',
    },
    checkedIcon: {
      backgroundColor: '#106c58',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106c58',
      },
    },
  });
  

const Login: React.FC = () => {
    const theme = useTheme();
    const query = useQuery()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const matchesBelow = useMediaQuery(theme.breakpoints.up('lg'))
    const [page, setPage] = useState<any>(
        query.get('page') === 'signup' ? 'registration-page' : query.get('page') === 'forgot-password' ?  'forgot-password' : query.has('page') ? query.get('page') :'login-page');
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { setUser, user, setBuyOrSellMode, buyOrSellMode, source, setSource } = useContext(User.Context);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [gender, setGender] = useState('M');
    const [response, setResponse] = useState(null);
    const [sessionID, setSessionID] = useState(null);
    const [smsCode, setSMSCode] = useState<string>('')
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    const idFrontRef = useRef<any | null>(null) 
    const idBackRef = useRef<any | null>(null) 
    const bankproofRef = useRef<any | null>(null) 
    

   
    const [emailCode, setEmailCode] = useState('');

    const [merchantOpenDialog, setMerchantOpenDialog] = useState(false)
    const [merchantDialogMessage, setMerchantDialogMessage] = useState('')

    const {values, handleChange} = useForm({ 
        forgot_password_email: '',
        register_username: '',
        register_email: '',
        register_password: '',
        register_passwordConfirm: '',
        login_email: '',
        login_password: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        street: '',
        street2: '',
        barangay: '',
        city: '',
        region: '',
        zip_code: '',
        contact_number: '',
    });

   

    const defaultValues = [
        'forgot_password_email',
        'register_username',
        'register_email',
        'register_password',
        'register_passwordConfirm',
        'login_email',
        'login_password',
        'first_name',
        'last_name',
        'street',
        'street2',
        'barangay',
        'city',
        'region',
        'zip_code',
        'contact_number',
    ]

    const [seconds, setSeconds] = useState(30)
    const [canResend, setCanResend] = useState(false);

    const [errorProfile1, setErrorProfile1] = useState({
        errors: {
            first_name: '',
            last_name: '',
        }
    })

    const [errorProfile2, setErrorProfile2] = useState({
        errors: {
            street: '',
            region: '',
            city: '',
            zip_code: '',
            barangay: '',
        }
    })

    const [errorLogin, setErrorLogin] = useState({
        errors: {
            
        }
    })

    const [otp, setOtp] = useState(new Array(4).fill(""));

    const handleOTPChange = (element: any, index: any) => {
        if(isNaN(element.value)) return false;

        setOtp([...otp.map((d: any, idx: any) => (idx === index) ? element.value : d)])

        if(element.nextSibling){
            element.nextSibling.focus()
        }
    }

    const [verifySuccess, setVerifSuccess] = useState(false)
    const [verifSuccessMessage, setVerifSuccessMessage] = useState('')
    const [verifError, setVerifError] = useState(false)
    const [verifErrorMessage, setVerifErrorMessage] = useState('')

    const [errorNumber, setErrorNumber] = useState('')

    const [errorForgotPassword, setErrorForgotPassword] = useState('')

    const [completedProf1, setCompletedProf1] = useState(false);
    const [completedProf2, setCompletedProf2] = useState(false);
    const [completedContact, setCompletedContact] = useState(false);

    //MERCHANT STATES
    const [isMerchantRejected , setIsMerchantRejected] = useState(false);
    const [rejectNote, setRejectNote] = useState('')

    const [editIDFront, setEditIDFront] = useState<any>(null)
    const [editIDBack, setEditIDBack] = useState<any>(null)
    const [editBankproof, setEditBankproof] = useState<any>(null)
    const [editBankAccountNumber, setEditBankAccountNumber] = useState<any>(null);
    const [editBankCode, setEditBankCode] = useState<any>(null);

    const {files, handleFiles} = useFormFiles ({
        IDFront: '',
        IDBack: '',
        Bankproof: '',
    });

    const [agreements, setAgreements] = useState({
        terms: false,
        privacy: false,
        installment: false,
    })

    const [bankCode, setBankCode] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState(0);

    const [addCardNumber, setAddCardNumber] = useState('');
    const [addCardExpiry, setAddCardExpiry] = useState('');
    const [addCardExpiryMonth, setAddCardExpiryMonth] = useState('')
    const [addCardExpiryYear, setAddCardExpiryYear] = useState('')
    const [addCardCVC, setAddCardCVC] = useState('')

    const [signUpPassword2, setSignUpPassword2] = useState('')

    const [preview, setPreview] = useState({
        IDFront: '',
        IDBack: '',
        Bankproof: '',
    })

    const [loaded, setLoaded] = useState({
        IDFront: false,
        IDBack: false,
        Bankproof: false
    })

    
    

    const imageIDFrontRef = useRef<HTMLImageElement>(null);
    const imageIDBackRef = useRef<HTMLImageElement>(null);
    const imageBankproofRef = useRef<HTMLImageElement>(null);

    const onImageIDFrontLoaded = () => setLoaded({...loaded, IDFront: true})
    const onImageIDBackLoaded = () => setLoaded({...loaded, IDBack: true})
    const onImageBankproofLoaded = () => setLoaded({...loaded, Bankproof: true})

    useEffect(() => {
        const idFrontCurrent = imageIDFrontRef.current
        const idBackCurrent = imageIDBackRef.current
        const bankProofCurrent = imageBankproofRef.current

        console.log(idFrontCurrent)

        if(idFrontCurrent && editIDFront){
            if(!editIDFront){
                idFrontCurrent.addEventListener('load', onImageIDFrontLoaded)
                return () => idFrontCurrent.removeEventListener('load', onImageIDFrontLoaded)
            }
        }
        if(idBackCurrent && editIDBack){
            if(!editIDBack){
                idBackCurrent.addEventListener('load', onImageIDBackLoaded)
                return () => idBackCurrent.removeEventListener('load', onImageIDBackLoaded)
            }
        }
        if(bankProofCurrent && editBankproof){
            if(!editBankproof){
                bankProofCurrent.addEventListener('load', onImageBankproofLoaded)
                return () => bankProofCurrent.removeEventListener('load', onImageBankproofLoaded)
            }
        }
        
    },[imageIDFrontRef, imageIDBackRef, imageBankproofRef, editIDFront, editIDBack, editBankproof])

    useEffect(() => {
        const idBackCurrent = imageIDBackRef.current
        
    },[imageIDBackRef])

    useEffect(() => {
        const bankProofCurrent = imageBankproofRef.current
        

    },[imageIDBackRef])

    useEffect(() => {
        console.log(loaded)
    },[loaded])

    const ImageIDFront = useMemo( () => {
        console.log(preview.IDFront)
        if(preview.IDFront !== '' || editIDFront){
            console.log(loaded.IDFront)
            return (
                <>
                <img ref={imageIDFrontRef} src={preview.IDFront !== '' ? URL.createObjectURL(preview.IDFront) : editIDFront ? editIDFront: ''} className="spinner" style={{
                    border: '2px solid white', 
                    width: matches ? '150px' : '150px',
                    height: matches ? '150px' : '150px',
                    display: editIDFront ? 'inline-block' :loaded.IDFront ? 'inline-block' :'none'
                }}
                onLoad={() => setLoaded({...loaded, IDFront: true})}
                />
                {!editIDFront && (
                <div style={{background:'rgba(0, 0, 0, 0.19)', position: 'absolute', top:'0', left: '0', height:'150px', width:'150px', display: loaded.IDFront ? 'none' :'inline-block'}}>
                    <CircularProgress style={{fill: 'white', color: 'white', }}/>
                </div>
                )}
                </>
            );
        }
        else{
            return false
        }
    },[preview.IDFront, editIDFront,loaded.IDFront]);
  
    const ImageIDBack = useMemo( () => {
        console.log(preview.IDBack)
        if(preview.IDFront !== '' || editIDBack){
           
            return (
                <>
                <img ref={imageIDBackRef} src={preview.IDBack !== '' ? URL.createObjectURL(preview.IDBack) : editIDBack ? editIDBack : ''} className="spinner" style={{
                    border: '2px solid white', 
                    width: matches ? '150px' : '150px',
                    height: matches ? '150px' : '150px',
                    display: editIDFront ? 'inline-block' :loaded.IDBack ? 'inline-block' :'none'
                }}
                onLoad={() => setLoaded({...loaded, IDBack: true})}
                />
                {!editIDBack && (
                <div style={{background:'rgba(0, 0, 0, 0.19)', position: 'absolute', top:'0', left: '0', height:'150px', width:'150px', display: loaded.IDBack ? 'none' :'inline-block'}}>
                    <CircularProgress style={{fill: 'white', color: 'white', }}/>
                </div>
                )}
                </>
            );
        }
        else{
            return false
        }
    },[preview.IDBack, editIDBack, loaded.IDBack]);

    const ImageBankProof = useMemo( () => {
        console.log(preview.Bankproof || editBankproof)
        if(preview.Bankproof !== '' || editBankproof){
            return (
            <>
                <img ref={imageBankproofRef} src={preview.Bankproof !== '' ? URL.createObjectURL(preview.Bankproof) : editBankproof ? editBankproof : ''} className="spinner" style={{
                    border: '2px solid white', 
                    width: matches ? '150px' : '150px',
                    height: matches ? '150px' : '150px',
                    display: editBankproof ? 'inline-block' : loaded.Bankproof ? 'inline-block' : 'none'
                }}
                onLoad={() => setLoaded({...loaded, Bankproof: true})}
                />
                {!editBankproof && (
                <div style={{background:'rgba(0, 0, 0, 0.19)', position: 'absolute', top:'0', left: '0', height:'150px', width:'150px', display: loaded.Bankproof ? 'none' :'inline-block'}}>
                    <CircularProgress style={{fill: 'white', color: 'white', }}/>
                </div>
                )}
            </>);
        }
        
        else{
            return false
        }
    },[preview.Bankproof, editBankproof, loaded.Bankproof]);
    

    const openIDFrontRef = () => {
        console.log('openID')
        idFrontRef.current.click()
    }
    const openIDBackRef = () => {
        idBackRef.current.click()
    }
    const openBankproofRef = () => {
        bankproofRef.current.click()
    }

    const [openIdle, setOpenIdle] = useState(false);

    const handleOnIdle = (event: any) => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        console.log(getRemainingTime())
        console.log(getElapsedTime());
        if(user){
            setOpenIdle(true)
        }
        
    }

    const handleOnActive = (event: any) => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
        console.log(getElapsedTime());
    }

    const handleOnAction = (event: any) => {
        console.log('user did something', event)
        console.log(getRemainingTime() / 1000 )
        console.log(getElapsedTime()/ 1000);
    }


    const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
        timeout: 300000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(() => {
        if(page === 'registration-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User signing up'
            })
        }else if (page === 'profile1-page' || page === 'profile2-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User setting up details'
            })
        }else if (page === 'contact-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User setting up contact'
            })
        }
        else if (page === 'contact-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User in fork page'
            })
        }
        else if (page === 'apply-merchant-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User in merchant application page'
            })
        }
        else if (page === 'add-card-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User in buyer application page'
            })
        }
        else if (page === 'sms-verify'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User verifying SMS'
            })
        }else if (page === 'email-verif-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User verifying email'
            })
        }
    },[page])

    useEffect(() => {
        if(page === "sms-verify"){

        
            if(seconds > 0){
                setTimeout(() => {
                    setSeconds(seconds - 1)
                },1000)
            }else{
                setCanResend(true)
            }
        }
        
    },[canResend, seconds, page])

    const resetErrors = () => {
        setRegErrorMSG({
            username: [''],
            email: [''],
            password1: [''],
            password2:[''],
        })

        setLogErrorMSG({
            non_field_errors: [''],
            email: [''],
            password: [''],
        })

        setErrorProfile1({
            errors: {
                first_name: '',
                last_name: '',
            }
        })

        setErrorProfile2({
            errors: {
                street: '',
                region: '',
                city: '',
                zip_code: '',
                barangay: '',
            }
        })

        setErrorNumber('')

        setErrorForgotPassword('')

        setSignUpPassword2('');
    }

    useEffect(() => {
        if(page){
            resetErrors();
        }
    }, [page])

    const [regErrorMSG, setRegErrorMSG] = useState<IRegErrorMsg>({
        username: [''],
        email: [''],
        password1: [''],
        password2:[''],
    });

    const [profileErrorMSG, setProfileErrorMSG] = useState<IProfileErrorMsg>({
        first_name: [''],
        contact_number: [''],
        last_name: [''],
        street: [''],
        street2: [''],
        barangay: [''],
        city: [''],
        region: [''],
        zip_code: [''],
    })

    const [logErrorMSG, setLogErrorMSG] = useState<ILogErrorMsg>({
        non_field_errors: [''],
        email: [''],
        password: [''],
    });
    
    useEffect(() => {
        if(user){
            console.log(user)
            if(user.rejection_notes.length > 0){
                let test = user.rejection_notes.filter((reject: any) => reject.type === "sell_authorize_rejected")
                console.log(test)
                if(test.length > 0){
                    setIsMerchantRejected(test.length > 0)
                    setRejectNote(test[0].note)
                }
                
                
            }
            setEditIDFront(user.IDFront)
            setEditIDBack(user.IDBack)
            setEditBankproof(user.Bankproof)
            setEditBankAccountNumber(user.bankAccountNumber)
            setEditBankCode(user.bankCode)
            
        }
    },[user])

    const subscribe = async () => {
        if(user) {
            let events: any = new EventSource(MyURL.EVENTS.APPLICATION+user.id);
            console.log("SUBSCRIBING")
            events.onmessage = (event: any) => {
              switch (event.type) {
                case 'message':
                  const data = JSON.parse(event.data);
                  console.log(data)
                   /*const updateActivities = [...activities]
                  updateActivities.push(data)
                  console.log(updateActivities);
                  setActivities(updateActivities);
                  */
                 if(data.event === "application" && data.action === "redirect"){
                    window.location.href = window.location.origin + '/app/seller/my-account';
                 }else if(data.event === "application" && data.action === "re-render"){
                    User.get(
                        (resp) => {
                            console.log(resp)
                            User.getSource((cardData) => {
                                setSource(cardData.data.results)
                                setUser(resp);
                                if(resp.emailConfirmed && page === 'email-verif-page'){
                                    if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                        //history.push('/app/my-account')
                                        setLoadingOverlay(false)
                                        //changePage('fork-page')
                                        if(query.get('applying_for') === "buyer"){
                                            console.log("BUYER SIGNUP")
                                            changePage('add-card-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                history.push('/app/buyer/my-account');
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                history.push('/app/seller/my-account');
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }
                                        else if(query.get('applying_for') === "merchant"){
                                            console.log("MERCHANT SIGNUP")
                                            changePage('apply-merchant-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                history.push('/app/buyer/my-account');
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                history.push('/app/seller/my-account');
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }else{
                                            changePage('fork-page');
                                        }
                                       
                                       
                                    }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }else if(!resp.contact_number && !resp.sms_verified){
                                        setLoadingOverlay(false)
                                        changePage('contact-page')
                                    }
                                    
                                    else{
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }
                                }
                            })
                        }
                    )
                 }else{
                    events.close()
                 }
                 
                  break;
              }
            };
    
            events.onerror = (e: any) => {
                console.log(e)
                events.close()
                events = null;
            }
            if(location.pathname.includes('my-account') || page !== 'pending-page'){
                console.log('CLOSING')
                events.close();
                events = null;
            }
            return () => {
                events.close();
              };
        }
        
    };

    const emailSubscribe = async (id?: any) => {
        if(user) {
            let events: any = new EventSource(MyURL.EVENTS.APPLICATION+(id !== undefined ? id :user.id));
            console.log("SUBSCRIBING")
            events.onmessage = (event: any) => {
              switch (event.type) {
                case 'message':
                  const data = JSON.parse(event.data);
                  console.log(data)
                   /*const updateActivities = [...activities]
                  updateActivities.push(data)
                  console.log(updateActivities);
                  setActivities(updateActivities);
                  */
                 if(data.event === "application" && data.action === "re-render"){
                    User.get(
                        (resp) => {
                            console.log(resp)
                            User.getSource((cardData) => {
                                setSource(cardData.data.results)
                                setUser(resp);
                                if(resp.emailConfirmed && page === 'email-verif-page'){
                                    if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                        //history.push('/app/my-account')
                                        setLoadingOverlay(false)
                                        //changePage('fork-page')
                                        setIsEmailConfirmed(resp.emailConfirmed)
                                        if(query.get('applying_for') === "buyer"){
                                            console.log("BUYER SIGNUP")
                                            changePage('add-card-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                history.push('/app/buyer/my-account');
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                history.push('/app/seller/my-account');
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }
                                        else if(query.get('applying_for') === "merchant"){
                                            console.log("MERCHANT SIGNUP")
                                            changePage('apply-merchant-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                history.push('/app/buyer/my-account');
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                history.push('/app/seller/my-account');
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }else{
                                            changePage('fork-page');
                                        }
                                       
                                       
                                    }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }else if(!resp.contact_number && !resp.sms_verified){
                                        setLoadingOverlay(false)
                                        changePage('contact-page')
                                    }
                                    
                                    else{
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }
                                }
                            })
                        }
                    )
                 }else{
                    events.close()
                 }
                 
                  break;
              }
            };
    
            events.onerror = (e: any) => {
                console.log(e)
                events.close()
                events = null;
            }
            if(location.pathname.includes('my-account') || page !== 'email-verif-page'){
                console.log('CLOSING')
                events.close();
                events = null;
            }
            return () => {
                events.close();
              };
        }
        
    };

    useEffect(() =>{
        if(page !== query.get('page')){
            history.push(`/login?page=${page}&${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'applying_for=buyer' : query.get('applying_for') === 'merchant' ? 'applying_for=merchant' : '' : '' }`)
        }
        
        //changePage(page);
        if(page === 'pending-page'){
            subscribe();
        }else if(page === 'email-verif-page' && user){
            
            emailSubscribe(user.id);
             
            
        }
    },[page, user])

    useEffect(() => {
        if(Auth.isAuthenticated()){
            User.get(
                (resp) => {
                    setUser(resp)
                }
            )
        }else{
            //window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
            //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
            //changePage('login-page')
            //Auth.logout();
        }
    },[])

    const getRegErrorMsg = useCallback((field) => {
        const key: (keyof IRegErrorMsg) = field;
        if(regErrorMSG.hasOwnProperty(field)){
            return regErrorMSG[key][0];
        }
    }, [regErrorMSG]);

    const getProfileErrorMsg = useCallback((field) => {
        const key: (keyof IProfileErrorMsg) = field;
        if(profileErrorMSG.hasOwnProperty(field)){
            return profileErrorMSG[key][0]
        }
    }, [profileErrorMSG])

    const getLogErrorMsg = useCallback((field) => {
        const key: (keyof ILogErrorMsg) = field;
        if(logErrorMSG.hasOwnProperty(field)){
            return logErrorMSG[key][0];
        }
    }, [logErrorMSG]);



    
    function useFocusNext() {
        const controls = useRef<any>([]);
      
        const handler = (event: any) => {
          if (event.keyCode === 13) {
            // Required if the controls can be reordered
            controls.current = controls.current
              .filter((control:any) => document.body.contains(control))
              .sort((a: any, b: any) =>
                a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
                  ? -1 : 1
              );
      
            const index : any= controls.current.indexOf(event.target);
            const next:any = controls.current[index + 1];
            next && next.focus();
      
            // IE 9, 10
            event.preventDefault();
          }
        };
      
        return useCallback((element: any) => {
          if (element && !controls.current.includes(element)) {
            controls.current.push(element);
            element.addEventListener('keydown', handler);
          }
        }, []);
    };
      
    const focusNextRef = useFocusNext();

    function handleEnter(event:any) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    const changePage = useCallback((thePage: string) => {
        setPage(thePage);
    }, []);

    const validatePage1 = () => {
        resetErrors();
        let hasErrors = false;
        let errors = {
            first_name: '',
            last_name: '',
        }
        if(values.first_name === '' ){
            errors.first_name = 'This field must not be blank';
            hasErrors = true;
        }
        if(values.last_name === ''){
            errors.last_name = 'This field must not be blank';
            hasErrors = true;
        }
      
        
        if(hasErrors){
            console.log(errors);
            setErrorProfile1({errors});
        }else{
            setErrorProfile1({errors});
            setCompletedProf1(true)
        }
    }

    useEffect(() => {
        if(completedProf1){
            changePage('profile2-page')
        }
    },[completedProf1])

    useEffect(() => {
        if(completedContact){
            Auth.updateNumber(
                values.contact_number,
                (resp) => {
                    console.log(resp)
                    sendOTP()
                },
                (e) => {
                    console.log(e, 'error number')
                    setErrorNumber(e.detail);
                }
            )
            
        }
    },[completedContact])
    
    const validateNumber = () => {
        resetErrors();
        let hasErrors = false; 
        let errors = {
            contact_number: ''
        }
        if(!values.contact_number.match(/^(9)\d{9}$/)){
            errors.contact_number = 'This field cannot be invalid'
            hasErrors = true;
        }

        if(hasErrors){
            console.log(errors);
            setErrorNumber(errors.contact_number);
        }else{
            setErrorNumber(errors.contact_number);
            setCompletedContact(true)
        }
    }
    
    useEffect(() => {
        if(completedProf2){
            saveProfile();
        }
    },[completedProf2])

    const validatePage2 = () => {
        resetErrors();
        let hasErrors = false;
        let errors = {
            street: '',
            region: '',
            city: '',
            zip_code: '',
            barangay: '',
        }

        if(values.street === '' || values.street.length > 200){
            hasErrors = true;
            errors.street = "This field must not be empty nor exceed 200 characters";
        }
        
        if(values.region === ''){
            hasErrors = true;
            errors.region = "This field must not be blank";
        }
        if(values.city === ''){
            hasErrors = true;
            errors.city = "This field must not be blank";
        }
        if(values.zip_code === ''){
            hasErrors = true;
            errors.zip_code = "This field must not be blank";
        }
        if(values.barangay === ''){
            hasErrors = true;
            errors.barangay = "This field must not be blank";
        }

        if(hasErrors){
            setErrorProfile2({errors})
        }else{
            setCompletedProf2(true)
        }
    }

   

    const saveProfile = () => {
        
        Auth.updateProfile(
            values.first_name,
            values.middle_name,
            values.last_name,
            gender,
            values.street,
            values.street2,
            values.region,
            values.city,
            values.barangay,
            values.zip_code,
            (response) => {
                console.log(response)
                if(!response.sms_verified){
                    changePage('contact-page');
                }/*else if(!response.emailConfirmed){
                    emailSubscribe(response.id);
                    changePage('email-verif-page')
                }*/else{
                    if(query.has('applying_for')){
                        if(query.get('applying_for') === "buyer"){
                            console.log("BUYER LOGIN")
                            if(response.buyAuthorized){
                                history.push('/app/buyer/my-account')
                            }else{
                                changePage('fork-page')
                            }
                        }
                        else if(query.get('applying_for') === "merchant"){
                            console.log("MERCHANT LOGIN")
                            if(response.buyAuthorized){
                                history.push('/app/seller/my-account')
                            }else{
                                changePage('fork-page')
                            }
                        }
                    }else{
                        if(response.sellAuthorized){
                            console.log("Sell View")
                            setBuyOrSellMode("sell")
                            history.push('/app/seller/my-account');
                            
                        }else if(response.buyAuthorized){
                            console.log("Buy View")
                            setBuyOrSellMode("buy")
                            history.push('/app/buyer/my-account');
                        }else{
                            console.log("Fork screen")
                            changePage('fork-page')
                        }
                    }
                }
                
            },
            (e)=> {
                console.log(e)
            }
        )
    }

    /*
    useEffect(() => {
        if(response !== null) {
            console.log(response);
            changePage('profile1-page');
        }
    },[response])*/

    const submitRegistration = useCallback(() => {
        setLoadingOverlay(true)
        
        resetErrors()
        Auth.register(
            values.register_email.substr(0, values.register_email.indexOf('@')), 
            values.register_email,
            values.register_password,
            values.register_passwordConfirm,
            (response) => {
                console.log(response);
                setResponse(response.key);
                Auth.paymayaInit();
                changePage('email-verif-page');
                User.get(
                    (resp) => {
                        setUser(resp)
                    }
                )
                setLoadingOverlay(false);
                //history.push('/my-account')
                
                /*Auth.login(
                    values.register_email,
                    values.register_password,
                    (response) => {
                        setLoadingOverlay(false)
                        console.log(response)
                        
                    },
                    (e) => {
                        setLoadingOverlay(false)
                        setRegErrorMSG(e);
                    }
                )*/
                
            },
            (e) => {
                setLoadingOverlay(false)
                setRegErrorMSG(e);
                if(values.register_password !== values.register_passwordConfirm){
                    setSignUpPassword2(`Those passwords didn't match. Try Again.`)
                }
                if(e[0]){
                    setSignUpPassword2(e[0])
                }
            }
        );
    }, [
        values.register_username, 
        values.register_email, 
        values.register_password, 
        values.register_passwordConfirm, 
    ]);

    const sendOTP = () => {
        Auth.initSMSVerification(
            '+63'+values.contact_number,
            (resp) => {
                if(resp.response.error){
                    setOpenErrorDialog(true)
                    setErrorDialogMessage(resp.response.error)
                    setCompletedContact(false)
                    console.log(resp.response.error)
                }else{
                    setSessionID(resp.response.sessionId)
                    setSeconds(30);
                    setLoadingOverlay(false)
                    changePage('sms-verify')
                }
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorDialogMessage(e.error)
                console.log(e)
            }
        )
    }

    const smsVerify = () => {
        Auth.smsVerification(
            sessionID,
            otp.join(""),
            (resp) => {
                console.log(resp)
                
                if(resp.response.status === "WAITING"){
                    setOpenErrorDialog(true)
                    setErrorDialogMessage("Error verifying SMS")
                }else if(resp.response.status === "EXPIRED"){
                    setOpenErrorDialog(true)
                    setErrorDialogMessage("OTP Code has expired. Please resend again.")
                }else{
                    setOpenDialog(true);
                    setDialogMessage('SMS Verification completed!')
                
                    if(Auth.isAuthenticated()){

                    
                        /*Auth.updateNumber(
                            values.contact_number !== ''? values.contact_number : user !== null ? user.contact_number : '',
                            (resp) => {
                                setOpenDialog(true);
                                setDialogMessage('SMS Verification completed!')
                            },
                            (e) => {
                                setOpenErrorDialog(true)
                                setErrorDialogMessage(e.error)
                                console.log(e)
                            }
                        )
                        */
                    }
                    
                }
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorDialogMessage(e.detail)
                console.log(e)
            }
        )
    }

    const addCard = () => {
        setLoadingOverlay(true)
        //Auth.paymayaInit();

        var res = addCardExpiry.split('/')
        Auth.tokenize_card(
            addCardNumber,
            addCardExpiryMonth,
            '20'+addCardExpiryYear,
            addCardCVC,
            (r) => {
                Auth.addCard(
                    r.paymentTokenId,
                    query.has('pid') ? query.get('pid') : null,
                    (resp) => {
                        console.log('card added successfully');
                        console.log(resp);
                        window.location.replace(resp.paymaya_response.verificationUrl)
                        /*setDialogMsg('Your card has been added successfully!');
                        setOpen(false);
                        setOpenSuccess(true);*/
                        setLoadingOverlay(false)
                    },
                    (e) => {
                        console.log(e);
                        setErrorDialogMessage("An error has occured");
                        setOpenErrorDialog(true)
                        setLoadingOverlay(false)
                    }
                )
                
            },
            (e) => {
                console.log(e);
                setErrorDialogMessage(e.message);
                let [cardNumb] = e.parameters.filter((resp: any) => resp.field === 'card.number') 
                if(cardNumb !== undefined){
                    setErrorDialogMessage(cardNumb.description)
                }
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
            }
        )
    }

    useEffect(() => {
        if(page === 'login-page' || page === 'registration-page'){
            //Auth.logout();
        }else if(Auth.isAuthenticated()){
            User.get(
                (resp) => {
                    setUser(resp)
                    setIsEmailConfirmed(resp.emailConfirmed)
                }
            )
        }
    },[])


    useEffect(() => {
        console.log(preview)
    },[preview])

    
    const memoIDFront = useMemo(() => {
        return preview.IDFront
    },[preview.IDFront])


    const memoBankAcctNum = useMemo(() => {
        return bankAccountNumber
    },[bankAccountNumber])

    const previewHandler = useCallback(async (e: any) => {
        if(e.target.files.length !== 0){
            let name: string = e.target.name;
            let newFile = e.target.files[0]
            /*let newObj = {
                ...preview,
                [name] : newFile
            }
            preview = newObj
            console.log(preview)
            console.log(newObj)
        */
            setPreview({
                ...preview,
                [name]: newFile
            })
        }
    },[preview])

    const deletePreview = (name: any) =>{
        /*preview ={
            ...preview,
            [name]: ''
        }*/
    }

    const sendUpdatedRequest = (e:any) => {
        setLoadingOverlay(true) 
        e.preventDefault()
        /*Auth.upgradeAcc(
            files.IDFront,
            files.IDBack,
            files.Bankproof,
            editBankCode,
            editBankAccountNumber,
            (response) => {
                console.log(response)
                setDialogMsg("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                setOpenSuccess(true)
                User.get((userdata: any) => {
                    setUser(userdata)
                })
                setLoadingOverlay(false)
                setEditBankDetails(false)
            },
            (e) => {
                setError(e.detail)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )*/
        
        
        Auth.accUpgrade.uploadIDFront(
            files.IDFront,
            (resp) => {
                Auth.accUpgrade.uploadIDBack(
                    files.IDBack,
                    (resp) => {
                        Auth.accUpgrade.uploadBankproof(
                            files.Bankproof,
                            (resp) => {
                                Auth.accUpgrade.updateBankDetails(
                                    editBankCode,
                                    editBankAccountNumber,
                                    (resp) => {
                                        console.log(resp)
                                        User.get((userdata: any) => {
                                            setUser(userdata)
                                        })
                                        setMerchantDialogMessage("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                                        setMerchantOpenDialog(true)
                                        setIsMerchantRejected(false)
                                        ReactPixel.track('Resubmit Merchant Application')
                                        setLoadingOverlay(false)
                                        //setEditBankDetails(false)
                                    },
                                    (e) => {
                                        setErrorDialogMessage(e.detail)
                                        setOpenErrorDialog(true)
                                        setLoadingOverlay(false)
                                        console.log(e);
                                    }
                                )
                            },
                            (e) => {
                                setErrorDialogMessage(e.detail)
                                setOpenErrorDialog(true)
                                setLoadingOverlay(false)
                                console.log(e);
                            }
                        )
                    },
                    (e) => {
                        setErrorDialogMessage(e.detail)
                        setOpenErrorDialog(true)
                        setLoadingOverlay(false)
                        console.log(e);
                    }
                )
            },
            (e) => {
                setErrorDialogMessage(e.detail)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )
        
    }

    const upgradeAccount = useCallback(
        (e: any) => {
            setLoadingOverlay(true)
            e.preventDefault();
            Auth.upgradeAcc(
                files.IDFront,
                files.IDBack,
                files.Bankproof,
                bankCode,
                bankAccountNumber,
                (response) => {
                    console.log(response)
                    setMerchantDialogMessage("Account upgrade request sent! Please allow up to 48 hours for verification.");
                    setMerchantOpenDialog(true)
                    ReactPixel.track('Submit Merchant Application')
                    setLoadingOverlay(false)
                    changePage('pending-page')
                    User.get((userdata: any) => {
                        User.getSource((cardData) => {
                            setSource(cardData.data.results)
                            setUser(userdata);
                            
                            /**
                             * 
                             * if(location.pathname.includes('buyer') && !userData.buyAuthorized){
                                history.push('/app/seller/vault-cards?no_card=true');
                            }else if(location.pathname.includes('seller') && !userData.sellAuthorized){
                                history.push('/app/buyer/account-upgrade?send_documents=true')
                            }
                             */
                            
                            setLoadingOverlay(false)
                        })
                    
                    })
                },
                (e) => {
                    let errorMsg = '';
                    if(e.bankProof !== undefined){
                        setErrorDialogMessage(e.bankProof[0])
                    }else if(e.IDFront !== undefined){
                        setErrorDialogMessage(e.IDFront[0])
                    }
                    else if(e.IDBack !== undefined){
                        setErrorDialogMessage(e.IDBack[0])
                    }else if(e.bankAccountNumber){
                        setErrorDialogMessage(e.bankAccountNumber[0])
                    }else{
                        setErrorDialogMessage(e.detail)
                    }
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
                
            )
        },
        [
            files.Bankproof,
            bankCode,
            files.IDBack,
            files.IDFront,
            bankAccountNumber
        ],
    )

    const resendEmailVerif = () => {
        setLoadingOverlay(true)
        if(user){
            Auth.resendVerifEmail(
                user.email,
                (resp) => {
                    console.log(resp)
                    setOpenDialog(true)
                    setLoadingOverlay(false)
                    setDialogMessage("A verification email has been sent.");
                },
                (e) => {
                    console.log(e)
                    setLoadingOverlay(false)
                    setErrorDialogMessage(e.new_password2 ? e.new_password2 : e.new_password1 ? e.new_password1 : "We have encountered an error. Please try again in a few minutes")
                    setOpenErrorDialog(true)
                }
            )
        }
        
    }

    const postRegisterLogin = () => {
        setLoadingOverlay(true)
        resetErrors()
        if(Auth.isAuthenticated()){
            User.get(
                (resp) => {
                    setUser(resp)
                    emailSubscribe(resp.id)
                    setLoadingOverlay(false)
                }
            )
        }else {

        
        Auth.login(
            values.login_email !== '' ? values.login_email : values.register_email,
            values.login_password !==  '' ? values.login_password : values.register_password,
            (response) => {
                
                //history.push('/app/my-account')
                //emailSubscribe();
                
                changePage('email-verif-page');
                /*User.get(
                    (resp) => {
                        console.log(resp)
                        if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                            //history.push('/app/my-account')
                            setLoadingOverlay(false)
                            //changePage('fork-page')
                            if(query.get('applying_for') === "buyer"){
                                console.log("BUYER SIGNUP")
                                changePage('add-card-page')
                                if(resp.buyAuthorized){
                                    console.log("Buy View")
                                    history.push('/app/buyer/my-account');
                                }else if(resp.sellAuthorized){
                                    console.log("Sell View")
                                    history.push('/app/seller/my-account');
                                }else{
                                    console.log("Fork screen")
                                }
                            }
                            else if(query.get('applying_for') === "merchant"){
                                console.log("MERCHANT SIGNUP")
                                changePage('apply-merchant-page')
                                if(resp.buyAuthorized){
                                    console.log("Buy View")
                                    history.push('/app/buyer/my-account');
                                }else if(resp.sellAuthorized){
                                    console.log("Sell View")
                                    history.push('/app/seller/my-account');
                                }else{
                                    console.log("Fork screen")
                                }
                            }
                           
                           
                        }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }else if(!resp.contact_number && !resp.sms_verified){
                            setLoadingOverlay(false)
                            changePage('contact-page')
                        }
                        
                        else{
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }
                    }
                )*/
            },
            (e) => {
                setLoadingOverlay(false)
                setRegErrorMSG(e);
            }
        )}
    }

    const verifyEmail = () => {
        if(Auth.isAuthenticated()) {
            Auth.authVerifyEmail(
                null,
                emailCode,
                (resp) => {
                    console.log(resp)
                    setVerifSuccess(true)
                    setUser(resp)
                    ReactPixel.track('Email Verified')
                    setIsEmailConfirmed(true)
                    setVerifSuccessMessage('Email successfully validated!');
                },
                (e) => {
                    console.log(e.error)
                    setVerifErrorMessage(e.error ? e.error : e.msg ? e.msg : 'There was an error validating your email. Please try again.');
                    setVerifError(true)
                }
            )
        }else{
            Auth.verifyEmail(
                null,
                emailCode,
                (resp) => {
                    console.log(resp)
                    setVerifSuccess(true)
                    setUser(resp)
                    setVerifSuccessMessage('Email successfully validated!');
                },
                (e) => {
                    console.log(e.error)
                    setVerifErrorMessage(e.error ? e.error : e.msg ? e.msg : 'There was an error validating your email. Please try again.');
                    setVerifError(true)
                }
            )
        }
    }

    const submitLogin = useCallback(() => {
        setLoadingOverlay(true)
        resetErrors()
        Auth.login(
            values.login_email,
            values.login_password,
            (response) => {
                console.log('moving to my account....');
                console.log(response)
                User.get(
                    (resp) => {
                        console.log(resp)
                        User.getSource((cardData) => {
                            setSource(cardData.data.results)
                            setUser(resp);
                            
                            /**
                             * 
                             * if(location.pathname.includes('buyer') && !userData.buyAuthorized){
                                history.push('/app/seller/vault-cards?no_card=true');
                            }else if(location.pathname.includes('seller') && !userData.sellAuthorized){
                                history.push('/app/buyer/account-upgrade?send_documents=true')
                            }
                             */
                            setIsEmailConfirmed(resp.emailConfirmed)
                            
                            if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                //history.push('/app/my-account')
                                //setLoadingOverlay(false)
                                //changePage('fork-page')
                                if(query.has('applying_for')){
                                    if(query.get('applying_for') === "buyer"){
                                        console.log("BUYER LOGIN")
                                        if(!resp.emailConfirmed){
                                            emailSubscribe(resp.id);

                                            setLoadingOverlay(false)
                                            changePage('email-verif-page');
                                        }
                                        else if(resp.buyAuthorized){
                                            setLoadingOverlay(true)
                                            window.location.href = window.location.origin + '/app/buyer/my-account'
                                        }
                                        else{
                                            setLoadingOverlay(false)
                                            changePage('add-card-page')
                                        }
                                    }
                                    else if(query.get('applying_for') === "merchant"){
                                        console.log("MERCHANT LOGIN")
                                        if(!resp.emailConfirmed){
                                            setLoadingOverlay(false)
                                            emailSubscribe(resp.id);
                                            changePage('email-verif-page');
                                        }
                                        else if(resp.sellAuthorized){
                                            setLoadingOverlay(true)
                                            window.location.href = window.location.origin + '/app/seller/my-account'
                                        }
                                        else{
                                            if(resp.Bankproof && (resp.IDBack && resp.IDFront)){

                                                setLoadingOverlay(false)
                                                changePage('pending-page')
                                            }else{
                                                setLoadingOverlay(false)
                                                changePage('apply-merchant-page')
                                            }
                                            
                                        }
                                    }
                                }else{
                                    if(resp.sellAuthorized){
                                        console.log("Sell View")
                                        setBuyOrSellMode("sell")
                                        setLoadingOverlay(true)
                                        window.location.href = window.location.origin + '/app/seller/my-account'
                                        
                                    }else if(!resp.emailConfirmed){
                                        emailSubscribe(resp.id);
                                        setLoadingOverlay(false)
                                        changePage('email-verif-page');
                                    }
                                    else if(resp.buyAuthorized){
                                        console.log("Buy View")
                                        setBuyOrSellMode("buy")
                                        window.location.href = window.location.origin + '/app/buyer/my-account'
                                        setLoadingOverlay(true)
                                    }else{
                                        console.log("Fork screen")
                                        setLoadingOverlay(false)
                                        changePage('fork-page')
                                    }
                                }
                                
                                
                               
                               
                            }else if(!resp.emailConfirmed){
                                emailSubscribe(resp.id);
                                setLoadingOverlay(false)
                                changePage('email-verif-page');
                            }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                setLoadingOverlay(false)
                                changePage('profile1-page')
                            }else if(!resp.contact_number && !resp.sms_verified){
                                setLoadingOverlay(false)
                                changePage('contact-page')
                            
                            }
                            else{
                                setLoadingOverlay(false)
                                changePage('profile1-page')
                            }
                            
                        })
                        
                    }
                )
               
                
            },
            (e) => {
                setLoadingOverlay(false)
                setLogErrorMSG(e);
            }
        )
    }, [
        values.login_email, 
        values.login_password, 
    ]);

    const forgotPassword = useCallback(() => {
        setLoadingOverlay(true)
        Auth.forgotPassword(
            values.forgot_password_email,
            (response) => {
                setLoadingOverlay(false)
                setOpenDialog(true)
                setDialogMessage("Your request for a password change has been sent! Please check your email.")
            },
            (e) => {
                setLoadingOverlay(false)
                setErrorForgotPassword(e.email[0])
                setLogErrorMSG(e)
            }
        )
    },[
        values.forgot_password_email
    ])

    const onKeyPress = (e: any) => {
        if (e.keyCode === 13 && page === 'login-page') {
         
          submitLogin();
          
        }else if (e.keyCode === 13 && page === 'contact-page') {
         
            validateNumber();
            
        }else if (e.keyCode === 13 && page === 'sms-verify') {
         
            smsVerify();
            
        }else if (e.keyCode === 13 && page === 'email-verif-page') {
         
            verifyEmail();
            
        }else if (e.keyCode === 13 && page === 'profile2-page'){

            validatePage2()

        }else if(e.keyCode === 13 && page === 'forgot-password'){

            forgotPassword()

        }else if(e.keyCode === 13 && page === 'add-card-page'){

            addCard()

        }
        else if(e.keyCode === 13 && page === 'apply-merchant-page'){
            if(user?.IDFront){
                sendUpdatedRequest(e)
            }else{
                upgradeAccount(e)
            }
            

        }
    }

    return(
        <>
        {loadingOverlay ?
            <Backdrop className={classes.backdrop} open={loadingOverlay} >
                <BounceLoader color={"#FF873A"} />
            </Backdrop>
        : null}
        <div className="login" >
            <div className="login-container" style={{width: matches ? '100vw' : '100vw', margin: 'auto'}}>
                <div style={{height: '-webkit-fill-available'}}>
                <Grid container style={{width: '100%', height: matches ?'100%': '90vh', margin: 'auto'}} direction="row" spacing={3}>
                  
                        <>
                        {query.has('applying_for') && query.get('applying_for') === 'buyer' && (

                        
                        <Grid container item xs={12} md={6}  direction="column" style={{backgroundColor: 'white', border: '1px solid #FF873A'}} alignItems="center" justify="center" >
                            <Grid item style={{padding: '50px'}}>
                                <img src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogo : query.get('applying_for') === 'merchant' ? sellerLogo : '' : ''}  style={{width: !matches ? '200px' : '300px'}} />
                            </Grid>
                            <Grid item>
                                <div style={{ textAlign: 'center', padding: '20px 0px 20px'}}>
                                    <Typography variant={matches ? "h6" : "h5"} style={{color: '#FF873A', fontWeight: 'bold'}}>
                                        {query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'Easy Pay in Installments' : query.get('applying_for') === 'merchant' ? 'Offer Installment Payments' : '' : ''}
                                    </Typography>
                                    <Typography variant={"body1"} style={{color: "black", padding:'10px 30px 30px 30px'}}>
                                        {query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'Pay in installment, fast and easy.' : query.get('applying_for') === 'merchant' ? 'Increase your sales by offering more payment options.' : '' : ''}Pay in installment, fast and easy.
                                    </Typography>
                                </div>
                            </Grid>
                            
                        </Grid>
                        )}

                        {query.has('applying_for') && query.get('applying_for') === 'merchant' && (
                            <Grid item xs={12} md={6} style={{background: `url(${merch})`, objectFit: 'contain', padding:'140px', backgroundRepeat: 'no-repeat', backgroundSize: matches ? 'auto 100%' : 'auto auto', width: '500px', height: 'auto', backgroundPosition: 'left center'}} />
                        )}
                        </>
                   
                    
               
                    <Grid container item xs={12} md={6} style={{padding: matchesBelow ? '50px 170px' : matches ? '50px 120px' :'50px 20px 80px', backgroundColor: '#FF873A',  border: '1px solid #FF873A'}} direction="row"  justify="center" alignItems={matches ? "center" : "flex-start"}>

                        <Grid item xs={12}>
                            <div style={{margin: matches ? '20px' : '20px', textAlign: 'left'}}>
                                <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: matches ? '100%' : '250px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: 'auto'}} isOpen={!isEmailConfirmed && ((page === 'fork-page' || page === 'pending-page') || (page === 'add-card-page' || page === 'apply-merchant-page'))}>
                                    <div onClick={() => {changePage('email-verif-page')}}> <p style={{fontWeight: 'bold'}}>Email Not Verified:</p> Please verify your email.</div>
                                </Alert>
                                <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: matches ? '100%' : '250px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: 'auto'}} isOpen={page === 'add-card-page'}>
                                    <div > <p style={{fontWeight: 'bold'}}>Note:</p> 4Gives will validate your card by charging PHP 10. Once it is validated, it will be immediately refunded to your card.</div>
                                    <br />
                                    <div > 4Gives will never take PHP 10 from your account.</div>
                                </Alert>
                            </div>
                            {/* LOGIN */}
                            {((query.has('page') && query.get('page') === 'login-page' ))&& (
                            <div className="form login-form">
                                <div className="login-title">
                                    
                                    <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}} src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                    <h4>{query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'Login as a Buyer' : query.get('applying_for') === 'merchant' ? 'Login as a Merchant' : 'Login' : 'Login'}</h4>
                                </div>
                               
                            {/**                                 {query.has('applying_for') && (

                                    <Row>
                                       
                                        <Col xs={12} sm={12}>
                                            <p 
                                                onClick={() => history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=registration-page&applying_for=merchant' : query.get('applying_for') === 'merchant' ? '/login?page=registration-page&applying_for=buyer' : '/login?page=registration-page': '/login?page=registration-page')}
                                                style={{cursor: 'pointer', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Are you a {query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'seller' : query.get('applying_for') === 'merchant' ? 'buyer' : '' : ''}?
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                                */}
                                <form>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='login_email'
                                        type='email'
                                        value={values.login_email}
                                        onChange={handleChange}
                                        placeholder='E-mail'
                                        icon={{ prefix: 'far', name: 'envelope'}}
                                        onKeyDown={handleEnter}
                                    />
                                </div>
                                {getLogErrorMsg('email') ? (
                                    <p className='email' style={{fontSize: '12px', margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { getLogErrorMsg('email')}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='login_password'
                                        type='password'
                                        value={values.login_password}
                                        onChange={handleChange}
                                        onKeyDown={onKeyPress}
                                        placeholder='Password'
                                        icon={{ prefix: 'fas', name: 'key'}}
                                    />
                                </div>
                                {getLogErrorMsg('password') ? (
                                    <p className='password' style={{fontSize: '12px', margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { getLogErrorMsg('password')}
                                    </p>
                                ) : null}
                                {getLogErrorMsg('non_field_errors') ? (
                                    <p className='none_field_error' style={{fontSize: '12px', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '10px'}}>
                                    { getLogErrorMsg('non_field_errors') ? `Error: ${getLogErrorMsg('non_field_errors')}` : null}
                                    </p>
                                ) : null}
                                
                                <div className="form-container">
                                    <Row>
                                        <Col>
                                            
                                            <Button 
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    submitLogin();
                                                }}
                                                color={'login-primary'}
                                                className='login-button fg-button-block form-button' 
                                            >  
                                                Sign In
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Link to={`/login?page=registration-page${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '&applying_for=merchant' : '' : '' }`} style={{textDecoration: 'none'}}>
                                                <Button 
                                                    onClick={(e: any) => {
                                                        e.preventDefault();
                                                        history.push(`/login${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '?page=registration-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '?page=registration-page&applying_for=merchant' : '?page=registration-page' : '?page=registration-page' }`)
                                                        changePage('registration-page')
                                                    }}
                                                    color={'login-secondary'}
                                                    className='goto-register-button fg-button-block'
                                                >  
                                                    Register
                                                </Button>
                                            </Link>
                                        </Col>
                                        
                                    </Row>
                                    

                                </div>
                                </form>
                                <div className="form-container page-linker-container">
                                
                                </div>
                                <div className="form-container page-linker-container">
                             
                                    <Button 
                                        onClick={() => {
                                            changePage('forgot-password');
                                        }}
                                        color={'login-secondary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        Forgot Password
                                    </Button>
                              
                                </div>
                                <div className="form-container page-linker-container">
                                    
                                    <Button 
                                        onClick={() => {
                                           
                                                history.push(`${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/' : query.get('applying_for') === 'merchant' ? '/merchant' : '/' : '/' }`)
                                            
                                            
                                            
                                        }}
                                        color={'login-secondary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        Go Back
                                    </Button>
                                    
                                </div>
                            </div>
                            )}
                            
                            {/* FORGOT PASSWORD */}
                            {((query.has('page') && query.get('page') === 'forgot-password' ))&& (
                                <div className="form registration-form">
                                    <div className="registration-title">
                                        <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}} src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <h4>Forgot Password</h4>
                                        <p>All fields are required</p>
                                    
                                    </div>
                                    <div className="form-container">
                                        <TextLoginInput 
                                            name='forgot_password_email'
                                            type='text'
                                            value={values.forgot_password_email}
                                            onChange={handleChange}
                                            placeholder='Email'
                                            onKeyDown={onKeyPress}
                                            icon={{ prefix: 'far', name: 'envelope'}}
                                            errorMessage={getRegErrorMsg('forgot_password_email')}
                                        />
                                    </div>
                                    {errorForgotPassword ? (
                                        <p className='errorForgotPassword' style={{fontSize: '12px', margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                        { errorForgotPassword}
                                        </p>
                                    ) : null}
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                forgotPassword()
                                            }}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            history.push(`/login${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '?applying_for=buyer' : query.get('applying_for') === 'merchant' ? '?applying_for=merchant' : '' : '' }`)
                                            changePage('login-page')
                                        }}
                                        color={'login-secondary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        Back
                                    </Button>
                                    </div>
                                </div>
                            )}

                            {/* FORK SCREEN */}
                            {((query.has('page') && query.get('page') === 'fork-page' ))&&  (
                                <div className="form fork-form">
                                    <div className="registration-title">
                                        <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <h4>What would you like to do?</h4>
                                       
                                        <p>Select an option to proceed</p>
                                    </div>
                                    <div className="form-container" >
                                        
                                        <div className="imgBtn-login" onClick={() => {
                                            User.get(
                                                (resp) => {
                                                    if(resp.buyAuthorized){
                                                        history.push('/app/buyer/my-account')
                                                    }else{
                                                        changePage('add-card-page')
                                                    }
                                                }
                                            )
                                            
                                        }}>
                                            <img src={purchaseIcon}  />
                                            <div className="imgBtn-text" >
                                                I would like to purchase an item in installments.
                                            </div>
                                        </div>
                                            
                                        
                                        
                                    </div>
                                    <div className="form-container">
                                        <div className="imgBtn-login" onClick={() => {
                                            User.get(
                                                (resp) => {
                                                    if(resp.sellAuthorized){
                                                        history.push('/app/seller/my-account')
                                                    }else{
                                                        if(user && user.IDFront && user.Bankproof && user.IDBack){
                                                            changePage('pending-page')
                                                        }else{
                                                            console.log(user)
                                                            changePage('apply-merchant-page')
                                                        }
                                                    }
                                                }
                                            )
                                        }}>
                                            <img src={merchantIcon}  />
                                            <div className="imgBtn-text" >
                                                I want to be a merchant that can offer installment payments.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                    
                                </div>
                            )}

                            {/* FORK SCREEN */}
                            {((query.has('page') && query.get('page') === 'pending-page' ))&& (
                                <div className="form pending-form">
                                    <div className="registration-title">
                                        <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        {isMerchantRejected ? 
                                            <>
                                                <h4>Your application has been rejected.</h4>
                                                <h4>Reason: {rejectNote}</h4>
                                            </>
                                        :
                                            <h4>You have a pending application, please wait for up to 48 hours for verification.</h4>
                                        }
                                       
                                    </div>
                                    {isMerchantRejected && (
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                               
                                                changePage('apply-merchant-page');
                                            }}
                                            color={'login-primary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            Resubmit Application
                                        </Button>
                                    </div>
                                    )}
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                setPreview({
                                                    IDFront: '',
                                                    IDBack: '',
                                                    Bankproof: '',
                                                })
                                                setBankAccountNumber(0)
                                                setBankCode('')
                                                changePage('fork-page');
                                            }}
                                            color={'login-primary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            I would like to purchase instead.
                                        </Button>
                                    </div>

                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                  
                                    
                                </div>
                            )}
                            
                            {/* EMAIL VERIF */}
                            {((query.has('page') && query.get('page') === 'email-verif-page' ))&& (
                                <div className="form fork-form">
                                    <div className="registration-title">
                                        <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <h4>Email Verification</h4>
                                        
                                        <p>Please enter the code that was sent to your email</p>
                                        
                                    </div>
                                    <div className="form-container">
                                        <TextLoginInput 
                                            name='emailCode'
                                            type='text'
                                            value={emailCode}
                                            onChange={(e) => {
                                                setEmailCode(e.target.value)
                                                
                                                
                                            }}
                                            onKeyDown={onKeyPress}
                                            placeholder='Enter your code'
                                        />
                                    
                                    </div>
                                    <div className="form-container">

                                        <Button 
                                            onClick={() => {
                                                verifyEmail()
                                            }}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>
                                    </div>
                                    
                                    <div className="form-container">

                                        <Button 
                                            onClick={() => {
                                                User.get(
                                                    (resp) => {
                                                        console.log(resp)
                                                        /*if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                                            //history.push('/app/my-account')
                                                            setLoadingOverlay(false)
                                                            //changePage('fork-page')*/
                                                            if(query.get('applying_for') === "buyer"){
                                                                console.log("BUYER SIGNUP")
                                                                if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                                                    setLoadingOverlay(false)
                                                                    changePage('profile1-page')
                                                                
                                                                }else if(!resp.contact_number && !resp.sms_verified){
                                                                    setLoadingOverlay(false)
                                                                    changePage('contact-page')
                                                                }
                                                                else if(!resp.buyAuthorized){
                                                                    history.push('/app/buyer/my-account');
                                                                }
                                                                
                                                                else if(resp.buyAuthorized){
                                                                    console.log("Buy View")
                                                                    history.push('/app/buyer/my-account');
                                                                }else{
                                                                    console.log("Fork screen")
                                                                }
                                                            }
                                                            else if(query.get('applying_for') === "merchant"){
                                                                console.log("MERCHANT SIGNUP")
                                                                
                                                                if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                                                    setLoadingOverlay(false)
                                                                    changePage('profile1-page')
                                                                
                                                                }else if(!resp.contact_number && !resp.sms_verified){
                                                                    setLoadingOverlay(false)
                                                                    changePage('contact-page')
                                                                }else if(resp.sellAuthorized){
                                                                    console.log("Sell View")
                                                                    history.push('/app/seller/my-account');
                                                                }else if (!resp.sellAuthorized ){
                                                                    if((resp.IDFront && resp.IDBack)  && resp.Bankproof){
                                                                        changePage('pending-page')
                                                                    }else{
                                                                        changePage('apply-merchant-page')
                                                                    }
                                                                    
                                                                }else{
                                                                    console.log("Fork screen")
                                                                }
                                                            }else{
                                                                changePage('fork-page')
                                                            }
                                                        
                                                        
                                                        /*}else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                                            setLoadingOverlay(false)
                                                            changePage('profile1-page')
                                                        }else if(!resp.contact_number && !resp.sms_verified){
                                                            setLoadingOverlay(false)
                                                            changePage('contact-page')
                                                        }
                                                        
                                                        else{
                                                            setLoadingOverlay(false)
                                                            changePage('profile1-page')
                                                        }*/
                                                    }
                                                )
                                            }}
                                            color={'login-secondary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Skip for Now
                                        </Button>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        resendEmailVerif()
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Didn't get your email? Click here to resend.
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </div>
                            )}

                            {/** APPLY FOR MERCHANT */}
                            {((query.has('page') && query.get('page') === 'apply-merchant-page' ))&& (
                                <div className="form apply-merchant-form">
                                    <div className="registration-title">
                                        <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <h4>Application for Merchant</h4>
                                    
                                        <p>To become a Merchant you will need to verify your identity by submitting a valid government ID, and verify your bank account to be used for receiving payments.</p>
                                        
                                    </div>
                                    {user && !((user.IDFront && user.IDBack)  && user.Bankproof) ?
                                        <>
                                        
                                    <Row>
                                    <p>Please upload a front and back picture of your government ID” (Important
                                            to say upfront what kind of government ID can they send if not all IDs are
                                            valid)</p>
                                        <Col md={12} xl={6}>
                                            
                                        <div className="form-container">
                                            <h6>Government ID Front</h6>
                                            
                                        
                                            <div className="image-container">
                                                            {/**      
                                                    <img 
                                                        className="image" 
                                                        src={memoIDFront !== '' ? URL.createObjectURL(memoIDFront) : ''}
                                                        style={{
                                                            border: '2px solid white', 
                                                            width: matches ? '150px' : '150px',
                                                            height: matches ? '150px' : '150px',
                                                            paddingTop: '10px'
                                                        }}
                                                    />*/}
                                                    {ImageIDFront}
                                                    
                                                    
                                                </div>
                                            <div className="fileupload">
                                                <FileUploadLogin
                                                    name='IDFront'
                                                    onChange={(e: any) => { previewHandler(e); handleFiles(e);} }
                                                    placeholder='Front ID'
                                                />
                                            </div>

                                            
                                        </div>
                                        </Col>
                                        <Col md={12} xl={6}>
                                            <div className="form-container">
                                                <h6>Government ID Back</h6>
                                                
                                           
                                                <div className="image-container">
                                                                
                                                    {ImageIDBack}
                                                    
                                                    
                                                </div>
                                         
                                                <div className="fileupload">
                                                    <FileUploadLogin
                                                        name='IDBack'
                                                        onChange={(e: any) => { previewHandler(e); handleFiles(e);} }
                                                        placeholder='Back ID'
                                                    />
                                                </div>

                                                
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        
                                        <Col md={12} xl={12}>
                                            <div className="form-container">
                                                <h6>Bank Statement</h6>
                                                <p>
                                                Please add your bank details and upload your latest bank statement. File
                                                format should be JPG, PNG or PDF, and must include your Full Name,
                                                Account Number and latest transactions
                                                </p>
                                                {/*preview.Bankproof !== '' &&(
                                                    <div className="image-container">
                                                                    
                                                        {ImageBankProof}
                                                        <CircularProgress />
                                                    </div>
                                                )
                                                    
                                                */}
                                                <div className="image-container">
                                                                    
                                                    {ImageBankProof}
                                                </div>
                                                <div className="fileupload">
                                                    <FileUploadLogin
                                                        name='Bankproof'
                                                        onChange={(e: any) => { previewHandler(e); handleFiles(e);} }
                                                        placeholder='Bankproof'
                                                    />
                                                </div>

                                                
                                            </div>
                                        </Col>
                                        <Col md={12} xl={12}>
                                            <form>
                                            <div className="form-container">
                                                <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                                <NativeSelect
                                                    id="demo-simple-select-autowidth"
                                                    name="bankCode"
                                                    value={bankCode}
                                                    className={classes.select}
                                                    onKeyDown={handleEnter}
                                                    style={{width: '100%', color: 'white'}}
                                                    onChange={(e: any)=>{console.log(e.target.value); setBankCode(e.target.value)}}
                                                >
                                                    <option value="" disabled>
                                                        Choose your bank...
                                                    </option>
                                                    {banks.map((bank : any) => (
                                                        <option key={bank.id} value={bank.value}>{bank.label}</option>
                                                    ))
                                                    }
                                                    
                                                </NativeSelect>
                                            </div>
                                            
                                            <div className="form-container">
                                                <h6>Account Number:</h6>
                                                <TextLoginInput 
                                                    name='bankAccountNumber'
                                                    onChange={(e: any) => 
                                                        setBankAccountNumber(e.target.value)
                                                    }
                                                    onKeyDown={onKeyPress}
                                                    type='number'
                                                    value={memoBankAcctNum}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                            </form>
                                        </Col>
                                    </Row>
                                    <div className="form-container">
                                        <Button 
                                            disabled={files.IDFront === '' || files.IDBack === '' || files.Bankproof === '' || bankCode === '' || bankAccountNumber === 0}
                                            onClick={upgradeAccount}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                setPreview({
                                                    IDFront: '',
                                                    IDBack: '',
                                                    Bankproof: '',
                                                })
                                                setBankAccountNumber(0)
                                                setBankCode('')
                                                changePage('fork-page');
                                            }}
                                            color={'login-secondary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            I changed my mind
                                        </Button>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                    </>
                                    :

                                    <>
                                       <Row>
                                        <p>Please upload a front and back picture of your government ID” (Important
                                            to say upfront what kind of government ID can they send if not all IDs are
                                            valid)</p>
                                        <Col md={12} xl={6}>
                                        
                                        <div className="form-container">
                                            <h6>Government ID Front</h6>
                                            
                                           
                                                <div className="image-container">
                                                                
                                                    {ImageIDFront}

                                                    
                                                    
                                                </div>
                                                <div onClick={openIDFrontRef} className='fg-uploadbutton'>
                                                    UPLOAD
                                                </div>
                                            <div className="fileupload-hidden">
                                                    {/***
                                                     *   <FileUpload
                                                        name='IDBack'
                                                        onChange={handleFiles}
                                                        ref={idBackRef}
                                                        placeholder='Back ID'
                                                    />
                                                     */}
                                                  
                                                    <input 
                                                        type="file" 
                                                        ref={idFrontRef} 
                                                        name="IDFront" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    setEditIDFront(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }
                                                        } 
                                                    />
                                            </div>

                                            
                                        </div>
                                        </Col>
                                        <Col md={12} xl={6}>
                                            <div className="form-container">
                                                <h6>Government ID Back</h6>
                                                
                                               
                                                    <div className="image-container">
                                                                    
                                                        {ImageIDBack}

                                                        
                                                    </div>
                                                    <div onClick={openIDBackRef} className='fg-uploadbutton'>
                                                            UPLOAD
                                                        </div>
                                                        
                                                <div className="fileupload-hidden">
                                                    {/***
                                                     *   <FileUpload
                                                        name='IDBack'
                                                        onChange={handleFiles}
                                                        ref={idBackRef}
                                                        placeholder='Back ID'
                                                    />
                                                     */}
                                                  
                                                    <input 
                                                        type="file" 
                                                        ref={idBackRef} 
                                                        name="IDBack" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    setEditIDBack(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }
                                                        } 
                                                    />
                                                </div>

                                                
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        
                                        <Col md={12} xl={12}>
                                            <div className="form-container">
                                                <h6>Bank Statement</h6>
                                                <p>
                                                Please add your bank details and upload your latest bank statement. File
                                                format should be JPG, PNG or PDF, and must include your Full Name,
                                                Account Number and latest transactions
                                                </p>
                                                
                                                    <div className="image-container">
                                                                    
                                                        {ImageBankProof}

                                                        
                                                        
                                                    </div>
                                                    <div onClick={openBankproofRef} className='fg-uploadbutton'>
                                                        UPLOAD
                                                    </div>
                                               
                                                
                                                
                                                <div className="fileupload-hidden">
                                                    {/***
                                                     * <FileUpload
                                                        name='Bankproof'
                                                        onChange={handleFiles}
                                                        ref={bankproofRef}
                                                        placeholder='Account Number'
                                                    />
                                                     */}
                                                    
                                                    <input 
                                                        type="file" 
                                                        ref={bankproofRef} 
                                                        name="Bankproof" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                                                                var i = Math.floor(Math.log(e.target.files[0].size) / Math.log(1024));
                                                                const imageSize = Math.round(e.target.files[0].size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
                                                                console.log(imageSize, 'IMAGE TEST');
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    setEditBankproof(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </Col>
                                        <Col md={12} xl={12}>
                                            <form>
                                            <div className="form-container">
                                                <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                                <NativeSelect
                                                    id="demo-simple-select-autowidth"
                                                    name="bankCode"
                                                    value={editBankCode}
                                                    style={{width: '100%', color: 'white'}}
                                                    onKeyDown={handleEnter}
                                                    className={classes.select}
                                                    onChange={(e: any)=>{console.log(e.target.value); setEditBankCode(e.target.value)}}
                                                >
                                                    <option value="" disabled>
                                                        Choose your bank...
                                                    </option>
                                                    {banks.map((bank : any) => (
                                                        <option key={bank.id} value={bank.value}>{bank.label}</option>
                                                    ))
                                                    }
                                                    
                                                </NativeSelect>
                                            </div>
                                            
                                            <div className="form-container">
                                                <h6>Account Number:</h6>
                                                <TextLoginInput 
                                                    name='bankAccountNumber'
                                                    onChange={(e: any) => setEditBankAccountNumber(e.target.value)}
                                                    type='number'
                                                    onKeyDown={onKeyPress}
                                                    value={editBankAccountNumber}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                            </form>
                                        </Col>
                                    </Row>
                                    <div className="form-container">
                                        <Button 
                                            disabled={editIDFront === '' || editIDBack === '' || editBankproof === '' || editBankCode === '' || editBankAccountNumber === 0}
                                            onClick={sendUpdatedRequest}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                setPreview({
                                                    IDFront: '',
                                                    IDBack: '',
                                                    Bankproof: '',
                                                })
                                                setEditBankproof('')
                                                setEditIDFront('')
                                                setEditIDBack('')
                                                setEditBankAccountNumber(0)
                                                setEditBankCode('')
                                                changePage('fork-page');
                                            }}
                                            color={'login-secondary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            I changed my mind
                                        </Button>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                    </>
                                    }
                                </div>
                            )}

                            {/** ADD CARD */}
                            {((query.has('page') && query.get('page') === 'add-card-page' ))&& (
                                <div className="form add-card-form">
                                    <div className="registration-title">
                                        <img  style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <h4>Your Account has been created</h4>
                                        <p>Add a credit card tp start shopping and sending payments with 4Gives</p>
                                        
                                    </div>
                                    <form>
                                    <div className="form-container">
                                        <TextLoginCardNumberInput 
                                            name='addCardNumber'
                                            type='text'
                                            value={addCardNumber}
                                            onKeyDown={handleEnter}
                                            onChange={(e) => {
                                                if(e.target.value.length < 17){
                                                    setAddCardNumber(e.target.value)
                                                }
                                                
                                            }}
                                            placeholder='Card Number'
                                        />
                                    
                                    </div>
                                    <div className="form-container">
                                        {/**
                                         * <TextLoginCardNumberInput 
                                            name='addCardExpiry'
                                            type='text'
                                            value={addCardExpiry}
                                            className={"expMonthYear"}
                                            onChange={(e) => {
                                                if(e.target.value.length < 17){
                                                    setAddCardExpiry(e.target.value)
                                                }
                                                
                                            }}
                                            placeholder='Card Expiry'
                                        />
                                         */}
                                        

                                        <TextLoginCardExpiryInput 
                                            expName1='addCardExpiryMonth'
                                            expName2='addCardExpiryYear'
                                            expValue1={addCardExpiryMonth}
                                            expValue2={addCardExpiryYear}
                                            expOnChange1={(e) => setAddCardExpiryMonth(e.target.value)}
                                            expOnChange2={(e) => setAddCardExpiryYear(e.target.value)}
                                            expOnKeyDown1={handleEnter}
                                            expOnKeyDown2={handleEnter}
                                            name="addCardExpiry"
                                            type='text'
                                            value={addCard}
                                            className={"expMonthYear"}
                                            onChange={(e) => {
                                                if(e.target.value.length < 17){
                                                    setAddCardExpiry(e.target.value)
                                                }
                                                
                                            }}
                                            placeholder='Card Expiry'
                                        />
                                    
                                    </div>
                                    <div className="form-container">
                                    
                                        <TextLoginCardNumberInput 
                                            name='addCardCVC'
                                            type='text'
                                            value={addCardCVC}
                                            className={"expMonthYear"}
                                            onChange={(e) => {
                                                if(e.target.value.length < 5){
                                                    setAddCardCVC(e.target.value)
                                                }
                                                
                                            }}
                                            onKeyDown={onKeyPress}
                                            placeholder='Card CVC'
                                        />
                                    
                                    </div>
                                    </form>
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                addCard()
                                            }}
                                            disabled={addCardCVC === '' || addCardExpiryMonth === '' || addCardExpiryYear === '' || addCardNumber === ''}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Add Card
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                changePage('fork-page');
                                            }}
                                            color={'login-secondary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            I changed my mind
                                        </Button>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </div>
                            )}
                                        
                            {/* REGISTRATION */}
                            {((query.has('page') && query.get('page') === 'registration-page' ))&& (
                            <div className="form registration-form">
                            <div className="registration-title">
                                <img style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                    <h4>{query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'Register as a Buyer' : query.get('applying_for') === 'merchant' ? 'Register as a Merchant' : 'User Registration' : 'User Registration'}</h4>
                                    <p>All fields are required</p>
                                    
                                </div>
                                {query.has('applying_for') && (

                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <p 
                                                onClick = {() => window.location.href= window.location.origin +  query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/loginpage=login-page&': '/loginpage=login-page&'}
                                                
                                                style={{cursor: 'pointer', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Already Registered? 
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <p 
                                                onClick={() => window.location.href= window.location.origin + query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=registration-page&applying_for=merchant' : query.get('applying_for') === 'merchant' ? '/login?page=registration-page&applying_for=buyer' : '/login?page=registration-page': '/login?page=registration-page'}
                                                style={{cursor: 'pointer', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Are you a {query.has('applying_for') ? query.get('applying_for') === 'buyer' ? 'seller' : query.get('applying_for') === 'merchant' ? 'buyer' : '' : ''}?
                                            </p>
                                        </Col>
                                    </Row>
                                 )}
                                <form>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='register_email'
                                        type='email'
                                        id='register_email'
                                        value={values.register_email}
                                        onChange={handleChange}
                                        onKeyDown={handleEnter}
                                        placeholder='Email'
                                        icon={{ prefix: 'far', name: 'envelope'}}
                                    />
                                </div>
                                {getRegErrorMsg('email') ? (
                                    <p className='email' style={{margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { getRegErrorMsg('email')}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='register_password'
                                        type='password'
                                        id='register_password'
                                        value={values.register_password}
                                        onChange={handleChange}
                                        onKeyDown={handleEnter}
                                        placeholder='Password'
                                        icon={{ prefix: 'fas', name: 'key'}}

                                    />
                                </div>
                                {getRegErrorMsg('password') ? (
                                    <p className='password' style={{margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { getRegErrorMsg('password')}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='register_passwordConfirm'
                                        type='password'
                                        id='register_passwordConfirm'
                                        value={values.register_passwordConfirm}
                                        onKeyDown={onKeyPress}
                                        onChange={handleChange}
                                        placeholder='Confirm Password'
                                        icon={{ prefix: 'fas', name: 'key'}}

                                    />
                                </div>
                                
                                {getRegErrorMsg('password2') ? (
                                    <p className='password2' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { getRegErrorMsg('password2')}
                                    </p>
                                ) : null}

                                {signUpPassword2 !== '' ? (
                                    <p className='signUpPassword2' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                        {signUpPassword2}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <Row>
                                        <Col xs={1} style={{paddingRight: '20px'}}>
                                    
                                            <Checkbox
                                                checked={agreements.terms}
                                                onChange={() => setAgreements({...agreements, terms: !agreements.terms})}
                                                name="checkedB"
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" style={{fill: 'white'}} />}
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" style={{fill: 'white'}} />
                                                }
                                            />
                                        </Col>
                                        <Col  style={{color: 'white', textAlign:'left', margin: 'auto'}}>
                                    
                                          
                                                I have read and accept the 
                                                <Link 
                                                target="_blank" 
                                                style={{
                                                    color: '#0072ff', 
                                                    textDecoration: 'underline'
                                                }} 
                                                to={`/${
                                                    query.has('applying_for') 
                                                    ? query.get('applying_for') === 'buyer' 
                                                        ? 'buyer' 
                                                    : query.get('applying_for') === 'merchant' 
                                                        ? 'merchant' 
                                                        : ''
                                                    : ''}/terms-conditions`
                                                }>
                                                    {' '}terms and condtions
                                                </Link> 
                                                {' '}and{' '}  
                                                <Link 
                                                target="_blank" 
                                                style={{
                                                    color: '#0072ff', 
                                                    textDecoration: 'underline'
                                                }} 
                                                to={`/${
                                                    query.has('applying_for') 
                                                    ? query.get('applying_for') === 'buyer' 
                                                        ? 'buyer' 
                                                    : query.get('applying_for') === 'merchant' 
                                                        ? 'merchant' 
                                                        : ''
                                                    : ''}/privacy-policy`
                                                }>
                                                    privacy policy
                                                </Link>.
                                            
                                        </Col>
                                    </Row>
                                    
                                 
                                </div>
                                </form>
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            submitRegistration()
                                        }}
                                        disabled={!agreements.terms}
                                        id='submit_signup'
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Sign Up
                                    </Button>

                                </div>
                                <div className="form-container page-linker-container">
                                    
                                    <Button 
                                        onClick={() => {
                                            history.push(`/${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '' : query.get('applying_for') === 'merchant' ? 'merchant' : '/' : '/' }`)
                                            changePage('login-page')
                                        }}
                                        color={'login-secondary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        Back
                                    </Button>
                                    
                                </div>
                            </div>
                            )}
                            
                            {((query.has('page') && query.get('page') === 'contact-page' ))&& (
                                <div className="form contact-form">
                                    <div className="profile-title">
                                        <img style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <p>Please enter your contact number. After submitting, a code will be sent to you.</p>
                                    </div>

                                    <div className="form-container">
                                        <TextContactLoginInput 
                                            name='contact_number'
                                            type='text'
                                            value={values.contact_number}
                                            onKeyDown={onKeyPress}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errorNumber !== '' && (
                                        <p className='errorNumber' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                            { errorNumber}
                                        </p>
                                    )}
                                    
                                    <div className="form-container">
                                        <Button 
                                            
                                            onClick={validateNumber}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </div>
                            )}

                            {/** PROFILE DETAILS PART 1*/}
                            {((query.has('page') && query.get('page') === 'profile1-page' ))&&  (
                            <div className="form profile1-form">
                            <div className="profile-title">
                            <img style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                    
                                    <p>This information needs to be accurate. Enter your name as shown on your ID.</p>
                                   
                                </div>
                                <form>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='first_name'
                                        type='text'
                                        value={values.first_name}
                                        onKeyDown={handleEnter}
                                        onChange={handleChange}
                                        placeholder='First Name'
                                    />
                                </div>
                                {errorProfile1.errors.first_name !== ''? (
                                    <p className='errorProfile1.errors.first_name' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorProfile1.errors.first_name}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='middle_name'
                                        type='text'
                                        value={values.middle_name}
                                        onKeyDown={handleEnter}
                                        onChange={handleChange}
                                        placeholder='Middle Name (Optional)' 
                                    />
                                </div>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='last_name'
                                        type='text'
                                        value={values.last_name}
                                        onKeyDown={handleEnter}
                                        onChange={handleChange}
                                        placeholder='Last Name'
                                    />
                                </div>
                                {errorProfile1.errors.last_name !== ''? (
                                    <p className='errorProfile1.errors.last_name' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorProfile1.errors.last_name}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    
                                        <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={(e) => setGender(e.target.value)}>
                                            <div style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center'}}>
                                                <FormControlLabel style={{color: 'white'}} value="M" control={<Radio checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} />} label="Male" />
                                                <FormControlLabel style={{color: 'white'}} value="F" control={<Radio checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />}/>} label="Female" />
                                            </div>
                                        </RadioGroup>
                        
                                    
                                </div>
                                </form>
                                
                                
                                <div className="form-container">
                                    <Button 
                                        
                                        onClick={validatePage1}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Next
                                    </Button>

                                </div>
                            </div>
                            )}

                            {((query.has('page') && query.get('page') === 'sms-verify' ))&& (
                                <div className="form sms-form" >
                                    <div className="profile-title" style={{paddingBottom: '0px'}}>
                                        <img style={{display: 'inline-block'}} src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                        <p>A code has been sent to +63{values.contact_number}</p>
                                    </div>
                                    {/** 
                                    <div className="form-container">
                                        <div className="custom-styles">
                                            <ReactInputVerificationCode  onChange={setSMSCode}/>
                                        </div>
                                       
                                    </div>*/}
                                    <div className="form-container">
                                        {otp.map((data, index) => {
                                            return (
                                                <input
                                                    className={`otp-field${index === 3 ? '-last' : ''}`}
                                                    type="text"
                                                    name="otp"
                                                    maxLength={1}
                                                    key={index}
                                                    value={data}
                                                    placeholder="."
                                                    onChange={e => handleOTPChange(e.target, index)}
                                                    onKeyDown={e => {
                                                        if(index === 3 && e.keyCode === 13){
                                                            smsVerify();
                                                        }
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="hidden">
                                        <input type="number" value={smsCode} style={{display:'none'}}/>
                                    </div>
                                    <div className="form-container">
                                        <Button 
                                            onClick={smsVerify}
                                            disabled={otp[3] === ''}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>
                                        {canResend ? (
                                            <div onClick={sendOTP} style={{color: 'white', fontSize: '12px', cursor: 'pointer', paddingTop: '20px', textDecoration: 'underline', textAlign: 'center'}}>
                                                Didn't get the code? Resend code.
                                            </div>
                                            
                                        ): (
                                            <div  style={{color: 'white', fontSize: '12px', paddingTop: '20px',  textAlign: 'center'}}>
                                                Type your code in {seconds} seconds.
                                            </div>
                                        )}

                                            <div  style={{color: 'white', fontSize: '12px', paddingTop: '20px', textDecoration: 'underline', textAlign: 'center'}}>
                                                <a onClick={() => {
                                                changePage('contact-page');
                                                setCompletedContact(false)
                                                }}
                                                style={{color: 'white', fontSize: '12px', cursor: 'pointer', paddingTop: '20px', textDecoration: 'underline', textAlign: 'center'}}>
                                                 Change Number?</a>
                                            </div>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </div>
                            )}
                            

                            {/**PROFILE DETAILS PART 2 */}
                            {((query.has('page') && query.get('page') === 'profile2-page' ))&&  (
                                <div className="form profile2-form">
                            <div className="profile-title">
                            <img style={{display: query.get('applying_for') === 'merchant' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={query.has('applying_for') ? query.get('applying_for') === 'buyer' ? buyerLogoWhite : query.get('applying_for') === 'merchant' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                    <p>Add your address. Use the one that's on your bills.</p>
                                   
                                </div>
                                <form>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='street'
                                        type='text'
                                        value={values.street}
                                        onChange={handleChange}
                                        onKeyDown={handleEnter}
                                        placeholder='Street Address'

                                    />
                                </div>
                                {errorProfile2.errors.street !== ''? (
                                    <p className='errorProfile2.errors.street' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorProfile2.errors.street}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='street2'
                                        type='text'
                                        value={values.street2}
                                        onChange={handleChange}
                                        onKeyDown={handleEnter}
                                        placeholder='Street Address 2'
                                    />
                                </div>
                                <div className="form-container">
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="region"
                                        value={values.region}
                                        style={{width: '100%'}}
                                        className={classes.select}
                                        inputProps={{
                                            classes: {
                                                icon: classes.selectIcon,
                                                root: classes.labelForm
                                            },
                                            className: classes.multilineColor,
                                            id: 'name-native-error',
                                        }}
                                        
                                        displayEmpty
                                        onChange={(e)=>{console.log(e.target.value); handleChange(e); handleEnter(e)}}
                                    >
                                        <MenuItem value="" disabled>
                                            <i>Select Province...</i>
                                        </MenuItem>
                                        {provinces.map((prov : any) => (
                                            <MenuItem key={prov.name} value={prov.name}>{prov.name}</MenuItem>
                                        ))
                                        }
                                        
                                    </Select>
                                    {errorProfile2.errors.region !== ''? (
                                            <p className='errorProfile2.errors.region' style={{backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                            { errorProfile2.errors.region}
                                            </p>
                                        ) : null}
                                </div>
                                
                                <div className="form-container">
                                
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="city"
                                        value={values.city}
                                        style={{width: '100%'}}
                                        className={classes.select}
                                        inputProps={{
                                            classes: {
                                                icon: classes.selectIcon,
                                                root: classes.labelForm
                                            },
                                            className: classes.multilineColor
                                        }}
                                        displayEmpty
                                        onChange={(e)=>{console.log(e.target.value); handleChange(e); handleEnter(e)}}
                                    >
                                        <MenuItem value="" disabled>
                                            <i>Select City...</i>
                                        </MenuItem>
                                        {filter(cities, {province: values.region}).map((city : any) => (
                                            <MenuItem key={city.name} value={city.name}>{city.name}</MenuItem>
                                        ))
                                        }
                                        
                                    </Select>
                                    {errorProfile2.errors.city !== ''? (
                                        <p className='errorProfile2.errors.city' style={{ backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                        { errorProfile2.errors.city}
                                        </p>
                                    ) : null}
                                </div>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='barangay'
                                        type='text'
                                        value={values.barangay}
                                        onKeyDown={handleEnter}
                                        onChange={handleChange}
                                        placeholder='Barangay'
                                    />
                                </div>
                                {errorProfile2.errors.barangay !== ''? (
                                    <p className='errorProfile2.errors.barangay' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorProfile2.errors.barangay}
                                    </p>
                                ) : null}
                                
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='zip_code'
                                        type='text'
                                        value={values.zip_code}
                                        onKeyDown={onKeyPress}
                                        onChange={(e) => {
                                          
                                            if(e.target.value.match(/^\d+$/) && e.target.value.length < 6){
                                                handleChange(e)
                                            }else if(e.target.value === ''){
                                                handleChange(e)
                                            }
                                        }}
                                        placeholder='Zip Code'
                                    />
                                </div>
                                {errorProfile2.errors.zip_code !== ''? (
                                    <p className='errorProfile2.errors.zip_code' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorProfile2.errors.zip_code}
                                    </p>
                                ) : null}
                                </form>
                                <div className="form-container">
                                    <Button 
                                        onClick={validatePage2}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Submit
                                    </Button>

                                </div>
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            setCompletedProf1(false);
                                            changePage('profile1-page')
                                        }}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Back
                                    </Button>

                                </div>
                                <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `${query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login'}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                            </div>  
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                </div>
            </div>
        </div>
        <SuccessDialog 
        openDialog={openDialog} 
        setOpenDialog={setOpenDialog} 
        onClose={() => {
            if(user){
                if(query.get('applying_for') === "buyer"){
                    console.log("BUYER SIGNUP")
                    history.push('/app/buyer/my-account');
                    if(user.buyAuthorized){
                        console.log("Buy View")
                        history.push('/app/buyer/my-account');
                    }else if(user.sellAuthorized){
                        console.log("Sell View")
                        history.push('/app/seller/my-account');
                    }else{
                        console.log("Fork screen")
                    }
                }
                else if(query.get('applying_for') === "merchant"){
                    console.log("MERCHANT SIGNUP")
                    changePage('apply-merchant-page')
                    if(user.buyAuthorized){
                        console.log("Buy View")
                        history.push('/app/buyer/my-account');
                    }else if(user.sellAuthorized){
                        console.log("Sell View")
                        history.push('/app/seller/my-account');
                    }else{
                        console.log("Fork screen")
                    }
                }else{
                    changePage('fork-page');
                }
            }
        }}
        dialogMessage={dialogMessage} />
        <SuccessDialog openDialog={merchantOpenDialog} setOpenDialog={setMerchantOpenDialog} onClose={() => query.get('applying_for') === 'merchant' && Auth.isAuthenticated() ? changePage('pending-page') : history.push('/login?page=login-page&applying_for=merchant') } dialogMessage={merchantDialogMessage} />
        <SuccessDialog 
            openDialog={verifySuccess} 
            setOpenDialog={setVerifSuccess} 
            onClose={() => 
                User.get(
                    (resp) => {
                        console.log(resp)
                        if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                            //history.push('/app/my-account')
                            setLoadingOverlay(false)
                            //changePage('fork-page')
                            if(query.get('applying_for') === "buyer"){
                                console.log("BUYER SIGNUP")
                                changePage('add-card-page')
                                if(resp.buyAuthorized){
                                    console.log("Buy View")
                                    history.push('/app/buyer/my-account');
                                }else if(resp.sellAuthorized){
                                    console.log("Sell View")
                                    history.push('/app/seller/my-account');
                                }else{
                                    console.log("Fork screen")
                                }
                            }
                            else if(query.get('applying_for') === "merchant"){
                                console.log("MERCHANT SIGNUP")
                                changePage('apply-merchant-page')
                                if(resp.buyAuthorized){
                                    console.log("Buy View")
                                    history.push('/app/buyer/my-account');
                                }else if(resp.sellAuthorized){
                                    console.log("Sell View")
                                    history.push('/app/seller/my-account');
                                }else{
                                    console.log("Fork screen")
                                }
                            }else{
                                changePage('fork-page');
                            }
                           
                           
                        }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }else if(!resp.contact_number && !resp.sms_verified){
                            setLoadingOverlay(false)
                            changePage('contact-page')
                        }
                        
                        else{
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }
                    }
                )
            } 
            dialogMessage={verifSuccessMessage} />
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
        <ErrorDialog openDialog={verifError} setOpenDialog={setVerifError} dialogMessage={verifErrorMessage} />

        <IdleLogoutDialog openDialog={openIdle} />
        </>
    );
}

export default withRouter(Login);