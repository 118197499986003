import React, {useCallback, useEffect, useState, useRef} from 'react';
import { TextInput, FileUpload } from '../../components/Input';
import { useForm, useFormFiles, useFormFilesEdit, useQuery } from '../../hooks';
import {  Row, Col, Alert } from 'reactstrap';
import { Button } from '../../components/Button';
import Auth from '../../auth';
import { banks } from '../../assets/banks';
import './style.scss';
import {  Select, MenuItem, CardContent, Grid, Button as MuiButton, IconButton, makeStyles, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Container} from 'reactstrap';
import EditIcon from '@material-ui/icons/Edit';
import { Card } from '@material-ui/core';
import { SuccessDialog, ErrorDialog } from '../../components';
import { User } from '../../models';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import close from '../../assets/images/close.png';

const mascot = require('../../assets/images/4gives_mascot.png');

const useStyles = makeStyles({
    root: {
      borderColor: '#FF873A',
    },
   
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
      paddingTop: 15, 
      alignSelf: 'center', 
      justifySelf: 'center', 
      color: '#FF873A'
    },
   
  });

export const AccountUpgrade: React.FC<{
    user: any;
    setLoadingOverlay: (val: boolean) => void;
    setUser: (val: any) => void;
}> = ({user, setLoadingOverlay, setUser}) => {
    const {values, handleChange} = useForm({ 
        bankAccountNumber: 0,
        bankCode: '',
    });

    const location = useLocation();

    const classes = useStyles();

    const {type} = useParams<{type: any}>()

    const idFrontRef = useRef<any | null>(null) 
    const idBackRef = useRef<any | null>(null) 
    const bankproofRef = useRef<any | null>(null) 
    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)
    const query = useQuery();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [editBankDetails, setEditBankDetails] = useState(false);
    const [editIDFront, setEditIDFront] = useState(user && user.IDFront ? user.IDFront : null)
    const [editIDBack, setEditIDBack] = useState(user && user.IDBack ? user.IDBack : null)
    const [editBankproof, setEditBankproof] = useState(user && user.Bankproof ? user.Bankproof : null)
    const [editBankAccountNumber, setEditBankAccountNumber] = useState( user && user.bankAccountNumber ? user.bankAccountNumber : null);
    const [editBankCode, setEditBankCode] = useState(user && user.bankCode ? user.bankCode : null );
    const [isRejected, setIsRejected] = useState<any>(false);
    const history = useHistory();
    const imgDefault = 'https://reservation.asiwebres.com/v4/App_Themes/images/noimage.png';

    const [openResubmitConfirm, setOpenResubmitConfirm] = useState(false)

    const [incomingSellerProposalsCount, setIncomingSellerProposalsCount] = useState<any>(0)

    const openIDFrontRef = () => {
        console.log('openID')
        idFrontRef.current.click()
    }
    const openIDBackRef = () => {
        idBackRef.current.click()
    }
    const openBankproofRef = () => {
        bankproofRef.current.click()
    }

    const {files, handleFiles} = useFormFiles ({
        IDFront: '',
        IDBack: '',
        Bankproof: '',
    });

    const [preview, setPreview] = useState({
        IDFront: '',
        IDBack: '',
        Bankproof: '',
    })

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result)
        };
        reader.onerror = error => reject(error);
    });

    function toDataURL(url: any, callback: any) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

    useEffect(()=> {
        if(editBankproof !== ''){
            console.log(toDataURL(editBankproof, function(base64image: any){
                console.log(base64image)
            }))
        }
    },[editBankproof])

    useEffect(() => {
        if(query.has('is-rejected')){
            setEditBankDetails(true)
        }
    },[])

    useEffect(() => {
        console.log(user);
        if(user && user !== undefined){
            if(user.buyAuthorized && user.sellAuthorized){
                setIsAuthorized(true)
            }
            if (user.rejection_notes.length > 0) {
                let hasReject: any = false;
                hasReject = user.rejection_notes.filter((test: any) => test.type === "sell_authorize_rejected").map((reject: any) => {
                    if(reject){
                        return true
                    }
                    
                })
                setIsRejected(hasReject[0])
            }

            Auth.getProposalsSell(
                '',
                (response) => {
                    console.log(response.results)
                    setIncomingSellerProposalsCount(response.count)
                 
                    //setLoadingOverlay(false)
                },
                (e) => {
                    console.log(e)
                }
            );
            
            setLoadingOverlay(false)
        }else{
            setLoadingOverlay(true)
        }
    },[user])

    const sendUpdatedRequest = () => {
        setLoadingOverlay(true)
        /*Auth.upgradeAcc(
            files.IDFront,
            files.IDBack,
            files.Bankproof,
            editBankCode,
            editBankAccountNumber,
            (response) => {
                console.log(response)
                setDialogMsg("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                setOpenSuccess(true)
                User.get((userdata: any) => {
                    setUser(userdata)
                })
                setLoadingOverlay(false)
                setEditBankDetails(false)
            },
            (e) => {
                setError(e.detail)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )*/
        if(incomingSellerProposalsCount > 0 && type === 'seller'){
            setError('You cannot resubmit if you have pending transactions.')
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
            setOpenResubmitConfirm(false)
        }
        else {
        
            Auth.accUpgrade.uploadIDFront(
                files.IDFront,
                (resp) => {
                    Auth.accUpgrade.uploadIDBack(
                        files.IDBack,
                        (resp) => {
                            Auth.accUpgrade.uploadBankproof(
                                files.Bankproof,
                                (resp) => {
                                    Auth.accUpgrade.updateBankDetails(
                                        editBankCode,
                                        editBankAccountNumber,
                                        (resp) => {
                                            console.log(resp)
                                            setDialogMsg("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                                            setOpenSuccess(true)
                                            setOpenResubmitConfirm(false)
                                            ReactPixel.trackCustom('Resubmit Merchant Application')
                                            User.get((userdata: any) => {
                                                setUser(userdata)
                                            })
                                            setLoadingOverlay(false)
                                            setEditBankDetails(false)
                                        },
                                        (e) => {
                                            setError(e.detail)
                                            setOpenErrorDialog(true)
                                            setLoadingOverlay(false)
                                            setOpenResubmitConfirm(false)
                                            console.log(e);
                                        }
                                    )
                                },
                                (e) => {
                                    setError(e.detail)
                                    setOpenResubmitConfirm(false)
                                    setOpenErrorDialog(true)
                                    setLoadingOverlay(false)
                                    console.log(e);
                                }
                            )
                        },
                        (e) => {
                            setError(e.detail)
                            setOpenResubmitConfirm(false)
                            setOpenErrorDialog(true)
                            setLoadingOverlay(false)
                            console.log(e);
                        }
                    )
                },
                (e) => {
                    setError(e.detail)
                    setOpenResubmitConfirm(false)
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
            )
        }
        
    }

    const upgradeAccount = useCallback(
        () => {
            setLoadingOverlay(true)
            Auth.upgradeAcc(
                files.IDFront,
                files.IDBack,
                files.Bankproof,
                values.bankCode,
                values.bankAccountNumber,
                (response) => {
                    console.log(response)
                    setDialogMsg("Account upgrade request sent! Please allow up to 48 hours for verification.");
                    ReactPixel.trackCustom('Submit Merchant Application')
                    setOpenSuccess(true)
                    setLoadingOverlay(false)
                    User.get((userdata: any) => {
                        setUser(userdata)
                        setEditIDFront(userdata.IDFront)
                        setEditIDBack(userdata.IDBack)
                        setEditBankproof(userdata.Bankproof)
                        setEditBankAccountNumber(userdata.bankAccountNumber)
                        setEditBankCode(userdata.bankCode)
                    })
                },
                (e) => {
                    setError(e.detail)
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
                
            )
        },
        [
            files.Bankproof,
            values.bankCode,
            files.IDBack,
            files.IDFront,
            values.bankAccountNumber
        ],
    )
    

    const previewHandler = (e: any) => {
        setPreview({
            ...preview,
            [e.target.name]: e.target.files[0]
        })
    }

    const deletePreview = (name: any) =>{
        setPreview({
            ...preview,
            [name]: ''
        })
    }

    return(
        <>
        <div className="page-content activate-account upgrade-account">
            <Container>
                {user && !((user.IDFront && user.IDBack)  && user.Bankproof) ?
                ///ADDDD
              
                    <>
                        {query.has("send_documents") && query.get("send_documents") === "true" && (
                            <Alert color="warning">
                                Info: Please send the required documents below to become a merchant.
                            </Alert>
                        )}
                        
                        <div className="activate-account-container fg-container">
                            <h1 className="page-title">
                                Merchant Account
                            </h1>
                            <p>Upgrade your account to start selling and buying on 4Gives</p>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="form-container">
                                        <h6>Government ID Front</h6>
                                        <p>Send your front side of your Government ID. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                        
                                        {preview.IDFront !== '' &&(
                                            <div className="image-container">
                                                            
                                                <img className="image" src={preview.IDFront !== '' ? URL.createObjectURL(preview.IDFront) : ''}/>
                                                
                                            </div>
                                        )}
                                        

                                        <div className="fileupload">
                                            <FileUpload
                                                name='IDFront'
                                                onChange={(e) => { previewHandler(e); handleFiles(e);} }
                                                onDelete={() => deletePreview('IDFront')}
                                                placeholder='Front ID'
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-container">
                                        <h6>Government ID Back</h6>
                                        <p>Send your back side of your Government ID. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                        {preview.IDBack !== '' &&(
                                            <div className="image-container">
                                                            
                                                <img className="image" src={preview.IDBack !== '' ? URL.createObjectURL(preview.IDBack) : ''}/>
                                                
                                            </div>
                                        )}
                                        <div className="fileupload">
                                            <FileUpload
                                                name='IDBack'
                                                onChange={(e) => { previewHandler(e); handleFiles(e);} }
                                                onDelete={() => deletePreview('IDBack')}
                                                placeholder='Back ID'
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="form-container">
                                        <h6>Bank Statement</h6>
                                        <p>Send your latest bank statement. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                        {preview.Bankproof !== '' &&(
                                            <div className="image-container">
                                                            
                                                <img className="image" src={preview.Bankproof !== '' ? URL.createObjectURL(preview.Bankproof) : ''}/>
                                                
                                            </div>
                                        )}
                                        <div className="fileupload">
                                            <FileUpload
                                                name='Bankproof'
                                                onChange={(e) => { previewHandler(e); handleFiles(e);} }
                                                onDelete={() => deletePreview('Bankproof')}
                                                placeholder='Account Number'
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-container">
                                        <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                        <Select
                                            id="demo-simple-select-autowidth"
                                            name="bankCode"
                                            value={values.bankCode}
                                            autoWidth
                                            displayEmpty
                                            onChange={(e)=>{console.log(e.target.value); handleChange(e)}}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Choose your bank</em>
                                            </MenuItem>
                                            {banks.map((bank : any) => (
                                                <MenuItem key={bank.id} value={bank.value}>{bank.label}</MenuItem>
                                            ))
                                            }
                                            
                                        </Select>
                                    </div>
                                    
                                    <div className="form-container">
                                        <h6>Account Number</h6>
                                        <p>Please input your bank account number.</p>
                                        <TextInput 
                                            name='bankAccountNumber'
                                            onChange={handleChange}
                                            type='number'
                                            value={values.bankAccountNumber}
                                            placeholder='Account Number'
                                        />
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="activate-account-button">
                                        
                                        <Button 
                                            color='primary'
                                            disabled={(((files.IDFront === '' || files.IDBack === '') || files.Bankproof === '' )|| values.bankCode === '') || values.bankAccountNumber === null}
                                            onClick={() => {
                                                upgradeAccount()
                                            }}
                                            className='fg-acountactivate-button'
                                        >
                                            Send Upgrade Request
                                        </Button>

                                        <p>
                                            Allow up to 48 hours for verification
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                  
                : 
                    <Container>
                        <div className="activate-account-container fg-container">
                            {false ? (
                                <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                                    <Grid item>
                                        <img className="image-mascot" src={mascot}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 className="page-title" style={{paddingRight:"30px", paddingLeft: "30px"}}>
                                            {user.sellAuthorized ? 'You can now sell using 4Gives.' : 'Your application is still pending. Please wait up to 48 hours for verification.'}
                                        </h1>
                                    </Grid>
                                    <Grid item>
                                        <Button 
                                            disabled={!isRejected}
                                            color='primary'
                                            onClick={()=>setEditBankDetails(true)}
                                            className='fg-acountactivate-button'
                                        >
                                            Resubmit Documents
                                        </Button>
                                    </Grid>
                                </Grid>
                            ):(
                                <>
                                <h1 className="page-title">
                                    Merchant Account {user.sellAuthorized ? 'Authorized' : '(Pending Application)'}
                                </h1>
                                    {true ? (
                                        <>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <div className="form-container">
                                                <h6>Government ID Front</h6>
                                                <p>Send your front side of your Government ID. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                                {editIDFront !== null && (
                                                    <Grid container item direction="row" alignItems="center" justify="center">
                                                        <div className="image-container">
                                                            
                                                            <img className="image" src={editIDFront ? editIDFront : ''}/>
                                                            
                                                            <div onClick={openIDFrontRef} className='fg-uploadbutton'>
                                                                    UPLOAD
                                                                </div>
                                                        </div>
                                                    </Grid>
                                                    )}
                                                <div className="fileupload-hidden">
                                                        {/***</div><FileUpload
                                                            name='IDFront'
                                                            ref={idFrontRef}
                                                            onChange={handleFiles}
                                                            placeholder='Front ID'
                                                        />*/}
                                                        <input 
                                                            type="file" 
                                                            ref={idFrontRef} 
                                                            name="IDFront" 
                                                            onChange={(e) => {
                                                                handleFiles(e); 
                                                                if(e.target.files !== null){
                                                                    var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                    if(FileSize > 2){
                                                                        setOpenErrorDialog(true)
                                                                        setError("File must be below 2MB.")
                                                                    }else{
                                                                        setEditIDFront(URL.createObjectURL(e.target.files[0]))
                                                                    }
                                                                }
                                                                
                                                            } 
                                                        } />
                                                    </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="form-container">
                                                <h6>Government ID Back</h6>
                                                <p>Send your back side of your Government ID. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                                {editIDBack !== null && (
                                                    <Grid container item direction="row" alignItems="center" justify="center">
                                                        <div className="image-container">
                                                            
                                                            <img className="image" src={editIDBack ? editIDBack : ''}/>
                                                           
                                                            <div onClick={openIDBackRef} className='fg-uploadbutton'>
                                                                UPLOAD
                                                            </div>
                                                        </div>
                                                    </Grid>    
                                                )}
                                                <div className="fileupload-hidden">
                                                    {/***
                                                     *   <FileUpload
                                                        name='IDBack'
                                                        onChange={handleFiles}
                                                        ref={idBackRef}
                                                        placeholder='Back ID'
                                                    />
                                                     */}
                                                  
                                                    <input 
                                                        type="file" 
                                                        ref={idBackRef} 
                                                        name="IDBack" 
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setError("File must be below 2MB.")
                                                                }else{
                                                                    setEditIDBack(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }
                                                        } 
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <div className="form-container">
                                                <h6>Bank Statement</h6>
                                                <p>Send your latest bank statement. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                                {editBankproof !== null && (
                                                    <Grid container item direction="row" alignItems="center" justify="center">
                                                        <div className="image-container">
                                                            
                                                            <img className="image" src={editBankproof ? editBankproof : ''}/>
                                                          
                                                            <div onClick={openBankproofRef} className='fg-uploadbutton'>
                                                                    UPLOAD
                                                                </div>
                                                        </div>
                                                    </Grid>
                                                )}
                                                <div className="fileupload-hidden">
                                                    {/***
                                                     * <FileUpload
                                                        name='Bankproof'
                                                        onChange={handleFiles}
                                                        ref={bankproofRef}
                                                        placeholder='Account Number'
                                                    />
                                                     */}
                                                    
                                                    <input 
                                                        type="file" 
                                                        ref={bankproofRef} 
                                                        name="Bankproof" 
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                                                                var i = Math.floor(Math.log(e.target.files[0].size) / Math.log(1024));
                                                                const imageSize = Math.round(e.target.files[0].size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
                                                                console.log(imageSize, 'IMAGE TEST');
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setError("File must be below 2MB.")
                                                                }else{
                                                                setEditBankproof(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="form-container">
                                                <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                                <Select
                                                    id="demo-simple-select-autowidth"
                                                    name="bankCode"
                                                    value={editBankCode ? editBankCode : values.bankCode}
                                                    autoWidth
                                                    displayEmpty
                                                    onChange={(e)=>{setEditBankCode(e.target.value); handleChange(e)}}
                                                >
                                                    <MenuItem value="" disabled>
                                                        <em>Choose your bank</em>
                                                    </MenuItem>
                                                    {banks.map((bank : any) => (
                                                        <MenuItem key={bank.id} value={bank.value}>{bank.label}</MenuItem>
                                                    ))
                                                    }
                                                    
                                                </Select>
                                            </div>
                                            
                                            <div className="form-container">
                                                <h6>Account Number</h6>
                                                <p>Enter your bank account number below</p>
                                                <TextInput 
                                                    name='bankAccountNumber'
                                                    onChange={(e) => {setEditBankAccountNumber(e.target.value); handleChange(e)}}
                                                    type='number'
                                                    value={editBankAccountNumber ? editBankAccountNumber : values.bankAccountNumber}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    ) : (
                                    <Row style={{alignContent: 'center', justifyContent: 'center'}}>
                                        <Col sm={12} md={6}>
                                            <div className="form-container">
                                                <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                                <Select
                                                    id="demo-simple-select-autowidth"
                                                    name="bankCode"
                                                    value={editBankCode ? editBankCode : values.bankCode}
                                                    autoWidth
                                                    displayEmpty
                                                    onChange={(e)=>{setEditBankCode(e.target.value); handleChange(e)}}
                                                >
                                                    <MenuItem value="" disabled>
                                                        <em>Choose your bank</em>
                                                    </MenuItem>
                                                    {banks.map((bank : any) => (
                                                        <MenuItem key={bank.id} value={bank.value}>{bank.label}</MenuItem>
                                                    ))
                                                    }
                                                    
                                                </Select>
                                            </div>
                                            
                                            <div className="form-container">
                                                <h6>Account Number</h6>
                                                <p>Enter your bank account number below</p>
                                                <TextInput 
                                                    name='bankAccountNumber'
                                                    onChange={(e) => {setEditBankAccountNumber(e.target.value); handleChange(e)}}
                                                    type='number'
                                                    value={editBankAccountNumber ? editBankAccountNumber : values.bankAccountNumber}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    ) }
                                    <Row>
                                        <Col sm={12} md={12}>
                                            <div className="activate-account-button">
                                                
                                                <Button 
                                                    color='primary'
                                                    onClick={() => setOpenResubmitConfirm(true) }
                                                    className='fg-acountactivate-button'
                                                >
                                                    Resubmit Upgrade Request
                                                </Button>

                                                <p>
                                                    Allow up to 48 hours for verification
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Container>
                }
                
            </Container>
        </div>
        <Dialog
            className={classes.dialogPaper} 
            open={openResubmitConfirm} 
            maxWidth={'xs'}
            fullWidth
            PaperProps={{style: {
                backgroundColor: '#FF873A',
                border: "1px solid #fee2b3",
                borderRadius: '10px'
            }}}
        >
            <DialogTitle className={classes.dialogTitle}>
                <span style={{color: 'white', fontWeight: 'bold', fontSize: '16px'}}>Confirm Delete Action</span>
                <img
                    src={close}
                    style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                    onClick={()=>{ setOpenResubmitConfirm(false); }}/>
            </DialogTitle>
            <DialogContent style={{textAlign:'center', padding: '20px 20px', color: 'white'}}>
                
                Are you sure you want to resubmit? You may not be a seller anymore.
            </DialogContent>
            <DialogActions>
                <MuiButton variant="contained" onClick={sendUpdatedRequest} style={{color: 'white', background:'#FF873A'}}>
                    <span style={{color: 'white'}}>Yes</span>
                </MuiButton>
                <MuiButton variant="contained" onClick={() => setOpenResubmitConfirm(false)} style={{color: 'white', background:'red'}}>
                    <span style={{color: 'white'}}>No</span>
                </MuiButton>
            </DialogActions>
        </Dialog>
        <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg} onClose={() => //history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller' : '' }/account-upgrade`)
        console.log('test')}/>
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </>
    );
}
