import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {  Col, Row } from 'react-grid-system';
import { Table, Card, CardBody, CardFooter, SuccessDialog, ErrorDialog, Button, ProposalViaEmailLink, OTPDialog, IncomingTransactions, ProposalDetails } from '../../components';
import moment from 'moment';
import WalletCard from '../../components/WalletCard';
import Auth from '../../auth';
import { TextInput,} from '../../components/Input'
import { User } from '../../models';
import { Dialog, DialogTitle, Grid, Card as MuiCard, CardContent as MuiCardContent, CardActions, makeStyles, MenuItem, Select, Button as MuiBtn, ButtonGroup, useMediaQuery, CardContent, Step, StepButton, Stepper, Typography, StepLabel, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '../../hooks';
import { Pagination } from '@material-ui/lab';
import URL from '../../services/url';

export const Incoming: React.FC<{
    classes?: any;
    user: any;
    source: any;
    setLoadingOverlay: (val: boolean) => void;
}> = ({classes, user, source, setLoadingOverlay}) => {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const [incomingProposals, setIncomingProposals] = useState<any | null>(null)
    const [proposalID, setProposalID] = useState<any | null>(query.get('pid'))
    
    const [proposalResult, setProposalResult] = useState<any | null>(null)
    const [openProposal, setOpenProposal] = useState(false);
    const [buyer, setBuyer] = useState<any | null>(null)
    const [merchant, setMerchant] = useState<any | null>(null)

    const [args, setArgs] = useState('?page_size=5');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const subscribe = async () => {
        const events = new EventSource(URL.EVENTS.TRANSACTIONS+user.id);
        console.log("SUBSCRIBING")
        events.onmessage = (event) => {
          switch (event.type) {
            case 'message':
              const data = JSON.parse(event.data);
              console.log(data)
               /*const updateActivities = [...activities]
              updateActivities.push(data)
              console.log(updateActivities);
              setActivities(updateActivities);
              */
             if(data.event === "transactions_incoming" && data.action === "re-render"){
                Auth.getProposalsBuy(
                    args,
                    (response) => {
                        console.log(response.results)
                        setCount(response.count)
                        setIncomingProposals(response.results);
                        setLoadingOverlay(false)
                    },
                    (e) => {
                        console.log(e)
                    }
                )
             }else{
                events.close()
             }
             
              break;
          }
        };

        events.onerror = (e) => {
            console.log(e)
            events.close()
        }
        if(!(location.pathname.includes('my-account'))){
            console.log('CLOSING')
            events.close();
            }
        return () => {
            events.close();
          };
      };

    const handleProposalClose = () => {
        if(proposalResult){
            setProposalID(null)
            setProposalResult(null)
            
            if(location.pathname.includes('create-offer')){
                history.push('/app/create-offer');
            }else if(location.pathname.includes('buyer')){
                history.push('/app/buyer/my-account');
            }
            setOpenProposal(false)
        }
    }

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [incomingType, setIncomingType] = useState('accepted');

    useEffect(() => {
        console.log("Loading Proposal/Transaction")
        if(proposalID && !proposalResult){
            console.log(query.get('pid'))
            Auth.getProposal(
                proposalID,
                (resp) => {
                    resp.type = "BUY"
                    if(user){
                        if(resp.type === "BUY"){
                            setBuyer(user);
                        }
                    
                        console.log(resp.type)
                    }
                    if(resp.merchant){
                        Auth.getUser(
                            resp.merchant,
                            (response) => {
                            console.log(response)
                            if(resp.type === "BUY"){
                                setMerchant(response);
                            }
                            setProposalResult(resp)
                            
                            },
                            (e) => {
                            console.log(e)
                            }
                        )
                    }else{
                        setProposalResult(resp)
                    }
                    
                },
                (e) => {
                    console.log(e)
                    setErrorMessage("Proposal not found.")
                    setOpenErrorDialog(true)
                }
            )
        }
       

        else if(proposalResult){
            setOpenProposal(true)
        }
    },[proposalResult, proposalID, location.search])

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setLoadingOverlay(true)
        setPage(value);
        if(value === 1){
            setArgs('?page_size=5');
        }else{
            setArgs('?page_size=5&page=' + value)
        }
    }

    useEffect(() => {
        subscribe()
    },[])
   

    useEffect(() =>{
        Auth.getProposalsBuy(
            args,
            (response) => {
                console.log(response.results)
                setCount(response.count)
                setIncomingProposals(response.results);
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
            }
        )
    },[args])
    

    return(
        <div>
            <Row nowrap style={{paddingTop: 20}}>
                <Col>
                    <MuiCard raised={true}>
                        <MuiCardContent>
                            <Grid container direction="row" justify="space-between">
                                <Grid item>
                                    <div style={{padding: '10px 10px', cursor: 'pointer'}}>
                                        <h5>Pending Transactions</h5>
                                    </div>
                                </Grid>
                                <Grid>
                                    <div onClick={() => history.push('/app/buyer/create-offer')} style={{padding: '10px 10px', cursor: 'pointer'}}>
                                        <h5>View All</h5>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            {incomingProposals && incomingProposals.length > 0 ? incomingProposals.map((result: any, index: any) => {
                                result.type = "BUY" 
                                result.incoming = true
                                result.isHome = true
                                return (
                                    <IncomingTransactions setID={(val: any) => setProposalID(val)} onClick={() => console.log(result.id)} refresh={() => console.log('refresh')}  key={result.id} result={result} />
                                )
                                }
                            ): 
                            <div style={{textAlign: 'center'}}>
                                <div style={{fontSize: '14px', paddingTop: '5px', textAlign:'center', color:'#FF873A', fontWeight: 500}}>No Incoming Transactions...</div> <br />
                            </div>
                            }

                            {incomingProposals && incomingProposals.length > 0 ? (
                                    <Pagination style={{paddingTop: '30px',}} count={Math.ceil(count/5)} page={page} shape="rounded" onChange={handlePageChange} /> 
                            ):null
                            }
                        </MuiCardContent>
                                                    
                    </MuiCard>
                </Col>
            </Row>
            {(proposalResult || openProposal) &&(
                <ProposalDetails result={proposalResult ? proposalResult : null} cards={source} merchant={merchant} buyer={buyer} modal={openProposal} setModal={handleProposalClose}/>
            )}
        </div>
    )
}