import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, Grid, makeStyles, MenuItem, Select, Container } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactInputVerificationCode from 'react-input-verification-code';
import './style.scss';
import { Button, ErrorDialog, SuccessDialog } from '..';
import Auth from '../../auth';
import { User } from '../../models';

const otpIcon = require('../../assets/images/otp-icon.png');
const close = require('../../assets/images/close.png');

export const OTPDialog: React.FC<{
    openDialog: boolean,
    setOpenDialog: (val: boolean) => void,
    onClose?: () => void,
    onFinish: () => void,
}> = ({openDialog, setOpenDialog, onClose, onFinish}) => {
    const {user} = useContext(User.Context)
    const [code, setOTPCode] = useState<string>('')
    const [uid, setUid] = useState<string>('')
    const [sendOTP, setSendOTP] = useState<boolean>(false)

    const [otp, setOtp] = useState(new Array(4).fill(""));

    const handleOTPChange = (element: any, index: any) => {
        if(isNaN(element.value)) return false;

        setOtp([...otp.map((d: any, idx: any) => (idx === index) ? element.value : d)])

        if(element.nextSibling){
            element.nextSibling.focus()
        }
    }

    const [openSuccDialog, setOpenSuccDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const [seconds, setSeconds] = useState(30)
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        if(uid === '' && openDialog){
            sendOTPRequest()
            
            setCanResend(false)
        }
    },[uid, openDialog])

    useEffect(() => {
        setSeconds(30)
    },[])

    useEffect(() => {
        if(openDialog){

        
            if(seconds > 0){
                setTimeout(() => {
                    setSeconds(seconds - 1)
                },1000)
            }else{
                setCanResend(true)
            }
        }
        else if (!openDialog){
            setSeconds(30)
            setCanResend(false)
        }
    },[canResend, seconds, openDialog])

    const sendOTPRequest = () => {
        Auth.otpSend(
            (resp) => {
                setUid(resp.response.sessionId)
                setSeconds(30)
                setSendOTP(true)
                setCanResend(false)
            },
            (e) => {
                console.log(e)
            }
        )
    }

    const verifyOTP = () => {
        Auth.verifyOTP(
            uid,
            otp.join(""),
            (resp) => {
                console.log(resp)
                if(resp.response.status === "WAITING"){
                    setOpenErrorDialog(true)
                    setErrorMessage("Error verifying SMS")
                }else if(resp.response.status === "EXPIRED"){
                    setOpenErrorDialog(true)
                    setErrorMessage("OTP Code has expired. Please resend again.")
                }else{
                    setOpenDialog(false);
                    onFinish()
                }
                //
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorMessage(e.error)
                console.log(e)
            }
        )
    }

    return (
        <>
        <Dialog 
            open={openDialog} 
            fullWidth={true} 
            maxWidth={'xs'} 
            onClose={() =>{
                if(onClose !== undefined) {
                    onClose()
                    setOpenDialog(false); 
                }else{
                    setOpenDialog(false);
                }
                
            }} 
            PaperProps={{
                style: {
                    backgroundColor: '#FF873A', 
                    border: "1px solid #FF873A",
                    borderRadius: '8px'
                },
            }}
        >
            <DialogTitle style={{color: 'green', textAlign: "center", backgroundColor: '#FF873A'}}>
                <img
                    src={close}
                    style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                    onClick={() =>{
                        if(onClose !== undefined) {
                            onClose()
                            setOpenDialog(false); 
                        }else{
                            setOpenDialog(false);
                        }
                    }} 
                />
                {/** 
                <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                    <Grid item>
                        <img src={otpIcon} style={{width: '50px', height: '50px'}}/>
                    </Grid>
                    
                </Grid>
                */}
                <div style={{paddingTop: 10, color: "white"}}>
                    One Time Password
                </div>
                    
                
            </DialogTitle>
            <Container>
                    <div style={{padding: 20, backgroundColor: '#FF873A'}}>
                        {!sendOTP ? 
                            <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                                <Grid item style={{color: 'white'}}>
                                    A one-time password is required to proceed.
                                </Grid>
                                <Grid item>
                                    <Button
                                        color='primary'
                                        onClick={sendOTPRequest}
                                        className='fg-acountactivate-button'
                                    >
                                        Send OTP
                                    </Button>
                                </Grid>
                            </Grid>
                        :
                            <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                                <Grid item>
                                
                                    <div className="form-container">
                                        {otp.map((data, index) => {
                                            return (
                                                <input
                                                    className={`otp-field${index === 3 ? '-last' : ''}`}
                                                    type="text"
                                                    name="otp"
                                                    maxLength={1}
                                                    key={index}
                                                    value={data}
                                                    placeholder="."
                                                    onChange={e => handleOTPChange(e.target, index)}
                                                    onKeyDown={e => {
                                                        if(index === 3 && e.keyCode === 13){
                                                            verifyOTP();
                                                        }
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                </Grid>
                                <Grid item >
                                    <Button
                                        color='login-secondary'
                                        onClick={verifyOTP}
                                        className='fg-acountactivate-button'
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {canResend ? (
                                    <Grid item onClick={sendOTPRequest} style={{color: 'white', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline'}}>
                                        Didn't get the code? Resend code.
                                    </Grid>
                                    
                                ): (
                                    <Grid item style={{color: 'white', fontSize: '12px'}}>
                                        Type your code in {seconds} seconds.
                                    </Grid>
                                )}
                            </Grid>
                        }
                      
                        
                        
                        

                    </div>
                
                
            </Container>
        </Dialog>
        <SuccessDialog openDialog={openSuccDialog} setOpenDialog={setOpenSuccDialog} dialogMessage={successMessage}/>
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorMessage} />
        </>
    );
}
    