import React, { useCallback, useState, useContext, useEffect } from 'react';
import {  Row, Col} from 'react-grid-system'
import { TextInput} from '../../components/Input'
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, useQuery } from '../../hooks';
import './style.scss';
import { Button, IncomingTransactions, SuccessDialog, ProposalViaEmailLink, ProposalDetails, ErrorDialog } from '../../components';
import { Alert, AlertTitle } from '@material-ui/lab';
import { User } from '../../models';
import Auth from '../../auth';
import Tabs from '@material-ui/core/Tabs';
import {Container} from 'reactstrap';
import Tab from '@material-ui/core/Tab';
import {  Select, Button as MuiBtn, MenuItem, FormControlLabel, makeStyles, Radio, RadioGroup, Card, CardContent, Grid, Dialog, DialogTitle, CardActions, useMediaQuery, useTheme, TablePagination } from '@material-ui/core';
import moment from 'moment';
import {Pagination} from '@material-ui/lab';
import URL from '../../services/url';

const mascot = require('../../assets/images/4gives_mascot.png');

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    indicator: {
        backgroundColor: "white",
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    labelForm: {
        color: '#fffff',
    },
    btnOutlined: {
        color: '#FF873A',
        borderColor: '#FF873A',
    },
    btn: {
        color: 'white',
        background: '#FF873A',
        borderColor: '#FF873A',
        '&:hover': {
            backgroundColor: '#FF873A',
          },
    },
    checkedIcon: {
      backgroundColor: '#FF873A',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FF873A',
      },
    },
  });

export const CreateSale: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {
    //let linkURL = null;
    const theme = useTheme();
    const classes = useStyles();
    const {user, source} = useContext<any>(User.Context)
    const [ incomingProposals, setIncomingProposals ] = useState<Array<[]> | null>(null);
    const [ incomingAccepted, setIncomingAccepted ] = useState<Array<[]> | null>(null);
    const [ incomingWaiting, setIncomingWaiting ] = useState<Array<[]> | null>(null);

    const [incomingType, setIncomingType] = useState<any>('accepted');

    const [ currentProposals, setCurrentProposals ] = useState<Array<[]> | null>(null);
    const [ completedProposals, setCompletedProposals ] = useState<Array<[]> | null>(null);
    const [ openEmail, setOpenEmail ] = useState(false)
    const [ openLink, setOpenLink ] = useState(false);

    const matches = useMediaQuery(theme.breakpoints.up('md'))

    const [acceptedArgs, setAcceptedArgs] = useState('?page_size=5')
    const [acceptedCount, setAcceptedCount] = useState(0);
    const [acceptedPage, setAcceptedPage] = useState(1);

    const [waitingArgs, setWaitingArgs] = useState('?page_size=5')
    const [waitingCount, setWaitingCount] = useState(0);
    const [waitingPage, setWaitingPage] = useState(1);

    const [args, setArgs] = useState('?page_size=5');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const [currArgs, setCurrArgs] = useState('?page_size=5&status=CURRENT');
    const [currCount, setCurrCount] = useState(0);
    const [currPage, setCurrPage] = useState(1);

    const [compArgs, setCompArgs] = useState('?page_size=5&status=COMPLETE');
    const [compCount, setCompCount] = useState(0);
    const [compPage, setCompPage] = useState(1);

    const [openEmailLinkPropose, setOpenEmailLinkPropose] = useState(false);
    const [proposeType, setProposeType] = useState<string | null>(null);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const query = useQuery();
    const [ emailOpenSuccess, setEmailOpenSuccess ] = useState(false);
    const [ successDialogMsg, setSuccessDialogMsg ] = useState<any>(null);
    const [ cards, setCards ] = useState<Array<[]> | null>(null);
    const [ linkView, setLinkView ] = useState(false);
    const [ sentView, setSentView ] = useState(false);
    const [ successView, setSuccessView ] = useState(false);
    const [ errorView, setErrorView ] = useState(null);
    const [ emailError, setEmailError ] = useState(null);
    const [linkURL, setLinkURL] = useState(null);
    const [type, setType] = useState('incoming');
    const [terms, setTerms] = useState('');
    const [eTerms, setETerms] = useState('');
    const [category, setCategory] = useState<string | null>(null);
    const [eCategory, setECategory] = useState<string | null>(null);
    const [buyerEmail, setBuyerEmail] = useState<string | null>(null);
    const [buyerID, setBuyerID] = useState(null);
    const [merchantID, setMerchantID] = useState(null);
    const [error, setError] = useState(null);
    const {values, handleChange} = useForm({
        name: '',
        desc: '',
        category: '',
        amountValue: '',
        eName: '',
        eDesc: '',
        eCategory: '',
        eAmountValue: '',
    })

    const history = useHistory();
    const location = useLocation();

    const [openDialog, setOpenDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const [proposalResult, setProposalResult] = useState<any | null>(null)
    const [openProposal, setOpenProposal] = useState(false);
    const [buyer, setBuyer] = useState<any | null>(null)
    const [merchant, setMerchant] = useState<any | null>(null)

    const [transactionResult, setTransactionResult] = useState<any | null>(null)


    const [proposalID, setProposalID] = useState<any | null>(query.get('pid'))
    const [transactionID, setTransactionID] = useState<any | null>(query.get('tid'))

    const subscribe = async () => {
        const events = new EventSource(URL.EVENTS.TRANSACTIONS+user.id);
        console.log("SUBSCRIBING")
        events.onmessage = (event) => {
          switch (event.type) {
            case 'message':
              const data = JSON.parse(event.data);
              console.log(data)
               /*const updateActivities = [...activities]
              updateActivities.push(data)
              console.log(updateActivities);
              setActivities(updateActivities);
              */
             if(data.event === "transactions_incoming" && data.action === "re-render"){
                Auth.getProposalsSell(
                    args,
                    (response) => {
                        console.log(response.results)
                        setCount(response.count)
                        setIncomingProposals(response.results);
                        setLoadingOverlay(false)
                    },
                    (e) => {
                        console.log(e)
                    }
                )
             }else if (data.event === "transactions_current" && data.action === "re-render") {
                 loadCurrTransactionsSell(currArgs)
             }else if (data.event === "transactions_complete" && data.action === "re-render") {
                 loadCompTransactionsSell(compArgs)
             }else{
                events.close()
             }
             
              break;
          }
        };

        events.onerror = (e) => {
            console.log(e)
            events.close()
        }
        if(!(location.pathname.includes('create-sale'))){
            console.log('CLOSING')
            events.close();
            }
        return () => {
            events.close();
          };
      };


    useEffect(() => {
        subscribe()
    },[])


    useEffect(() => {
        console.log("Loading Proposal/Transaction")
        if(proposalID && !proposalResult){
            console.log(query.get('pid'))
            Auth.getProposal(
                proposalID,
                (resp) => {
                    resp.type = "SELL"
                    if(user){
                        if(resp.type === "SELL"){
                            setMerchant(user);
                        }
                    
                        console.log(resp.type)
                    }
                    if(resp.buyer){
                        Auth.getUser(
                            resp.buyer,
                            (response) => {
                            console.log(response)
                            if(resp.type === "SELL"){
                                setBuyer(response);
                            }
                            setProposalResult(resp)
                            
                            },
                            (e) => {
                            console.log(e)
                            }
                        )
                    }else{
                        setProposalResult(resp)
                    }
                    
                },
                (e) => {
                    console.log(e)
                    setErrorMessage("Proposal not found.")
                    setOpenErrorDialog(true)
                }
            )
        }
        else if(transactionID && !transactionResult){
            Auth.getTransaction(
                transactionID,
                (resp) => {
                    resp.type = "SELL"
                    if(user){
                        if(resp.type === "SELL"){
                            setMerchant(user);
                        }
                        if(resp.buyer){
                            Auth.getUser(
                                resp.buyer,
                                (response) => {
                                  console.log(response)
                                  if(resp.type === "SELL"){
                                    setBuyer(response);
                                  }
                                  setTransactionResult(resp)
                                  
                                },
                                (e) => {
                                  console.log(e)
                                }
                            )
                        }else{
                            setTransactionResult(resp)
                        }
                        
                    
                        console.log(resp.type)
                    }
                },
                (e) => {
                    console.log(e)
                    setErrorMessage("Transaction not found.")
                    setOpenErrorDialog(true)
                }
            )
        }

        else if(proposalResult || transactionResult){
            setOpenProposal(true)
        }
    },[proposalResult, transactionResult, transactionID, proposalID, location.search])


    const handleProposalClose = () => {
        if(proposalResult || transactionResult){
            setProposalID(null)
            setTransactionID(null)
            setProposalResult(null)
            setTransactionResult(null)
            if(location.pathname.includes('create-sale')){
                history.push('/app/seller/create-sale');
            }
            setOpenProposal(false)
        }
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setLoadingOverlay(true)
        setPage(value);
        if(value === 1){
            setArgs('?page_size=5');
        }else{
            setArgs('?page_size=5&page=' + value)
        }
    }

    const handleAcceptedPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setLoadingOverlay(true)
        setAcceptedPage(value);
        if(value === 1){
            setAcceptedArgs('?page_size=5');
        }else{
            setAcceptedArgs('?page_size=5&page=' + value)
        }
    }

    const handleWaitingPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setLoadingOverlay(true)
        setWaitingCount(value);
        if(value === 1){
            setWaitingArgs('?page_size=5');
        }else{
            setWaitingArgs('?page_size=5&page=' + value)
        }
    }

    const handlePageChangeCurrent = (event: React.ChangeEvent<unknown>, value: number) => {
        setLoadingOverlay(true)
        setCurrPage(value);
        if(value === 1){
            setCurrArgs(`?page_size=5&status=CURRENT`);
        }else{
            setCurrArgs('?page_size=5&status=CURRENT&page=' + value)
        }
    }

    const handlePageChangeComp = (event: React.ChangeEvent<unknown>, value: number) => {
        setLoadingOverlay(true)
        setCompPage(value);
        if(value === 1){
            setCompArgs(`?page_size=5&status=COMPLETE`);
        }else{
            setCompArgs('?page_size=5&status=COMPLETE&page=' + value)
        }
    }


    const loadCurrTransactionsSell = async (filter: any) => {
        Auth.getTransactionsSell(
            filter,
            (res) => {
                console.log(res)
                setCurrCount(res.count)
                setCurrentProposals(res.results)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e.error);
            }
        )
    }

    const loadCompTransactionsSell = async (filter: any) => {
        Auth.getTransactionsSell(
            filter,
            (res) => {
                console.log(res)
                setCompCount(res.count)
                setCompletedProposals(res.results)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e.error);
            }
        )
    }

    const loadAcceptedProposals = async (filter: any) => {
        Auth.getProposalsSellAccepted(
            filter,
            (resp) => {
                setAcceptedCount(resp.count);
                setIncomingAccepted(resp.results)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
            }
        )
    }

    const loadWaitingProposals = async (filter: any) => {
        Auth.getProposalsSellWaiting(
            filter,
            (resp) => {
                setWaitingCount(resp.count);
                setIncomingWaiting(resp.results)
                
            },
            (e) => {
                console.log(e)
            }
        )
    }

    useEffect(() => {
        loadAcceptedProposals(acceptedArgs)
    }, [acceptedArgs])

    useEffect(() => {
        loadWaitingProposals(waitingArgs)
    },[waitingArgs])

    useEffect(() => {
        Auth.getProposalsSell(
            args,
            (response) => {
                console.log(response.results)
                setIncomingProposals(response.results)
                setCount(response.count)
                
                
            },
            (e) => {
                console.log(e)
            }
        )
    },[args])

    useEffect(() => {
        if(cards === null){
            Auth.getCards(
                (response) => {
                    console.log(response)
                    setCards(response)
                },
                (e) =>{
                    console.log(e)
                }
            )
        }
        if(user && !user.sellAuthorized){
            history.push('/app/seller/account-upgrade?send_documents=true');
        }
    }, [cards, user])

    useEffect(() => {
        loadCurrTransactionsSell(currArgs)
    },[currArgs])

    useEffect(() => {
        loadCompTransactionsSell(compArgs)
    },[compArgs])

    useEffect(() => {
        if(User.userData){
            setLoadingOverlay(true)
            User.get((userData) => {
                setMerchantID(userData.id);
                console.log(userData)


                /**
                 * Auth.getTransactions(
                    '/?page_size=500',
                    (response) => {
                        console.log(response.results)
                        let currArr = [];
                        let compArr = [];
                        for (const [key, value] of Object.entries(response.results)) {
                            console.log(response.results[key]);
                            if(response.results[key].merchant === userData.id){
                                if(response.results[key].status === "COMPLETE"){
                                    compArr.push(response.results[key])
                                }else{
                                    currArr.push(response.results[key])
                                }
                            }    
                        }
                        setCurrentProposals(currArr);
                        setCompletedProposals(compArr);
                    },
                    (e) => {
                        console.log(e)
                    }
                    )
                 * 
                 */
                
                
                setLoadingOverlay(false)
            });
            Auth.getProposalsSell(
                args,
                (response) => {
                    console.log(response.results)
                    setCount(response.count);
                    setIncomingProposals(response.results);
                },
                (e) => {
                    console.log(e)
                }
            )
            console.log(User.userData);   
        }
    }, [User])

    const handleCategoryChange = (val: any) => {
        if(val === "MERCHANDISE"){
            setTerms("1")
            setCategory(null)
        }
        else{
            setTerms('')
            setCategory(null)
        }
    }

    const handleECategoryChange = (val: any) => {
        if(val === "MERCHANDISE"){
            setETerms("1")
            setECategory(null)
        }
        else{
            setETerms('')
            setECategory(null)
        }
    }

    const getBuyer = () => {
        setLoadingOverlay(true)
        Auth.getIDViaEmail(
            buyerEmail,
            (response) => {
                setBuyerID(response)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setEmailError(e.detail)
                setLoadingOverlay(false)
            }
        )
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrPage(0);
    };
    
    const handleChangePage = (event: any, newPage: any) => {
        setCurrPage(newPage);
    };

    const handleTypeChange = (value: any) => {
        if(value === "link" && value !== type){
            setBuyerEmail(null)
            setBuyerID(null)
            setError(null)
            setLinkView(false)
            setLinkURL(null)
            setSentView(false)
            setSuccessView(false)
        }
        setType(value)
    }

  


    function numberWithCommas(x: any) {
        console.log(x);
        return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        handleTypeChange(newValue);
    }

    
    return(
        <>
        <div className="page-content create-sale">
            <Container>
                <Card raised>
                    <CardContent>
                        <Grid container item direction="row" alignItems="flex-start" justify="center">
                            <Grid item container direction="column" alignItems="center" justify="center" spacing={2} xs={12} md={3}>
                                <h1 className="page-title">Payments</h1>
                                    <Grid item>
                                        <MuiBtn
                                            variant="outlined"
                                            className={classes.btnOutlined}
                                            style={{width: '210px'}}
                                            onClick={() => {
                                                console.log("Open email")
                                                setProposeType('Email')
                                                setOpenEmailLinkPropose(true)
                                            } }
                                        >
                                            <span style={{color: '#FF873A'}}>Send Email Payment Request</span>
                                        </MuiBtn>
                                    </Grid>
                                    <Grid item>
                                        <MuiBtn
                                            variant="contained"
                                            style={{width: '210px'}}
                                            onClick={() => {
                                                console.log("Open link")
                                                setProposeType('Link')
                                                setOpenEmailLinkPropose(true)
                                            } }
                                            className={classes.btn}
                                        >
                                            <span style={{color: 'white'}}>Create a Payment Request Link</span>
                                        </MuiBtn>
                                    </Grid>
                            </Grid>
                            <Grid item xs={12} md={8}>
                            <div className="create-sale-container fg-container">
                                {/**
                                 * 
                                 * 
                                 * 
                                 *   <div className="form-container">
                                    
                                        <RadioGroup aria-label="gender" name="gender1" value={type} onChange={(e) => handleTypeChange(e.target.value)}>
                                            <div style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center'}}>
                                                <FormControlLabel style={{color: 'white'}} value="email" control={<Radio checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} />} label="Proposal via Email" />
                                                <FormControlLabel style={{color: 'white'}} value="link" control={<Radio checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />}/>} label="Proposal via Link" />
                                            </div>
                                        </RadioGroup>
                                        
                                    <button style={{display: 'hidden'}} onClick={() => setOpenEmail(true)} > test</button>

                                </div>
                                */}
                                
                                <div style={{margin: "8px 0px"}}>
                                    <Tabs 
                                    value={type} 
                                    classes={{indicator: classes.indicator}}
                                    onChange={handleTabChange} 
                                    style={{background: '#FF873A', border: "1px solid #FF873A", borderRadius: "5px"}}
                                    variant={matches ? "fullWidth" : "scrollable"} 
                                    scrollButtons="auto" 
                                    aria-label="simple tabs example"
                                    >
                                        <Tab value="incoming" label={<span style={{fontSize: 13, color: "white"}}>Incoming</span>}wrapped />
                                        <Tab value="current" label={<span style={{fontSize: 13, color: "white"}}>Accepted</span>} wrapped />
                                        <Tab value="completed" label={<span style={{fontSize: 13, color: "white"}}>Completed</span>} wrapped />
                                        
                                    </Tabs>
                                </div>

                                

                                {(type === "current" && currentProposals) ? (
                                    currentProposals.length > 0 ? currentProposals.map((result: any, index: any) => {
                                        result.type = "SELL" 
                                        result.incoming = false;
                                        return (
                                            <IncomingTransactions setID={(val: any) => setTransactionID(val)} type={"current"} {...cards} index={index} key={result.id} result={result} />
                                        )
                                    }
                                   
                                ):
                                    <div style={{textAlign: 'center'}}>
                                        <img className="image-mascot" src={mascot} /><br />
                                        <div style={{fontSize: '18px', paddingTop: '5px', textAlign:'center', color:'#FF873A', fontWeight: 500}}>No Accepted Transactions...</div> <br />
                                    </div>
                                ): <div>
                                        
                                    </div>}

                                {type === "completed" && completedProposals ? (
                                    completedProposals.length > 0 ? completedProposals.map((result: any, index: any) => {
                                        result.type = "SELL" 
                                        result.incoming = false;
                                        return (
                                            <IncomingTransactions  setID={(val: any) => setTransactionID(val)} type={"completed"} {...cards} index={index} key={result.id} result={result} />
                                        )
                                    }
                                ):
                                    <div style={{textAlign: 'center'}}>
                                        <img className="image-mascot" src={mascot} /><br />
                                        <div style={{fontSize: '18px', paddingTop: '5px', textAlign:'center', color:'#FF873A', fontWeight: 500}}>No Completed Transactions...</div> <br />
                                    </div>
                                ): <div></div>}

                                {type === "incoming" && incomingProposals ? (
                                   
                                    
                                    incomingProposals.length > 0 ? incomingProposals.map((result: any, index: any) => {
                                        result.type = "SELL" 
                                        result.incoming = true;
                                        return (
                                            <>
                                            <IncomingTransactions setID={(val: any) => setProposalID(val)} type={"incoming"} {...cards} index={index} key={result.id} result={result} />
                                            
                                            </>
                                        )
                                    }):

                                    <div style={{textAlign: 'center'}}>
                                        <img className="image-mascot" src={mascot} /><br />
                                        <div style={{fontSize: '18px', paddingTop: '5px', textAlign:'center', color:'#FF873A', fontWeight: 500}}>No Incoming Transactions...</div> <br />
                                    </div>
                                    
                                ): <div></div>}
                                 {type === "incoming" ? (
                                    <Pagination style={{paddingTop: '30px',}} count={Math.ceil(count/5)} page={page} shape="rounded" onChange={handlePageChange} />    
                                ): type === "current" ? (
                                    <Pagination style={{paddingTop: '30px',}} count={Math.ceil(currCount/5)} page={currPage} shape="rounded" onChange={handlePageChangeCurrent} /> 
                                ): type === "completed" ? (
                                    <Pagination style={{paddingTop: '30px',}} count={Math.ceil(compCount/5)} page={compPage} shape="rounded" onChange={handlePageChangeComp} /> 
                                ): null
                                }

                                
                            
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                   
                </CardActions>
            </Card>

            </Container>
        
        </div>
        <ProposalViaEmailLink 
            classes={classes} 
            user={user} 
            setLoadingOverlay={setLoadingOverlay} 
            proposeType={proposeType}
            setOpenEmailLinkPropose={setOpenEmailLinkPropose}
            openEmailLinkPropose={openEmailLinkPropose}
            type={"SELL"}
        />

        <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={successMessage}/>
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorMessage} />
        {(proposalResult || transactionResult || openProposal) &&(
            <ProposalDetails result={proposalResult ? proposalResult : transactionResult} cards={source} merchant={merchant} buyer={buyer} modal={openProposal} setModal={handleProposalClose}/>
        )}
        </>
    )
}