import React, { useCallback, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ProposalDetails } from '..';
import { User } from '../../models';
import Auth from '../../auth';
import {SuccessDialog, ErrorDialog} from '../../components';
import './style.scss'
import { Select, MenuItem, FormControlLabel, makeStyles, Radio, RadioGroup, Card, CardContent, Grid, Dialog, DialogTitle, CardActions, Divider } from '@material-ui/core';
import moment from 'moment';
import ListIcon from '@material-ui/icons/List';
import { count } from 'console';
import { Pagination, Alert } from 'reactstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';



const detailsIcon = require('../../assets/images/details.png');
const close = require('../../assets/images/close.png');


const useStyles = makeStyles({
    root: {
      borderColor: '#FF873A',
    },
    
    indicator: {
        backgroundColor: "#FF873A",
    },
    transactIcon: {
      fill: '#FF873A',
      fontSize: '30px'
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
      paddingTop: 15, 
      alignSelf: 'center', 
      justifySelf: 'center', 
      color: '#FF873A'
    },
    labelForm: {
        color: '#fffff',
    },
    cardEntry: {
      cursor: 'pointer',
      backgroundColor: '#fee2b3',
      '&:hover': {
        backgroundColor: '#fceaca',
      }
    },
    cardEntrySell: {
      cursor: 'pointer',
      backgroundColor: '#fee2b3',
      '&:hover': {
        backgroundColor: '#fceaca',
      }
    },
    divider: {
      backgroundColor: '#FF873A',
      border: "1px solid #FF873A",
    },
    checkedIcon: {
      backgroundColor: '#FF873A',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FF873A',
      },
    },
  });

export interface IncomingTransactionsProps {
  result: any | null;
  cards?: any | null;
  setID: (e?: any) => void;
}

export const IncomingTransactions: React.FC<IncomingTransactionsProps | any> = ({result, setID}) => {
    const classes = useStyles();
    const history = useHistory();
    const {user, source} = useContext<any>(User.Context)
    const [modal, setModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [copied, setCopied] = useState(false);
    const [continued, isContinued] = useState(false);
    const [newResult, setNewResult] = useState<any| null>(null);
    const [cards, setCards] = useState<Array<[]> | null>(source ? source : null);

    const [category, setCategory] = useState("");
    const [terms, setTerms] = useState("");
    const [sourceID, setSourceID] = useState(null);
    const [buyer, setBuyer] = useState<any | null>(null);
    const [merchant, setMerchant] = useState<any | null>(null);
   
 

    const payNow = (id: any) => {
      Auth.acceptProposal(
        id,
        sourceID,
        (resp) => {
          console.log(resp)
          setModal(false)
          setOpenDialog(true)
          setDialogMessage("The transaction will reflect on your data.");
          
        },
        (e) => {
          console.log(e)
          setOpenErrorDialog(true)
          setErrorDialogMessage(e.data.error ? e.data.error : e.data.paymaya_response.message)
        }
      )
    }

    useEffect(() => {
      if(cards === null){
          Auth.getCards(
              (response) => {
                  console.log(response)
                  setCards(response)
              },
              (e) =>{
                  console.log(e)
              }
          )
      }
    })

    const handleCategoryChange = (val: any) => {
      if(val === "STRAIGHT"){
          setTerms("1")
          setCategory(val)
          ///UPDATE PROPOSAL
          Auth.updateProposal(
            result.id,
            1,
            val,
            (resp) => {
              console.log(resp)
              //refresh();
              //setModal(false)
              setIsEdit(false);
              
            },
            (e) => {
              console.log(e)
            }
          )
      }
      else{
          setTerms('')
          setCategory(val);
          ///UPDATE PROPOSAL
      }
    }

   

    const openModal = (pid: any) => {
      if(user){
        if(result.type === "BUY"){
          setBuyer(user);
        }else{
          setMerchant(user);
        }
        
        console.log(result.type)
      }
      if(result.merchant && result.type === "BUY"){
        console.log("FETCHING MERCHANT")
        Auth.getUser(
          result.merchant,
          (response) => {
            console.log(response)
            if(result.type === "BUY"){
              setMerchant(response);
            }else{
              setBuyer(response);
            }
            setModal(true)
          },
          (e) => {
            console.log(e)
          }
        )
      }else if(result.buyer && result.type === "SELL"){
        console.log("FETCHING BUYER")
        Auth.getUser(
          result.buyer,
          (response) => {
            console.log(response)
            if(result.type === "BUY"){
              setMerchant(response);
            }else{
              setBuyer(response);
            }
            setModal(true)
          },
          (e) => {
            console.log(e)
          }
        )
      }else{
        setModal(true)
      }
     
    }

    function numberWithCommas(x: any) {
        return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    
    if(result){

    return (
        <>
          <div style={{paddingTop: 15, paddingRight: 20, paddingLeft: 20, cursor: 'pointer'}}>
            
            <div className="proposal-entry" onClick={() => {
              if(result.type === "SELL"){
                if(result.incoming){
                  if(result.isHome){
                    setID(result.id)
                    history.push(`/app/seller/proposal/${result.id}`)
                  }else{
                    setID(result.id)
                    history.push(`/app/seller/proposal/${result.id}`)
                  }
                  
                }else{
                  setID(result.id)
                  history.push(`/app/seller/transaction/${result.id}`)
                }
                
              }else{
                if(result.incoming){
                  if(result.isHome){
                    setID(result.id)
                    history.push(`/app/buyer/proposal/${result.id}`)
                  }else{
                    setID(result.id)
                    history.push(`/app/buyer/proposal/${result.id}`)
                  }
                }else{
                  setID(result.id)
                  history.push(`/app/buyer/transaction/${result.id}`)
                }
              }
            }} key={result.id}>
              <Grid container item direction="column" spacing={2}  justify="space-around">
                  <Grid container item direction="row" alignItems="flex-start" justify="space-between">
                      <Grid item className={"label-date"}>
                          {moment(result.created).format("MMMM D, YYYY")} 
                          
                      </Grid>
                      
                  </Grid>
                  <Grid container item direction="row" alignItems="center" justify="space-between">
                      <Grid item className={"importantLabel"}>
                        {result.name}
                      </Grid>
                      <Grid item className={"importantLabel"}>
                      {numberWithCommas(result.amount)}
                      </Grid>
                      
                  </Grid>
                  <Grid container item direction="row" spacing={2} alignItems="center" justify="space-between">
                    <Grid container xs={12} sm={6} item direction="column" alignItems="flex-start" justify="space-between">
                        <Grid item className={"label-date"}>
                          Status: {result.type === "SELL" ?
                            result.status === "CURRENT" ?
                              "AWAITING FOR PAYOUT"
                              : 
                              result.status
                            : 
                            result.status}
                        </Grid>
                        <Grid item className={"label-ref"}>
                            Reference: {result.reference} 
                            
                        </Grid>
                        
                    </Grid>
                    {/* {result.type === "SELL" && !result.incoming && (
                      
                      <Grid item xs={12} sm={6} className={"importantLabel"} style={{textAlign: 'end'}}>
                        Total Payout: {numberWithCommas(result.total_payout)}
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>
                
              </div>
              <Divider variant="fullWidth" classes={{root: classes.divider}}/>
          </div>
          {/**.
           * 
           * <ProposalDetails modal={modal} result={result} cards={cards} buyer={buyer} merchant={merchant} setModal={setModal} />
           * 
          */}
          {/***
           * <Dialog 
            className={classes.dialogPaper} 
            fullWidth={true}  
            maxWidth={'sm'}
            PaperProps={{style: {
              backgroundColor: '#fee2b3',
            }}}
            onClose={() => setModal(false)} open={modal}
          >
            <DialogTitle className={classes.dialogTitle}>
              <img
                  src={close}
                  style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                  onClick={()=>setModal(false)}
              />
              <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                  <Grid item>
                      <img src={detailsIcon} style={{width: '50px', height: '50px'}}/>
                  </Grid>
                  
              </Grid>
              <div style={{paddingTop: 10, color: "#FF873A"}}>
                  Transaction Details
              </div>
            </DialogTitle>
            <div style={{margin: "10px 20px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
              <div style={{padding: 25}}>
              <Grid container item direction="column" spacing={2} justify="flex-start">
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Status: 
                  </Grid>
                  <Grid item>
                    {resultStatus}
                  </Grid>
                </Grid>
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Transaction Name: 
                  </Grid>
                  <Grid item>
                    {result.name}
                  </Grid>
                </Grid>
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Category: 
                  </Grid>
                  <Grid item>
                    {resultCategory}
                  </Grid>
                </Grid>
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Amount: 
                  </Grid>
                  <Grid item >
                    {numberWithCommas(result.amount)}
                  </Grid>
                </Grid>
                {resultCategory === "INSTALLMENT" &&(
                  <>
                  <Grid container item direction="row" justify="space-between" alignItems="center">
                    <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                      Terms:
                    </Grid>
                    <Grid item>
                      {resultTerms + " months"}
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" justify="space-between" alignItems="center">
                    <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                      Transaction Fee: 
                    </Grid>
                    <Grid item>
                      {numberWithCommas(resultTransactFee)}
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" justify="space-between" alignItems="center">
                    <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                      Interest: 
                    </Grid>
                    <Grid item>
                      {resultInterest+"%"}
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" justify="space-between" alignItems="center">
                    <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                      Interest Fee: 
                    </Grid>
                    <Grid item>
                      {numberWithCommas(resultInterestCharge)}
                    </Grid>
                  </Grid>
                  </>
                )}
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Customer: 
                  </Grid>
                  <Grid item>
                    {buyer ? buyer.first_name+" "+buyer.last_name : 'None'}
                  </Grid>
                </Grid>
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Merchant: 
                  </Grid>
                  <Grid item>
                    {merchant ? merchant.first_name+" "+merchant.last_name : 'Anon'}
                  </Grid>
                </Grid>
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    Start Date: 
                  </Grid>
                  <Grid item>
                    {moment(result.created).format("MMMM D, YYYY h:mm A")}
                  </Grid>
                </Grid>
                <Grid container item direction="row" justify="space-between" style={{paddingBottom: 30}} alignItems="center">
                  <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                    {resultCategory === "INSTALLMENT" ? "Monthly Amount: " : "Total Amount: "} 
                  </Grid>
                  <Grid item>
                    {numberWithCommas(resultMonthly)}
                  </Grid>
                </Grid>
                { resultStatus !== "COMPLETE" && result.type === "SELL" && (
                  <>
                  {resultStatus === "WAITING" && (
                    <Grid container item justify="center" alignItems="center">
                      <Grid item>
                        <Button 
                            color='primary'
                            onClick={() => {
                              acceptProposal(result.id)
                            }}
                            className='create-sale-button'
                        >
                            Accept
                        </Button>
                      </Grid>
                    </Grid>
                    )}
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid container item direction="column" justify="center" alignItems="center">
                        <Grid item>
                          Link to Copy: 
                        </Grid>
                        <Grid item>
                          
                          <div className="link-copy">
                                <CopyToClipboard text={`https://api.4gives.me/api/v1/transactions/proposal/${result.id}/`} onCopy={() => setCopied(true)}>
                                    <Grid container item direction="row" alignContent="center" justify="center">
                                        <Grid item>
                                            <div className="text">
                                              {`https://api.4gives.me/api/v1/transactions/proposal/${result.id}/`}{!copied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CopyToClipboard>
                            </div>
                        </Grid>
                      </Grid>

                    </Grid>
                    </>
                )

                }
                {result.type === "BUY" && resultStatus === "WAITING" && (
                <Grid container item justify="center" alignItems="center">
                  <Grid item style={{ fontWeight: 'bold', color: '#FF873A'}}>
                    Please wait for the seller to accept this proposal!
                  </Grid>
                </Grid>
                )}
                {result.type === "BUY" && resultStatus === "ACCEPTED" ? (
                    <>
                      <Grid container item justify="center" alignItems="center">
                        <Grid item>
                          <Button 
                              color='login-secondary'
                              onClick={() => {
                                isContinued(true)
                                setIsEdit(false)
                              }}
                              className='transact-button'
                          >
                              Continue
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container item justify="center" alignItems="center">
                        <Grid item>
                          <Button 
                              color='login-primary'
                              onClick={() => {
                                setIsEdit(true)
                                isContinued(false)
                              }}
                              className='transact-button'
                          >
                              Edit
                          </Button>
                        </Grid>
                      </Grid>
                      {resultStatus === "ACCEPTED" && isEdit && (
                      <>
                        <Grid container item justify="center" alignItems="center">
                          <Grid item>
                            <Select
                                id="demo-simple-select-autowidth"
                                name="category"
                                value={category}
                                style={{width: '200px'}}
                                onChange={(e: any)=>{handleCategoryChange(e.target.value);}}
                            >
                                <MenuItem value="" disabled>
                                    <em>Choose transaction type...</em>
                                </MenuItem>
                                
                                <MenuItem value={'STRAIGHT'}>STRAIGHT</MenuItem>
                                <MenuItem value={'INSTALLMENT'}>INSTALLMENT</MenuItem>
                                
                            </Select>
                          </Grid>
                        </Grid>
                        
                        {category === "INSTALLMENT" && (
                        <Grid container item justify="center" alignItems="center">
                          <Grid item>
                            <Select
                                id="demo-simple-select-autowidth"
                                name="terms"
                                value={terms}
                                style={{width: '200px'}}
                                onChange={(e: any)=>{ setTerms(e.target.value);handleTermChange(e.target.value)}}
                            >
                                <MenuItem value="" disabled>
                                    <em>Choose monthly terms...</em>
                                </MenuItem>
                                
                                <MenuItem value={'3'}>3</MenuItem>
                                <MenuItem value={'6'}>6</MenuItem>
                                <MenuItem value={'9'}>9</MenuItem>
                                <MenuItem value={'12'}>12</MenuItem>
                                
                            </Select>
                          </Grid>
                        </Grid>
                        
                        )}
                      </>
                      )
                      
                      }
                      {resultStatus === "ACCEPTED" && continued ? (
                        <>
                      <Grid container item justify="center" alignItems="center">
                        <Grid item>
                            <Select
                                id="demo-simple-select-autowidth"
                                name="id"
                                value={sourceID}
                                style={{width: '300px'}}
                                onChange={(e:any)=>{setSourceID(e.target.value);}}
                            >
                                <MenuItem value="" disabled>
                                    <em>Choose your source</em>
                                </MenuItem>
                                {cards !== null ? cards.map((card : any) => {
                                
                                   if(card.installmentAuthorized){
                                     return(
                                      <MenuItem key={card.id} value={card.id} >{card.name}</MenuItem>
                                     )
                                    
                                   }
                                    
                                  
                                  }
                                ): null}
                                
                            </Select>
                        </Grid>
                        
                      </Grid>
                      {resultCategory === null || resultCategory === "MERCHANDISE"? (
                        <>
                      <Grid container item justify="center" alignItems="center">
                        <Grid item>
                          <Select
                              id="demo-simple-select-autowidth"
                              name="category"
                              value={category}
                              style={{width: '200px'}}
                              onChange={(e: any)=>{handleCategoryChange(e.target.value);}}
                          >
                              <MenuItem value="" disabled>
                                  <em>Choose transaction type...</em>
                              </MenuItem>
                              
                              <MenuItem value={'STRAIGHT'}>STRAIGHT</MenuItem>
                              <MenuItem value={'INSTALLMENT'}>INSTALLMENT</MenuItem>
                              
                          </Select>
                        </Grid>
                      </Grid>
                      
                      {category === "INSTALLMENT" && (
                      <Grid container item justify="center" alignItems="center">
                        <Grid item>
                          <Select
                              id="demo-simple-select-autowidth"
                              name="terms"
                              value={terms}
                              defaultValue=''
                              style={{width: '200px'}}
                              onChange={(e: any)=>{ setTerms(e.target.value);handleTermChange(e.target.value)}}
                          >
                              <MenuItem value="" disabled>
                                  <em>Choose monthly terms...</em>
                              </MenuItem>
                              
                              <MenuItem value={'3'}>3</MenuItem>
                              <MenuItem value={'6'}>6</MenuItem>
                              <MenuItem value={'9'}>9</MenuItem>
                              <MenuItem value={'12'}>12</MenuItem>
                              
                          </Select>
                        </Grid>
                      </Grid>
                      )}
                      </>
                      ):null

                      }
                      
                      <Grid container item justify="center" alignItems="center">
                        <Grid item>
                          <Button 
                              color='login-primary'
                              onClick={() => {
                                payNow(result.id)
                              }}
                              className='create-sale-button'
                          >
                              Pay Now
                          </Button>
                        </Grid>
                      </Grid>
                      </>
                      ): resultStatus === "WAITING" ?(
                    <Grid container item justify="center" alignItems="center">
                      <Grid item style={{ fontWeight: 'bold', color: '#FF873A'}}>
                        Please wait for the seller to accept this proposal!
                      </Grid>
                    </Grid>
                      ) : null}
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid container item direction="column" justify="center" alignItems="center">
                        <Grid item>
                          Link to Copy: 
                        </Grid>
                        <Grid item>
                          <div className="link-copy">
                              <CopyToClipboard text={`https://api.4gives.me/api/v1/transactions/proposal/${result.id}/`} onCopy={() => setCopied(true)}>
                                  <Grid container item direction="row" alignContent="center" justify="center">
                                      <Grid item>
                                          <div className="text">
                                            {`https://api.4gives.me/api/v1/transactions/proposal/${result.id}/`}{!copied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                          </div>
                                      </Grid>
                                  </Grid>
                              </CopyToClipboard>
                          </div>
                        </Grid>
                      </Grid>

                    </Grid>
                    </>
                  ):
                null
                }
                
                
                
              </Grid>
              </div>
            </div>
          </Dialog>
          <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={dialogMessage} />
          <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
           */}
          
        </>
    )
    }else{
      return(
      <div>

      </div>
      )
    }
}