import { Card, Dialog, DialogTitle, Divider, Grid, Select, MenuItem, makeStyles, CardHeader, CardContent, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import { result } from "lodash"
import { Button, ProposalDetailsContent, TextCardExpiryInput, TextInput } from "../../components"
import moment from "moment"
import React, { useContext, useState } from "react"
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import { useHistory, useLocation, useParams } from "react-router-dom"
import { SuccessDialog, ErrorDialog } from "../../components"
import Auth from "../../auth"
import {Container} from 'reactstrap';
import { useQuery } from "../../hooks";
import { useEffect } from "react";
import { OTPDialog } from "../../components/OTPDialog";
import { Alert } from "@material-ui/lab";
import { User } from "../../models";
import { useMediaQuery } from "@material-ui/core";

const detailsIcon = require('../../assets/images/details.png');

interface ParamTypes {
    id?: any
    type?: any
}


export const ProposalTransaction: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {

    const location = useLocation();

    const {user, source} = useContext<any>(User.Context)

    const {id, type} = useParams<ParamTypes>();

    const history = useHistory();

    const query = useQuery();

    const [openDialog, setOpenDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [proposalResult, setProposalResult] = useState<any | null>(null)
    const [openProposal, setOpenProposal] = useState(false);
    const [buyer, setBuyer] = useState<any | null>(null)
    const [merchant, setMerchant] = useState<any | null>(null)

    const [transactionResult, setTransactionResult] = useState<any | null>(null)


    const [proposalID, setProposalID] = useState<any | null>(type === 'proposal' ? id : null)
    const [transactionID, setTransactionID] = useState<any | null>(type === 'transaction' ? id : null)

    useEffect(() => {
        console.log("Loading Proposal/Transaction")
        if(proposalID && !proposalResult){
            console.log(query.get('pid'))
            Auth.getProposal(
                proposalID,
                (resp) => {
                    resp.type = location.pathname.includes('buyer') ? 'BUY' : location.pathname.includes('seller') ? 'SELL' : ''
                    if(user){
                        if(resp.type === "BUY"){
                            setBuyer(user);
                        }else if(resp.type === "SELL"){
                            setMerchant(user)
                        }
                    
                        console.log(resp.type)
                    
                        if(resp.merchant && resp.type === "BUY"){
                            /*Auth.getUser(
                                resp.merchant,
                                (response) => {
                                    console.log(response)
                                    if(resp.type === "BUY"){
                                        setMerchant(response);
                                        console.log(resp)
                                    setProposalResult(resp)
                                    setLoadingOverlay(false)
                                    }else{
                                        console.log(resp)
                                        setProposalResult(resp)
                                        setLoadingOverlay(false)
                                    }
                                    
                                
                                },
                                (e) => {
                                    console.log(e)
                                }
                            )*/
                            setProposalResult(resp)
                            setLoadingOverlay(false)
                        }else if(resp.buyer && resp.type === "SELL"){
                            /*Auth.getUser(
                                resp.buyer,
                                (response) => {
                                    console.log(response)
                                    if(resp.type === "SELL"){
                                        setBuyer(response);
                                    }
                                    
                                },
                                (e) => {
                                    console.log(e)
                                }
                            )*/
                            setProposalResult(resp)
                            setLoadingOverlay(false)
                        }else {
                            setProposalResult(resp)
                            setLoadingOverlay(false)
                        }
                    }
                    
                    
                    
                },
                (e) => {
                    console.log(e)
                    setErrorMessage("Proposal not found.")
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                }
            )
        }
        else if(transactionID && !transactionResult){
            Auth.getTransaction(
                transactionID,
                (resp) => {
                    resp.type = location.pathname.includes('buyer') ? 'BUY' : location.pathname.includes('seller') ? 'SELL' : ''
                    if(user){
                        if(resp.type === "BUY"){
                            setBuyer(user);
                        }else if (resp.type === "SELL"){
                            setMerchant(user)
                        }
                        if(resp.merchant && resp.type === "BUY"){
                            Auth.getUser(
                                resp.merchant,
                                (response) => {
                                  console.log(response)
                                  if(resp.type === "BUY"){
                                    setMerchant(response);
                                  }
                                  setTransactionResult(resp)
                                  setLoadingOverlay(false)
                                  
                                },
                                (e) => {
                                  console.log(e)
                                }
                            )
                        }else if(resp.buyer && resp.type === "SELL"){
                            Auth.getUser(
                                resp.buyer,
                                (response) => {
                                  console.log(response)
                                  if(resp.type === "SELL"){
                                    setBuyer(response);
                                  }
                                  setTransactionResult(resp)
                                  setLoadingOverlay(false)
                                  
                                },
                                (e) => {
                                  console.log(e)
                                }
                            )
                        }else{
                            setTransactionResult(resp)
                            setLoadingOverlay(false)
                        }
                        
                    
                        console.log(resp.type)
                    }
                },
                (e) => {
                    console.log(e)
                    setErrorMessage("Transaction not found.")
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                }
            )
        }

        else if(proposalResult || transactionResult){
            console.log(proposalResult)
            setOpenProposal(true)
        }
    },[proposalResult, transactionResult, transactionID, proposalID, location.search])

    return(
        <div className="page-content proposal-transaction-details">  
            <Container>
                {((proposalResult || transactionResult) && openProposal) && (
                    <ProposalDetailsContent setLoadingOverlay={setLoadingOverlay} result={proposalResult ? proposalResult : transactionResult} cards={source} merchant={merchant} buyer={buyer}/>
                )}
            </Container>
        </div>
    )
}