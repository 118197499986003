import React, {useState, useEffect} from 'react';
import { TextInput, FileUpload } from '../../components/Input';
import { useForm, useFormFiles, useQuery } from '../../hooks';
import {  Row, Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import {
    InputLabel,
    MenuItem,
    Container,
    FormControl,
    Select,
    Card,
    CardContent,
} from '@material-ui/core';
import { Button } from '../../components/Button';
import Auth from '../../auth';

import './style.scss';
import { SuccessDialog, ErrorDialog } from '../../components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    selectInput: {
        minWidth: 300,
        maxWidth: 300,
    }
}));



export const AccountActivation: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const classes = useStyles();
    const [cards, setCards] = useState<Array<[]> | null>(null);
    const [refId, setRefId] = useState<any | null>('');
    const [sourceId, setSourceId] = useState<any | null>('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)
    const {values, handleChange} = useForm({ 
        cardCutoff: '',
        cardLimit: '',
        id: '',
    });

    const history = useHistory()

    const query = useQuery();

    const {files, handleFiles} = useFormFiles({
        Cardstatement: '',
    });

    useEffect(() => {
        if(cards === null){
            setLoadingOverlay(true)
            Auth.getCards(
                (response) => {
                    console.log(response)
                    setCards(response)
                    if(query.has("sid") && sourceId === ''){
                        response.map((card: any) => {
                            
                            if(card.id.toString() === query.get("sid")){
                                
                                setSourceId(card.id)
                                setRefId(card.reference_id)
                            }
                        })
                        
                    }
                    setLoadingOverlay(false)
                },
                (e) =>{
                    setLoadingOverlay(false)
                    console.log(e)
                }
            )
        }
        
    })

    const activateAccount = () => {
        setLoadingOverlay(true)
        Auth.activateAcc(
            sourceId,
            refId,
            files.Cardstatement,
            values.cardCutoff,
            values.cardLimit,
            (response) => {
                console.log(response)
                setDialogMsg("Card Activation Request sent! Please allow up to 48 hours for verification.")
                setOpenSuccess(true);
                setLoadingOverlay(false)
            },
            (e) => {
                setError(e)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )
    }


    if(cards !== null){
    return(
        <>
        <div className="page-content activate-account">
            <Container>
                <Row>
                    <Col sm={12} md={{ size: 9, offset: 1 }}>
                   
                        <div className="activate-account-container fg-container">
                            <h1 className="page-title">
                                Card Activation
                            </h1>
                            <p>Activate installments for your account by providing the following:</p>
                            <br></br>
                            <p>1. Select your card</p>
                            <p>2. Credit card statement</p>
                            <p>3. Monthly cut off</p>
                            <p>4. Card Limit</p>
                            <br></br>
                            <p>Note: allow 48 hours for verification. If successful, you may now purchase items from anyone who has a 4gives seller account access with monthly installments!</p>
                            
                                    {cards.length > 0 ? (
                                        <Row>
                                            <Col xs={12} className={classes.selectInput}>
                                                <div className="form-container">
                                                    <h6 id="demo-simple-select-autowidth-label">Select your card:</h6>
                                                    <Select
                                                        id="demo-simple-select-autowidth"
                                                        name="id"
                                                        value={sourceId}
                                                        style={{width: '300px'}}
                                                        onChange={(e)=>{console.log(e.target.value); setSourceId(e.target.value)}}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            <em>Choose your source</em>
                                                        </MenuItem>
                                                        {cards !== null ? cards.map((card : any) => {
                                                            if(!card.installmentAuthorized){
                                                                return(
                                                                    <MenuItem key={card.id} value={card.id} onChange={()=>setRefId(card.reference_id)} onSelect={()=>setRefId(card.reference_id)}>{card.name}</MenuItem>
                                                                )
                                                                
                                                            }
                                                            
                                                        }): null}
                                                        
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    ): (
                                        <Row>
                                            <Col sm={12} md={12}>
                                                
                                                <div className="activate-account-button">
                                                    <h6>No card found</h6>
                                                    <Button 
                                                        color='primary'
                                                        onClick={() => {history.push('/app/buyer/vault-cards')}}
                                                        className='fg-acountactivate-button'
                                                    >
                                                        Add Card
                                                    </Button>

                                                    
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    
                                
                            {sourceId && (
                                <>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="form-container">
                                        <h6>Credit Card Statement</h6>
                                        <p>Send your latest credit card statement. Please upload a clear copy. (Valid formats: JPG, PNG, PDF)</p>
                                        <div className="fileupload">
                                            <FileUpload
                                                name='Cardstatement'
                                                onChange={handleFiles}
                                                placeholder='Account Number'
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="form-container">
                                        <h6>Monthly Cut Off</h6>
                                        <TextInput 
                                            name='cardCutoff'
                                            onChange={handleChange}
                                            type='text'
                                            value={values.cardCutoff}
                                            placeholder='Monthly Cut Off'
                                        />
                                    </div>
                                    <div className="form-container">
                                        <h6>Card Limit</h6>
                                        <TextInput 
                                            name='cardLimit'
                                            onChange={handleChange}
                                            type='text'
                                            value={values.cardLimit}
                                            placeholder='Card Limit'
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                   
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="activate-account-button">
                                        
                                        <Button 
                                            color='primary'
                                            onClick={activateAccount}
                                            className='fg-acountactivate-button'
                                        >
                                            Send Activation Request
                                        </Button>
                                        <Button 
                                            color='secondary'
                                            onClick={()=> history.goBack()}
                                            className='fg-acountactivate-button-back'
                                        >
                                            Back
                                        </Button>
                                        <p>
                                            Allow up to 48 hours for verification
                                        </p>

                                        
                                    </div>
                                </Col>
                            </Row>
                            </>
                            )
                                
                            }
                            
                            
                            
                        </div>
                       
                    </Col>

                </Row>
                
            </Container>
        </div>
        <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg}/>
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </>
    );
    }else{
        return(<div></div>)
    }
}
