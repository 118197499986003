import { Card, Dialog, DialogTitle, Divider, Grid, Select, MenuItem, makeStyles, CardHeader, CardContent, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import { result } from "lodash"
import { Button, TextCardExpiryInput, TextInput } from '..';
import moment from "moment"
import React, { useContext, useState } from "react"
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import { useHistory, useLocation } from "react-router-dom"
import { SuccessDialog, ErrorDialog } from ".."
import Auth from "../../auth"
import { useQuery } from "../../hooks";
import { useEffect } from "react";
import { OTPDialog } from "../OTPDialog";
import { Alert } from "@material-ui/lab";
import { User } from "../../models";
import { useMediaQuery } from "@material-ui/core";
import './style.scss'
import ReactPixel from 'react-facebook-pixel';

const detailsIcon = require('../../assets/images/details.png');
const close = require('../../assets/images/close.png');

const useStyles = makeStyles({
    root: {
      borderColor: '#FF873A',
    },
    
    indicator: {
        backgroundColor: "#FF873A",
    },
    transactIcon: {
      fill: '#FF873A',
      fontSize: '30px'
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
      paddingTop: 15, 
      alignSelf: 'center', 
      justifySelf: 'center', 
      color: '#FF873A'
    },
    labelForm: {
        color: '#fffff',
    },
    cardEntry: {
      cursor: 'pointer',
      backgroundColor: '#fee2b3',
      '&:hover': {
        backgroundColor: '#fceaca',
      }
    },
    cardEntrySell: {
      cursor: 'pointer',
      backgroundColor: '#fee2b3',
      '&:hover': {
        backgroundColor: '#fceaca',
      }
    },
    checkedIcon: {
      backgroundColor: '#FF873A',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FF873A',
      },
    },
  });


export interface ProposalDetailsProps {
    modal?: any | null;
    result?: any | null;
    cards?: any | null;
    buyer?: any | null;
    merchant?: any | null;
    setModal?: (val: any) => void;
    setLoadingOverlay: (val: boolean) => void;
}

export const ProposalDetailsContent: React.FC<ProposalDetailsProps | any> = ({result, cards, buyer, merchant, modal, setModal, setLoadingOverlay}) => {

    const classes = useStyles();

    const location = useLocation();

    const { user, source } = useContext(User.Context)
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [copied, setCopied] = useState(false);
    const [refCopied, setRefCopied] = useState(false);
    const [continued, isContinued] = useState(false);
    const [showCategoryEdit, setCategoryEdit] = useState(false);
    const [resultCategory, setResultCategory] = useState(result.category ? result.category_name : "MERCHANDISE");
    const [resultPaymentType, setResultPaymentType] = useState(result.payment_type_name);
    const [resultTerms, setResultTerms] = useState(result.terms)
    const [resultMonthly, setResultMonthly] = useState(result.monthly)
    const [resultAmount, setResultAmount] = useState(result.amount);
    const [resultStatus, setResultStatus] = useState(result.status)
    const [resultRef, setResultRef] = useState(result.reference);
    const [resultTransactFee, setResultTransactFee] = useState(result.transaction_fee)
    const [resultInterest, setResultInterest] = useState(result.monthly_interest)
    const [resultInterestCharge, setResultInterestCharge] = useState(result.interest_charge);
    const [resultEndDate, setResultEndDate] = useState(result.end_date)
    const [openOTP, setOpenOTP] = useState(false);
    const [paymentType, setPaymentType] = useState(result.payment_type ? result.payment_type : '');
    const [terms, setTerms] = useState("");
    const [sourceID, setSourceID] = useState(null);
    const [paymentTypeList, setPaymentTypeList] = useState<any | null>(null)
    const [showODP, setShowODP] = useState(false);
    const [cardNumber, setCardNumber] = useState<string>('');
    const [cardMonth, setCardMonth] = useState<string>('');
    const [cardYear, setCardYear] = useState<string>('');
    const [cardCVC, setCardCVC] = useState<string>('');

    const [proposalRedirect, setProposalRedirect] = useState<any | null>(null);

    const [paymentHistory, setPaymentHistory] = useState<Array<[]> | null>((result.type === "BUY" && result.payments) && result.payments.length > 0 ? result.payments.slice(0) : null);

    const matches = useMediaQuery('(max-width: 500px)')

    const [paymentID, setPaymentID] = useState<any | null>(null)

    const [sourceIDSelected, setSourceIDSelected] = useState<any| null>(null)

    const [isErrorMsg, setIsErrorMsg] = useState<any | null>(null);

    const query = useQuery();

    useEffect(() => {
      if(paymentTypeList === null){
        Auth.getServiceTypes(
          (resp) => {
            setPaymentTypeList(resp.results)
          },
          (e) => {
            console.log(e)
          }
        )
      }
    },[paymentTypeList])

    function numberWithCommas(x: any) {
        x = parseInt(x);
        console.log(x)
        return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const acceptProposal = (id: any) => {
      if(result.type === "SELL"){
        console.log(result.id.toString());
        Auth.acceptSellerProposal(
          id,
          user ? user.id : null,
          result.buyer,
          paymentType,
          (response) => {
            console.log(response)
            //refresh();
            //setModal(false)
            if(result.proposal_type === "BUYING"){
              setOpenDialog(true)
              setDialogMessage("The proposal has been accepted!");
            }
            getNewValues(response)
          },
          (e) => {
            console.log(e)
          }
        )
      }
      else if(result.type === "BUY"){
        console.log(result.id.toString());
        Auth.acceptSellerProposal(
          id,
          result.merchant,
          user ? user.id : null,
          result.is_interest_shouldered_by_merchant ? 2 : resultCategory === "RENT" ? 2 : null,
          (response) => {
            console.log(response)
            //refresh();
            //setModal(false)
            setOpenDialog(true)
            setDialogMessage("You have accepted this offer! You can now proceed with the payment.");
            getNewValues(response)
          },
          (e) => {
            console.log(e)
          }
        )
      }
    }

    const getNewValues = (newResult: any) => {
      setResultCategory(newResult.category ? newResult.category_name : 1)
      setPaymentType(newResult.payment_type)
      setResultPaymentType(newResult.payment_type_name)
      setResultInterest(newResult.monthly_interest)
      setResultInterestCharge(newResult.interest_charge)
      setResultTerms(newResult.terms)
      setResultTransactFee(newResult.transaction_fee)
      setResultRef(newResult.reference)
      setResultAmount(newResult.amount)
      setResultMonthly(newResult.monthly)
      setResultStatus(newResult.status)
      setResultEndDate(newResult.end_date)
      setPaymentHistory(newResult.payments)
    } 

    useEffect(() => {
      if(sourceID){
        if(resultCategory === "RENT"){
          Auth.updateProposal(
            result.id,
            result.terms,
            2,
            (resp) => {
              getNewValues(resp)
            },
            (e) => {
              console.log(e)
            }
          )
        }
        if(paymentType !== ''){
          if(sourceIDSelected && (!result.is_interest_shouldered_by_merchant && resultCategory === "MERCHANDISE")){
            handleTermChange(3, paymentType)
          }
        }
      }
    },[sourceID])


    const payNow = (id: any) => {
   
        Auth.acceptProposal(
          id,
          sourceID,
          (resp) => {
            let string = '4gives_transaction';
            ReactPixel.trackCustom('Pay Now', {
              content_ids: [result.id],
              currency: 'PHP',
              value: result.monthly
            })
            setProposalRedirect(resp[string].id)
            
            setOpenDialog(true)
            setDialogMessage("You have successfully paid the proposal.");
            
          },
          (e) => {
            console.log(e)
            setOpenErrorDialog(true)
            setErrorDialogMessage(e.data.error ? e.data.error : e.data.paymaya_response.message)
          }
        )
      
      
    }

    const checkIfCardIsAuthorized = (sid: any) => {
        let isInstall = cards.filter((test: any) => 
          test.id === sid
        ).map((card: any) => {
          console.log(card)
          return card.installmentAuthorized
        })
        return isInstall[0]
    }

    const newPayment = () => {
      setLoadingOverlay(true)
      Auth.tokenize_card(
        cardNumber,
        cardMonth,
        '20'+cardYear,
        cardCVC,
        (resp) => {
          Auth.onDemandPay(
            paymentID,
            resp.paymentTokenId,
            (r) => {
              window.location.replace(r.verification_url)
              //setDialogMessage("Redirecting you to the paymaya page for verification...")
              //setOpenDialog(true)
            },
            (e) => {
              setOpenErrorDialog(true)
              setErrorDialogMessage(e.error)
            }
          )
        },
        (e) => {
          setOpenErrorDialog(true)
          setErrorDialogMessage(e)
        }
      )
    }
    
    const handleCategoryChange = (val: any) => {
        if(val === 1){
            setTerms("1")
            setPaymentType(val)
            ///UPDATE PROPOSAL
            Auth.updateProposal(
              result.id,
              1,
              val,
              (resp) => {
                console.log(resp)
                //refresh();
                //setModal(false)
                setIsEdit(false);
                if(resp.error){
                  setOpenErrorDialog(true)
                  setErrorDialogMessage(resp.error)
                }else{
                  getNewValues(resp)
                }
                
                
              },
              (e) => {
                console.log(e)
              }
            )
        }
        else{
          let isError = cards.filter((resp: any) => sourceID === resp.id).map((card: any) => {
            return card.installmentAuthorized;
          })
          if(isError[0]){
            setIsErrorMsg("Your card does not support installments");
          }
            //if(result.is_interest_shouldered_by_merchant){
              setTerms('3')
              setPaymentType(val);
            
              //
              
              console.log(isError)
              handleTermChange(3, val)
           // }else{
             // setOpenErrorDialog(true)
              setErrorDialogMessage(`You can't change the terms set by the merchant.`)
           // }
            
            ///UPDATE PROPOSAL
        }
      }
  
      const handleTermChange = (val: any, payType?: any) => {
        setTerms(val)
        console.log(val)
        if(sourceIDSelected){
          Auth.updateProposal(
            result.id,
            val,
            payType ? payType : paymentType,
            (resp) => {
              console.log(resp)
              //refresh();
              //setModal(false)
              setIsEdit(false);
              getNewValues(resp)
              
            },
            (e) => {
              console.log(e)
            }
          )
        }
        
      }

      
    return (
        <>
        <div className="proposal-details-content" style={{border: '1px solid #ececec', paddingTop: '20px'}}>
            
            
              <Grid container item direction="column" spacing={4} alignItems="center" justify="center">
                  <Grid item>
                      <img src={detailsIcon} style={{width: '50px', height: '50px'}}/>
                  </Grid>
                  
              </Grid>
              <div style={{paddingTop: 10, color: "#FF873A", textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>
                  Transaction Details
              </div>

            <div style={{margin: "10px 20px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
              <div style={{padding: matches ? '25px' :'25px 100px'}}>
                <Grid container item direction="row" spacing={5}>
                  <Grid xs={12} lg={12} container item direction="column" spacing={2} justify="flex-start">
                    {result.type === "SELL" ? (
                      <> {/// CURRENT SELL
                      }
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold', fontSize: '14px'}}>
                          Reference: 
                        </Grid>
                        <Grid item style={{fontSize: '14px'}}>
                          {resultRef}
                        </Grid>
                      </Grid>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Status: 
                        </Grid>
                        <Grid item>
                          {resultStatus}
                          {/*result.type === "SELL" ?
                                  result.buyer_status === "COMPLETE" ?
                                    "AWAITING FOR PAYOUT"
                                    : 
                                    resultStatus
                                  : 
                    resultStatus*/}
                        </Grid>
                      </Grid>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Transaction Name: 
                        </Grid>
                        <Grid item>
                          {result.name}
                        </Grid>
                      </Grid>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Amount: 
                        </Grid>
                        <Grid item >
                          {numberWithCommas(result.amount)}
                        </Grid>
                      </Grid>
                    
                        <>
                          {result.is_interest_shouldered_by_merchant && (

                            <>
                          <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                              Monthly Interest: 
                            </Grid>
                            <Grid item >
                              {result.monthly_interest}
                            </Grid>
                          </Grid>
                          <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                              Interest Fee: 
                            </Grid>
                            <Grid item >
                              {result.interest_fee ? numberWithCommas(result.interest_fee) : 'N/A'}
                            </Grid>
                          </Grid>
                          </>
                           )}
                          
                          <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                              Merchant Fee: 
                            </Grid>
                            <Grid item >
                              {result.merchant_fee ? result.merchant_fee : 'N/A'}
                            </Grid>
                          </Grid>
                          <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                              Total Merchant Fee: 
                            </Grid>
                            <Grid item >
                              {result.total_merchant_fee ? numberWithCommas(result.total_merchant_fee) : 'N/A'}
                            </Grid>
                          </Grid>
                          
                        </>
               
                      {result.total_payout && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Total Payout: 
                        </Grid>
                        <Grid item >
                          {numberWithCommas(result.total_payout)}
                        </Grid>
                      </Grid>
                      )}
                      
                      {resultCategory === "RENT" && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold', fontSize: '14px'}}>
                          Transaction Fee: 
                        </Grid>
                        <Grid item style={{fontSize: '14px'}}>
                          {numberWithCommas(result.transaction_fee)}
                        </Grid>
                      </Grid>
                      )}
                      
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Created by:"}
                        </Grid>
                        <Grid item>
                        {result.proposal_type === "BUYING" ? result.buyer ? result.buyer_name : 'None' : result.merchant ? result.merchant_name : 'None'}
                        </Grid>
                      </Grid>
                      
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Payout Date:"}
                        </Grid>
                        <Grid item>
                          {result.disbursement_date ? moment(result.disbursement_date).format("MMMM D, YYYY h:mm A") : 'N/A'}
                        </Grid>
                      </Grid>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Transaction Date:"}
                        </Grid>
                        <Grid item>
                          {moment(result.created).format("MMMM D, YYYY h:mm A")}
                        </Grid>
                      </Grid>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Interest shouldered by merchant:"}
                        </Grid>
                        <Grid item>
                          {result.is_interest_shouldered_by_merchant ? "Yes": "No"}
                        </Grid>
                      </Grid>
                      {result.used && (
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          { "Reusable Link Count:"}
                          </Grid>
                          <Grid item>
                            {result.used}
                          </Grid>
                        </Grid>
                      )}
                      </>
                    ) : (result.status !== "COMPLETE" && result.status !== "CURRENT") ? 
                    (
                      <>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Reference: 
                      </Grid>
                      <Grid item>
                        {resultRef}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Category: 
                      </Grid>
                      <Grid item>
                        {resultCategory}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Status: 
                      </Grid>
                      <Grid item>
                      {result.type === "SELL" ?
                              result.buyer_status === "COMPLETE" ?
                                "AWAITING FOR PAYOUT"
                                : 
                                resultStatus
                              : 
                              resultStatus}
                      </Grid>
                    </Grid>
                    
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Transaction Name: 
                      </Grid>
                      <Grid item>
                        {result.name}
                      </Grid>
                    </Grid>
                    {/***
                     * 
                     * <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Category
                      </Grid>
                      <Grid item>
                        {resultCategory}
                      </Grid>
                    </Grid>
                    * 
                    */}
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Amount: 
                      </Grid>
                      <Grid item >
                        {numberWithCommas(result.amount)}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Created by:"}
                        </Grid>
                      <Grid item>
                        {result.proposal_type === "BUYING" ? result.buyer ? result.buyer_name : 'None' : result.merchant ? result.merchant_name : 'None'}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Interest shouldered by merchant:"}
                        </Grid>
                        <Grid item>
                          {result.is_interest_shouldered_by_merchant ? "Yes": "No"}
                        </Grid>
                      </Grid>
                    
                    
                    <Grid item>
                      <Divider />
                    </Grid>
                    
                    
                    
                    
              
                    
                    {(resultPaymentType === "INSTALLMENT" && (sourceIDSelected || ((result.is_interest_shouldered_by_merchant && resultCategory === 'MERCHANDISE') || resultCategory === "RENT"))) && (
                      <>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Terms:
                        </Grid>
                        <Grid item>
                          {resultTerms + " months"}
                        </Grid>
                      </Grid>
                      {resultCategory === "RENT" && (
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Transaction Fee: 
                        </Grid>
                        <Grid item>
                          {numberWithCommas(resultTransactFee)}
                        </Grid>
                      </Grid>
                      )}
                      {resultInterestCharge > 0 && (
                        <>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                            Interest: 
                          </Grid>
                          <Grid item>
                            {resultInterest}
                          </Grid>
                        </Grid>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                            Monthly Interest Fee: 
                          </Grid>
                          <Grid item>
                            {numberWithCommas(resultInterestCharge.toFixed(2))}
                          </Grid>
                        </Grid>
                        </>
                      )}
                      </>
                    )}
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Start Date: 
                      </Grid>
                      <Grid item>
                        {moment(result.created).format("MMMM D, YYYY h:mm A")}
                      </Grid>
                    </Grid>
                    {/***
                     * END DATE
                     */}
                    {(resultPaymentType === "INSTALLMENT" || resultCategory === "RENT") && (
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Grid item style={{ fontWeight: 'bold' }}>
                                End Date:
                            </Grid>
                            <Grid item>
                                {moment(resultEndDate).format("MMMM D, YYYY h:mm A")}
                            </Grid>
                        </Grid>
                    )}
                    <Grid container item direction="row" justify="space-between" style={{paddingBottom: 30}} alignItems="center">
                      <Grid xs={3} sm={6} item style={{ fontWeight: 'bold', fontSize: '23px'}}>
                        {resultCategory === "RENT" ? "Monthly Amortization: " : (resultPaymentType === "INSTALLMENT" && (sourceIDSelected || result.is_interest_shouldered_by_merchant)) ? "Monthly Amortization: " : "Total Amount: "} 
                      </Grid>
                      <Grid item style={{ fontWeight: 'bold', fontSize: '23px', color: '#FF873A'}}>
                        {resultCategory === "RENT" ? numberWithCommas(resultMonthly) : (resultPaymentType === "INSTALLMENT" && (sourceIDSelected || result.is_interest_shouldered_by_merchant)) ? numberWithCommas(resultMonthly) : numberWithCommas(resultAmount)}
                      </Grid>
                    </Grid>
                    
                      </>
                    )
                    
                    :(
                      <>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Reference: 
                      </Grid>
                      <Grid item>
                        {resultRef}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Category: 
                      </Grid>
                      <Grid item>
                        {resultCategory}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Status: 
                      </Grid>
                      <Grid item>
                      {result.type === "SELL" ?
                              result.buyer_status === "COMPLETE" ?
                                "AWAITING FOR PAYOUT"
                                : 
                                resultStatus
                              : 
                              resultStatus}
                      </Grid>
                    </Grid>
                    {(resultPaymentType && resultCategory === "MERCHANDISE") && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Payment Type: 
                        </Grid>
                        <Grid item>
                          {resultPaymentType}
                        </Grid>
                      </Grid>
                    )}
                    {result.credit_card_used && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Card Used: 
                        </Grid>
                        <Grid item>
                          {result.credit_card_used.details.card_type + " - "+ result.credit_card_used.details.last_four_digits}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Transaction Name: 
                      </Grid>
                      <Grid item >
                        {result.name}
                      </Grid>
                    </Grid>
                    {result.request_for_on_demand && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Payment Status: 
                        </Grid>
                        <Grid item style={{color: 'red'}}>
                          FAILED
                        </Grid>
                      </Grid>
                    )}
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Description:
                      </Grid>
                      <Grid item>
                        {result.description}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          { "Created by:"}
                        </Grid>
                        <Grid item>
                          {result.proposal_type === "BUYING" ? result.buyer ? result.buyer_name : 'None' : result.merchant ? result.merchant_name : 'None'}
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        { "Interest shouldered by merchant:"}
                        </Grid>
                        <Grid item>
                          {result.is_interest_shouldered_by_merchant ? "Yes": "No"}
                        </Grid>
                      </Grid>
                    {/**
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                      {"Customer:"}
                      </Grid>
                      <Grid item>
                        {result.buyer_name ? result.buyer_name : 'None'}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        {"Merchant:"} 
                      </Grid>
                      <Grid item>
                        {result.merchant_name ? result.merchant_name : 'Anon'}
                      </Grid>
                    </Grid>
                     */}
                    <Grid item>
                      <Divider />
                    </Grid>
                    
                    
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Amount: 
                      </Grid>
                      <Grid item >
                        {numberWithCommas(result.amount)}
                      </Grid>
                    </Grid>
                    {result.completed_payments && (
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        {"Payments:"} 
                      </Grid>
                      <Grid item>
                        {result.completed_payments } Months
                      </Grid>
                    </Grid>
                    )}
                    
                    {resultPaymentType === "INSTALLMENT" && !(result.completed_payments) && (
                      <>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Terms:
                        </Grid>
                        <Grid item>
                          {resultTerms + " months"}
                        </Grid>
                      </Grid>
                      {resultCategory === "RENT" && (
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Transaction Fee: 
                        </Grid>
                        <Grid item>
                          {numberWithCommas(resultTransactFee)}
                        </Grid>
                      </Grid>
                      )}
                      {resultInterestCharge > 0 && (
                        <>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                            Interest: 
                          </Grid>
                          <Grid item>
                            {resultInterest}
                          </Grid>
                        </Grid>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                            Monthly Interest Fee: 
                          </Grid>
                          <Grid item>
                            {numberWithCommas(resultInterestCharge.toFixed(2))}
                          </Grid>
                        </Grid>
                        </>
                      )}
                      </>
                    )}
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Start Date: 
                      </Grid>
                      <Grid item>
                        {moment(result.created).format("MMMM D, YYYY h:mm A")}
                      </Grid>
                    </Grid>
                    {/***
                     * END DATE
                     */}
                    {(resultPaymentType === "INSTALLMENT" || resultCategory === "RENT") && (
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Grid item style={{ fontWeight: 'bold' }}>
                                End Date:
                            </Grid>
                            <Grid item>
                                {moment(result.end_date).format("MMMM D, YYYY h:mm A")}
                            </Grid>
                        </Grid>
                    )}
                    <Grid container item direction="row" justify="space-between" style={{paddingBottom: 30}} alignItems="center">
                      <Grid xs={12} sm={4} item style={{ fontWeight: 'bold', fontSize: matches ? '16px' :'20px'}}>
                        {resultPaymentType === "INSTALLMENT" ? "Monthly Amortization: " : "Total Amount: "} 
                      </Grid>
                      <Grid item xs={12} sm={6} />
                      <Grid xs={12} sm={2} item style={{ fontWeight: 'bold', fontSize: matches ? '16px' :'20px', color: '#FF873A'}}>
                        {resultPaymentType === "INSTALLMENT" ? numberWithCommas(resultMonthly) : numberWithCommas(resultAmount)}
                      </Grid>
                    </Grid>
                    
                      </>
                    )}
                    {/**<Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Reference: 
                      </Grid>
                      <Grid item>
                        {resultRef}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Status: 
                      </Grid>
                      <Grid item>
                      {result.type === "SELL" ?
                              result.buyer_status === "COMPLETE" ?
                                "AWAITING FOR PAYOUT"
                                : 
                                "CURRENT"
                              : 
                              resultStatus}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Transaction Name: 
                      </Grid>
                      <Grid item>
                        {result.name}
                      </Grid>
                    </Grid>
                    
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Amount: 
                      </Grid>
                      <Grid item >
                        {numberWithCommas(result.amount)}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                      {result.proposal_type === "BUYING" ? "Created by:" : "Customer"}
                      </Grid>
                      <Grid item>
                        {buyer ? buyer.first_name+" "+buyer.last_name : 'None'}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        {result.proposal_type === "SELLING" ? "Created by:" : "Merchant:"} 
                      </Grid>
                      <Grid item>
                        {merchant ? merchant.first_name+" "+merchant.last_name : 'Anon'}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Category: 
                      </Grid>
                      <Grid item>
                        {resultCategory}
                      </Grid>
                    </Grid>
                    {(resultPaymentType && resultCategory === "MERCHANDISE") && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Payment Type: 
                        </Grid>
                        <Grid item>
                          {resultPaymentType}
                        </Grid>
                      </Grid>
                    )}
                    {result.type === "BUY" && result.credit_card_used && (
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Card Used: 
                        </Grid>
                        <Grid item>
                          {result.credit_card_used.details.card_type + " - "+ result.credit_card_used.details.last_four_digits}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Amount: 
                      </Grid>
                      <Grid item >
                        {numberWithCommas(result.amount)}
                      </Grid>
                    </Grid>
                    {resultPaymentType === "INSTALLMENT" && (
                      <>
                      <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Terms:
                        </Grid>
                        <Grid item>
                          {resultTerms + " months"}
                        </Grid>
                      </Grid>
                      {resultCategory === "RENT" && (
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                        <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                          Transaction Fee: 
                        </Grid>
                        <Grid item>
                          {numberWithCommas(resultTransactFee)}
                        </Grid>
                      </Grid>
                      )}
                      {resultInterestCharge > 0 && (
                        <>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                            Interest: 
                          </Grid>
                          <Grid item>
                            {resultInterest+"%"}
                          </Grid>
                        </Grid>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                          <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                            Interest Fee: 
                          </Grid>
                          <Grid item>
                            {numberWithCommas(resultInterestCharge.toFixed(2))}
                          </Grid>
                        </Grid>
                        </>
                      )}
                      </>
                    )}
                    
                    {result.completed_payments && (
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        {"Payments:"} 
                      </Grid>
                      <Grid item>
                        {result.completed_payments } Months
                      </Grid>
                    </Grid>
                    )}
                    <Grid container item direction="row" justify="space-between" alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold'}}>
                        Start Date: 
                      </Grid>
                      <Grid item>
                        {moment(result.created).format("MMMM D, YYYY h:mm A")}
                      </Grid>
                    </Grid>
                    {/***
                    * END DATE
                    
                    <Grid container item direction="row" justify="space-between" style={{paddingBottom: 30}} alignItems="center">
                      <Grid xs={3} sm={5} item style={{ fontWeight: 'bold', fontSize: '23px'}}>
                        {resultPaymentType === "INSTALLMENT" ? "Monthly Amount: " : "Total Amount: "} 
                      </Grid>
                      <Grid item style={{ fontWeight: 'bold', fontSize: '23px', color: '#FF873A'}}>
                        {resultPaymentType === "INSTALLMENT" ? numberWithCommas(resultMonthly) : numberWithCommas(resultAmount)}
                      </Grid>
                    </Grid>
                    */}
                    { resultStatus !== "COMPLETE" && result.type === "SELL" && (
                      <>
                      {(result.proposal_type === "BUYING") && (resultStatus === "WAITING" && resultStatus !== "ACCEPTED") && (
                        <Grid container item justify="center" alignItems="center">
                          <Grid item>
                            <Button 
                                color='primary'
                                disabled={user ? !((user.emailConfirmed && user.sms_verified) && user.sellAuthorized) : true}
                                onClick={() => {
                                  acceptProposal(result.id);
                                  ReactPixel.trackCustom('Proposal Accepted', {
                                    content_ids: [result.id],
                                    currency: 'PHP',
                                    value: result.monthly
                                  })
                                }}
                                className='create-sale-button'
                            >
                                Accept
                            </Button>
                          </Grid>
                        </Grid>
                        )}
                        
                        
                        </>
                    )
                    

                    }
                    
                    {(result.type === "BUY" && result.proposal_type === "BUYING") && (resultStatus === "WAITING" && resultStatus !== "ACCEPTED") && (
                    <Grid container item justify="center" alignItems="center">
                      <Grid item style={{ fontWeight: 'bold', color: '#FF873A'}}>
                        Please wait for the seller to accept this proposal!
                      </Grid>
                    </Grid>
                    )}

                    {(!(result.buyer && result.merchant) && resultStatus === "WAITING") && (result.type === "BUY" && result.proposal_type === "BUYING") &&(
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                              <Grid container item direction="column" justify="center"  spacing={1}alignItems="center">
                                <Grid item>
                                  Refence Code: 
                                </Grid>
                                <Grid item>
                                  <div className="link-copy">
                                      <CopyToClipboard text={result.reference} onCopy={() => setRefCopied(true)}>
                                          <Grid  spacing={1} container item direction="row" alignItems="center" justify="space-between">
                                              <Grid item>
                                                  <div className="text-proposal">
                                                    {result.reference}
                                                  </div>
                                              </Grid>
                                              <Grid item style={{textAlign: 'center', padding: '0px 10px'}}>
                                                {!refCopied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                              </Grid>
                                          </Grid>
                                      </CopyToClipboard>
                                  </div>
                                </Grid>
                                <Grid item>
                                  Link: 
                                </Grid>
                                <Grid item>
                                  <div className="link-copy">
                                      <CopyToClipboard text={result.link} onCopy={() => setCopied(true)}>
                                          <Grid  spacing={1} container item direction="row" alignItems="center" justify="space-between">
                                              <Grid item>
                                                  <div className="text-proposal">
                                                    {result.link}
                                                  </div>
                                              </Grid>
                                              <Grid item style={{textAlign: 'center', padding: '0px 10px'}}>
                                                {!copied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                              </Grid>
                                          </Grid>
                                      </CopyToClipboard>
                                  </div>
                                </Grid>
                              </Grid>

                            </Grid>
                          )}

                    {(!(result.buyer && result.merchant) && resultStatus === "WAITING") && (result.type === "SELL" && result.proposal_type === "SELLING") &&(
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                              <Grid container item direction="column" justify="center" spacing={1} alignItems="center">
                                <Grid item>
                                  Refence Code: 
                                </Grid>
                                <Grid item>
                                  <div className="link-copy">
                                      <CopyToClipboard text={result.reference} onCopy={() => setRefCopied(true)}>
                                          <Grid  spacing={1} container item direction="row" alignItems="center" justify="space-between">
                                              <Grid item>
                                                  <div className="text-proposal">
                                                    {result.reference}
                                                  </div>
                                              </Grid>
                                              <Grid item style={{textAlign: 'center', padding: '0px 10px'}}>
                                                {!refCopied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                              </Grid>
                                          </Grid>
                                      </CopyToClipboard>
                                  </div>
                                </Grid>
                                <Grid item>
                                  Link: 
                                </Grid>
                                <Grid item>
                                  <div className="link-copy">
                                      <CopyToClipboard text={result.link} onCopy={() => setCopied(true)}>
                                          <Grid  spacing={1} container item direction="row" alignItems="center" justify="space-between">
                                              <Grid item>
                                                  <div className="text-proposal">
                                                    {result.link}
                                                  </div>
                                              </Grid>
                                              <Grid item style={{textAlign: 'center', padding: '0px 10px'}}>
                                                {!copied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                              </Grid>
                                          </Grid>
                                      </CopyToClipboard>
                                  </div>
                                </Grid>
                              </Grid>

                            </Grid>
                          )}

                    {(result.type === "BUY" && result.proposal_type === "SELLING") && (resultStatus === "WAITING" && resultStatus !== "ACCEPTED") && 
                      (
                        ////
                        <>
                        {result.type === "BUY" && cards.length > 0 ? (
                        <Grid container item justify="center" alignItems="center">
                          <Grid item>
                            <Button 
                                color='primary'
                                disabled={user ? !((user.emailConfirmed && user.sms_verified) && user.buyAuthorized) : true}
                                onClick={() => {
                                  acceptProposal(result.id);
                                  ReactPixel.trackCustom('Proposal Accepted', {
                                    content_ids: [result.id],
                                    currency: 'PHP',
                                    value: result.monthly
                                  })
                                }}
                                className='create-sale-button'
                            >
                                Accept
                            </Button>
                          </Grid>
                        </Grid>
                        ): (
                        <Grid container item justify="center" alignItems="center">
                          <Grid item>
                            <Button 
                                color='primary'
                                onClick={() => history.push(`/app${location.pathname.includes('buyer') ? '/buyer' : location.pathname.includes('seller') ? '/seller' : ''}/vault-cards?add-card=true&pid=${result.id}`)}
                                className='create-sale-button'
                            >
                                Add Source
                            </Button>
                          </Grid>
                        </Grid>
                        )}
                        
                        </>
                      )
                    }
                    {result.type === "BUY" && resultStatus === "ACCEPTED" ? (
                        
                        <>
                        {cards.length > 0 ?
                        (
                          <Grid container item justify="center" alignItems="center">
                            <Grid item>
                              <Button 
                                  color='login-secondary'
                                  onClick={() => {
                                    isContinued(true)
                                    setIsEdit(false)
                                  }}
                                  className='transact-button'
                              >
                                  Continue
                              </Button>
                            </Grid>
                          </Grid>
                        ) 
                        :
                        (
                        <Grid container item justify="center" alignItems="center">
                          <Grid item>
                            <Button 
                                color='primary'
                                onClick={() => history.push(`/app${location.pathname.includes('buyer') ? '/buyer' : location.pathname.includes('seller') ? '/seller' : ''}/vault-cards?add-card=true&pid=${result.id}`)}
                                className='create-sale-button'
                            >
                                Add Source
                            </Button>
                          </Grid>
                        </Grid>
                        )}
                          
                          {/***
                           * {resultCategory === "MERCHANDISE" && (
                          <Grid container item justify="center" alignItems="center">
                            <Grid item>
                              <Button 
                                  color='login-primary'
                                  onClick={() => {
                                    setIsEdit(true)
                                    isContinued(false)
                                  }}
                                  className='transact-button'
                              >
                                  Edit
                              </Button>
                            </Grid>
                          </Grid>
                          )

                          }
                          
                          * 
                          */}
                          
                          
                          {resultStatus === "ACCEPTED" && continued ? (
                            <>
                          <Grid container direction="column" item justify="center" alignItems="center">
                            <Grid item>
                              <div className="label">
                                Please select your Payment Source
                              </div>
                            </Grid>
                            <Grid item>
                                <Select
                                    id="demo-simple-select-autowidth"
                                    name="id"
                                    value={sourceID}
                                    style={{width: '200px'}}
                                    onChange={(e:any)=>{
                                      setSourceID(e.target.value); 
                                      
                                      if(!result.is_interest_shouldered_by_merchant && resultCategory === "MERCHANDISE"){
                                        setCategoryEdit(true);
                                      }
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        <em>Choose your source</em>
                                    </MenuItem>
                                    {cards !== null ? cards.map((card : any) => {
                                          return(
                                            <MenuItem key={card.id} onClick={()=> setSourceIDSelected(card.installmentAuthorized)} value={card.id} >{card.name}</MenuItem>
                                          )
                                      }
                                    ): null}
                                    
                                </Select>
                            </Grid>
                            
                          </Grid>
                          {showCategoryEdit && ((resultCategory === "MERCHANDISE") && !result.is_interest_shouldered_by_merchant)? (
                            <>
                          <Grid container direction="column" item justify="center" alignItems="center">
                            <Grid item>
                              <div className="label">
                                Please select your desired Payment Type
                              </div>
                            </Grid>
                            <Grid item>
                              <Select
                                  id="demo-simple-select-autowidth"
                                  name="category"
                                  value={paymentType}
                                  style={{width: '200px'}}
                                  onChange={(e: any)=>{handleCategoryChange(e.target.value);}}
                              >
                                  <MenuItem value="" disabled>
                                      <em>Choose transaction type...</em>
                                  </MenuItem>
                                  
                                  {paymentTypeList && paymentTypeList.map((type: any) => (
                                      <MenuItem value={type.id}>{type.name}</MenuItem>
                                  ))}
                              </Select>
                            </Grid>
                          </Grid>
                          
                          {(paymentType === 2 && !result.is_interest_shouldered_by_merchant) && (
                          <Grid container item justify="center" alignItems="center">
                            <Grid item>
                              <Select
                                  id="demo-simple-select-autowidth"
                                  name="terms"
                                  value={terms}
                                  defaultValue=''
                                  style={{width: '200px'}}
                                  onChange={(e: any)=>{ setTerms(e.target.value);handleTermChange(e.target.value)}}
                              >
                                  <MenuItem value="" disabled>
                                      <em>Choose monthly terms...</em>
                                  </MenuItem>
                                  
                                  <MenuItem value={'3'}>3</MenuItem>
                                  <MenuItem value={'6'}>6</MenuItem>
                                  <MenuItem value={'9'}>9</MenuItem>
                                  <MenuItem value={'12'}>12</MenuItem>
                                  
                              </Select>
                            </Grid>
                          </Grid>
                          )}
                          
                          </>
                          ):null

                          }
                          <Grid container item justify="center" alignItems="center">
                            <Grid item>
                              {!sourceIDSelected && sourceID ? "Your card doesn't support installments" : ''}
                            </Grid>
                          </Grid>
                          <Grid container item justify="center" alignItems="center">
                            <Grid item>
                              <Button 
                                  disabled={!sourceID || resultCategory === "RENT" ? false : !(resultCategory === "MERCHANDISE" || resultCategory === null)}
                                  color='login-primary'
                                  onClick={() => {
                                    if(!sourceIDSelected && paymentType === 2){
                                      setErrorDialogMessage("Your card doesn't support installments. Please upgrade your card in the Sources page.")
                                      setOpenErrorDialog(true)
                                    }else{
                                      setOpenOTP(true)
                                    }
                                    
                                  }}
                                  className='create-sale-button'
                              >
                                  Pay Now
                              </Button>
                            </Grid>
                          </Grid>
                          </>
                          ): result.proposal_type === 'BUYING' && resultStatus === "WAITING" ?(
                        <Grid container item justify="center" alignItems="center">
                          <Grid item style={{ fontWeight: 'bold', color: '#FF873A'}}>
                            Please wait for the seller to accept this proposal!
                          </Grid>
                        </Grid>
                          ) : (result.proposal_type === 'SELLING' && resultStatus === "WAITING") && (
                          <Grid container item justify="center" alignItems="center">
                            <Grid item>
                              <Button 
                                  color='primary'
                                  onClick={() => {
                                    console.log('accepted')
                                  }}
                                  className='create-sale-button'
                              >
                                  Accept
                              </Button>
                            </Grid>
                          </Grid>
                          ) }
                          
                        
                        </>
                      ):
                      result.type === "BUY" && (resultStatus === "COMPLETE" || resultStatus === "CURRENT") ? 
                      (
                        <>
                          

                          
                          {!(result.buyer && result.merchant) && resultStatus === "WAITING" &&(
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                              <Grid container item direction="column" justify="center" spacing={1} alignItems="center">
                                <Grid item>
                                  Refence Code: 
                                </Grid>
                                <Grid item>
                                  <div className="link-copy">
                                      <CopyToClipboard text={result.reference} onCopy={() => setRefCopied(true)}>
                                          <Grid  spacing={1} container item direction="row" alignItems="center" justify="space-between">
                                              <Grid item>
                                                  <div className="text-proposal">
                                                    {result.reference}
                                                  </div>
                                              </Grid>
                                              <Grid item style={{textAlign: 'center', padding: '0px 10px'}}>
                                                {!refCopied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                              </Grid>
                                          </Grid>
                                      </CopyToClipboard>
                                  </div>
                                </Grid>
                                <Grid item>
                                  Link: 
                                </Grid>
                                <Grid item>
                                  <div className="link-copy">
                                      <CopyToClipboard text={result.link} onCopy={() => setCopied(true)}>
                                          <Grid  spacing={1} container item direction="row" alignItems="center" justify="space-between">
                                              <Grid item>
                                                  <div className="text-proposal">
                                                    {result.link}
                                                  </div>
                                              </Grid>
                                              <Grid item style={{textAlign: 'center', padding: '0px 10px'}}>
                                                {!copied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                              </Grid>
                                          </Grid>
                                      </CopyToClipboard>
                                  </div>
                                </Grid>
                              </Grid>

                            </Grid>
                          )}
                            
                          
                        </>
                      )
                      :

                      result.type === "SELL" && (resultStatus === "COMPLETE" || resultStatus === "CURRENT") &&
                      (
                         <>
                         </>
                      )
                    }
                    
                    
                    
                  </Grid>
                  <Grid xs={12} lg={12}>
                    {result.type === "BUY" && (resultStatus === "COMPLETE" || resultStatus === "CURRENT") ? 
                      (
                        <>
                          
                          

                          
                    
                            <Grid item>
                            <div style={{padding: '20px', border: '1px solid #ececec', borderRadius:'5px'}}>
                              <div style={{ color: "#FF873A", fontWeight: 'bold', paddingBottom: '10px', textAlign: 'center'}}>
                                Payment History
                              </div>
                              <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                                <Divider />
                                </div>
                              {paymentHistory && paymentHistory.sort().map((payment: any, index: any) => (
                                <>
                                <Grid container item direction="row" alignItems="center" >
                                  <Grid item >
                                    
                                  </Grid>
                                  <Grid  container direction="column" justify="center" alignItems="center" item>
                                    <Grid item style={{fontSize: matches ? '13px' : '14px', fontWeight: 'bold', textAlign: 'center'}}>
                                      {payment.interval_date === null ? "Created on: "+moment(payment.created).format("MMMM D, YYYY h:mm A") : "Next Payment: "+moment(payment.interval_date).format("MMMM D, YYYY h:mm A")}
                                    </Grid>
                                    <Grid item style={{fontSize: matches ? '10px' : '11px', fontWeight: 'bold', padding: '5px 20px', textAlign: 'center', color: payment.on_penalty || (payment.status === "FAILED" || payment.status === "Failed, awaiting for payment of monthly amortization and late penalty fees") ? 'red' :'#7E7E7E'}}>
                                      { payment.on_penalty || (payment.status === "FAILED" || payment.status === "Failed, awaiting for payment of monthly amortization and late penalty fees") ? 'Failed, awaiting for payment of monthly amortization and late penalty fees.' : payment.status}
                                    </Grid>
                                    <Grid item style={{fontSize: '11px', fontWeight: 'bold', color: '#7E7E7E'}}>
                                      {numberWithCommas(payment.amount)}
                                    </Grid>
                                    {payment.on_penalty || (payment.status === "FAILED" || payment.status === "Failed, awaiting for payment of monthly amortization and late penalty fees") && (
                                      <>
                                     
                                      
                                        <Grid container item style={{paddingTop: '10px'}} justify="center" alignItems="center">
                                          <Grid item>
                                            {/** 
                                            <Alert severity="error" onClick={() => setShowODP(true)} style={{cursor: 'pointer'}}>
                                              Transaction Failed. Click here to use a different payment method.
                                            </Alert>
                                            */}
                                            <Button
                                                color='login-primary'
                                                onClick={(e: any) => {e.preventDefault(); setShowODP(true); setPaymentID(payment.id)}}
                                                className='create-sale-button'
                                            >
                                                Retry Payment
                                            </Button>
                                          </Grid>
                                        </Grid>
                                  
                                    
                                      </>
                                      
                                    )}
                                  </Grid>
                                </Grid>
                                <div style={{padding: '10px 0px'}}>
                                  <Divider />
                                </div>
                                </>
                              ))}
                            </div>
                            </Grid>
                          
                        </>
                      )
                      :

                      result.type === "SELL" && (resultStatus === "COMPLETE" || resultStatus === "CURRENT") &&
                      (
                          <Grid item>
                            <div style={{padding: '20px', border: '1px solid #ececec', borderRadius:'5px'}}>
                              <div style={{ color: "#FF873A", fontWeight: 'bold', paddingBottom: '10px', textAlign: 'center'}}>
                                Transaction History
                              </div>
                              
                              {result.activities && result.activities.slice(0).reverse().map((activity: any) => (
                                <>
                                <div style={{padding: '10px 0px'}}>
                                  <Divider />
                                </div>
                                
                                <Grid container item direction="row" alignItems="center" >
                                  <Grid item >
                                    
                                  </Grid>
                                  <Grid  container direction="column" justify="center" alignItems="center" item>
                                    <Grid item style={{fontSize: matches ? '11px' : '14px', padding: matches ? '0px' :  '0px 20px', fontWeight: 'bold', textAlign: 'center'}}>
                                      {activity.message}
                                    </Grid>
                                    <Grid item style={{fontSize: matches ? '10px' : '11px', fontWeight: 'bold', padding: '0px 20px', textAlign: 'center', color: '#7E7E7E'}}>
                                      {moment(activity.created).format("MMMM D, YYYY h:mm A")}
                                    </Grid>
                                    <Grid item style={{fontSize: '11px', fontWeight: 'bold', paddingTop: '10px', color: '#7E7E7E'}}>
                                      {numberWithCommas(activity.amount)}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                
                                </>
                              ))}
                            </div>
                          </Grid>
                      )
                    }
                  </Grid>
              </Grid>
              </div>
            </div>
          </div>
          <OTPDialog openDialog={openOTP} setOpenDialog={(e) => {setOpenOTP(e)}} onFinish={() => payNow(result.id)}/>
          <SuccessDialog onClose={() => {
            if(proposalRedirect){
              window.location.href = window.location.origin + `/app/buyer/transaction/${proposalRedirect}`;
              history.push(`/app/buyer/transaction/${proposalRedirect}`)
            }
          }} openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={dialogMessage} />
          <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
          <Dialog 
            className={classes.dialogPaper} 
            fullWidth={true}  
            maxWidth={'sm'}
            PaperProps={{style: {
              backgroundColor: '#fee2b3',
              border: "1px solid #fee2b3",
              borderRadius: '20px'
            }}}
            open={showODP && paymentID}
          >
            <DialogTitle className={classes.dialogTitle}>
              <img
                  src={close}
                  style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px', height: '20px'}}
                  onClick={()=>{setShowODP(false); setPaymentID(null);}}
              />
              <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                  <Grid item>
                      <img src={detailsIcon} style={{width: '50px', height: '50px'}}/>
                  </Grid>
                  
              </Grid>
              <div style={{paddingTop: 10, color: "#FF873A"}}>
                  Retry Payment
              </div>
            </DialogTitle>
            <div style={{margin: "10px 20px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
              <div style={{padding: 25}}>
                  <Grid item>
                      
                        <Grid container direction="column" spacing={2} item alignItems="center" justify="center">
                          <Grid container item>
                            <Grid container direction="row" alignItems="center" justify="space-between" item>
                              <Grid item xs={12} sm={6}>
                                <span style={{color: '#FF873A', fontWeight: 'bold'}}>Card Number:</span>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextInput 
                                    name="cardNumber"
                                    value={cardNumber || ''}
                                    type='text'
                                    placeholder="Card Number"
                                    onChange={(e:any) => {e.preventDefault();setCardNumber(e.target.value)}}
                                /> 
                              </Grid>
                            </Grid>
                            
                          </Grid>
                          
                          <Grid container item>
                            <Grid container direction="row" alignItems="center" justify="space-between" item>
                              <Grid item xs={12} sm={6}>
                                <span style={{color: '#FF873A', fontWeight: 'bold'}}>Expiry Month:</span>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextCardExpiryInput 
                                  expName1='expMonth'
                                  expName2='expYear'
                                  expValue1={cardMonth || ''}
                                  expValue2={cardYear || ''}
                                  expOnChange1={(e: any) => {e.preventDefault();setCardMonth(e.target.value)}}
                                  expOnChange2={(e: any) => {e.preventDefault();setCardYear(e.target.value)}}
                                  name="addCardExpiry"
                                  type='text'
                                  value={cardMonth}
                                  className={"expMonthYear"}
                                  onChange={(e: any) => {
                                      console.log('')
                                      
                                  }}
                                  placeholder='Card Expiry'
                              />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item>
                            <Grid container direction="row" alignItems="center" justify="space-between" item>
                              <Grid item xs={12} sm={6}>
                                <span style={{color: '#FF873A', fontWeight: 'bold'}}>CVC:</span>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextInput 
                                    name="cardCVC"
                                    value={cardCVC || ''}
                                    type='text'
                                    placeholder="CVC"
                                    onChange={(e:any) => {e.preventDefault();setCardCVC(e.target.value)}}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Button 
                                disabled={cardNumber === '' || cardMonth === '' || cardYear === '' || cardCVC === ''}
                                color='login-primary'
                                onClick={(e: any) => {e.preventDefault();newPayment()}}
                                className='create-sale-button'
                            >
                                Pay Now
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                  </div>
              </div>  
                          
          </Dialog>
        </>
    )
}