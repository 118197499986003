import { Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { matches } from 'lodash';
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/4giveslogo.png';
import Auth from '../../auth';
import { Button, ErrorDialog, SuccessDialog, TextLoginInput } from '../../components';
import { useQuery } from '../../hooks';
import './style.scss';
import passwordIcon from '../../assets/images/fork_screen icons/02.png';

const PasswordReset: React.FC<any> = () => {
    const history = useHistory();
    const query = useQuery();
    const [token, setToken] = useState<any>(query.has('token') ? query.get('token') :'');
    const [uid, setUId] = useState(query.has('uid') ? query.get('uid') : '')
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const matchesBelow = useMediaQuery(theme.breakpoints.up('lg'))

    const [newPass1, setNewPass1] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    const changePass = () => {
        Auth.newPassword(
            token,
            uid,
            newPass1,
            (resp) => {
                console.log(resp)
                setOpenDialog(true)
                setDialogMessage('Password has been successfully changed!')
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorDialogMessage(e.msg)
            }
        )
    }

    return(
        <>
            <div className="email-verif-page">
                <div className="email-verif-page-container">
                    <div style={{height: '-webkit-fill-available'}}>
                        <Grid container style={{width: '100%', height: '100%', margin: 'auto'}} direction="row" spacing={3}>
                            {matches ?  (
                                <>
                    

                                
                                <Grid container item xs={12} md={6}  direction="column" style={{backgroundColor: 'white', border: '1px solid #FF873A'}} alignItems="center" justify="center" >
                                    <Grid item style={{padding: '20px'}}>
                                        <img src={passwordIcon} style={{width: !matches ? '200px' : '300px'}} />
                                    </Grid>
                                    <Grid item>
                                        <div style={{ textAlign: 'center', padding: '20px 0px 20px'}}>
                                            <Typography variant={matches ? "h6" : "h5"} style={{color: '#FF873A', fontWeight: 'bold'}}>
                                                Reset Password
                                            </Typography>
                                            <Typography variant={"body1"} style={{color: "black", padding:'10px 30px 30px 30px'}}>
                                                Please enter a new password for your account.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    
                                </Grid>
                          

                                </>
                            ):null}
                            
                            <Grid container item xs={12} md={6} style={{padding: matchesBelow ? '50px 170px' : matches ? '50px 120px' :'20px', backgroundColor: '#FF873A', overflowY: 'auto', height: matches ? '-webkit-fill-available' : '100%'  , border: '1px solid #FF873A'}} direction="row" justify="center" alignItems="center">

                                <Grid item xs={12}>
                                    <div className="form verif-form">
                                        <div className="verif-title">
                                            <img src={logo} alt="" onClick={() => history.push('')} className='main_logo'/> 
                                            <h4>New Password</h4>
                                        </div>
                                        <div className="form-container">
                                            <TextLoginInput 
                                                name='newPass1'
                                                type='password'
                                                value={newPass1}
                                                onChange={(e: any) => setNewPass1(e.target.value)}
                                                placeholder='New Password'
                                                icon={{ prefix: 'far', name: 'envelope'}}
                                            />
                                        </div>
                                        <div className="form-container">
                                            <TextLoginInput 
                                                name='newPass2'
                                                type='password'
                                                value={newPass2}
                                                onChange={(e: any) => setNewPass2(e.target.value)}
                                                placeholder='Confirm Password'
                                                icon={{ prefix: 'far', name: 'envelope'}}
                                            />
                                        </div>
                                        <div className="form-container">
                                            <Button 
                                                onClick={changePass}
                                                disabled={newPass1 === '' || newPass2 === '' || newPass1 !== newPass2}
                                                color={'login-primary'}
                                                className='login-button fg-button-block form-button' 
                                            >  
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={dialogMessage} onClose={() => history.push('/app/my-account')}/>
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
        </>
    );
}


export default PasswordReset
