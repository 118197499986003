import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, Grid, makeStyles, MenuItem, Select, Container, DialogActions } from '@material-ui/core';
import { Button } from '..';


const error = require('../../assets/images/error.png');
const close = require('../../assets/images/close.png');

export const ErrorDialog: React.FC<{
    openDialog: boolean,
    setOpenDialog: (val: boolean) => void,
    dialogMessage: string | null,
    onProceed?: () => void,
    onBack?: () => void,
}> = ({openDialog, setOpenDialog, dialogMessage, onProceed, onBack}) => 
    <Dialog 
        open={openDialog} 
        fullWidth={true} 
        maxWidth={'xs'} 
        onClose={() => {setOpenDialog(false)}}
        PaperProps={{
            style: {
                backgroundColor: '#FF873A', 
                border: "1px solid #FF873A",
                borderRadius: '8px'
            },
        }} 
    >
        <DialogTitle style={{color: 'green', textAlign: "center",backgroundColor: '#FF873A'}}>
            <img
                src={close}
                style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                onClick={()=>setOpenDialog(false)}
            />
            {/** 
            <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                <Grid item>
                    <img src={error} style={{width: '50px', height: '50px'}}/>
                </Grid>
                
            </Grid>
            */}
            <div style={{paddingTop: 10, color: "white", fontWeight: 'bold'}}>
                Error
            </div>
            
        </DialogTitle>
        <Container style={{backgroundColor: '#f7723'}}>
            
                <div style={{padding: 20,marginTop: '-30px', textAlign: 'center', backgroundColor: '#f7723'}}>
                    
                    <Grid container item direction="row" alignContent="center" justify="center" style={{backgroundColor: '#f7723'}}>
                        <Grid item style={{color: 'white', textAlign: 'center', padding: '15px'}}>
                            <span style={{color: 'white'}}>{dialogMessage}</span>
                        </Grid>
                    </Grid>
                </div>
                {onProceed !== undefined && onBack !== undefined &&(
                <div style={{margin: "5px", marginBottom: '20px'}}>
                <Grid container direction="column" justify="center"  alignItems="center" style={{backgroundColor: '#f7723'}}>
                    
                    <Grid item sm={12} xs={12}>
                        <Button 
                            color='login-primary'
                            onClick={onBack}
                            className='create-sale-button'
                        >
                            Back to Merchant site
                        </Button>
                    </Grid>
                    <div style={{padding: '5px'}}></div>
                    <Grid item sm={12} xs={12}>
                    
                        <Button 
                            color='primary'
                            onClick={onProceed}
                            className='create-sale-button'
                        >
                            Proceed to Activation Page
                        </Button>
                    </Grid>
                    <div style={{padding: '5px'}}></div>
                    <Grid item sm={12} xs={12}>
                        <Button 
                            color='login-primary'
                            onClick={() => setOpenDialog(false)}
                            className='create-sale-button'
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
                </div>
            )}
            
            
           
        </Container>
        
        
        
    </Dialog>