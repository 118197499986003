import React from "react";
import {Helmet} from "react-helmet";
import image from '../assets/images/4giveslogo.png';

interface HelmetProps {
    quote?: string,
    title?: string,
    image?: string,
    description?: string,
    hashtag?: string,
}

export default function HelmetMetaData(props: HelmetProps){
    let currentUrl = window.location.href;
    let quote = props.quote !== undefined ? props.quote : "";
    let title = props.title !== undefined ? "4Gives | "+props.title : "4Gives";
    let image = props.image !== undefined ? props.image : "http://dev.thetechsetter.com/static/media/4giveslogo.eba5538b.png";
    let description = props.description !== undefined ? props.description  : 'Providing modern payments';
    let hashtag = props.hashtag !== undefined ? props.hashtag : "#4gives";

    return (
        <Helmet>
     <title>{title}</title>
     <meta charSet="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content={currentUrl} />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={title} />
     <meta property="quote" content={quote} />
     <meta name="description" content={description} />
     <meta property="image" content={image} />
     <meta property="og:image" content={image} />
     <meta property="og:image:secure" content={image} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={title} />
     <meta property="og:quote" content={quote} />
     <meta property="og:hashtag" content={hashtag} />
     <meta property="og:image" content={image} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={currentUrl} />
     <meta property="og:site_name" content="CampersTribe" />
     <meta property="og:description" content={description} />    </Helmet>
    )
}