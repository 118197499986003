import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/4giveslogo.png';
import Auth from '../../auth';
import { Dialog, DialogTitle, Grid, makeStyles, MenuItem, Select, Container, useTheme, useMediaQuery, Typography } from '@material-ui/core';
import { Button, ErrorDialog, SuccessDialog, TextLoginInput } from '../../components';
import { useQuery } from '../../hooks';
import infoIcon from '../../assets/images/info_icon-min.png';
import close from '../../assets/images/close.png';
import './style.scss';
import emailIcon from '../../assets/images/fork_screen icons/SendbyEmail.png';
import { User } from '../../models';

const EmailVerificationPage: React.FC<any> = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const matchesBelow = useMediaQuery(theme.breakpoints.up('lg'))
    const history = useHistory();
    const query = useQuery();
    const [code, setCode] = useState<string | null>(query.has('confirmation_key') ? query.get('confirmation_key') :'');
    const [uid, setUId] = useState(query.has('uid') ? query.get('uid') : '')
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(query.has('require_code') ? true : false );
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    
    

    useEffect(() => {
        if(code !== '' && uid !== ''){
            verifyEmail();
        }
    },[code, uid])

    useEffect(() => {
        setIsLoggedIn(Auth.key() !== null)
    })

    

    const verifyEmail = () => {
        if(Auth.isAuthenticated()) {
            console.log('henlo butt')
            Auth.authVerifyEmail(
                query.has('uid') ? uid : null,
                code,
                (resp) => {
                    console.log(resp)
                    setOpenDialog(true)
                    setDialogMessage('Email successfully validated!');
                },
                (e) => {
                    console.log(e.error)
                    setErrorDialogMessage(e.error ? e.error : e.msg ? e.msg : 'There was an error validating your email. Please try again.');
                    setOpenErrorDialog(true)
                }
            )
        }else{
            console.log('henlo')
            Auth.verifyEmail(
                query.has('utm_source') ? uid : null,
                code,
                (resp) => {
                    console.log(resp)
                    setOpenDialog(true)
                    setDialogMessage('Email successfully validated!');
                },
                (e) => {
                    console.log(e.error)
                    setErrorDialogMessage(e.error ? e.error : e.msg ? e.msg : 'There was an error validating your email. Please try again.');
                    setOpenErrorDialog(true)
                }
            )
        }
    }

    return(
        <>
            <div className="email-verif-page" style={{padding: 0}}>
                <div className="email-verif-page-container" style={{width: (matches !== undefined && matches) ? '100vw' : '100vw', padding: 0}}>
                    <div style={{height:'-webkit-fill-available'}}>
                        <Grid container style={{width: '100%', height: '100%', margin: 'auto'}} direction="row" spacing={3}>
                            {matches && (

                            
                            <Grid container item xs={12} md={6}  direction="column" style={{backgroundColor: 'white', border: '1px solid #FF873A'}} alignItems="center" justify="center" >
                                <Grid item style={{padding: '50px'}}>
                                    <img src={emailIcon} style={{width: !matches ? '200px' : '300px'}}/>
                                </Grid>
                                <Grid item>
                                    <div style={{ textAlign: 'center', padding: '20px 0px 20px'}}>
                                        <Typography variant={matches ? "h6" : "h5"} style={{color: '#FF873A', fontWeight: 'bold'}}>
                                            An Email with your code has been sent!
                                        </Typography>
                                        <Typography variant={"body1"} style={{color: "black", padding:'10px 30px 30px 30px'}}>
                                            Please click the link in the email or copy the code.
                                        </Typography>
                                    </div>
                                </Grid>
                                
                            </Grid>

                            )}
                            <Grid container item xs={12} md={6} style={{padding: matchesBelow ? '50px 180px' : matches ? '50px 140px' :'20px', backgroundColor: '#FF873A', overflowY: 'auto', height: matches ? '-webkit-fill-available' : '100%'  , border: '1px solid #FF873A'}} direction="row" justify="center" alignItems="center">

                                <Grid item xs={12}>
                                    <div className="form verif-form">
                                        <div className="verif-title">
                                            <img src={logo} alt="" onClick={() => history.push('/')} className='main_logo'/> 
                                            <h4>Email Verification</h4>
                                        </div>
                                        <div className="form-container">
                                            <TextLoginInput 
                                                name='code'
                                                type='code'
                                                value={code}
                                                onChange={(e: any) => setCode(e.target.value)}
                                                placeholder='Code'
                                                icon={{ prefix: 'far', name: 'envelope'}}
                                            />
                                        </div>
                                        <div className="form-container">
                                            <Button 
                                                onClick={verifyEmail}
                                                disabled={code === null}
                                                color={'login-primary'}
                                                className='login-button fg-button-block form-button' 
                                            >  
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    
                </div>
            </div>
            <Dialog 
                open={openInfoDialog} 
                fullWidth={true} 
                maxWidth={'xs'} 
                onClose={() =>{
                
                        setOpenInfoDialog(false); 
                    
                    
                }} 
                PaperProps={{
                    style: {
                        backgroundColor: '#fee2b3', 
                        border: "1px solid #FF873A",
                        borderRadius: '20px'
                    },
                }}
            >
                <DialogTitle style={{color: 'green', textAlign: "center"}}>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={() =>{
                        
                            setOpenInfoDialog(false);
                        
                        }} 
                    />
                    <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                        <Grid item>
                            <img src={infoIcon} style={{width: '50px', height: '50px'}}/>
                        </Grid>
                        
                    </Grid>
                    <div style={{paddingTop: 10, color: "#FF873A"}}>
                        One Time Password
                    </div>
                        
                    
                </DialogTitle>
                <Container>
                    <div style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                        <div style={{padding: 20}}>
                        
                                <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                                    <Grid item>
                                        You must verify your email first before proceeding.
                                    </Grid>
                                    <Grid item >
                                        <Button
                                            color='primary'
                                            onClick={() => setOpenInfoDialog(false)}
                                            className='fg-acountactivate-button'
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                            
                                </Grid>
                        
                        
                            
                            
                            

                        </div>
                    </div>
                    
                </Container>
            </Dialog>
            <SuccessDialog openDialog={openDialog} onClose={() => {
                if(Auth.isAuthenticated()){
                    User.get(
                        (resp) => {
                            console.log(resp)
                            
                                
                            if(resp.sellAuthorized){
                                console.log("Sell View")
                                history.push('/app/seller/my-account');
                            }else if(resp.buyAuthorized){
                                console.log("Buy View")
                                history.push('/app/buyer/my-account');
                            }else{
                                history.push('/');
                            }
                        
                               
                               
                            
                        }
                    )
                }else{
                    history.push('/');
                }
                 }} setOpenDialog={setOpenDialog} dialogMessage={dialogMessage} />
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
        </>
    );
}


export default EmailVerificationPage