import React, { useState, useMemo, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, useLocation, HashRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import 'bootstrap/dist/css/bootstrap.css';
import 'react-multi-carousel/lib/styles.css';
import Admin from './layouts/Admin';
import PageNotFound from './layouts/404';
import { routes, PrivateRoute, PublicRoute } from './routes';
import {Home, BuyerHome, MerchantHome, Faqs, TermsConditions, PrivacyPolicy, InstallmentAgreement} from './pages';
import Login from './pages/Login';
import { User } from './models';
import { IUser } from './interface';
import classes from '*.module.css';
import { makeStyles, Theme, createStyles, Backdrop } from '@material-ui/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { AppPay, AppGcashPay} from './pages';
import EmailVerificationPage from './pages/EmailVerificationPage';
import PasswordReset from './pages/PasswordReset';
import HelmetMetaData from './components/HelmetMetaData';
import ReactGA from 'react-ga';
import Auth from './auth';
import ReactPixel from 'react-facebook-pixel';
import ProposalDetailsLogin from './pages/ProposalDetailsLogin';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const history = createBrowserHistory();
library.add(fas, far);

const App = () => {
  const classes = useStyles();
  const [ user, setUser ] = useState<IUser | null>(null);
  const [ source, setSource ] = useState<any | null>([]);
  const [ buyOrSellMode, setBuyOrSellMode] = useState<any | null>(null);
  const userValue = useMemo(() => ({ user, source, buyOrSellMode, setUser, setSource, setBuyOrSellMode}), [user, source, buyOrSellMode, setUser ,setSource, setBuyOrSellMode]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  useEffect(() => {
    const trackingID = "UA-172952789-3"

    ReactGA.initialize(trackingID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: true, // enable logs
    };
    ReactPixel.init('655735148425626', undefined, options)
    ReactPixel.pageView();
    ReactPixel.track('')
    console.log('test')
  },[])

  let refreshKeyPressed = false;
  let modifierPressed = false;

  var f5key = 116;
  var rkey = 82;
  var modkey = [17, 224, 91, 93];

  // Check for refresh keys
  document.addEventListener(
      'keydown',
      function(evt: any) {
          // Check for refresh
          if (evt.which == f5key || modifierPressed && evt.which == rkey) {
              refreshKeyPressed = true;
          }

          // Check for modifier
          if (modkey.indexOf(evt.which) >= 0) {
              modifierPressed = true;
          }
      }
  );

  // Check for refresh keys
  document.addEventListener(
      'keyup',
      function(evt: any) {
          // Check undo keys
          if (evt.which == f5key || evt.which == rkey) {
              refreshKeyPressed = false;
          }

          // Check for modifier
          if (modkey.indexOf(evt.which) >= 0) {
              modifierPressed = false;
          }
      }
  );

  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    if(refreshKeyPressed){
      console.log('test')
     
    }else if(modifierPressed){
      console.log('test2')
    }else{
      //Auth.logout()
    }
    //Auth.logout()
  });

  history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname + location.search)
    console.log(location.pathname)
    ReactPixel.pageView();
    console.log(action)
  })
  
  return (
    <User.Context.Provider value={userValue}>

      <>
      
      {loadingOverlay ?
        <Backdrop className={classes.backdrop} open={loadingOverlay} >
            <BounceLoader color={"#FF873A"} />
        </Backdrop>
        : null}

     
      <Router history={history}>
        
        
        <Switch>
        
            { /*routes.public.map(page =>
                <PublicRoute key={page.name} path={page.url} component={page.component} />
              )*/
            }
            <Route exact path="/proposal/:reference" component={ProposalDetailsLogin} />
            <Route exact path="/" component={BuyerHome}/>
            <Route exact path="/merchant" component={MerchantHome}/>
            <Route exact path="/faqs" component={Faqs}/>
            <Route exact path="/terms-conditions" component={TermsConditions}/>
            <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
            <Route exact path="/installment-agreement" component={InstallmentAgreement}/>
            <Route exact path="/buyer/installment-agreement" component={InstallmentAgreement}/>
            <Route exact path="/merchant/installment-agreement" component={InstallmentAgreement}/>
            <Route exact path="/buyer/faqs" component={Faqs}/>
            <Route exact path="/buyer/terms-conditions" component={TermsConditions}/>
            <Route exact path="/buyer/privacy-policy" component={PrivacyPolicy}/>
            <Route exact path="/merchant/faqs" component={Faqs}/>
            <Route exact path="/merchant/terms-conditions" component={TermsConditions}/>
            <Route exact path="/merchant/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/login" component={Login}/>
            <Route path="/web-pay" component={AppPay} />
            <Route path="/verify" component={EmailVerificationPage} />
            <Route path="/password-reset" component={PasswordReset} />
            <PrivateRoute path='/app' setLoadingOverlay={setLoadingOverlay} user={userValue} component={Admin} />
            
          
        </Switch>
      </Router>
      </>
    </User.Context.Provider>
  );
}

export default App;