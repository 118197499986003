import classes from '*.module.css';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, Theme, Toolbar, Typography, Divider, ListItemIcon, MenuItem, MenuList, SwipeableDrawer, useMediaQuery, Drawer } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Footer from '../../layouts/Footer';
import ReactGA from 'react-ga'
import DialpadIcon from '@material-ui/icons/Dialpad';
import SettingsIcon from '@material-ui/icons/Settings';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import MenuIcon from '@material-ui/icons/Menu';
import CreateIcon from '@material-ui/icons/Create';
import HelpIcon from '@material-ui/icons/Help';
import iosBadge from '../../assets/images/ios-badge.png';
import googleBadge from '../../assets/images/google-play-badge.png';

const logo = require('../../assets/images/4giveslogo_buyer.svg');
const defaultLogo = require('../../assets/images/4giveslogo.png');
const sellerLogo = require('../../assets/images/4giveslogo_seller.svg');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    menuButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '0',
    },
    title: {
      flexGrow: 1,
    },
    toolbarBtns: {
        marginLeft: '0',
        marginRight: '0'
    },
    toolbarBtnMobile: {
      marginLeft: 'auto',
    },
    content: {
      flex: 1,
      marginTop: '0px',
  marginBottom: '0px',
  marginRight: '0px',
  marginLeft: '0px',
      overflowY: 'auto'
    },
    btns: {
      borderRadius: '20px',
      padding: '10px 10px',
    },
    loginBtns: {
        borderRadius: '8px',
        width: '105px',
        padding: '5px 20px',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
      background: '#fff',
    },
    menuLabel: {
      color: '#FF873A'
  },
  }),
);

export const MobileSideBar: React.FC<{
    calcRef?: any;
    howRef?: any;
    reqRef?: any;
    executeScroll?: (req: any) => void;
    openDrawer: boolean;
    setOpenDrawer: (val: boolean) => void;
}> = ({calcRef, howRef, reqRef, executeScroll, openDrawer, setOpenDrawer}) => {

    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    
    useEffect(() => {
        console.log('test')   
    })

    return (
        <>
        
    {!location.pathname.includes('merchant') ? (
        <Drawer 
                anchor={'right'} 
                classes={{paper: classes.paper}} 
                open={openDrawer} onClose={() => {setOpenDrawer(false)}}>
                <div className="drawernav">
                    <Grid container justify="center" spacing={2}>
                        
                        
                        <Grid xs={12} item style={{ textAlign: 'center' }}>
                            <img src={defaultLogo} style={{maxWidth: '150px', margin: 'auto'}} />
                        </Grid>
                        <Divider />
                        <Grid xs={12}  item>
                            <MenuList>
                                <MenuItem onClick={() => history.push('/merchant')}>
                                    <Button style={{textTransform: 'none'}}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <AttachMoneyIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">4Gives for Sellers</Typography>
                                    </Button>
                                </MenuItem>
                                
                                <MenuItem  >
                                    <Button href="#calc" style={{textTransform: 'none'}} onClick={(e:any) => {
                                    if(calcRef !== undefined && executeScroll !== undefined){
                                        executeScroll(calcRef)
                                        setOpenDrawer(false)
                                    }
                                }}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <DialpadIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">Calculator</Typography>
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button style={{textTransform: 'none'}} href="#howRef" onClick={(e:any) => {
                                        if(howRef !== undefined && executeScroll !== undefined){
                                            executeScroll(howRef)
                                            setOpenDrawer(false)
                                        }
                                    }}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <SettingsIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">How it Works</Typography>
                                    </Button>
                                </MenuItem>
                              
                                <Divider />
                                <MenuItem >
                                    <Button style={{textTransform: 'none'}}onClick={() => {
                                        history.push('/faqs')
                                    }}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <HelpIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">FAQs</Typography>
                                    </Button>
                                </MenuItem>
                                <Divider />
                                <MenuItem >
                                    <Button style={{textTransform: 'none'}}  onClick={() => window.location.href = window.location.origin + "/login?page=login-page&applying_for=buyer" }>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <AccountCircleIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Login</Typography>
                                    </Button>
                                </MenuItem>
                                <MenuItem onClick={() => window.location.href = window.location.origin + "/login?page=registration-page&applying_for=buyer" }>
                                    <Button style={{textTransform: 'none'}}  onClick={() => window.location.href = window.location.origin + "/login?page=registration-page&applying_for=buyer" }>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <CreateIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">Sign Up</Typography>
                                    </Button>
                                </MenuItem>
                                <img src={iosBadge} style={{height: '100%', width: '180.00px', margin: 'auto'}}/>
                
                                <img src={googleBadge} style={{height: '100%', width: '180.00px', margin: 'auto'}}/>
                            </MenuList>
                                
                            <span style={{ fontWeight: 'bold', fontSize: '16px', color: "#FF873A" }}></span>
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
    ) : location.pathname.includes('merchant') ? (
            <Drawer 
                anchor={'right'} 
                classes={{paper: classes.paper}} 
                open={openDrawer} onClose={() => {setOpenDrawer(false)}}>
                <div className="drawernav">
                    <Grid container justify="center" spacing={2}>
                        
                        
                        <Grid xs={12} item onClick={() => history.push('/')} style={{ textAlign: 'center' }}>
                            <img src={defaultLogo} style={{maxWidth: '150px', margin: 'auto'}} />
                        </Grid>
                        <Divider />
                        <Grid xs={12}  item>
                            <MenuList>
                                <MenuItem >
                                    <Button style={{textTransform: 'none'}} onClick={() => history.push('/')}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <StarsIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel}  variant="body1">4Gives for Buyers</Typography>
                                    </Button>
                                </MenuItem>

                                <MenuItem >
                                    <Button style={{textTransform: 'none'}} href="#howRef" onClick={(e:any) => {
                                        if(howRef !== undefined && executeScroll !== undefined){
                                            history.push('/merchant#howRef')
                                            executeScroll(howRef)
                                            setOpenDrawer(false)
                                        }
                                    }}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <SettingsIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">How it Works</Typography>
                                    </Button>
                                </MenuItem>
                            
                                  <Divider />  
                                <MenuItem >
                                    <Button style={{textTransform: 'none'}}onClick={() => {
                                        history.push('/merchant/faqs')
                                    }}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <HelpIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">FAQs</Typography>
                                    </Button>
                                </MenuItem>
                                <Divider />
                                <MenuItem >
                                    <Button style={{textTransform: 'none'}}  onClick={() => window.location.href = window.location.origin + "/login?page=login-page&applying_for=merchant" }>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <AccountCircleIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Login</Typography>
                                    </Button>
                                </MenuItem>
                                <MenuItem >
                                    <Button style={{textTransform: 'none'}}  onClick={() => window.location.href = window.location.origin + "/login?page=registration-page&applying_for=merchant" }>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <CreateIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">Sign Up</Typography>
                                    </Button>
                                </MenuItem>
                                <img src={iosBadge} style={{height: '100%', width: '180.00px', margin: 'auto'}}/>
                
                                <img src={googleBadge} style={{height: '100%', width: '180.00px', margin: 'auto'}}/>
                            </MenuList>
                            <span style={{ fontWeight: 'bold', fontSize: '16px', color: "#FF873A" }}></span>
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
    ) : (
        <Drawer 
                anchor={'right'} 
                classes={{paper: classes.paper}} 
                open={openDrawer} onClose={() => {setOpenDrawer(false)}}>
                <div className="drawernav">
                    <Grid container justify="center" spacing={2}>
                        
                        
                        <Grid xs={12} item style={{ textAlign: 'center' }}>
                            <img src={logo} style={{maxWidth: '150px', margin: 'auto', paddingTop: '10px'}} />
                        </Grid>
                        <Divider />
                        <Grid xs={12}  item>
                            <MenuList>
                                <MenuItem onClick={() => history.push('/')}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <StarsIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">Buyer Mode</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => history.push('/merchant')}>
                                    <ListItemIcon style={{minWidth: '28px'}}>
                                        <div className="menuIcon">
                                            <LocalAtmIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                        </div>
                                    </ListItemIcon>
                                    <Typography className={classes.menuLabel} variant="body1">Seller Mode</Typography>
                                </MenuItem>
                        
                                
                                
                            </MenuList>
                            <span style={{ fontWeight: 'bold', fontSize: '16px', color: "#FF873A" }}></span>
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
    )
    
    
    }</>)
}

export const HomeNavBar: React.FC<{
    calcRef?: any;
    howRef?: any;
    reqRef?: any;
    executeScroll?: (req: any) => void;
}> = ({calcRef, howRef, reqRef, executeScroll}) => {

    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const matchesNav = useMediaQuery('(min-width: 1014px)')
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        console.log('test app')
      })



    return (
        <div>
            {!location.pathname.includes('merchant') ? (
                <>
            
            <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none'}}>
                <Toolbar>
                  
                  <IconButton edge="start" onClick={() => history.push('/')} className={classes.menuButton} color="inherit" aria-label="menu">
                      <img src={defaultLogo} style={{maxWidth: matches ? '200px' : '150px', fontSize: 40}} />
                  </IconButton>
                  {!matchesNav ? (
                    <span style={{marginLeft: '-20px'}}>
                      <IconButton  edge="end" style={{left: "-50"}} onClick={() => setOpenDrawer(true)} color="inherit" aria-label="menu">
                        <MenuIcon style={{fill: '#FF873A'}} />
                      </IconButton>
                    </span>
                 
                  ):(
                  <span className={classes.toolbarBtns}>
                    <Button variant="text" onClick={() => history.push(!location.pathname.includes('buyer') ? '/merchant' : '/')} className={classes.btns} style={{color: '#FF873A', fontSize: 14}}><span style={{color: '#FF873A'}}>Become a 4Gives {!location.pathname.includes('buyer') ? 'Seller' : 'Buyer'}</span></Button>
                    <Button variant="text" 
                    href="#calc"
                    className={classes.btns} 
                    style={{color: '#FF873A', fontSize: 14}} 
                    onClick={(e:any) => {
                        if(calcRef !== undefined && executeScroll !== undefined){
                            executeScroll(calcRef)
                            setOpenDrawer(false)
                        }
                    }}><span style={{color: '#FF873A'}}>Calculator</span></Button>
                    <Button 
                    variant="text" 
                    href="#howRef"
                    className={classes.btns} 
                    style={{color: '#FF873A', fontSize: 14}} 
                    onClick={(e:any) => {
                        if(howRef !== undefined && executeScroll !== undefined){
                            executeScroll(howRef)
                            setOpenDrawer(false)
                        }
                    }}><span style={{color: '#FF873A'}}  >How it Works</span></Button>
                    {/** 
                    <Button variant="text" 
                    className={classes.btns} 
                    style={{color: '#FF873A', fontSize: 14}} 
                    onClick={(e:any) => {
                        if(reqRef !== undefined && executeScroll !== undefined){
                            executeScroll(reqRef)
                            //setOpenDrawer(false)
                        }
                    }}><span style={{color: '#FF873A'}}>Requirements</span></Button>
                    */}
                    <Button variant="contained" onClick={() => history.push('/login?page=login-page&applying_for=buyer')} className={classes.loginBtns} style={{color: 'white', backgroundColor: '#FF873A', fontSize: 14}}><span style={{color: 'white'}}>Login</span></Button>
                    <Button variant="contained" onClick={() => history.push('/login?page=registration-page&applying_for=buyer')} className={classes.loginBtns} style={{color: 'white', backgroundColor: '#FF873A', fontSize: 14}}><span style={{color: 'white'}}>Sign Up</span></Button>
                    
                  </span>
                  )}
                 
                </Toolbar>
            </AppBar>
            <MobileSideBar executeScroll={executeScroll} howRef={howRef} calcRef={calcRef} reqRef={reqRef} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            
                </>
            ): location.pathname.includes('merchant') ? (
            <>
            <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none'}}>
                <Toolbar>
                  
                  <IconButton edge="start" onClick={() => history.push('/')} className={classes.menuButton} color="inherit" aria-label="menu">
                      <img src={defaultLogo} style={{maxWidth: matches ? '200px' : '150px', fontSize: 40}} />
                  </IconButton>
                  {!matchesNav ? (
                    <span style={{marginLeft: '-20px'}}>
                      <IconButton  edge="end" style={{left: "-50"}} onClick={() => setOpenDrawer(true)} color="inherit" aria-label="menu">
                        <MenuIcon style={{fill: '#FF873A'}} />
                      </IconButton>
                    </span>
                 
                  ):(
                  <span className={classes.toolbarBtns}>
                    <Button variant="text" className={classes.btns} onClick={() => history.push('/')} style={{color: '#FF873A', fontSize: 16}}><span style={{color: '#FF873A'}}>Become 4Gives Buyer</span></Button>
                    <Button 
                    variant="text" 
                    href="#howRef"
                    className={classes.btns} 
                    style={{color: '#FF873A', fontSize: 14}} 
                    onClick={(e:any) => {
                        if(howRef !== undefined && executeScroll !== undefined){
                            executeScroll(howRef)
                            setOpenDrawer(false)
                        }
                    }}><span style={{color: '#FF873A'}}  >How it Works</span></Button>
                     {/** 
                    <Button variant="text" 
                    className={classes.btns} 
                    style={{color: '#FF873A', fontSize: 14}} 
                    onClick={(e:any) => {
                        if(reqRef !== undefined && executeScroll !== undefined){
                            executeScroll(reqRef)
                            //setOpenDrawer(false)
                        }
                    }}><span style={{color: '#FF873A'}}>Requirements</span></Button>
                    */}
                    <Button variant="contained" onClick={() => history.push('/login?page=login-page&applying_for=merchant')} className={classes.loginBtns} style={{color: 'white', backgroundColor: '#FF873A', fontSize: 15}}><span style={{color: 'white'}}>Login</span></Button>
                    <Button variant="contained" onClick={() => history.push('/login?page=registration-page&applying_for=merchant')} className={classes.loginBtns} style={{color: 'white', backgroundColor: '#FF873A', fontSize: 15}}><span style={{color: 'white'}}>Sign Up</span></Button>
                  </span>
                  )}
                 
                </Toolbar>
            </AppBar>

            <MobileSideBar executeScroll={executeScroll} howRef={howRef} calcRef={calcRef} reqRef={reqRef} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            </>
            ): (
                <>
                <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none'}}>
                    <Toolbar>
                    
                    <IconButton edge="start" onClick={() => history.push('/')} className={classes.menuButton} color="inherit" aria-label="menu">
                        <img src={defaultLogo} style={{maxWidth: matches ? '200px' : '150px', fontSize: 40}} />
                    </IconButton>
                    {!matches ? (
                        <span style={{marginLeft: '-20px'}}>
                        <IconButton  edge="end" style={{left: "-50"}} onClick={() => setOpenDrawer(true)} color="inherit" aria-label="menu">
                            <MenuIcon style={{fill: '#FF873A'}} />
                        </IconButton>
                        </span>
                    
                    ):(
                    <span className={classes.toolbarBtns}>
                        <Button variant="text" onClick={() => history.push(location.pathname.includes('buyer') ? '/merchant' : '/')} className={classes.btns} style={{color: '#a9a9a9', fontSize: 15}}><span style={{color: '#a9a9a9'}}>Go to 4Gives {location.pathname.includes('buyer') ? 'Buyer' : 'Seller'}</span></Button>
                        <Button variant="text" className={classes.btns} onClick={() => history.push('/')} style={{color: '#a9a9a9', fontSize: 16}}><span style={{color: '#a9a9a9'}}>Go to 4Gives Buyer</span></Button>
                    </span>
                    )}
                    
                    </Toolbar>
                </AppBar>

                <MobileSideBar executeScroll={executeScroll} howRef={howRef} calcRef={calcRef} reqRef={reqRef} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
               
                </>
            )}
        </div>
    )
}