import {
  Backdrop,
  Container,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { useQuery } from '../../hooks';
import {
  Button,
  SuccessDialog,
  ErrorDialog,
  TextLoginInput,
  TextLoginCardExpiryInput,
} from '../../components';
import Auth from '../../auth';
import Cookies from 'js-cookie';
import logo from '../../assets/images/4giveslogo.png';
import { Col, Row } from 'react-grid-system';
import { BounceLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { User } from '../../models';
import error from '../../assets/images/error.png';
import CreditCard from './CreditCard';
import Gcash from './Gcash';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  labelForm: {
    color: '#fffff',
  },
  multilineColor: {
    color: '#ececec',
    fontSize: '18px',
    fontWeight: 300,
  },
  select: {
    '&:before': {
      borderColor: '#ececec',
    },
    '&:after': {
      borderColor: '#ececec',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid #ececec`,
    },
  },
  selectIcon: {
    fill: '#ececec',
  },
  dropdownStyle: {
    border: '1px solid #FF873A',
    borderRadius: '5%',
    backgroundColor: '#FF873A',
    color: 'white',
  },
  checkedIcon: {
    backgroundColor: '#106c58',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106c58',
    },
  },
});

const AppPay: React.FC<any> = () => {
  const query = useQuery();
  const [transactData, setTransactData] = useState<any>(null);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(null)

  useEffect(() => {
    if (!transactData) {
      if (query.has('id')) {
        setLoadingOverlay(true);
        Auth.getTransactionCreateDetails(
          `?id=${query.get('id')}`,
          (resp) => {
            setTransactData(resp.data);
            setLoadingOverlay(false);
            setPaymentMethod(resp.data.payment_method)
          },
          (e) => {
            console.log(e);
            setLoadingOverlay(false);
            setIsError(true);
            setErrorMsg(e.error);
          }
        );
      } else {
        setIsError(true);
        setErrorMsg('Transaction not found.');
      }
    }
  }, []);

  if (isError) {
    return (
      <div className="app-pay-details-container" style={{border: isError ? '1px solid #d2d2d2' : 'none', padding: isError ? '30px 45px' : '0', width: isError ? '500px' : '100vw', margin: 'auto'}}>
        <div className="app-pay-details">
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <img
                src={error}
                style={{ width: '100px', height: '100px' }}
              />
            </Grid>
            <Grid item>{errorMsg}</Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <Button
            color="login-primary"
            onClick={() =>
              transactData
                ? window.location.replace(
                    transactData.failure_redirect_url
                  )
                : window.location.replace(
                    'https://dev.thetechsetter.com'
                  )
            }
            className="create-sale-button"
          >
            Go to 4Gives
          </Button>
        </div>
      </div>
    )
  }

  if (paymentMethod === 'GCash') {
    return  <Gcash transactData={transactData} setTransactData={setTransactData}/>
  } 
  else {
    return <CreditCard transactData={transactData}/>
  }

};

export default AppPay;
