import axios from 'axios';
import URL from './url';
import Auth from '../auth';

let httpRequest;


const config = {
    baseURL: URL.SERVER, 
    timeout: 30000, //30 seconds
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': ''
    }
};



export const ApiRequest = {
    get: (url: string) => {
      config.headers.Authorization = `Token ${Auth.key()}`;
      console.log(Auth.key())
      httpRequest = axios.create(config);
      return httpRequest.get(url);
    },
    post: (url: string, args = {}, isImage?: boolean) => {
      config.headers.Authorization = `Token ${Auth.key()}`;
      if(isImage !== undefined){
        config.headers['Content-Type'] = 'multipart/form-data'
      }
      httpRequest = axios.create(config);
      return httpRequest.post(url, args);
    },
    paymayaPost: (url: string, args = {}) => {
      config.headers.Authorization = `Basic cGsteElhRjZpSkZKNzJ6aUpkTDJpa1daNjhoRFBGemNxOWw0bFFCVEtKbjdIUA==`;
      httpRequest = axios.create(config);
      return httpRequest.post(url, args);
    },
    paymonggoPost: (url: string, args = {}) => {
      config.headers.Authorization = `Basic c2tfbGl2ZV9yQVNEdnFlQWdONDdwZmVTZGRDM3B2UEc6`;
      httpRequest = axios.create(config);
      return httpRequest.post(url, args);
    },
    paymayaGet: (url: string) => {
      config.headers.Authorization = `Basic cGsteElhRjZpSkZKNzJ6aUpkTDJpa1daNjhoRFBGemNxOWw0bFFCVEtKbjdIUA==`;
      httpRequest = axios.create(config);
      return httpRequest.get(url);
    },
    patch: (url: string, args = {}, isImage?: boolean) => {
      config.headers.Authorization = `Token ${Auth.key()}`;
      if(isImage !== undefined){
        config.headers['Content-Type'] = 'multipart/form-data'
      }else{
        config.headers['Content-Type'] = 'application/json'
      }
      httpRequest = axios.create(config);
      return httpRequest.patch(url, args);
    },
    put: (url: string, args = {}) => {
      config.headers.Authorization = `Token ${Auth.key()}`;
      httpRequest = axios.create(config);
      return httpRequest.put(url, args);
    },
    delete: (url: string, args = {}) => {
      config.headers.Authorization = `Token ${Auth.key()}`;
      httpRequest = axios.create(config)
      return httpRequest.delete(url, args)
    },
    webLinkGet: (url: string, args = {}) => {
      config.headers.Authorization = `Basic e84f73c4d153637c30a0462b94dc42172dcf77cd`;
      httpRequest = axios.create(config)
      return httpRequest.get(url, args)
    },
    newPasswordPost: (url: string, args= {}, token: string) => {
      config.headers.Authorization = `Token ${token}`;
      httpRequest = axios.create(config)
      return httpRequest.post(url, args);
    }

};

export const HttpRequest = {
  get: (url: string) => {
    config.headers.Authorization = '';
    httpRequest = axios.create(config);
    return httpRequest.get(url);
  },
  post: (url: string, args = {}, headers = {}) => {
    config.headers.Authorization = '';
    httpRequest = axios.create(config);
    
    return httpRequest.post(url, args, headers);
  },
  put: (url: string, args = {}) => {
    config.headers.Authorization = '';
    httpRequest = axios.create(config);
    
    return httpRequest.put(url, args);
  },
};

