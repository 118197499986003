import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {  Col, Row } from 'react-grid-system';
import { Table, Card, CardBody, CardFooter, SuccessDialog, ErrorDialog, Button, ProposalViaEmailLink, OTPDialog } from '../../components';
import moment from 'moment';
import WalletCard from '../../components/WalletCard';
import Auth from '../../auth';
import { TextInput, TextLoginInput,} from '../../components/Input'
import { User } from '../../models';
import { Dialog, DialogTitle, Grid, Card as MuiCard, CardContent as MuiCardContent, CardActions, makeStyles, MenuItem, Select, Button as MuiBtn, ButtonGroup, useMediaQuery, CardContent, Step, StepButton, Stepper, Typography, StepLabel, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import { Pagination } from '@material-ui/lab';
import { updateTypeAssertion } from 'typescript';
import { RecentActivity } from '../../layouts/RecentActivity';
import {Alert, Container} from 'reactstrap';
import { Incoming } from './IncomingSell';
import createSale from '../../assets/images/fork_screen icons/create_sale.png';
import proposalKey from '../../assets/images/fork_screen icons/proposal_key.png';
import close from '../../assets/images/close.png';

const mascot = require('../../assets/images/4gives_mascot.png');

const useStyles = makeStyles({
    root: {
        borderColor: '#FF873A'
    },
    indicator: {
        backgroundColor: "#FF873A",
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
        paddingTop: 15, 
        alignSelf: 'center', 
        justifySelf: 'center', 
        color: '#FF873A'
    },
    select: {
        '&:before': {
            borderColor: '#FF873A',
        },
        '&:after': {
            borderColor: '#FF873A',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid #FF873A`
        }
    },
    
    selectIcon: {
        fill: '#ececec',
    },
    labelForm: {
        color: '#fffff',
    },
    cardEntry: {
      cursor: 'pointer',
      
    },
    btn: {
        color: 'white',
        background: '#FF873A',
        borderColor: '#FF873A',
        '&:hover': {
            backgroundColor: '#FF873A',
          },
    },
    btnOutlined: {
        color: '#FF873A',
        borderColor: '#FF873A',
        background: '#fff',
    },
    step: {
        color: '#FF873A !important',
        "& $completed": {
          color: "lightgreen !important"
        },
        "& $active": {
          color: "pink !important"
        },
        "& $disabled": {
          color: "red !important"
        }
    },
    divider: {
        backgroundColor: '#FF873A',
        border: "1px solid #FF873A",
    },
    active: {}, //needed so that the &$active tag works
    completed: {},
    checkedIcon: {
      backgroundColor: '#FF873A',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FF873A',
      },
    },
  });

function getSteps() {
    return ['Verified Email', 'Card Activated', 'Account Upgraded'];
}



export const MerchantAccount: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const theme = useTheme();
    const classes = useStyles();
    const {user, source, setUser} = useContext<any>(User.Context)
    const [cards, setCards] = useState<Array<[]> | null>(source);
    const [cardActivated, setCardActivated] = useState(false);
    const [result, setResult] = useState<any>(null);
    const [newResult, setNewResult] = useState<any>(null);

    const [openProposalKey, setOpenProposalKey] = useState(false);

    const [buyer, setBuyer] = useState<any>(null)
    const [buyerName, setBuyerName] = useState('')
    const [merchant, setMerchant] = useState<any>(null);
    const [merchantName, setMerchantName] = useState('');

    const [ openDialog, setOpenDialog ] = useState(false);
    const [ openEmailVerif, setOpenEmailVerif ] = useState(false);
    const [verifCode, setVerifCode] = useState<string | null>(null)
    const [openEmailLinkPropose, setOpenEmailLinkPropose] = useState(false);
    const [proposeType, setProposeType] = useState<string | null>(null);
    const [type, setType] = useState<string | null>("BUY");
    const [ paymentType, setPaymentType ] = useState<any>("");
    const [ terms, setTerms ] = useState("");
    const [ openSource, setOpenSource ] = useState(false);
    const [ sourceID, setSourceID ] = useState(null)
    const [ dialogMessage, setDialogMessage ] = useState<string | null>(null);
    const [transactLink, setTransactLink] = useState<string | null>(null);
    const [id, setID] = useState(user ? user.id : '')
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set<number>(null));
    const [skipped, setSkipped] = React.useState(new Set<number>(null));
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const mdBreak = useMediaQuery('(min-width:768px)')
    const history = useHistory();
    const steps = getSteps();
    const [paymentTypeList, setPaymentTypeList] = useState<any | null>(null)
    const [openOTP, setOpenOTP] = useState(false);

    

    const [sourceIDSelected, setSourceIDSelected] = useState<any| null>(null)

    
    const handleCloseProposalKey = () => {
        setTransactLink(null)
        setOpenProposalKey(false)
    }

    const handleClose = () => {
        setBuyer(null)
        setMerchant(null)
        setResult(null)
        setNewResult(null)
        setOpenSource(false)
        setSourceID(null)
        setPaymentType("")
        setProposeType(null)
        setTerms("")
    }


    useEffect(() => {
        if(paymentTypeList === null){
          Auth.getServiceTypes(
            (resp) => {
              setPaymentTypeList(resp.results)
            },
            (e) => {
              console.log(e)
            }
          )
        }
      },[paymentTypeList])

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
      };

    const totalSteps = () => {
        return getSteps().length;
      };

    function isStepComplete(step: number) {
        return completed.has(step);
    }

    useEffect(() => {
        //console.log(user);
        console.log("Loading Component")
        if(cards === null){
            Auth.getCards(
                (response) => {
                    //console.log(response)
                    setCards(response)
                    Object.entries(response).map(([key, value]: any) => 
                        value.installmentAuthorized ? setCardActivated : null
                    )
                },
                (e) =>{
                    console.log(e)
                }
            )
        }
        

    })
    useEffect(() => {
        console.log(completed)
    },[completed])
    const checkCompleted = async () => {
        if(user !== null && cards !== null){
            console.log("UPDATING STEPS!")
            setCompleted(new Set<number>());
            const newCompleted = new Set(completed);
            if(user.emailConfirmed){
                
                newCompleted.add(0);
                if (completed.size !== totalSteps()) {
                    setActiveStep(activeStep+1)
                }
            }
            if(cards){
                const isInstall = cards.map((data:any) => {
                    if(data.installmentAuthorized){
                      return true
                    }else{
                      return false
                    }
                  })
                console.log(isInstall)
                const test =  isInstall.some((val) => {
                    if(val === true){
                        return true
                    }
                })
                console.log(test)
                setCardActivated(test);
                if(test){
                    newCompleted.add(1);
                    if (completed.size !== totalSteps()) {
                        setActiveStep(activeStep+1)
                    }
                }
            }
            if(user.sellAuthorized && user.buyAuthorized){
                newCompleted.add(2);
                
                if (completed.size !== totalSteps()) {
                    setActiveStep(activeStep+2)
                }
            }
            setCompleted(newCompleted)
        }
    }

    useEffect(() => {
        console.log(user);
        checkCompleted();
    },[user, cards])
   
    useEffect(() => {
        if(newResult !== null){
            setLoadingOverlay(false)
            if(source.length > 0){
                setOpenSource(true)
            }
        }
    },[newResult])

    useEffect(() => {
        if(sourceID){
          if(result.category_name === "RENT"){
            Auth.updateProposal(
              result.id,
              result.terms,
              2,
              (resp) => {
                setNewResult(resp)
              },
              (e) => {
                console.log(e)
              }
            )
          }
          if(paymentType !== ''){
            if(sourceIDSelected){
              handleTermChange(3, paymentType)
            }
          }
        }
      },[sourceID])
  

    const submitTransactLink = () => {
        setLoadingOverlay(true)
        
        if(transactLink){
            Auth.submitCode(
                transactLink,
                (refResp) => {
                    if(refResp.Link.includes('link')){
                        Auth.getProposalLink(
                            refResp.Link.substring(refResp.Link.lastIndexOf('/')+1),
                            (resp) => {
                                let link = resp.link.match(/\/proposal\/(\d+)\//)
                                if(link){
                                    //console.log(link[1]);
                                    history.push(`/app/seller/proposal/${link[1]}`)
                                    Auth.getProposal(
                                        link[1],
                                        (response) => {
                                           
                                            if(response.proposal_type === "BUYING" && response.buyer === user.id){
                                                console.log('you cannot use your own proposal')
                                                setErrorMessage("You cannot use your own proposal")
                                                setOpenErrorDialog(true)
                                                setLoadingOverlay(false)
                                            }
                                            else if(response.proposal_type === "SELLING" && response.merchant === user.id){
                                                console.log('you cannot use your own proposal')
                                                setErrorMessage("You cannot use your own proposal")
                                                setOpenErrorDialog(true)
                                                setLoadingOverlay(false)
                                            }
                                            else{
                                                if(!user.emailConfirmed){
                                                    setErrorMessage("You must verify your email first before proceeding.")
                                                    setOpenErrorDialog(true)
                                                    setLoadingOverlay(false)
                                                }else if(response.proposal_type === "SELLING" && (!user.buyAuthorized && !(source.length > 0))){
                                                    setErrorMessage("You must be a authorized as a buyer first before proceeding.")
                                                    setOpenErrorDialog(true)
                                                    setLoadingOverlay(false)
                                                }else if(response.proposal_type === "BUYING" && !user.sellAuthorized){
                                                    setErrorMessage("You must be a authorized as a seller first before proceeding.")
                                                    setOpenErrorDialog(true)
                                                    setLoadingOverlay(false)
                                                }else{
                                                    console.log(response);
                                                    setResult(response)
                                                    if(response.buyer && response.proposal_type === "BUYING"){
                                                        setBuyer(response.buyer)
                                                        Auth.getUser(
                                                            response.buyer,
                                                            (userResp) => {
                                                                console.log("GETTING USER")
                                                                setBuyerName(userResp.first_name + " "+ userResp.last_name)
                                                                setLoadingOverlay(false)
                                                            },
                                                            (e) => {
                                                                console.log(e)
                                                                setLoadingOverlay(false)
                                                            }
                                                        )
                                                    }
                                                    else if(response.merchant && response.proposal_type === "SELLING"){
                                                        console.log(response.merchant)
                                                        Auth.getUser(
                                                            response.merchant,
                                                            (userResp) => {
                                                                console.log("GETTING USER")
                                                                setMerchant(response.merchant)
                                                                setMerchantName(userResp.first_name + " "+ userResp.last_name)
                                                                setLoadingOverlay(false)
                                                            },
                                                            (e) => {
                                                                console.log(e)
                                                                setLoadingOverlay(false)
                                                            }
                                                        )
                                                    }
                                                }
                                                
                                            }
                                            setLoadingOverlay(false)
                                        },
                                        (e) => {
                                            setErrorMessage(e.error)
                                            handleCloseProposalKey()
                                            setOpenErrorDialog(true)
                                            setLoadingOverlay(false)
                                        }
                                    )
                                }
                            },
                            (e) => {
                                setErrorMessage(e.error)
                                setOpenErrorDialog(true)
                                handleCloseProposalKey()
                                setLoadingOverlay(false)
                            }
                        )
                    }else{
                        console.log(refResp)
                        let link = refResp.Link.substring(refResp.Link.lastIndexOf('/')+1)
                        console.log(link)
                        if(link){
                            //console.log(link[1]);
                            history.push(`/app/seller/proposal/${link}`)
                            Auth.getProposal(
                                link,
                                (response) => {
                                    if(response.proposal_type === "BUYING" && response.buyer === user.id){
                                        console.log('you cannot use your own proposal')
                                        setErrorMessage("You cannot use your own proposal")
                                        setOpenErrorDialog(true)
                                        setLoadingOverlay(false)
                                    }
                                    else if(response.proposal_type === "SELLING" && response.merchant === user.id){
                                        console.log('you cannot use your own proposal')
                                        setErrorMessage("You cannot use your own proposal")
                                        setOpenErrorDialog(true)
                                        setLoadingOverlay(false)
                                    }
                                    else{
                                        console.log(response);
                                        if(!user.emailConfirmed){
                                            setErrorMessage("You must verify your email first before proceeding.")
                                            setOpenErrorDialog(true)
                                            setLoadingOverlay(false)
                                        }else if(response.proposal_type === "SELLING" && (!user.buyAuthorized && !(source.length > 0))){
                                            setErrorMessage("You must be a authorized as a buyer first before proceeding.")
                                            setOpenErrorDialog(true)
                                            setLoadingOverlay(false)
                                        }else if(response.proposal_type === "BUYING" && !user.sellAuthorized){
                                            setErrorMessage("You must be a authorized as a seller first before proceeding.")
                                            setOpenErrorDialog(true)
                                            setLoadingOverlay(false)
                                        }else{
                                            if(response.status === "WAITING"){
                                                setResult(response)
                                                if(response.buyer && response.proposal_type === "BUYING"){
                                                    setBuyer(response.buyer)
                                                    Auth.getUser(
                                                        response.buyer,
                                                        (userResp) => {
                                                            console.log("GETTING USER")
                                                            setBuyerName(userResp.first_name + " "+ userResp.last_name)
                                                            setLoadingOverlay(false)
                                                        },
                                                        (e) => {
                                                            console.log(e)
                                                        }
                                                    )
                                                }
                                                else if(response.merchant && response.proposal_type === "SELLING"){
                                                    console.log(response.merchant)
                                                    Auth.getUser(
                                                        response.merchant,
                                                        (userResp) => {
                                                            console.log("GETTING USER")
                                                            setMerchant(response.merchant)
                                                            setMerchantName(userResp.first_name + " "+ userResp.last_name)
                                                            setLoadingOverlay(false)
                                                        },
                                                        (e) => {
                                                            console.log(e)
                                                        }
                                                    )
                                                }
                                            }else{
                                                setErrorMessage("This proposal has been accepted by a user.")
                                                handleCloseProposalKey()
                                                setLoadingOverlay(false)
                                                setOpenErrorDialog(true)
                                            }

                                        }
                                    }
                                },
                                (e) => {
                                    console.log(e);
                                    handleCloseProposalKey()
                                    setErrorMessage(e.error)
                                    setOpenErrorDialog(true)
                                    setLoadingOverlay(false)
                                }
                            )
                        }
                           
                    }
                },
                (e) =>{
                    setErrorMessage(e.error)
                    handleCloseProposalKey()
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                }
            )
            
            
        }else{
            setErrorMessage('Please input a reference code.')
            handleCloseProposalKey()
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
        }
    }

    const payNow = () => {
        setLoadingOverlay(true)
        Auth.acceptProposal(
          result.id,
          sourceID,
          (resp) => {
            //console.log(resp)
            setDialogMessage("The transaction will reflect on your data.")
            handleClose();
            setOpenDialog(true)
            setLoadingOverlay(false)
          },
          (e) => {
            console.log(e)
            setOpenErrorDialog(true)
            setErrorMessage(e.error)
            setLoadingOverlay(false)
          }
        )
      }
    
    const verifyEmail = () => {
        setLoadingOverlay(true)
        const id = user.id
        console.log(id)
        Auth.verifyEmail(
            id,
            verifCode,
            (resp) => {
                console.log(resp)
                setUser(resp)
                setOpenEmailVerif(false)
                setDialogMessage("You have successfully verified your email!")
                setOpenDialog(true)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setOpenErrorDialog(true)
                setErrorMessage(e)
                setLoadingOverlay(false)
            }
        )
    }

    const handleCategoryChange = (val: any) => {
        if(val === 1){
            setTerms("1")
            setPaymentType(val)
            ///UPDATE PROPOSAL
            Auth.updateProposal(
              result.id,
              1,
              val,
              (resp) => {
                //console.log(resp)
                setResult(resp)
              },
              (e) => {
                console.log(e)
              }
            )
        }
        else{
            setTerms('')
            setPaymentType(val);
            ///UPDATE PROPOSAL
        }
      }

    /*const handleTermChange = (val: any) => {
        setTerms(val)
        console.log(val)
        Auth.updateProposal(
          result.id,
          val,
          paymentType,
          (resp) => {
            console.log(resp)
            setResult(resp)
          },
          (e) => {
            console.log(e)
          }
        )
      }*/
      const handleTermChange = (val: any, payType?: any) => {
        setTerms(val)
        console.log(val)
        if(sourceIDSelected){
          Auth.updateProposal(
            result.id,
            val,
            payType ? payType : paymentType,
            (resp) => {
                console.log(resp)
                setResult(resp)
              
            },
            (e) => {
              console.log(e)
            }
          )
        }
        
      }

    const acceptProposal = () => {
        console.log(id)
        setLoadingOverlay(true)
        if(buyer){
            Auth.acceptSellerProposal(
                result.id,
                id,
                buyer,
                result.is_interest_shouldered_by_merchant ? 2 : null,
                (response) => {
                    console.log(response)
                    handleClose();
                    setDialogMessage("You have accepted the proposal!")
                    setOpenDialog(true)
                    setLoadingOverlay(false)
                    
                },
                (e) => {
                    console.log(e);
                }
            )
        }
        if(merchant){
            Auth.acceptSellerProposal(
                result.id,
                merchant,
                id,
                result.is_interest_shouldered_by_merchant ? 2 : null,
                (response) => {
                    console.log(response)
                    setNewResult(response)
                    setResult(response)
                    setLoadingOverlay(false)
                },
                (e) => {
                    console.log(e);
                }
            )
        }
    }

    function numberWithCommas(x: any) {
        console.log(x)
        return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

    useEffect(() => {
        if(user){
            setID(user.id)
        }
    },[user])
    
    if(user){
        return(
            <><>
                <div className="page-content my-account">
                    
                    <Container>
                    
                        {/*!user.emailConfirmed && (
                            <Alert color="warning">
                                Info: You have not yet verified your email. Please check your email and <a href="#" onClick={() => setOpenEmailVerif(true)} className="alert-link">click here</a> to verify using the code in your email
                            </Alert>
                        )}
                        {!user.sellAuthorized && (
                            <Alert color="warning">
                                Info: You have not yet submitted the required documents to start selling using 4Gives. <a href="#" onClick={() => history.push('/app/account-upgrade')} className="alert-link">Click here</a> to become a merchant
                            </Alert>
                        )}
                        {source.length < 1 && (
                            <Alert color="warning">
                                Info: You have no payment stored in the system. <a href="#" onClick={() => history.push('/app/vault-cards')} className="alert-link">Click here</a> to add a payment method.
                            </Alert>
                        )}
                        {/***
                         * 
                         * <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                const stepProps: { completed?: boolean } = {};
                                                const labelProps = {};
                                                if (isStepSkipped(index)) {
                                                    stepProps.completed = false;
                                                }
                                                return (
                                                    <Step key={label} {...stepProps} classes={{
                                                        root: classes.step, 
                                                        completed: classes.completed 
                                                    }}
                                                
                                                        style={{color: '#FF873A !important'}}
                                                    >
                                                        <StepButton
                                                            onClick={()=> {
                                                                if(index === 0){
                                                                    setOpenEmailVerif(true)
                                                                }
                                                                if(index === 1){
                                                                    history.push('/app/account-activation')
                                                                }
                                                                if(index === 2){
                                                                    history.push('/app/account-upgrade')
                                                                }
                                                            }}
                                                            completed={isStepComplete(index)}
                                                            classes={{
                                                                root: classes.step
                                                            }}
                                                            disabled={isStepComplete(index)}
                                                            style={{
                                                                color: '#FF873A !important',
                                                                fontSize: mdBreak ? '13px' :'16px'
                                                            }}
                                                        >
                                                            <StepLabel
                                                                StepIconProps={{ 
                                                                    classes:{ 
                                                                        root: classes.step, 
                                                                        active: classes.active, 
                                                                        completed: classes.completed 
                                                                    } 
                                                                }}
                                                            >
                                                                {label}
                                                            </StepLabel>
                                                            
                                                        </StepButton>
                                                    </Step>
                                                );
                                                })}
                                            </Stepper>
                         * 
                         */}
                            <div className="my-account-container fg-container">
                                
                                
                                <Row justify="center" nowrap >
                                    <Col md={12}>
                                        {/***
                                         *<Row nowrap style={{paddingTop: 20}}>
                                            <Col>
                                            <MuiCard raised={true}>
                                                <MuiCardContent>
                                                    <div style={{paddingLeft: '20px', alignItems: 'center'}}>
                                               
                                                        <Row align="center" justify="center">
                                                            <label className="container">Buyer Authorized
                                                                <input type="checkbox" disabled checked={user ? user.buyAuthorized : false} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </Row>
                                                        <Row align="center" justify="center">
                                                            <label className="container">Installment Ready
                                                                <input type="checkbox" disabled checked={cardActivated} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </Row>
                                                        <Row align="center" justify="center">
                                                            <label className="container">Seller Authorized
                                                                <input type="checkbox" disabled checked={user ? user.sellAuthorized : false} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </Row>
                                                    </div>
                                                </MuiCardContent>
                                            </MuiCard>
                                            </Col>
                                        </Row>
                                         * 
                                         */}
                                        
                                        {/**
                                         * <Row nowrap style={{paddingTop: 20}}>
                                            <Col >
                                            

                                                <MuiCard raised={true}>
                                                    <MuiCardContent>
                                                        <h5>Total Spent</h5>
                                                        {user && numberWithCommas(user.total_spend ? user.total_spend : 0)}
                                                    </MuiCardContent>
                                                    
                                                </MuiCard>
                                            </Col>
                                            <Col >
                                                
                                                <MuiCard raised={true}>
                                                    <MuiCardContent>
                                                        <h5>Total Earned</h5>
                                                        {user && numberWithCommas(user.total_earned ? user.total_earned : 0)}
                                                    </MuiCardContent>
                                                    
                                                </MuiCard>
                                            </Col>
                                        </Row>
                                         * 
                                         */}
                                        
                                        
                                        <Row nowrap >
                                            <Col>
                                                <MuiCard raised={true}>
                                                    <MuiCardContent>
                                                        
                                                        <Grid container item direction="row"  spacing={2} justify="space-around" alignItems="center">
                                                    
                                                           <Grid item>
                                                               {/**
                                                                * 
                                                                * 
                                                                
                                                                <MuiBtn
                                                                    variant="contained"
                                                                    style={{width: '160px'}}
                                                                    onClick={() => {
                                                                        if(user.emailConfirmed){
                                                                            setOpenEmailLinkPropose(true)
                                                                        }else{
                                                                            history.push('/verify?require_code=true')
                                                                        }
                                                                        
                                                                    } }
                                                                    className={classes.btn}
                                                                >
                                                                    <span style={{color: 'white', fontSize: '11px'}}>Create a payment</span>
                                                                </MuiBtn>
                                                                */}
                                                                <div style={{padding: '10px'}}>

                                                                                                                                    
                                                                    <div className="imgBtn" onClick={() => {
                                                                        if(user.emailConfirmed){
                                                                            history.push('/app/seller/proposal-create')
                                                                            setOpenEmailLinkPropose(true)
                                                                        }else{
                                                                            history.push('/verify?require_code=true')
                                                                        }
                                                                    }}>
                                                                        <img src={createSale} style={{width: '100%'}} />
                                                                        <div className="imgBtn-text" >
                                                                            <span>Create a Payment Request</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            
                                                            <Grid item>
                                                                <div style={{padding: '10px'}}>

                                                                        
                                                                    <div className="imgBtn" onClick={() => {
                                                                        if(user.emailConfirmed){
                                                                            setOpenProposalKey(true)
                                                                        }else{
                                                                            history.push('/verify?require_code=true')
                                                                        }
                                                                    }}>
                                                                        <img src={proposalKey} style={{width: '100%'}} />
                                                                        <div className="imgBtn-text" >
                                                                            <span>Use a proposal key</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            
                                                            
                                                        </Grid>
                                                    </MuiCardContent>
                                                </MuiCard>
                                            </Col>
                                       
                                        </Row>
                                        {/***
                                         * 
                                         * 
                                         * 
                                        
                                        <Row nowrap style={{paddingTop: 20}}>
                                            <Col>
                                                <MuiCard raised={true}>
                                                    <MuiCardContent>
                                                        <h5>Create Proposal</h5>
                                                        <Grid container item direction="row" style={{ paddingTop: 10 }} spacing={2} justify="space-around" alignItems="center">
                                                    
                                                            <Grid item>
                                                                <MuiBtn
                                                                    variant="contained"
                                                                    className={classes.btn}
                                                                    style={{width: '160px'}}
                                                                    onClick={() => {
                                                                        if(user.emailConfirmed){
                                                                            setProposeType('Email')
                                                                            setOpenEmailLinkPropose(true)
                                                                        }else{
                                                                            history.push('/verify?require_code=true')
                                                                        }
                                                                    } }
                                                                >
                                                                    <span style={{color: 'white', fontSize: '11px'}}>Propose via Email</span>
                                                                </MuiBtn>
                                                            </Grid>
                                                            <Grid item>
                                                                <MuiBtn
                                                                    variant="contained"
                                                                    style={{width: '160px'}}
                                                                    onClick={() => {
                                                                        if(user.emailConfirmed){
                                                                            setProposeType('Link')
                                                                            setOpenEmailLinkPropose(true)
                                                                        }else{
                                                                            history.push('/verify?require_code=true')
                                                                        }
                                                                        
                                                                    } }
                                                                    className={classes.btn}
                                                                >
                                                                    <span style={{color: 'white', fontSize: '11px'}}>Propose via Link</span>
                                                                </MuiBtn>
                                                            </Grid>
                                                            
                                                            
                                                        </Grid>
                                                    </MuiCardContent>
                                                </MuiCard>
                                            </Col>
                                            
                                        </Row>\ */}
                                        {mdBreak && (
                                            <>
                                                
                                                {/**
                                                 *     <Row nowrap style={{paddingTop: 20}}>
                                                    <Grid container item direction="row" style={{ paddingTop: 10 }} justify="space-around" alignItems="center">
                                                        <Grid item>
                                                            <MuiBtn
                                                                variant="outlined"
                                                                className={classes.btnOutlined}
                                                                style={{width: '100px'}}
                                                                onClick={() => {
                                                                    history.push('/app/create-sale');
                                                                } }
                                                            >
                                                                <span style={{color: '#FF873A'}}>Sell</span>
                                                            </MuiBtn>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiBtn
                                                                variant="contained"
                                                                style={{width: '100px'}}
                                                                onClick={() => {
                                                                    history.push('/app/create-offer');
                                                                    
                                                                } }
                                                                className={classes.btn}
                                                            >
                                                                <span style={{color: 'white'}}>Buy</span>
                                                            </MuiBtn>
                                                        </Grid>

                                                        


                                                    </Grid>
                                                    
                                                </Row>
                                                 */}
                                            
                                                
                                            </>
                                        )}
                                    </Col>
                                    {/**
                                     * 
                                     * {mdBreak && (
                                    <Col md={6}>
                                        <RecentActivity setLoadingOverlay={setLoadingOverlay} user={user} source={source} setOpenDialog={setOpenDialog} setDialogMessage={setDialogMessage} setOpenErrorDialog={setOpenErrorDialog} setErrorMessage={setErrorMessage}/>
                                    </Col>
                                    )}
                                     * 
                                    */}
                                    
                                    
                                    
                                    
                                </Row>
                                {
                                    
                                        <>
                                        {/**
                                         * <Row style={{paddingTop: 20}}>
                                            <Col>
                                                <MuiCard raised={true}>
                                                    <MuiCardContent>
                                                        <h5>Create Proposal</h5>
                                                        <Grid container item direction="row" style={{ paddingTop: 10 }} spacing={2} justify="space-around" alignItems="center">
                                                    
                                                            <Grid item>
                                                                <MuiBtn
                                                                    variant="contained"
                                                                    className={classes.btn}
                                                                    style={{width: '160px'}}
                                                                    onClick={() => {
                                                                        setProposeType('Email')
                                                                        setOpenEmailLinkPropose(true)
                                                                    } }
                                                                >
                                                                    <span style={{color: 'white', fontSize: '11px'}}>Propose via Email</span>
                                                                </MuiBtn>
                                                            </Grid>
                                                            <Grid item>
                                                                <MuiBtn
                                                                    variant="contained"
                                                                    style={{width: '160px'}}
                                                                    onClick={() => {
                                                                        setProposeType('Link')
                                                                        setOpenEmailLinkPropose(true)
                                                                    } }
                                                                    className={classes.btn}
                                                                >
                                                                    <span style={{color: 'white', fontSize: '11px'}}>Propose via Link</span>
                                                                </MuiBtn>
                                                            </Grid>
                                                            
                                                            
                                                        </Grid>
                                                    </MuiCardContent>
                                                </MuiCard>
                                            </Col>
                                        </Row>
                                         * 
                                         */}
                                        <Incoming setLoadingOverlay={setLoadingOverlay} user={user} classes={classes} source={source}/>
                                        <RecentActivity setLoadingOverlay={setLoadingOverlay} type={"SELL"} source={source} user={user} setOpenDialog={setOpenDialog} setDialogMessage={setDialogMessage} setOpenErrorDialog={setOpenErrorDialog} setErrorMessage={setErrorMessage}/>
                                        
                                        {/***
                                         * 
                                         * <Grid container item direction="row" style={{ paddingTop: 20 }} justify="space-around" alignItems="center">
                                            <Grid item>
                                                <MuiBtn
                                                    variant="outlined"
                                                    className={classes.btnOutlined}
                                                    style={{width: '100px'}}
                                                    onClick={() => {
                                                        history.push(user && user.sellAuthorized ? '/app/create-sale' : '/app/account-upgrade?send_documents=true');
                                                        
                                                    } }
                                                >
                                                    <span style={{color: '#FF873A'}}>Sell</span>
                                                </MuiBtn>
                                            </Grid>
                                            <Grid item>
                                                <MuiBtn
                                                    variant="contained"
                                                    style={{width: '100px'}}
                                                    onClick={() => {
                                                        history.push('/app/create-offer');
                                                        
                                                    } }
                                                    className={classes.btn}
                                                >
                                                    <span style={{color: 'white'}}>Buy</span>
                                                </MuiBtn>
                                            </Grid>
                                        </Grid>
                                         * 
                                         */}
                                        
                                    
                                        
                                        </>
                                    
                                }
                            
                                
                                
                                    
                            
                                
                            </div>
                    </Container>
                        
                        

                </div>
            </>
            <OTPDialog openDialog={openOTP} setOpenDialog={setOpenOTP} onFinish={payNow}/>
            <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={dialogMessage} />
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorMessage} />
            <ProposalViaEmailLink 
                classes={classes} 
                user={user} 
                setLoadingOverlay={setLoadingOverlay} 
                setOpenEmailLinkPropose={setOpenEmailLinkPropose}
                openEmailLinkPropose={openEmailLinkPropose}
                type={"SELL"}
            />



            <Dialog 
                className={classes.dialogPaper}
                fullWidth={true} 
                maxWidth={'sm'} 
                onClose={() => setOpenEmailVerif(false)} 
                open={openEmailVerif}
                PaperProps={{style: {
                backgroundColor: '#fee2b3',
                }}}
            >
                <DialogTitle style={{ paddingTop: 15 }}>
                    Email Verification
                    <img
                        src='https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png'
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={()=>setOpenEmailVerif(false)}/>
                </DialogTitle>
                    <div style={{margin: "10px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                        <div style={{ padding: 25 }}>
                            <Grid container item direction="column" spacing={2} justify="space-around" alignItems="center">
                                <Grid item>
                                    <TextInput 
                                        name='code'
                                        type='text'
                                        value={verifCode}
                                        onChange={(e: any)=>{ setVerifCode(e.target.value)}}
                                        placeholder='Code'
                                    />
                                </Grid>
                                <Grid item>
                                    <Button 
                                        color='primary'
                                        onClick={verifyEmail}
                                        className='create-sale-button'
                                    >
                                        Verify
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
            </Dialog>


            <Dialog 
                className={classes.dialogPaper}
                fullWidth={true} 
                maxWidth={'xs'} 
                open={openProposalKey}
                PaperProps={{style: {
                    backgroundColor: '#FF873A',
                    border: "1px solid #FF873A",
                    borderRadius: '8px'
                }}}
            >
                <DialogTitle className={classes.dialogTitle}>
                <span style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>Proposal Key</span>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={()=>{handleCloseProposalKey()}}/>
                </DialogTitle>
                <div style={{ padding: '15px', color: '#FF873A', backgroundColor: '#FF873A' }}>
                    <Grid direction="row" spacing={5} style={{backgroundColor: '#FF873A'}} justify="center" alignItems="center">
                        <Grid item xs={12} sm={6} style={{margin: 'auto'}}>
                            <TextLoginInput
                                name="transactLink"
                                onChange={(e) => { setTransactLink(e.target.value); } }
                                type='text'
                                value={transactLink}
                                placeholder='Enter Proposal Key' 
                            />
                        </Grid>
                        
                    </Grid>
                    <Grid direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <div style={{paddingTop:'20px', textAlign:'center'}}>
                                <Button 
                                    color='login-secondary'
                                    onClick={submitTransactLink}
                                    className='create-sale-button'
                                >
                                    Submit
                                </Button>
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
                
            </Dialog>


            {result !== null && (
                <>
            <Dialog 
            className={classes.dialogPaper}
            fullWidth={true} 
            maxWidth={'sm'} 
            onClose={handleClose} 
            open={result}
            PaperProps={{style: {
                backgroundColor: '#fee2b3',
                border: "1px solid #fee2b3",
                borderRadius: '20px'
            }}}
            >
                <DialogTitle style={{ paddingTop: 15 }}>
                    Transaction Details
                    <img
                        src='https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png'
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={handleClose}/>
                </DialogTitle>
                <div style={{margin: "10px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                    <div style={{ padding: 25 }}>
                        <Grid container item direction="column" spacing={2} justify="flex-start">
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    Reference:
                                </Grid>
                                <Grid item>
                                    {result.reference}
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                                                Status:
                                </Grid>
                                <Grid item>
                                    {result.status}
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                                    Transaction Name:
                                </Grid>
                                <Grid item>
                                    {result.name}
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    Proposed By: 
                                </Grid>
                                <Grid item>
                                    {result.proposal_type === "SELLING" ? merchantName : buyerName}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    Category:
                                </Grid>
                                <Grid item>
                                    {result.category ? result.category_name : "MERCHANDISE"} 
                                </Grid>
                            </Grid>
                            {/*result.payment_type && (
                                <Grid container item direction="row" justify="space-between" alignItems="center">
                                    <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                        Payment Type:
                                    </Grid>
                                    <Grid item>
                                        {result.payment_type ? result.payment_type_name : "MERCHANDISE"} 
                                    </Grid>
                                </Grid>
                            )*/}
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    {"Amount: "} 
                                </Grid>
                                <Grid item>
                                    {numberWithCommas(result.amount)}
                                </Grid>
                            </Grid>
                            
                            
                            {result.payment_type_name === "INSTALLMENT" && sourceIDSelected && (
                                <>
                                    <Grid container item direction="row" justify="space-between" alignItems="center">
                                        <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                                                        Terms:
                                        </Grid>
                                        <Grid item>
                                            {result.terms + " months"}
                                        </Grid>
                                    </Grid>
                                    {result.category_name === "RENT" && (
                                    <Grid container item direction="row" justify="space-between" alignItems="center">
                                        <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                                                                Transaction Fee:
                                        </Grid>
                                        <Grid item>
                                            {result ? numberWithCommas(result.transaction_fee) : null}
                                        </Grid>
                                    </Grid>
                                    )}
                                    
                                    {result.interest_charge > 0 && (
                                        <>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                                                                Interest:
                                            </Grid>
                                            <Grid item>
                                                {result.interest !== null ?  result.interest + "%" : null}
                                            </Grid>
                                        </Grid>
                                    
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                                Monthly Interest Fee: 
                                            </Grid>
                                            <Grid item>
                                                {result ? numberWithCommas(result.interest_charge.toFixed(2)/result.terms) : null}
                                            </Grid>
                                        </Grid>
                                        </>
                                    )}
                                    
                                </>
                            )}
                        
                        
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    Start Date:
                                </Grid>
                                <Grid item>
                                    {moment(result.created).format("MMMM D, YYYY h:mm A")}
                                </Grid>
                            </Grid>
                            {result.payment_type_name === "INSTALLMENT" || result.category_name === "RENT" && (
                                <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    End Date:
                                </Grid>
                                <Grid item>
                                    {moment(result.end_date).format("MMMM D, YYYY h:mm A")}
                                </Grid>
                            </Grid>
                            )}
                            {result.proposal_type === "SELLING" && (
                                <Grid container item direction="row" justify="space-between" style={{paddingBottom: 30}} alignItems="center">
                                    <Grid xs={3} sm={6} item style={{ fontWeight: 'bold', fontSize: '23px'}}>
                                        {result.category_name === "RENT" ? "Monthly Amortization: " : (result.payment_type_name === "INSTALLMENT" && sourceIDSelected) ? "Monthty Amortization: " : "Total Amount: "} 
                                    </Grid>
                                    <Grid item style={{ fontWeight: 'bold', fontSize: '23px', color: '#FF873A'}}>
                                        {result.category_name === "RENT" ? numberWithCommas(result.monthly) : (result.payment_type_name === "INSTALLMENT" && sourceIDSelected) ? numberWithCommas(result.monthly) : numberWithCommas(result.amount)}
                                    </Grid>
                                </Grid>
                            )}
                            
                            
                            {result.status !== "COMPLETE" && (
                                <>
                                    {result.status === "WAITING" && (
                                        <>
                                        
                                            <Grid container item justify="center" alignItems="center">
                                            <Grid item>
                                                <Button 
                                                    color='primary'
                                                    onClick={() => acceptProposal()}
                                                    className='create-sale-button'
                                                >
                                                    Accept
                                                </Button>
                                            </Grid>
                                            </Grid>
                                          
                                          
                                          
                                        </>
                                    )}
                                    {(result.status === "ACCEPTED" && !(source.length > 0)) && (
                                        <Grid container item justify="center" alignItems="center">
                                          <Grid item>
                                              <Button 
                                                  color='primary'
                                                  onClick={() => history.push('/app/vault-cards?add-card=true')}
                                                  className='create-sale-button'
                                              >
                                                  Add Source
                                              </Button>
                                          </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}

                            {openSource && (
                                <>
                                <Grid container direction="column" item justify="center" alignItems="center">
                                    <Grid item>
                                        <div className="label">
                                            Please select your Payment Source
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            id="demo-simple-select-autowidth"
                                            name="id"
                                            value={sourceID}
                                            style={{width: '200px'}}
                                            onChange={(e:any)=>{setSourceID(e.target.value);}}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Choose your source</em>
                                            </MenuItem>
                                            {cards !== null ? cards.map((card : any) => (
                                                <MenuItem key={card.id} onClick={()=> setSourceIDSelected(card.installmentAuthorized)} value={card.id} >{card.name}</MenuItem>
                                            )): null}
                                            
                                        </Select>
                                    </Grid>
                                
                                </Grid>
                                {result.category === 1 && sourceID && (
                                <Grid container item justify="center" alignItems="center">
                                    <Grid item>
                                        <div className="label">
                                            Please select your desired Payment Type
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            id="demo-simple-select-autowidth"
                                            name="category"
                                            value={paymentType}
                                            style={{width: '200px'}}
                                            onChange={(e: any)=>{handleCategoryChange(e.target.value);}}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Choose transaction type...</em>
                                            </MenuItem>
                                            
                                            {paymentTypeList && paymentTypeList.map((type: any) => (
                                                <MenuItem value={type.id}>{type.name}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                    </Grid>
                                </Grid>
                                )
                                }
                                {paymentType === 2 && (
                                <Grid container item justify="center" alignItems="center">
                                <Grid item>
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="terms"
                                        value={terms}
                                        style={{width: '200px'}}
                                        onChange={(e: any)=>{ setTerms(e.target.value);handleTermChange(e.target.value)}}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Choose monthly terms...</em>
                                        </MenuItem>
                                        
                                        <MenuItem value={'3'}>3</MenuItem>
                                        <MenuItem value={'6'}>6</MenuItem>
                                        <MenuItem value={'9'}>9</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                        
                                    </Select>
                                </Grid>
                                </Grid>
                                )}
                                <Grid container item justify="center" alignItems="center">
                                    <Grid item>
                                    {!sourceIDSelected && sourceID ? "Your card doesn't support installments" : ''}
                                    </Grid>
                                </Grid>
                                <Grid container item justify="center" alignItems="center">
                                <Grid item>
                                    <Button 
                                        color='primary'
                                        onClick={() => {
                                            if(!sourceIDSelected && (paymentType === 2 || result.payment_type === 2)){
                                                setErrorMessage("Your card doesn't support installments. Please upgrade your card in the Sources page.")
                                                setOpenErrorDialog(true)
                                              }else{
                                                setOpenOTP(true)
                                              }
                                        }}
                                        className='create-sale-button'
                                    >
                                        Pay Now
                                    </Button>
                                </Grid>
                                </Grid>
                                </>
                            )}



                        </Grid>
                    </div>
                </div>
            </Dialog>
            </>
            )}
            
                </>
        );
    }else{
        return(
            <div></div>
        )
    }
}