import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import createBrowserHistory from 'history/createBrowserHistory';
import { Button as CustomButton } from '../../components/Button';
//import {Container} from 'reactstrap';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import iosBadge from '../../assets/images/ios-badge.png';
import googleBadge from '../../assets/images/google-play-badge.png';
import ShopIcon from '@material-ui/icons/Shop';
import AppleIcon from '@material-ui/icons/Apple';

const logo = require('../../assets/images/4giveslogo.png');
const logoWhite = require('../../assets/images/4giveslogo_white.png');


const Footer: React.FC = () =>   {
    const matches = useMediaQuery('(max-width:960px)')
    const location = useLocation();
    const history = useHistory();

    return(
    <div style={{background: '#FF873A', margin: 'auto', padding: !matches ? '20px 60px' : '20px 20px 60px'}}>
        <Container>
          <Row justify="center" align="start" style={{padding: matches ? '0px 50px' : '0px'}}>
            <Col xs={12} sm={3}  >
              <Row ><span style={{color:"white", padding: '5px', cursor:'pointer'}} onClick={() => history.push('/')}>Home</span></Row>
              <Row ><span style={{color:"white", padding: '5px', cursor:'pointer'}} onClick={() => history.push('/merchant')}>4Gives for Sellers</span></Row>
              <Row ><span style={{color:"white", cursor: 'pointer', padding: '5px'}} 
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/faqs' : location.pathname.includes('merchant') ? '/merchant/faqs' : '/faqs' }`)
              }}>FAQs</span></Row>
              
            </Col>
            <Col xs={12} sm={3}>
              <Row ><span style={{color:"white", cursor: 'pointer', padding: '5px'}} 
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/terms-conditions' : location.pathname.includes('merchant') ? '/merchant/terms-conditions' : '/terms-conditions' }`)
              }}>Terms and Conditions</span></Row>
              <Row ><span style={{color:"white", cursor: 'pointer', padding: '5px'}} 
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/privacy-policy' : location.pathname.includes('merchant') ? '/merchant/privacy-policy' : '/privacy-policy' }`)
              }}>Privacy Policy</span></Row>
              <Row ><span style={{color:"white", cursor: 'pointer', padding: '5px'}} 
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/installment-agreement' : location.pathname.includes('merchant') ? '/merchant/installment-agreement' : '/installment-agreement' }`)
              }}>Installment Agreement</span></Row>
              
              <Row>
                <IconButton>
                  <FacebookIcon style={{fill:'white'}}/>
                </IconButton>
                <IconButton>
                  <InstagramIcon style={{fill:'white'}}/>
                </IconButton>
                <IconButton>
                  <ShopIcon style={{fill:'white'}}/>
                </IconButton>
                <IconButton>
                  <AppleIcon style={{fill:'white'}}/>
                </IconButton>
              </Row>
            </Col>
            {/** 
            <Col style={{paddingTop: '20px'}}>
              <Row style={{color:"white", cursor: 'pointer'}} 
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/terms-conditions' : location.pathname.includes('merchant') ? '/merchant/terms-conditions' : '/terms-conditions' }`)
              }}>Terms and Conditions</Row>
              <Row style={{color:"white", cursor: 'pointer'}}
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/privacy-policy' : location.pathname.includes('merchant') ? '/merchant/privacy-policy' : '/privacy-policy' }`)
              }}>Privacy Policy</Row>
              <Row style={{color:"white", cursor: 'pointer'}}
              onClick={() => {
                history.push(`${location.pathname.includes('buyer') ? '/faqs' : location.pathname.includes('merchant') ? '/merchant/faqs' : '/faqs' }`)
              }}>FAQs</Row>
            </Col>
            */}
            <Col xs={12} sm={3}>
              <Row>
             
                  <img src={iosBadge} style={{height: '100%', width: '180.00px', margin: 'auto'}}/>
                  
                  <img src={googleBadge} style={{height: '100%', width: '180.00px', margin: 'auto'}}/>
                  
              </Row>
            </Col>
            
            <Col xs={8} sm={3} >
              <Row align={matches ? 'center' : 'center'} justify={matches ? 'center' : 'center'}>
                <div style={{alignContent: 'center', justifyContent: 'center', textAlign:'center', paddingTop: '20px', color: 'white'}}>
                  <img src={logoWhite} style={{maxWidth: '200px', cursor:'pointer'}} onClick={() => history.push('/')} />
                  
                  <br />
                  
                </div>
                <br />
                <span style={{textAlign: 'right', color: 'white', fontSize: '14px', fontFamily: `'Montserrat', sans-serif`, paddingTop: '10px'}}>All Rights Reserved 2021</span>
              </Row>
                
                
                
            </Col>
          </Row>
        </Container>
      </div>
    )

}
export default Footer;