import React, { useState, useEffect } from 'react';
import './style.scss';
import {ReactComponent as PesoSign} from '../../assets/images/peso.svg';
import {
    IconPrefix,
    IconName
} from '@fortawesome/fontawesome-svg-core';
import Icon from '../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OnChangeType {
    name: string,
    type?: string,
    value?: any,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onDelete?: () => void,
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    expName1?: string;
    expName2?: string;
    expValue1?: any;
    id?: string,
    expValue2?: any;
    expOnChange1?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    expOnChange2?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    expOnKeyDown1?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    expOnKeyDown2?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    className?: string,
    placeholder?: string,
    disabled?: any
    icon?: { prefix: IconPrefix, name: IconName },
    errorMessage?: string,
    pesoIcon?: boolean
    length?: any;
    pattern?: any;
    ref?: any;
    
}

const FGIcon = (prefix: IconPrefix, name: IconName) => 
    <div className='fg-input-icon-container'>
        <FontAwesomeIcon 
            icon={Icon(prefix, name)} 
            className='fg-input-icon'
        />
    </div>

const LoginFGIcon = (prefix: IconPrefix, name: IconName) => 
    <div className='fg-input-icon-container-login'>
        <FontAwesomeIcon 
            icon={Icon(prefix, name)} 
            className='fg-input-icon'
        />
    </div>

export const TextInput: React.FC<OnChangeType> = ({ name, ref, type = 'text', id, value, onKeyDown, onChange, disabled, className, icon, length, errorMessage, placeholder, pesoIcon, pattern }) => 
    <div className="fg-input">
        { icon && FGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }

        <input 
            name={name}
            type={type}
            value={value || ''}
            ref={ref}
            id={id}
            disabled={disabled || false}
            onKeyDown={onKeyDown}
            onChange={onChange} 
            maxLength={length}
            pattern={pattern}
            className={`fg-textinput ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const TextContactInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, className, icon, errorMessage, placeholder, pesoIcon }) => 
    <div className="fg-input">
        { icon && FGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }
        <span style={{fontSize: 16, paddingTop: 1}}></span>
        <input 
            name={name}
            type={type}
            value={value}
            onChange={onChange} 
            maxLength={10}
            className={`fg-textinput ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const TextLoginInput: React.FC<OnChangeType> = ({ name, id, type = 'text', value, onChange, className, icon, errorMessage, placeholder, pesoIcon, onKeyDown, ref }) => 
    <div className="fg-inputlogin" >
        { icon && LoginFGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }

        <input 
            name={name}
            ref={ref}
            type={type}
            id={id}
            value={value}
            onChange={onChange} 
            style={{color: 'white'}}
            onKeyDown={onKeyDown}
            className={`fg-textinputlogin ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        
        {/**
         * 
         * color: white;
        border: '1px solid red';
        padding: '10px';
        border-radius: '10px';
        background-color: #ff4c4c;
         * 
         */}
        { errorMessage && <p className="errorMessage" style={{marginTop: '10px', backgroundColor: '#ff4c4c', padding: '10px', border: '1px solid red', borderRadius: '10px', color: 'white', fontSize: '12px'}}>{errorMessage}</p> }
    </div>

export const TextLoginCardNumberInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, className, icon, errorMessage, placeholder, pesoIcon, onKeyDown }) => 
    <div className="fg-cardinput" >
        { icon && LoginFGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }

        <input 
            name={name}
            type={type}
            value={value}
            onChange={onChange} 
            style={{color: 'white'}}
            onKeyDown={onKeyDown}
            pattern="[0-9]*"
            maxLength={16}
            className={`fg-cardtextinput ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        {/**
         * 
         * color: white;
        border: '1px solid red';
        padding: '10px';
        border-radius: '10px';
        background-color: #ff4c4c;
         * 
         */}
        { errorMessage && <p className="errorMessage" style={{marginTop: '10px', backgroundColor: '#ff4c4c', padding: '10px', border: '1px solid red', borderRadius: '10px', color: 'white', fontSize: '12px'}}>{errorMessage}</p> }
    </div>

export const TextLoginCardExpiryInput: React.FC<OnChangeType> = ({ className, icon, errorMessage, placeholder, pesoIcon, expName1, expName2, expValue1, expValue2, expOnChange1, expOnChange2, expOnKeyDown1, expOnKeyDown2 }) => 
    <div className="fg-cardinput" >
        { icon && LoginFGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }

        <div className="exp-wrapper-login">
            <input  
                className="exp-login"
                name={expName1}
                value={expValue1}
                onChange={expOnChange1}
                onKeyDown={expOnKeyDown1}
                id="month" 
                maxLength={2}
                pattern="[0-9]*" 
                inputMode="numeric" 
                placeholder="MM" 
                type="text" 
                data-pattern-validate 
            />
            <input  
                className="exp-login" 
                id="year"
                name={expName2}
                value={expValue2}
                onChange={expOnChange2}
                onKeyDown={expOnKeyDown2}
                maxLength={2}
                pattern="[0-9]*" 
                inputMode="numeric" 
                placeholder="YY" 
                type="text" 
                data-pattern-validate 
            />
        </div>

      
        {/**
         * 
         * color: white;
        border: '1px solid red';
        padding: '10px';
        border-radius: '10px';
        background-color: #ff4c4c;
         * 
         */}
        { errorMessage && <p className="errorMessage" style={{marginTop: '10px', backgroundColor: '#ff4c4c', padding: '10px', border: '1px solid red', borderRadius: '10px', color: 'white', fontSize: '12px'}}>{errorMessage}</p> }
    </div>

export const TextCardExpiryInput: React.FC<OnChangeType> = ({ className, icon, errorMessage, placeholder, pesoIcon, expName1, expName2, expValue1, expValue2, expOnChange1, expOnChange2, expOnKeyDown1, expOnKeyDown2 }) => 
    <div className="fg-cardinput" >
        { icon && LoginFGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }

        <div className="exp-wrapper">
            <input  
                className="exp"
                name={expName1}
                value={expValue1}
                onChange={expOnChange1}
                onKeyDown={expOnKeyDown1}
                id="month" 
                maxLength={2}
                pattern="[0-9]*" 
                inputMode="numeric" 
                placeholder="MM" 
                type="text" 
                data-pattern-validate 
            />
            <input  
                className="exp" 
                id="year"
                name={expName2}
                value={expValue2}
                onChange={expOnChange2}
                onKeyDown={expOnKeyDown2}
                maxLength={2}
                pattern="[0-9]*" 
                inputMode="numeric" 
                placeholder="YY" 
                type="text" 
                data-pattern-validate 
            />
        </div>

      
        {/**
         * 
         * color: white;
        border: '1px solid red';
        padding: '10px';
        border-radius: '10px';
        background-color: #ff4c4c;
         * 
         */}
        { errorMessage && <p className="errorMessage" style={{marginTop: '10px', backgroundColor: '#ff4c4c', padding: '10px', border: '1px solid red', borderRadius: '10px', color: 'white', fontSize: '12px'}}>{errorMessage}</p> }
    </div>

export const TextContactLoginInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, onKeyDown, className, icon, errorMessage, placeholder, pesoIcon }) => 
    <div className="fg-inputlogin" >
        { icon && LoginFGIcon(icon.prefix, icon.name) }
        { pesoIcon && 
            <div className='pesoSign'>
                <PesoSign />
            </div>
        }
        <span style={{color: 'white', paddingTop: 1}}>+63 </span>
        <input 
            name={name}
            type={type}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChange} 
            maxLength={10}
            style={{color: 'white'}}
            className={`fg-textinputlogin ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const NumberInput: React.FC<OnChangeType> = ({ onChange, className, ...props }) =>
    <input className={`fg-input fg-numberinput ${className || ''}`} {...props} />

export const FileUpload: React.FC<OnChangeType> = ({ name, type = 'file', value, onChange, onDelete, className, icon, errorMessage, ref }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [fileSizeError, setFileSizeError] = useState(false);

    return(
        <div className="fg-input fg-input-file">
            <input 
                id={name}
                name={name}
                type={type}
                value={value}
                ref={ref}
                className={`fg-fileupload ${className || ''}`} 
                accept="image/jpg,image/jpeg,image/png,application/pdf"
                onChange={e => {
                    console.log(e.target.files);
                    
                    if(e.target.files !== null){
                        console.log(e.target.files[0]);
                        
                    }
                    if(e.target.files && e.target.files.length !== 0) {
                        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                        var i = Math.floor(Math.log(e.target.files[0].size) / Math.log(1024));
                        const imageSize = Math.round(e.target.files[0].size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
                        console.log(imageSize, 'IMAGE TEST');
                        var FileSize = e.target.files[0].size / 1024 / 1024;
                        if(FileSize > 2){
                            setFileSizeError(true)
                        }else{
                            console.log('selected');
                            let theseFiles = [];
                            let uploadedFiles = Array.from(e.target.files);
                            theseFiles = [...uploadedFiles];
                            console.log(theseFiles)
                            setFiles(theseFiles);
                            onChange(e);
                        }
                       
                    }
                }} 
                multiple
            />

            { /*files && files.length > 0 &&
                <ul className='uploadList'>
                    { files.map((f, index) =>
                        <li key={`${index}_filelist`}>
                            { (f.type === 'image/png' || f.type === 'image/jpg' || f.type === 'image/jpeg') ?
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file-image')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            : f.type === 'image/pdf' ?
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file-pdf')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            :
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            }
                            <span>
                                {f.name.length > 20 ?
                                    `${f.name.substring(0, 10)}...${f.name.slice(-5)}`
                                    :
                                    f.name
                                }
                            </span>
                            <div className='removeUpload'
                                onClick={()=> {
                                    console.log('removing', f);
                                    const theFile = f;
                                    const theFiles = files;
                                    let newFiles = [];
                                    if(onDelete){
                                        onDelete();
                                    }
                                    newFiles = theFiles.filter(tf => tf.name !== theFile.name);
                                    setFiles(newFiles);
                                }}
                            >
                                x
                            </div>
                        </li>
                    )}
                </ul>
                            */}
            
            <label htmlFor={name}>
                { files.length === 0 ?
                    <>
                        <FontAwesomeIcon 
                            icon={Icon('fas', 'cloud-upload-alt')} 
                            className='fg-fileinput-icon'
                            size={"5x"}
                        />
                        <div className='fg-uploadbutton'>
                            UPLOAD
                        </div>
                    </> :
                    <div className='fg-uploadbutton'>
                        UPLOAD
                    </div>
                    /**
                    <div className='fg-uploadbutton'>
                        UPLOAD MORE
                    </div>
                     */
                }
            </label>
            { fileSizeError && <p className="errorMessage">File must be less than 2mb!</p>}
            { errorMessage && <p className="errorMessage">{errorMessage}</p> }
        </div>
    );
}

export const FileUploadLogin: React.FC<OnChangeType> = ({ name, type = 'file', value, onChange, onDelete, className, icon, errorMessage, ref }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [fileSizeError, setFileSizeError] = useState(false);

    return(
        <div className="fg-input fg-input-file-login">
            <input 
                id={name}
                name={name}
                type={type}
                value={value}
                ref={ref}
                className={`fg-fileupload ${className || ''}`} 
                accept="image/jpg,image/jpeg,image/png,application/pdf"
                onChange={e => {
                    console.log(e.target.files);
                    
                    if(e.target.files !== null){
                        console.log(e.target.files[0]);
                        
                    }
                    if(e.target.files && e.target.files.length !== 0) {
                        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                        var i = Math.floor(Math.log(e.target.files[0].size) / Math.log(1024));
                        const imageSize = Math.round(e.target.files[0].size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
                        console.log(imageSize, 'IMAGE TEST');
                        var FileSize = e.target.files[0].size / 1024 / 1024;
                        if(FileSize > 2){
                            setFileSizeError(true)
                        }else{
                            console.log('selected');
                            let theseFiles = [];
                            let uploadedFiles = Array.from(e.target.files);
                            theseFiles = [...uploadedFiles];
                            console.log(theseFiles)
                            setFiles(theseFiles);
                            onChange(e);
                        }
                       
                    }
                }} 
                multiple
            />

            { /*files && files.length > 0 &&
                <ul className='uploadList'>
                    { files.map((f, index) =>
                        <li key={`${index}_filelist`}>
                            { (f.type === 'image/png' || f.type === 'image/jpg' || f.type === 'image/jpeg') ?
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file-image')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            : f.type === 'image/pdf' ?
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file-pdf')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            :
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            }
                            <span>
                                {f.name.length > 20 ?
                                    `${f.name.substring(0, 10)}...${f.name.slice(-5)}`
                                    :
                                    f.name
                                }
                            </span>
                            <div className='removeUpload'
                                onClick={()=> {
                                    console.log('removing', f);
                                    const theFile = f;
                                    const theFiles = files;
                                    let newFiles = [];
                                    if(onDelete){
                                        onDelete();
                                    }
                                    newFiles = theFiles.filter(tf => tf.name !== theFile.name);
                                    setFiles(newFiles);
                                }}
                            >
                                x
                            </div>
                        </li>
                    )}
                </ul>
                            */}
            
            <label htmlFor={name}>
                { files.length === 0 ?
                    <>
                        <FontAwesomeIcon 
                            icon={Icon('fas', 'cloud-upload-alt')} 
                            className='fg-fileinput-icon'
                            size={"5x"}
                        />
                        <div className='fg-uploadbutton'>
                            UPLOAD
                        </div>
                    </> :
                    <div className='fg-uploadbutton'>
                        UPLOAD
                    </div>
                    /**
                    <div className='fg-uploadbutton'>
                        UPLOAD MORE
                    </div>
                     */
                }
            </label>
            { fileSizeError && <p className="errorMessage">File must be less than 2mb!</p>}
            { errorMessage && <p className="errorMessage">{errorMessage}</p> }
        </div>
    );
}
