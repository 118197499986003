import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, Grid, makeStyles, MenuItem, Select, Container } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './style.scss';
import Auth from '../../auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks';

const success = require('../../assets/images/4gives_additional icons-03.png');
const close = require('../../assets/images/close.png');

export const IdleLogoutDialog: React.FC<{
    openDialog: boolean,
    setOpenDialog?: (val: boolean) => void,
    onClose?: () => void,
    dialogMessage?: string | null,
    isProposal?: any | null,
    reference?: any | null,
    link?: any | null,
}> = ({openDialog, isProposal, reference}) => {
    const history = useHistory();
    const query = useQuery()
    const location = useLocation();
    useEffect(() => {
        if(openDialog){
            setTimeout(function(){ 
                Auth.logout(); 
                if(query.has('applying_for')){
                    window.location.href = window.location.origin+`/login${query.get('applying_for') === "buyer" ? '?page=login-page&applying_for=buyer' : query.get('applying_for') === "merchant"? '?page=login-page&applying_for=merchant' :  ''}`
                
                }else if(isProposal && reference){
                    window.location.href = window.location.origin+`/proposal/${reference}?page=login-page`
                }
                else{
                    window.location.href = window.location.origin+`/login${location.pathname.includes('buyer') ? '?page=login-page&applying_for=buyer' : location.pathname.includes('seller') || location.pathname.includes('merchant') ? '?page=login-page&applying_for=merchant' :  ''}`
                }
                
            }, 5000);
        }
        
    },[openDialog])
 
    return (
        <Dialog 
            open={openDialog} 
            fullWidth={true} 
            maxWidth={'sm'} 
            
            PaperProps={{
                style: {
                    backgroundColor: '#FF873A', 
                    border: "1px solid #FF873A",
                    borderRadius: '8px'
                },
            }}
        >
            <DialogTitle style={{color: 'green', textAlign: "center"}}>
                
                
                <div style={{paddingTop: 10, color: "white"}}>
                    Logout
                </div>
                    
                
            </DialogTitle>
            <Container>
               
                <div style={{padding: 20}}>
                    
                    <Grid container item direction="row" style={{backgroundColor: '#FF873A'}} alignContent="center" justify="center">
                        <Grid item style={{textAlign: 'center', color: 'white', padding: '15px'}}>
                            You have been away for 5 minutes. Logging you out...
                        </Grid>
                    </Grid>
                    
                </div>
                
                
            </Container>
        </Dialog>
    );
}
    