// @ts-nocheck
import {
    Backdrop,
    Container,
    Divider,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
    useTheme,
  } from '@material-ui/core';
  import React, { useState, useEffect, useCallback } from 'react';
  import './style.scss';
  import { useQuery } from '../../hooks';
  import {
    Button,
    SuccessDialog,
    ErrorDialog,
    TextLoginInput,
    TextLoginCardExpiryInput,
  } from '../../components';
  import Auth from '../../auth';
  import Cookies from 'js-cookie';
  import logo from '../../assets/images/4giveslogo.png';
  import { Col, Row } from 'react-grid-system';
  import { BounceLoader } from 'react-spinners';
  import { useHistory } from 'react-router';
  import { User } from '../../models';
  import error from '../../assets/images/error.png';
  
  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    backdrop: {
      zIndex: 1000,
      color: '#fff',
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    labelForm: {
      color: '#fffff',
    },
    multilineColor: {
      color: '#ececec',
      fontSize: '18px',
      fontWeight: 300,
    },
    select: {
      '&:before': {
        borderColor: '#ececec',
      },
      '&:after': {
        borderColor: '#ececec',
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid #ececec`,
      },
    },
    selectIcon: {
      fill: '#ececec',
    },
    dropdownStyle: {
      border: '1px solid #FF873A',
      borderRadius: '5%',
      backgroundColor: '#FF873A',
      color: 'white',
    },
    checkedIcon: {
      backgroundColor: '#106c58',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106c58',
      },
    },
  });
  
  const AppGcashPay: React.FC<any> = () => {
  
    const theme = useTheme();
  
    const query = useQuery();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userSources, setUserSources] = useState<any>([]);
    const [paymentTypeList, setPaymentTypeList] = useState<any | null>(null);
    const [transactData, setTransactData] = useState<any>(null);
  
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
  
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const matchesBelow = useMediaQuery(theme.breakpoints.up('lg'))
  
    const [terms, setTerms] = useState('3');
    const [paymentType, setPaymentType] = useState<any>('');
    const [sourceID, setSourceID] = useState<any>('');
    const [openDialog, setOpenDialog] = useState(false);
    const [cardActivated, setCardActivated] = useState(null);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');
    const [merchantName, setMerchantName] = useState<string>('');
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const [loggedEmail, setLoggedEmail] = useState('');
    const [sourceIDSelected, setSourceIDSelected] = useState(false);
  
    const [paymentForm, setPaymentForm] = useState(false);
    const [payAsGuest, setPayAsGuest] = useState(true);
  
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expMonth, setExpMonth] = useState('');
    const [expYear, setExpYear] = useState('');
    const [cvc, setCVC] = useState('');
  
    const handleCategoryChange = (val: any) => {
      if (val === 1) {
        setTerms('1');
        setPaymentType(val);
      } else {
        setTerms('3');
        setPaymentType(val);
      }
    };
  
    function numberWithCommas(x: any) {
      return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  
    const checkIfActivated = (results: any) => {
      const isInstall = results.map((data: any) => {
        if (data.installmentAuthorized) {
          return true;
        } else {
          return false;
        }
      });
      const test = isInstall.some((val: any) => {
        if (val === true) {
          return true;
        }
      });
      setCardActivated(test);
    };
  
    /**useEffect(() => {
          if(Auth.isAuthenticated() && userSources.length === 0 && !paymentTypeList){
              console.log("Run sources")
              User.get(
                  (resp) => {
                      setLoggedEmail(resp.email)
                  }
              )
              getSources()    
          }
              
      },[userSources, paymentTypeList])*/
  
    const getSources = () => {
      Auth.getSources(
        (resp) => {
          if (resp.results.length > 0) {
            setUserSources(resp.results);
            checkIfActivated(resp.results);
            Auth.getServiceTypes(
              (resp) => {
                setPaymentTypeList(resp.results);
              },
              (e) => {
                setErrorDialogMessage(e.error);
                setOpenErrorDialog(true);
              }
            );
          } else {
         
          }
        },
        (e) => {
          setErrorDialogMessage(e.error);
          setOpenErrorDialog(true);
        }
      );
    };
    function handleEnter(event:any) {
      if (event.keyCode === 13) {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
  }
  
    useEffect(() => {
      if (terms !== '' && transactData) {
        Auth.installmentCompute(
          transactData.amount,
          terms,
          (resp) => {
            setTotalAmount(resp.data);
          },
          (e) => {
            setLoadingOverlay(false);
            setErrorDialogMessage(e.error);
            setOpenErrorDialog(true);
          }
        );
      }
    }, [terms]);
  
    useEffect(() => {
      if (!transactData) {
        if (query.has('id')) {
          setLoadingOverlay(true);
          Auth.getTransactionCreateDetails(
            `?id=${query.get('id')}`,
            (resp) => {
              setTransactData(resp.data);
              setLoadingOverlay(false);
            },
            (e) => {
              setLoadingOverlay(false);
              setIsError(true);
              setErrorMsg(e.error);
            }
          );
        } else {
          setIsError(true);
          setErrorMsg('Transaction not found.');
        }
      }
    }, []);
  
    const submitLogin = useCallback(() => {
      setLoadingOverlay(true);
      Auth.login(
        email,
        password,
        (response) => {
          User.get((resp) => {
            setLoggedEmail(resp.email);
            getSources();
            setPaymentForm(true);
            setLoadingOverlay(false);
            setTransactData(prev => ({...prev, buyer:{...prev.buyer, email: resp.email} }))
            
          });
        },
        (e) => {
          setLoadingOverlay(false);
          setErrorDialogMessage(
            e.error
              ? e.error
              : e.non_field_errors
              ? e.non_field_errors
              : e.password
              ? 'Error in Password: ' + e.password
              : 'An error has been encountered.'
          );
          setOpenErrorDialog(true);
        }
      );
    }, [email, password]);
  
    const changeUser = () => {
      Auth.logout();
      setEmail('');
      setPassword('');
      setPaymentForm(false);
    };
  
    const payNow = (e: any) => {
      e.preventDefault()
      setLoadingOverlay(true);
          Auth.getTransactionGcashDetails(
            transactData.name,
            transactData.guest_name,
            transactData.buyer,
            transactData.address,
            transactData.amount,
            transactData.sub_merchant_id,
            transactData.sub_merchant_name,
            transactData.reference,
            transactData.success_redirect_url,
            transactData.failure_redirect_url,
            transactData.api_key,
            payAsGuest,
            (resp) => {
              window.location.href = resp.redirect_url;
            },
            (e) => {
              setLoadingOverlay(false);
              setErrorDialogMessage(e);
              setOpenErrorDialog(true);
            }
          );
    };
  
  
    const onKeyPress = (e: any, isPassword?: boolean) => {
      if(isPassword){
        setPassword(e.target.value)
      }
      if (e.keyCode === 13 && payAsGuest) {
        payNow(e)
      } 
      if(e.keyCode === 13 && email !== '' && isPassword){
        e.preventDefault()
        submitLogin()
      }
    }

    return (
      <>
        {loadingOverlay ? (
          <Backdrop className={classes.backdrop} open={loadingOverlay}>
            <BounceLoader color={'#FF873A'} />
          </Backdrop>
        ) : null}
        <div className="app-pay">
          <div style={{height: '-webkit-fill-available'}}>
            <Grid container style={{width: '100%', height: '100%', margin: 'auto'}} direction="column" justify="space-around" spacing={3}>
              <Grid container item xs={12} sm={isError ? 12 : 6} style={{width: matches ? '100vw' : '100vw', margin: 'auto'}} >
                <div className="app-pay-details-container" style={{border: isError ? '1px solid #d2d2d2' : 'none', padding: isError ? '30px 45px' : '0', width: isError ? '500px' : '100vw'}}>
                <br/>
                    <div className="app-pay-details-title">
                      <img src={logo} alt="" className="main_logo" />
                    </div>
                    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                      <Divider />
                    </div>
                  
                    {transactData && (
                      <div className="app-pay-details">
                        <Grid
                          container
                          item
                          direction="column"
                          spacing={2}
                          alignItems="center"
                          justify="center"
                        >
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Transaction Name:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                            {transactData.name}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                               Merchant name:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                           GCash
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Name:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                               {transactData.guest_name}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                             Email:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                                {transactData.buyer.email}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Amount:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                                {numberWithCommas(transactData.amount)}
                            </Grid>
                          </Grid>
  
                          {paymentType === 2 && sourceIDSelected && (
                            <>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Terms:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {terms}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Interest rate:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {`${totalAmount.interest}%`}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Interest charge per month:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {totalAmount.monthly_interest_charge}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Monthly:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {numberWithCommas(totalAmount.monthly)}
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </div>
                    )}
  
                    {isError && (
                      <>
                        <div className="app-pay-details">
                          <Grid
                            container
                            direction="column"
                            spacing={2}
                            alignItems="center"
                            justify="center"
                          >
                            <Grid item>
                              <img
                                src={error}
                                style={{ width: '100px', height: '100px' }}
                              />
                            </Grid>
                            <Grid item>{errorMsg}</Grid>
                          </Grid>
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                          <Button
                            color="login-primary"
                            onClick={() =>
                              transactData
                                ? window.location.replace(
                                    transactData.failure_redirect_url
                                  )
                                : window.location.replace(
                                    'https://dev.thetechsetter.com'
                                  )
                            }
                            className="create-sale-button"
                          >
                            Go to 4Gives
                          </Button>
                        </div>
                      </>
                    )}
                </div>
                
              </Grid>
              {/** 
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <Divider />
              </div>
              */}
              {!isError && (
              <Grid style={{backgroundColor: '#FF873A'}}>
                <br/>
                <br/>
                <br/>
               
            {transactData && (
              <div className="app-pay-container">
                {paymentForm ? (
                  <div className="app-pay-form">
                    <div className="app-pay-title">
                      <h4>Payment</h4>
                      <p style={{ margin: 0 }}>Logged in as {loggedEmail}</p>
                      <p
                        onClick={changeUser}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Change User
                      </p>
                      
                    </div>
                   
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justify="center"
                    >
                    
                      {paymentType === 2 && (
                        <Grid
                          container
                          direction="row"
                          item
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={4}>
                            <div style={{ color: 'white' }}>Terms</div>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Select
                              id="demo-simple-select-autowidth"
                              name="category"
                              value={terms}
                              className={classes.select}
                              inputProps={{
                                classes: {
                                  icon: classes.selectIcon,
                                  root: classes.labelForm,
                                },
                                className: classes.multilineColor,
                              }}
                              style={{ width: '200px' }}
                              onChange={(e: any) => {
                                setTerms(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>Choose transaction type...</em>
                              </MenuItem>
                              <MenuItem value={'3'}>3</MenuItem>
                              <MenuItem value={'6'}>6</MenuItem>
                              <MenuItem value={'9'}>9</MenuItem>
                              <MenuItem value={'12'}>12</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      )}
  
                      <Grid item xs={12}>
                        <Button
                          onClick={payNow}
                          color={'login-primary'}
                          className="login-button fg-button-block form-button"
                        >
                          Pay Now
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ) : payAsGuest ? (
                  
                  <div className="app-pay-form">
                    <div className="app-pay-title">
                   
                      <p>Please proceed to payment if all details are correct.</p>
                    </div>
                    
                    <form>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item xs={12}>
                        <Button
                          onClick={payNow}
                          color={'login-primary'}
                          className="login-button fg-button-wide-block form-button"
                        >
                          Pay Now
                        </Button>
                      </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    
                    </form>
                    <div style={{marginBottom: 270}}/>
                    <p
                        onClick={() => setPayAsGuest(false)}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Login instead?
                      </p>
                  </div>
                ) : (
                  <div className="app-pay-login-form">
                    <div className="app-pay-login-title">
                      <h4>Login</h4>
                      <p>Please login to proceed with your purchase</p>
                    </div>
                    <form>
                      <div className="form-container">
                        <TextLoginInput
                          name="login_email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={handleEnter}
                          placeholder="E-mail"
                          icon={{ prefix: 'far', name: 'envelope' }}
                        />
                      </div>
                      <div className="form-container">
                        <TextLoginInput
                          name="login_password"
                          type="password"
                          value={password}
                          onChange={(e) => {setPassword(e.target.value)}}
                          onKeyDown={(e)=>onKeyPress(e, true)}
                          placeholder="Password"
                          icon={{ prefix: 'fas', name: 'key' }}
                        />
                      </div>
  
                    </form>
                    <div className="form-container">
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              submitLogin();
                            }}
                            color={'login-primary'}
                            className="login-button fg-button-block form-button"
                          >
                            Log In
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography
                            style={{
                              textDecoration: 'underline',
                              textAlign: 'center',
                              paddingTop: '20px',
                            }}
                          >
                            <a
                              style={{ cursor: 'pointer', color: 'white' }}
                              href="https://dev.thetechsetter.com/login?page=registration-page&applying_for=buyer"
                              target="_blank"
                            >
                              Don't have an account? Click here!{' '}
                            </a>
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography
                            style={{
                              fontSize: '15px',
                              textAlign: 'center',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                            }}
                          >
                            OR{' '}
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              setPayAsGuest(true);
                            }}
                            color={'login-secondary'}
                            className="login-button fg-button-block form-button"
                          >
                            Pay as Guest
                          </Button>
                        </Col>
                      </Row>
                      
                    </div>
                  </div>
                )}
              </div>
            )}
            </Grid>
            )}
            </Grid>
            </div>
          
        </div>
        <SuccessDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          dialogMessage={dialogMessage}
        />
        <ErrorDialog
          openDialog={openErrorDialog}
          setOpenDialog={setOpenErrorDialog}
          dialogMessage={errorDialogMessage}
        />
      </>
    );
  };
  
  export default AppGcashPay;
  