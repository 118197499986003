import { Card, Dialog, DialogTitle, Divider, Grid, Select, MenuItem, makeStyles, CardHeader, CardContent, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import { result } from "lodash"
import { Button, ProposalCreateContent, ProposalDetailsContent, TextCardExpiryInput, TextInput } from "../../components"
import moment from "moment"
import React, { useContext, useState } from "react"
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import { useHistory, useLocation, useParams } from "react-router-dom"
import { SuccessDialog, ErrorDialog } from "../../components"
import Auth from "../../auth"
import {Container} from 'reactstrap';
import { useQuery } from "../../hooks";
import { useEffect } from "react";
import { OTPDialog } from "../../components/OTPDialog";
import { Alert } from "@material-ui/lab";
import { User } from "../../models";
import { useMediaQuery } from "@material-ui/core";
import './style.scss';

const useStyles = makeStyles({
    root: {
        borderColor: '#FF873A'
    },
    indicator: {
        backgroundColor: "#FF873A",
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
        paddingTop: 15, 
        alignSelf: 'center', 
        justifySelf: 'center', 
        color: '#FF873A'
    },
    select: {
        '&:before': {
            borderColor: '#FF873A',
        },
        '&:after': {
            borderColor: '#FF873A',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid #FF873A`
        }
    },
    
    selectIcon: {
        fill: '#ececec',
    },
    labelForm: {
        color: '#fffff',
    },
    cardEntry: {
      cursor: 'pointer',
      
    },
    btn: {
        color: 'white',
        background: '#FF873A',
        borderColor: '#FF873A',
        '&:hover': {
            backgroundColor: '#FF873A',
          },
    },
    btnOutlined: {
        color: '#FF873A',
        borderColor: '#FF873A',
        background: '#fff',
    },
    step: {
        color: '#FF873A !important',
        "& $completed": {
          color: "lightgreen !important"
        },
        "& $active": {
          color: "pink !important"
        },
        "& $disabled": {
          color: "red !important"
        }
    },
    divider: {
        backgroundColor: '#FF873A',
        border: "1px solid #FF873A",
    },
    active: {}, //needed so that the &$active tag works
    completed: {},
    checkedIcon: {
      backgroundColor: '#FF873A',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FF873A',
      },
    },
  });


interface ParamTypes{
    type: any | null;
}


export const ProposalCreate: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
    user: any | null,
}> = ({setLoadingOverlay, user}) => {

    const { type } = useParams<ParamTypes>();

    const classes = useStyles();
    
    return(
        <div className="page-content proposal-create">
            <Container>

                <div className="proposal-create-container">
                    <ProposalCreateContent 
                        classes={classes} 
                        user={user} 
                        setLoadingOverlay={setLoadingOverlay} 
                        type={type === "buyer" ? "BUY" : type === "seller" ? "SELL" : ''}
                    />
                </div>
                
            </Container>
        </div>
    )
}