import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {  Row, Col} from 'react-grid-system'
import { FileUpload, TextCardExpiryInput, TextInput, TextLoginCardExpiryInput} from '../../components/Input'
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { Button, ErrorDialog, SuccessDialog } from '../../components';
import { useForm, useFormFiles, useQuery } from '../../hooks';
import { User } from '../../models';
import Auth from '../../auth';
import './style.scss'
import {  Button as MuiButton, Divider, makeStyles, Dialog, DialogTitle, Grid, CardContent, Card, TableHead, Table, TableBody, TableCell, TableContainer, TableRow, MenuItem, Select, IconButton, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import { toBase64 } from '../../functions';
import {Alert, Container} from 'reactstrap';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPixel from 'react-facebook-pixel';

export const InstallmentUpgradeForm: React.FC<{
    setLoadingOverlay: (val: boolean) => void;
}> = ({setLoadingOverlay}) => {

    const {user, source, setSource} = useContext<any>(User.Context)

    const {category, cardID} = useParams<{category: any; cardID: any}>()

    const [id, setID] = useState<any>(null)

    const location = useLocation();

    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)
    const cardStatementRef = useRef<any | null>(null) 
    const query = useQuery();

    const [activateSource, setActivateSource] = useState<any | null>(null)
    const [editCardstatement, setEditCardstatement] = useState<any | null>(null);
    const [editCardLimit, setEditCardLimit] = useState<any | null>(null);

    const [agreement, setAgreement] = useState(false);

    const openCardStatement = () => {
        console.log('openID')
        cardStatementRef.current.click()
    }

    const history = useHistory();

    const [values, setValues] = useState({
        cardNumber: '',
        expMonth: '',
        expYear: '',
        CVC: '',
        cardCutoff: '',
        cardLimit: ''
    })

    const [imageChanged, setImageChanged] = useState(false);
    
    const [imgFiles, setImgFiles] = useState({
        Cardstatement: ''
    })

    useEffect(() => {
        if(!activateSource && id){
            Auth.getSource(
                id,
                (resp) => {
                    console.log(resp)
                    setEditCardstatement(resp.Cardstatement)
                    setEditCardLimit(resp.cardLimit)
                    setActivateSource(resp)
                },
                (e) => {
                    setError(e)
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                }
            )
        }
        if(!id && query.has('id')){
            setID(query.get('id'))
        }
    },[activateSource, id])

    const [previewCardStatement, setPreviewCardStatement] = useState('')

    const [errorCardNumber, setErrorCardNumber] = useState('')

    const resubmitDocuments = () => {
        setLoadingOverlay(true)
        if(!imageChanged){
            setError("Please upload a different card statement.")
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
        }else{
            Auth.activateAcc(
                activateSource.id,
                activateSource.reference_id,
                imgFiles.Cardstatement,
                '0',
                editCardLimit,
                (response) => {
                    console.log(response)
                    setDialogMsg("Card Activation Request has been resubmitted! Please allow up to 48 hours for verification.")
                    setOpenSuccess(true);
                    ReactPixel.trackCustom('Resubmit Installment Application')
                    setLoadingOverlay(false)
                },
                (e) => {
                    setError(e)
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
            )
        }
    }

    const handleFileChange = async (e: any) => {
        console.log(e)
        console.log(e.target.name)
        console.log(toBase64(e.target.files[0]))
        setImgFiles({
            ...imgFiles,
            [e.target.name]: await toBase64(e.target.files[0])
        })
    }

    const activateAccount = () => {
        setLoadingOverlay(true)
        if(parseInt(values.cardLimit) <= 0){
            setError('Card Limit must not be 0.')
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
        }else{

            
            Auth.activateAcc(
                activateSource.id,
                activateSource.reference_id,
                imgFiles.Cardstatement,
                '0',
                values.cardLimit,
                (response) => {
                    console.log(response)
                    setDialogMsg("Card Activation Request sent! Please allow up to 48 hours for verification.")
                    setOpenSuccess(true);
                    ReactPixel.trackCustom('Submit Installment Application')
                    setLoadingOverlay(false)
                },
                (e) => {
                    if(e.Cardstatement !== undefined){
                        setError(e.Cardstatement[0])
                    }else if(e.cardLimit !== undefined){
                        setError(e.cardLimit[0])
                    }
                    
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
            )
        }
    }

    const previewHandler = (e: any) => {
        handleFileChange(e)
        setPreviewCardStatement(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <div className="page-content installment-upgrade-form" style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', borderRadius: '20px'}}>
            <Container>
            <div style={{border: '1px solid #ececec', padding: 25, borderRadius: '5px'}}>
                {activateSource ? (
                    <>
                    <Row>
                        <Col sm={12} md={12}>
                            {activateSource  && activateSource.Cardstatement ? (
                                <div className="form-container" style={{textAlign: 'center'}}>
                                    <h6 style={{color: '#FF873A', fontStyle: 'bold', fontSize: '18px'}}>Credit Card Statement for {activateSource && activateSource.type+' - '+activateSource.name}</h6>
                                    <p style={{paddingBottom:'20px'}}>Send your latest credit card statement. Please upload a clear copy showing your Full Name, Credit Cart Number and Credit Limit. (Valid formats: JPG, PNG, PDF)</p>
                                    {editCardstatement !== null && (
                                            <Grid container item direction="row" alignItems="center" justify="center">
                                                <div className="image-container">
                                                    
                                                    <img className="image" src={editCardstatement ? editCardstatement : ''}/>
                                                    
                                                    <div onClick={openCardStatement} className='fg-uploadbutton'>
                                                        UPLOAD
                                                    </div>
                                                </div>
                                            </Grid>
                                            )}
                                    <div className="fileupload-hidden">
                                            {/***
                                             *   <FileUpload
                                                name='IDBack'
                                                onChange={handleFiles}
                                                ref={idBackRef}
                                                placeholder='Back ID'
                                            />
                                                */}
                                            
                                            <input 
                                                type="file" 
                                                ref={cardStatementRef} 
                                                name="Cardstatement" 
                                                accept="application/pdf, image/*"
                                                onChange={(e) => {
                                                    previewHandler(e); 
                                                    if(e.target.files !== null){
                                                        var FileSize = e.target.files[0].size / 1024 / 1024;
                                                        if(FileSize > 2){
                                                            setOpenErrorDialog(true)
                                                            setError("File must be below 2MB.")
                                                        }else{
                                                            setEditCardstatement(URL.createObjectURL(e.target.files[0]))
                                                            setImageChanged(true)
                                                        }
                                                    }
                                                }
                                                } 
                                            />
                                    </div>
                                </div>
                            ):(
                                <div className="form-container" style={{textAlign: 'center'}}>
                                    <h6 style={{color: '#FF873A', fontStyle: 'bold', fontSize: '18px'}}>Credit Card Statement for {activateSource && activateSource.type+' - '+activateSource.name}</h6>
                                    <p style={{paddingBottom:'20px'}}>Send your latest credit card statement. Please upload a clear copy showing your Full Name, Credit Cart Number and Credit Limit. (Valid formats: JPG, PNG, PDF)</p>
                                    
                                    {previewCardStatement !== '' && (
                                        <div className="image-container">
                                                    
                                            <img className="image" src={previewCardStatement !== '' ? previewCardStatement : ''}/>
                                            
                                        </div>
                                    )}
                                    
                                    <div className="fileupload">
                                        <FileUpload
                                            name='Cardstatement'
                                            onChange={(e) => {
                                                previewHandler(e)
                                            }}
                                            placeholder='Account Number'
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                            {/**
                             * 
                             * <Row>
                                <div className="form-container">
                                    <h6>Monthly Cut Off</h6>
                                    <TextInput 
                                        name='cardCutoff'
                                        onChange={(e) => setValues({...values, cardCutoff: e.target.value})}
                                        type='text'
                                        value={values.cardCutoff}
                                        placeholder='Monthly Cut Off'
                                    />
                                </div>
                            </Row>
                                * 
                                */}
                            
                            
                            
                    </Row>
                    <Row style={{justifyContent: 'center'}}>
                        <div className="form-container" style={{paddingTop: '20px', textAlign: 'center'}}>
                            <h6>Card Limit</h6>
                            <p>Input your current Credit Card limit as shown on the latest credit card statement.</p>
                            <p style={{paddingBottom:'20px'}}>This limit will be used to validate your 4Gives credit limit.</p>
                            <TextInput 
                                name='cardLimit'
                                onChange={(e) => {
                                    if(editCardLimit){
                                        if(e.target.value.match(/^\d+$/) && e.target.value !== '0'){
                                            setEditCardLimit(e.target.value)
                                        }else if(e.target.value === ''){
                                            setEditCardLimit(e.target.value)
                                        }
                                        
                                    }else{
                                        if(e.target.value.match(/^\d+$/) && e.target.value !== '0'){
                                            setValues({...values, cardLimit: e.target.value});
                                        }else if(e.target.value === ''){
                                            setValues({...values, cardLimit: e.target.value});
                                        }
                                        
                                    }
                                }}
                                
                                type='text'
                                value={editCardLimit ? editCardLimit : values.cardLimit}
                                placeholder='Card Limit'
                            />
                        </div>
                    </Row>
                    <div className="form-container" style={{margin: 'auto'}}>
                        <Row align="center" justify="center"  style={{justifyContent: 'center', paddingTop: '10px'}}>
                            
                           
                            <Col xs={12} sm={8} offset={{xs: 3, sm: 4}} style={{color: '#464646', textAlign:'left'}}>
                        
                                <Checkbox
                                    checked={agreement}
                                    onChange={() => setAgreement(!agreement)}
                                    name="checkedB"
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" style={{fill: '#464646'}} />}
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" style={{fill: '#FF873A'}} />
                                    }
                                />
                                    I have read and accept the <Link style={{color: '#0072ff', textDecoration: 'underline'}} to={`/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'merchant' : ''}/installment-agreement`}>installment agreement</Link>.
                                
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="activate-account-button">
                                
                                <Button 
                                    color='primary'
                                    onClick={activateSource && (activateSource.Cardstatement || activateSource.cardLimit) ? resubmitDocuments : activateAccount}
                                    className='fg-acountactivate-button'
                                    disabled={!agreement}
                                >
                                    {activateSource && (activateSource.Cardstatement || activateSource.cardLimit) ? 'Resubmit Application' : ' Submit Application'}
                                </Button>
                                
                                <p>
                                    Allow up to 48 hours for verification
                                </p>

                                
                            </div>
                        </Col>
                    </Row>
                    </>
                ) : (
                    <Grid direction="row" alignItems="center" justify="center">
                        <Grid item xs={12} style={{margin: 'auto', textAlign: 'center'}}>
                            <CircularProgress color="primary" style={{color: '#FF873A'}}/>
                        </Grid>
                    </Grid>
                )}
            </div>
            </Container>
            <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg} onClose={() => history.push(`/app/${location.pathname.includes('buyer') ? 'buyer' : location.pathname.includes('seller') ? 'seller' : ''}/vault-cards`)}/>
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </div>
    )
}