import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';

export const Button: React.FC<{ 
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
        color: string,
        className?: string,
        id?: string,
        disabled?: boolean
    }> = ({ color, children, onClick, className, id, disabled }) =>
    <button disabled={disabled} id={id} className={`fg-button fg-button-${color} ${className || ''}`} onClick={onClick}>
        { children }
    </button>

export const ButtonLink: 
    React.FC<{ url: string, className?: string }> = 
    ({ url, className, children }) =>
    <NavLink to={url} className={`button ${className}`}>
        { children }
    </NavLink>

export const Link: React.FC<{ url: string, className?: string}> =
    ({ url, className, children }) =>
    <NavLink to={url} className={`button-link ${className ? className : ''}`} >
        { children }
    </NavLink>
