import {
    Backdrop,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    makeStyles,
    MenuItem,
    NativeSelect,
    Radio,
    RadioGroup,
    Select,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect, useCallback, useContext, useRef, useMemo } from 'react';
import './style.scss';
import clsx from 'clsx';
import { useForm, useFormFiles, useQuery } from '../../hooks';
import {
  Button,
  SuccessDialog,
  ErrorDialog,
  TextLoginInput,
  TextContactLoginInput,
  IdleLogoutDialog,
} from '../../components';
import Auth from '../../auth';
import Cookies from 'js-cookie';
import logo from '../../assets/images/4giveslogo.png';
import { Col, Row } from 'react-grid-system';
import { BounceLoader } from 'react-spinners';
import { User } from '../../models';
import error from '../../assets/images/error.png';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Link, useHistory, withRouter, useLocation, useParams } from 'react-router-dom';
import MyURL from '../../services/url';
import ReactGA from 'react-ga';
import { Alert } from 'reactstrap';
import { FileUploadLogin, TextLoginCardExpiryInput, TextLoginCardNumberInput } from '../../components/Input';
import { banks } from '../../assets/banks';
import ReactInputVerificationCode from 'react-input-verification-code';
import { filter } from 'lodash';
import { cities } from '../../assets/cities';
import { provinces } from '../../assets/provinces';
import purchaseIcon from '../../assets/images/fork_screen icons/Buyer.png';
import merchantIcon from '../../assets/images/fork_screen icons/Seller.png';
import ReactPixel from 'react-facebook-pixel';
import { useIdleTimer } from 'react-idle-timer';

interface IRegErrorMsg {
  username: [string];
  email: [string];
  password1: [string];
  password2: [string];
}

interface IProfileErrorMsg {
  first_name: [string];
  last_name: [string];
  street: [string];
  contact_number: [string];
  street2: [string];
  barangay: [string];
  city: [string];
  region: [string];
  zip_code: [string];
}

interface ILogErrorMsg {
  non_field_errors: [string];
  email: [string];
  password: [string];
}
  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    backdrop: {
      zIndex: 1000,
      color: '#fff',
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    labelForm: {
      color: '#fffff',
    },
    multilineColor: {
      color: '#ececec',
      fontSize: '18px',
      fontWeight: 300,
    },
    select: {
      '&:before': {
        borderColor: '#ececec',
      },
      '&:after': {
        borderColor: '#ececec',
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid #ececec`,
      },
    },
    selectIcon: {
      fill: '#ececec',
    },
    dropdownStyle: {
      border: '1px solid #FF873A',
      borderRadius: '5%',
      backgroundColor: '#FF873A',
      color: 'white',
    },
    checkedIcon: {
      backgroundColor: '#106c58',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106c58',
      },
    },
  });
  
  const ProposalDetailsLogin: React.FC<any> = () => {
  
    const theme = useTheme();
    const query = useQuery()
    const [page, setPage] = useState<string | null>(query.get('page') === 'signup' ? 'registration-page' : query.get('page') === 'forgot-password' ?  'forgot-password' : query.has('page') ? query.get('page') :'login-page');
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { setUser, user, setBuyOrSellMode, buyOrSellMode, source, setSource } = useContext(User.Context);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [gender, setGender] = useState('M');
    const [response, setResponse] = useState(null);
    const [sessionID, setSessionID] = useState(null);
    const [smsCode, setSMSCode] = useState<string>('')
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    const idFrontRef = useRef<any | null>(null) 
    const idBackRef = useRef<any | null>(null) 
    const bankproofRef = useRef<any | null>(null) 
    
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const matchesBelow = useMediaQuery(theme.breakpoints.up('lg'))
    const [emailCode, setEmailCode] = useState('');

    const [merchantOpenDialog, setMerchantOpenDialog] = useState(false)
    const [merchantDialogMessage, setMerchantDialogMessage] = useState('')

    const {values, handleChange} = useForm({ 
        forgot_password_email: '',
        register_username: '',
        register_email: '',
        register_password: '',
        register_passwordConfirm: '',
        login_email: '',
        login_password: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        street: '',
        street2: '',
        barangay: '',
        city: '',
        region: '',
        zip_code: '',
        contact_number: '',
    });

    const defaultValues = [
        'forgot_password_email',
        'register_username',
        'register_email',
        'register_password',
        'register_passwordConfirm',
        'login_email',
        'login_password',
        'first_name',
        'last_name',
        'street',
        'street2',
        'barangay',
        'city',
        'region',
        'zip_code',
        'contact_number',
    ]

    const [seconds, setSeconds] = useState(30)
    const [canResend, setCanResend] = useState(false);

    const [errorProfile1, setErrorProfile1] = useState({
        errors: {
            first_name: '',
            last_name: '',
        }
    })

    const [errorProfile2, setErrorProfile2] = useState({
        errors: {
            street: '',
            region: '',
            city: '',
            zip_code: '',
            barangay: '',
        }
    })

    const [errorLogin, setErrorLogin] = useState({
        errors: {
            
        }
    })

    const [otp, setOtp] = useState(new Array(4).fill(""));

    const handleOTPChange = (element: any, index: any) => {
        if(isNaN(element.value)) return false;

        setOtp([...otp.map((d: any, idx: any) => (idx === index) ? element.value : d)])

        if(element.nextSibling){
            element.nextSibling.focus()
        }
    }


    const [verifySuccess, setVerifSuccess] = useState(false)
    const [verifSuccessMessage, setVerifSuccessMessage] = useState('')
    const [verifError, setVerifError] = useState(false)
    const [verifErrorMessage, setVerifErrorMessage] = useState('')

    const [errorNumber, setErrorNumber] = useState('')

    const [errorForgotPassword, setErrorForgotPassword] = useState('')

    const [completedProf1, setCompletedProf1] = useState(false);
    const [completedProf2, setCompletedProf2] = useState(false);
    const [completedContact, setCompletedContact] = useState(false);

    //MERCHANT STATES
    const [isMerchantRejected , setIsMerchantRejected] = useState(false);
    const [rejectNote, setRejectNote] = useState('')

    const [editIDFront, setEditIDFront] = useState<any>(null)
    const [editIDBack, setEditIDBack] = useState<any>(null)
    const [editBankproof, setEditBankproof] = useState<any>(null)
    const [editBankAccountNumber, setEditBankAccountNumber] = useState<any>(null);
    const [editBankCode, setEditBankCode] = useState<any>(null);

    const {files, handleFiles} = useFormFiles ({
        IDFront: '',
        IDBack: '',
        Bankproof: '',
    });

    const [agreements, setAgreements] = useState({
        terms: false,
        privacy: false,
        installment: false,
    })

    const [bankCode, setBankCode] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState(0);

    const [addCardNumber, setAddCardNumber] = useState('');
    const [addCardExpiry, setAddCardExpiry] = useState('');
    const [addCardExpiryMonth, setAddCardExpiryMonth] = useState('')
    const [addCardExpiryYear, setAddCardExpiryYear] = useState('')
    const [addCardCVC, setAddCardCVC] = useState('')

    const [signUpPassword2, setSignUpPassword2] = useState('')

    const [preview, setPreview] = useState({
        IDFront: '',
        IDBack: '',
        Bankproof: '',
    })

    const [loaded, setLoaded] = useState({
        IDFront: false,
        IDBack: false,
        Bankproof: false
    })
    

    const imageIDFrontRef = useRef<HTMLImageElement>(null);
    const imageIDBackRef = useRef<HTMLImageElement>(null);
    const imageBankproofRef = useRef<HTMLImageElement>(null);

    const onImageIDFrontLoaded = () => setLoaded({...loaded, IDFront: true})
    const onImageIDBackLoaded = () => setLoaded({...loaded, IDBack: true})
    const onImageBankproofLoaded = () => setLoaded({...loaded, Bankproof: true})

    useEffect(() => {
        const idFrontCurrent = imageIDFrontRef.current
        const idBackCurrent = imageIDBackRef.current
        const bankProofCurrent = imageBankproofRef.current

        if(idFrontCurrent && editIDFront){
            if(!editIDFront){
                idFrontCurrent.addEventListener('load', onImageIDFrontLoaded)
                return () => idFrontCurrent.removeEventListener('load', onImageIDFrontLoaded)
            }
        }
        if(idBackCurrent && editIDBack){
            if(!editIDBack){
                idBackCurrent.addEventListener('load', onImageIDBackLoaded)
                return () => idBackCurrent.removeEventListener('load', onImageIDBackLoaded)
            }
        }
        if(bankProofCurrent && editBankproof){
            if(!editBankproof){
                bankProofCurrent.addEventListener('load', onImageBankproofLoaded)
                return () => bankProofCurrent.removeEventListener('load', onImageBankproofLoaded)
            }
        }
        
    },[imageIDFrontRef, imageIDBackRef, imageBankproofRef, editIDFront, editIDBack, editBankproof])

    useEffect(() => {
        const idBackCurrent = imageIDBackRef.current
        
    },[imageIDBackRef])

    useEffect(() => {
        const bankProofCurrent = imageBankproofRef.current
        

    },[imageIDBackRef])

    const ImageIDFront = useMemo( () => {
        if(preview.IDFront !== '' || editIDFront){
            return (
                <>
                <img ref={imageIDFrontRef} src={preview.IDFront !== '' ? URL.createObjectURL(preview.IDFront) : editIDFront ? editIDFront: ''} className="spinner" style={{
                    border: '2px solid white', 
                    width: matches ? '150px' : '150px',
                    height: matches ? '150px' : '150px',
                    display: editIDFront ? 'inline-block' :loaded.IDFront ? 'inline-block' :'none'
                }}
                onLoad={() => setLoaded({...loaded, IDFront: true})}
                />
                {!editIDFront && (
                <div style={{background:'rgba(0, 0, 0, 0.19)', position: 'absolute', top:'0', left: '0', height:'150px', width:'150px', display: loaded.IDFront ? 'none' :'inline-block'}}>
                    <CircularProgress style={{fill: 'white', color: 'white', }}/>
                </div>
                )}
                </>
            );
        }
        else{
            return false
        }
    },[preview.IDFront, editIDFront,loaded.IDFront]);
  
    const ImageIDBack = useMemo( () => {
        if(preview.IDFront !== '' || editIDBack){
           
            return (
                <>
                <img ref={imageIDBackRef} src={preview.IDBack !== '' ? URL.createObjectURL(preview.IDBack) : editIDBack ? editIDBack : ''} className="spinner" style={{
                    border: '2px solid white', 
                    width: matches ? '150px' : '150px',
                    height: matches ? '150px' : '150px',
                    display: editIDFront ? 'inline-block' :loaded.IDBack ? 'inline-block' :'none'
                }}
                onLoad={() => setLoaded({...loaded, IDBack: true})}
                />
                {!editIDBack && (
                <div style={{background:'rgba(0, 0, 0, 0.19)', position: 'absolute', top:'0', left: '0', height:'150px', width:'150px', display: loaded.IDBack ? 'none' :'inline-block'}}>
                    <CircularProgress style={{fill: 'white', color: 'white', }}/>
                </div>
                )}
                </>
            );
        }
        else{
            return false
        }
    },[preview.IDBack, editIDBack, loaded.IDBack]);

    const ImageBankProof = useMemo( () => {
        if(preview.Bankproof !== '' || editBankproof){
            return (
            <>
                <img ref={imageBankproofRef} src={preview.Bankproof !== '' ? URL.createObjectURL(preview.Bankproof) : editBankproof ? editBankproof : ''} className="spinner" style={{
                    border: '2px solid white', 
                    width: matches ? '150px' : '150px',
                    height: matches ? '150px' : '150px',
                    display: editBankproof ? 'inline-block' : loaded.Bankproof ? 'inline-block' : 'none'
                }}
                onLoad={() => setLoaded({...loaded, Bankproof: true})}
                />
                {!editBankproof && (
                <div style={{background:'rgba(0, 0, 0, 0.19)', position: 'absolute', top:'0', left: '0', height:'150px', width:'150px', display: loaded.Bankproof ? 'none' :'inline-block'}}>
                    <CircularProgress style={{fill: 'white', color: 'white', }}/>
                </div>
                )}
            </>);
        }
        
        else{
            return false
        }
    },[preview.Bankproof, editBankproof, loaded.Bankproof]);
    

    const openIDFrontRef = () => {
        idFrontRef.current.click()
    }
    const openIDBackRef = () => {
        idBackRef.current.click()
    }
    const openBankproofRef = () => {
        bankproofRef.current.click()
    }

    const [openIdle, setOpenIdle] = useState(false);

    const handleOnIdle = (event: any) => {
        console.log(getRemainingTime())
        console.log(getElapsedTime());
        if(user){
            setOpenIdle(true)
        }
        
    }

    const handleOnActive = (event: any) => {
        console.log(getElapsedTime());
    }

    const handleOnAction = (event: any) => {
        console.log('user did something', event)
        console.log(getRemainingTime() / 1000 )
        console.log(getElapsedTime()/ 1000);
    }


    const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
        timeout: 300000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(() => {
        if(page === 'registration-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User signing up'
            })
        }else if (page === 'profile1-page' || page === 'profile2-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User setting up details'
            })
        }else if (page === 'contact-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User setting up contact'
            })
        }
        else if (page === 'contact-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User in fork page'
            })
        }
        else if (page === 'apply-merchant-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User in merchant application page'
            })
        }
        else if (page === 'add-card-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User in buyer application page'
            })
        }
        else if (page === 'sms-verify'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User verifying SMS'
            })
        }else if (page === 'email-verif-page'){
            ReactGA.event({
                category: 'Sign Up',
                action: 'User verifying email'
            })
        }
    },[page])

    useEffect(() => {
        if(page === "sms-verify"){

        
            if(seconds > 0){
                setTimeout(() => {
                    setSeconds(seconds - 1)
                },1000)
            }else{
                setCanResend(true)
            }
        }
        
    },[canResend, seconds, page])

    const resetErrors = () => {
        setRegErrorMSG({
            username: [''],
            email: [''],
            password1: [''],
            password2:[''],
        })

        setLogErrorMSG({
            non_field_errors: [''],
            email: [''],
            password: [''],
        })

        setErrorProfile1({
            errors: {
                first_name: '',
                last_name: '',
            }
        })

        setErrorProfile2({
            errors: {
                street: '',
                region: '',
                city: '',
                zip_code: '',
                barangay: '',
            }
        })

        setErrorNumber('')

        setErrorForgotPassword('')

        setSignUpPassword2('');
    }

    useEffect(() => {
        if(page){
            resetErrors();
        }
    }, [page])

    const [regErrorMSG, setRegErrorMSG] = useState<IRegErrorMsg>({
        username: [''],
        email: [''],
        password1: [''],
        password2:[''],
    });

    const [profileErrorMSG, setProfileErrorMSG] = useState<IProfileErrorMsg>({
        first_name: [''],
        contact_number: [''],
        last_name: [''],
        street: [''],
        street2: [''],
        barangay: [''],
        city: [''],
        region: [''],
        zip_code: [''],
    })

    const [logErrorMSG, setLogErrorMSG] = useState<ILogErrorMsg>({
        non_field_errors: [''],
        email: [''],
        password: [''],
    });
    
    useEffect(() => {
        if(user){
            console.log(user)
            if(user.rejection_notes.length > 0){
                let test = user.rejection_notes.filter((reject: any) => reject.type === "sell_authorize_rejected")
                console.log(test)
                if(test.length > 0){
                    setIsMerchantRejected(test.length > 0)
                    setRejectNote(test[0].note)
                }
                
                
            }
            setEditIDFront(user.IDFront)
            setEditIDBack(user.IDBack)
            setEditBankproof(user.Bankproof)
            setEditBankAccountNumber(user.bankAccountNumber)
            setEditBankCode(user.bankCode)
            
        }
    },[user])

    const subscribe = async () => {
        if(user) {
            let events: any = new EventSource(MyURL.EVENTS.APPLICATION+user.id);
            console.log("SUBSCRIBING")
            events.onmessage = (event: any) => {
              switch (event.type) {
                case 'message':
                  const data = JSON.parse(event.data);
                  console.log(data)
                   /*const updateActivities = [...activities]
                  updateActivities.push(data)
                  console.log(updateActivities);
                  setActivities(updateActivities);
                  */
                 if(data.event === "application" && data.action === "redirect"){
                    window.location.href = window.location.origin + '/app/seller/my-account';
                 }else if(data.event === "application" && data.action === "re-render"){
                    User.get(
                        (resp) => {
                            console.log(resp)
                            User.getSource((cardData) => {
                                setSource(cardData.data.results)
                                setUser(resp);
                                if(resp.emailConfirmed && page === 'email-verif-page'){
                                    if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                        //history.push('/app/my-account')
                                        setLoadingOverlay(false)
                                        console.log("SUBSCRIME EMAILLLL")
                                        //changePage('fork-page')
                                        if(receiverType === "BUY"){
                                            console.log("BUYER SIGNUP")
                                            changePage('add-card-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }
                                        else if(receiverType === "SELL"){
                                            console.log("MERCHANT SIGNUP")
                                            changePage('apply-merchant-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }else{
                                            changePage('fork-page');
                                        }
                                       
                                       
                                    }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }else if(!resp.contact_number && !resp.sms_verified){
                                        setLoadingOverlay(false)
                                        changePage('contact-page')
                                    }
                                    
                                    else{
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }
                                }
                            })
                        }
                    )
                 }else{
                    events.close()
                 }
                 
                  break;
              }
            };
    
            events.onerror = (e: any) => {
                console.log(e)
                events.close()
                events = null;
            }
            if(location.pathname.includes('my-account') || page !== 'pending-page'){
                console.log('CLOSING')
                events.close();
                events = null;
            }
            return () => {
                events.close();
              };
        }
        
    };

    const emailSubscribe = async (id?: any) => {
        if(user) {
            let events: any = new EventSource(MyURL.EVENTS.APPLICATION+(id !== undefined ? id :user.id));
            console.log("SUBSCRIBING")
            events.onmessage = (event: any) => {
              switch (event.type) {
                case 'message':
                  const data = JSON.parse(event.data);
                  console.log(data)
                   /*const updateActivities = [...activities]
                  updateActivities.push(data)
                  console.log(updateActivities);
                  setActivities(updateActivities);
                  */
                 if(data.event === "application" && data.action === "re-render"){
                    User.get(
                        (resp) => {
                            console.log(resp)
                            User.getSource((cardData) => {
                                setSource(cardData.data.results)
                                setUser(resp);
                                if(resp.emailConfirmed && page === 'email-verif-page'){
                                    if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                        //history.push('/app/my-account')
                                        setLoadingOverlay(false)
                                        //changePage('fork-page')
                                        setIsEmailConfirmed(resp.emailConfirmed)
                                        console.log("SUBSCRIME EMAILLLL")
                                        if(receiverType === "BUY"){
                                            console.log("BUYER SIGNUP")
                                            changePage('add-card-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }
                                        else if(receiverType === "SELL"){
                                            console.log("MERCHANT SIGNUP")
                                            changePage('apply-merchant-page')
                                            if(resp.buyAuthorized){
                                                console.log("Buy View")
                                                window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                            }else if(resp.sellAuthorized){
                                                console.log("Sell View")
                                                window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                            }else{
                                                console.log("Fork screen")
                                            }
                                        }else{
                                            changePage('fork-page');
                                        }
                                       
                                       
                                    }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }else if(!resp.contact_number && !resp.sms_verified){
                                        setLoadingOverlay(false)
                                        changePage('contact-page')
                                    }
                                    
                                    else{
                                        setLoadingOverlay(false)
                                        changePage('profile1-page')
                                    }
                                }
                            })
                        }
                    )
                 }else{
                    events.close()
                 }
                 
                  break;
              }
            };
    
            events.onerror = (e: any) => {
                console.log(e)
                events.close()
                events = null;
            }
            if(location.pathname.includes('my-account') || page !== 'email-verif-page'){
                console.log('CLOSING')
                events.close();
                events = null;
            }
            return () => {
                events.close();
              };
        }
        
    };

    useEffect(() =>{
        if(page !== query.get('page')){
            history.push(`/proposal/${reference}?page=${page}`)
        }
        if(page === 'pending-page'){
            subscribe();
        }else if(page === 'email-verif-page' && user){
            
            emailSubscribe(user.id);
             
            
        }
    },[page, user])

    useEffect(() => {
        if(Auth.isAuthenticated()){
            User.get(
                (resp) => {
                    setUser(resp)
                }
            )
        }else{
            //window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
            //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
            changePage('login-page')
            //Auth.logout();
        }
    },[])

    const getRegErrorMsg = useCallback((field) => {
        const key: (keyof IRegErrorMsg) = field;
        if(regErrorMSG.hasOwnProperty(field)){
            return regErrorMSG[key][0];
        }
    }, [regErrorMSG]);

    const getProfileErrorMsg = useCallback((field) => {
        const key: (keyof IProfileErrorMsg) = field;
        if(profileErrorMSG.hasOwnProperty(field)){
            return profileErrorMSG[key][0]
        }
    }, [profileErrorMSG])

    const getLogErrorMsg = useCallback((field) => {
        const key: (keyof ILogErrorMsg) = field;
        if(logErrorMSG.hasOwnProperty(field)){
            return logErrorMSG[key][0];
        }
    }, [logErrorMSG]);

    const resetLoginRegistration = () => {
        /*
        defaultValues.map((name: any) => {
            handleChange({
                target: {
                    name: [name],
                    value: ''
                },
            })
        })*/
        
        handleChange({
            target: {
                name: 'login_password',
                value: ''
            },
        })
        handleChange({
            target: {
                name: 'login_email',
                value: ''
            },
        })
        handleChange({
            target: {
                name: 'register_email',
                value: ''
            },
        })
        handleChange({
            target: {
                name: 'register_password',
                value: ''
            },
        })
        handleChange({
            target: {
                name: 'register_passwordConfirm',
                value: ''
            },
        })
        handleChange({
            target: {
                name: 'register_username',
                value: ''
            },
        })
    }

    const changePage = useCallback((thePage: string) => {
        setPage(thePage);
    }, []);

    const validatePage1 = (e: any) => {
        e.preventDefault();
        resetErrors();
        let hasErrors = false;
        let errors = {
            first_name: '',
            last_name: '',
        }
        if(values.first_name === '' ){
            errors.first_name = 'This field must not be blank';
            hasErrors = true;
        }
        if(values.last_name === ''){
            errors.last_name = 'This field must not be blank';
            hasErrors = true;
        }
      
        
        if(hasErrors){
            console.log(errors);
            setErrorProfile1({errors});
        }else{
            setErrorProfile1({errors});
            setCompletedProf1(true)
        }
    }

    useEffect(() => {
        if(completedProf1){
            changePage('profile2-page')
        }
    },[completedProf1])

    useEffect(() => {
        if(completedContact){
            Auth.updateNumber(
                values.contact_number,
                (resp) => {
                    console.log(resp)
                    sendOTP()
                },
                (e) => {
                    console.log(e)
                }
            )
        }
    },[completedContact])
    
    const validateNumber = () => {
        resetErrors();
        let hasErrors = false; 
        let errors = {
            contact_number: ''
        }
        if(!values.contact_number.match(/^(9)\d{9}$/)){
            errors.contact_number = 'This field cannot be invalid'
            hasErrors = true;
        }

        if(hasErrors){
            console.log(errors);
            setErrorNumber(errors.contact_number);
        }else{
            setErrorNumber(errors.contact_number);
            setCompletedContact(true)
        }
    }
    
    useEffect(() => {
        if(completedProf2){
            saveProfile();
        }
    },[completedProf2])

    const validatePage2 = (e: any) => {
        e.preventDefault();
        resetErrors();
        let hasErrors = false;
        let errors = {
            street: '',
            region: '',
            city: '',
            zip_code: '',
            barangay: '',
        }

        if(values.street === '' || values.street.length > 200){
            hasErrors = true;
            errors.street = "This field must not be empty nor exceed 200 characters";
        }
        
        if(values.region === ''){
            hasErrors = true;
            errors.region = "This field must not be blank";
        }
        if(values.city === ''){
            hasErrors = true;
            errors.city = "This field must not be blank";
        }
        if(values.zip_code === ''){
            hasErrors = true;
            errors.zip_code = "This field must not be blank";
        }
        if(values.barangay === ''){
            hasErrors = true;
            errors.barangay = "This field must not be blank";
        }

        if(hasErrors){
            setErrorProfile2({errors})
        }else{
            setCompletedProf2(true)
        }
    }

   

    const saveProfile = () => {
        
        Auth.updateProfile(
            values.first_name,
            values.middle_name,
            values.last_name,
            gender,
            values.street,
            values.street2,
            values.region,
            values.city,
            values.barangay,
            values.zip_code,
            (response) => {
                console.log(response)
                if(!response.sms_verified){
                    changePage('contact-page');
                }/*else if(!response.emailConfirmed){
                    emailSubscribe(response.id);
                    changePage('email-verif-page')
                }*/else{
                    if(receiverType){
                        if(receiverType === "BUY"){
                            console.log("BUYER LOGIN")
                            if(response.buyAuthorized){
                                window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` 
                            }else{
                                changePage('fork-page')
                            }
                        }
                        else if(receiverType === "SELL"){
                            console.log("MERCHANT LOGIN")
                            if(response.buyAuthorized){
                                window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` 
                            }else{
                                changePage('fork-page')
                            }
                        }
                    }else{
                        if(response.sellAuthorized){
                            console.log("Sell View")
                            setBuyOrSellMode("sell")
                            window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                            
                        }else if(response.buyAuthorized){
                            console.log("Buy View")
                            setBuyOrSellMode("buy")
                            window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                        }else{
                            console.log("Fork screen")
                            changePage('fork-page')
                        }
                    }
                }
                
            },
            (e)=> {
                console.log(e)
            }
        )
    }

    useEffect(() => {
        if(response !== null) {
            changePage('profile1-page');
        }
    },[response])

    const submitRegistration = useCallback(() => {
        setLoadingOverlay(true)
        
        resetErrors()
        Auth.register(
            values.register_email.substr(0, values.register_email.indexOf('@')), 
            values.register_email,
            values.register_password,
            values.register_passwordConfirm,
            (response) => {
                console.log(response);
                setResponse(response.key);
                User.get(
                    (resp) => {
                        setUser(resp)
                    }
                )
                Auth.paymayaInit();
                changePage('email-verif-page');
                setLoadingOverlay(false);
                //history.push('/my-account')
                
                /*Auth.login(
                    values.register_email,
                    values.register_password,
                    (response) => {
                        setLoadingOverlay(false)
                        console.log(response)
                        
                    },
                    (e) => {
                        setLoadingOverlay(false)
                        setRegErrorMSG(e);
                    }
                )*/
                
            },
            (e) => {
                setLoadingOverlay(false)
                setRegErrorMSG(e);
                if(values.register_password !== values.register_passwordConfirm){
                    setSignUpPassword2(`Those passwords didn't match. Try Again.`)
                }
                if(e[0]){
                    setSignUpPassword2(e[0])
                }
            }
        );
    }, [
        values.register_username, 
        values.register_email, 
        values.register_password, 
        values.register_passwordConfirm, 
    ]);

    const sendOTP = () => {
        Auth.initSMSVerification(
            '+63'+values.contact_number,
            (resp) => {
                if(resp.response.error){
                    setOpenErrorDialog(true)
                    setErrorDialogMessage(resp.response.error)
                    setCompletedContact(false)
                    console.log(resp.response.error)
                }else{
                    setSessionID(resp.response.sessionId)
                    setSeconds(30);
                    setLoadingOverlay(false)
                    changePage('sms-verify')
                }
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorDialogMessage(e.error)
                console.log(e)
            }
        )
    }

    const smsVerify = () => {
        Auth.smsVerification(
            sessionID,
            otp.join(""),
            (resp) => {
                console.log(resp)
                
                if(resp.response.status === "WAITING"){
                    setOpenErrorDialog(true)
                    setErrorDialogMessage("Error verifying SMS")
                }else if(resp.response.status === "EXPIRED"){
                    setOpenErrorDialog(true)
                    setErrorDialogMessage("OTP Code has expired. Please resend again.")
                }else{
                    Auth.updateNumber(
                        values.contact_number,
                        (resp) => {
                            setOpenDialog(true);
                            setDialogMessage('SMS Verification completed!')
                        },
                        (e) => {
                            setOpenErrorDialog(true)
                            setErrorDialogMessage(e.error)
                            console.log(e)
                        }
                    )
                    
                }
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorDialogMessage(e)
                console.log(e)
            }
        )
    }

    const addCard = () => {
        setLoadingOverlay(true)
        //Auth.paymayaInit();

        var res = addCardExpiry.split('/')
        Auth.tokenize_card(
            addCardNumber,
            addCardExpiryMonth,
            '20'+addCardExpiryYear,
            addCardCVC,
            (r) => {
                Auth.addCard(
                    r.paymentTokenId,
                    proposalData.id,
                    (resp) => {
                        console.log('card added successfully');
                        console.log(resp);
                        window.location.replace(resp.paymaya_response.verificationUrl)
                        /*setDialogMsg('Your card has been added successfully!');
                        setOpen(false);
                        setOpenSuccess(true);*/
                        setLoadingOverlay(false)
                    },
                    (e) => {
                        console.log(e);
                        setErrorDialogMessage("An error has occured");
                        setOpenErrorDialog(true)
                        setLoadingOverlay(false)
                    }
                )
                
            },
            (e) => {
                console.log(e);
                setErrorDialogMessage(e.message);
                let [cardNumb] = e.parameters.filter((resp: any) => resp.field === 'card.number') 
                if(cardNumb !== undefined){
                    setErrorDialogMessage(cardNumb.description)
                }
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
            }
        )
    }

    useEffect(() => {
        if(page === 'login-page' || page === 'registration-page'){
            //Auth.logout();
        }else if(Auth.isAuthenticated()){
            User.get(
                (resp) => {
                    setUser(resp)
                    setIsEmailConfirmed(resp.emailConfirmed)
                }
            )
        }
    },[])


    useEffect(() => {
        console.log(preview)
    },[preview])

    
    const memoIDFront = useMemo(() => {
        return preview.IDFront
    },[preview.IDFront])


    const memoBankAcctNum = useMemo(() => {
        return bankAccountNumber
    },[bankAccountNumber])

    const previewHandler = useCallback(async (e: any) => {
        if(e.target.files.length !== 0){
            let name: string = e.target.name;
            let newFile = e.target.files[0]
            /*let newObj = {
                ...preview,
                [name] : newFile
            }
            preview = newObj
            console.log(preview)
            console.log(newObj)
        */
            setPreview({
                ...preview,
                [name]: newFile
            })
        }
    },[preview])

    function handleEnter(event:any) {
        if (event.keyCode === 13) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
    }

    const deletePreview = (name: any) =>{
        /*preview ={
            ...preview,
            [name]: ''
        }*/
    }

    const sendUpdatedRequest = (e: any) => {
        e.preventDefault()
        setLoadingOverlay(true)
        /*Auth.upgradeAcc(
            files.IDFront,
            files.IDBack,
            files.Bankproof,
            editBankCode,
            editBankAccountNumber,
            (response) => {
                console.log(response)
                setDialogMsg("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                setOpenSuccess(true)
                User.get((userdata: any) => {
                    setUser(userdata)
                })
                setLoadingOverlay(false)
                setEditBankDetails(false)
            },
            (e) => {
                setError(e.detail)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )*/
        
        
        Auth.accUpgrade.uploadIDFront(
            files.IDFront,
            (resp) => {
                Auth.accUpgrade.uploadIDBack(
                    files.IDBack,
                    (resp) => {
                        Auth.accUpgrade.uploadBankproof(
                            files.Bankproof,
                            (resp) => {
                                Auth.accUpgrade.updateBankDetails(
                                    editBankCode,
                                    editBankAccountNumber,
                                    (resp) => {
                                        console.log(resp)
                                        User.get((userdata: any) => {
                                            setUser(userdata)
                                        })
                                        setMerchantDialogMessage("Your request to resubmit documents has been sent! Please allow up to 48 hours for verification.");
                                        setMerchantOpenDialog(true)
                                        setIsMerchantRejected(false)
                                        ReactPixel.track('Resubmit Merchant Application')
                                        setLoadingOverlay(false)
                                        //setEditBankDetails(false)
                                    },
                                    (e) => {
                                        setErrorDialogMessage(e.detail)
                                        setOpenErrorDialog(true)
                                        setLoadingOverlay(false)
                                        console.log(e);
                                    }
                                )
                            },
                            (e) => {
                                setErrorDialogMessage(e.detail)
                                setOpenErrorDialog(true)
                                setLoadingOverlay(false)
                                console.log(e);
                            }
                        )
                    },
                    (e) => {
                        setErrorDialogMessage(e.detail)
                        setOpenErrorDialog(true)
                        setLoadingOverlay(false)
                        console.log(e);
                    }
                )
            },
            (e) => {
                setErrorDialogMessage(e.detail)
                setOpenErrorDialog(true)
                setLoadingOverlay(false)
                console.log(e);
            }
        )
        
    }

    const upgradeAccount = useCallback(
        (e: any) => {
            e.preventDefault();
            setLoadingOverlay(true)
            Auth.upgradeAcc(
                files.IDFront,
                files.IDBack,
                files.Bankproof,
                bankCode,
                bankAccountNumber,
                (response) => {
                    console.log(response)
                    setMerchantDialogMessage("Account upgrade request sent! Please allow up to 48 hours for verification.");
                    setMerchantOpenDialog(true)
                    ReactPixel.track('Submit Merchant Application')
                    setLoadingOverlay(false)
                    changePage('pending-page')
                    User.get((userdata: any) => {
                        User.getSource((cardData) => {
                            setSource(cardData.data.results)
                            setUser(userdata);
                            
                            /**
                             * 
                             * if(location.pathname.includes('buyer') && !userData.buyAuthorized){
                                history.push('/app/seller/vault-cards?no_card=true');
                            }else if(location.pathname.includes('seller') && !userData.sellAuthorized){
                                history.push('/app/buyer/account-upgrade?send_documents=true')
                            }
                             */
                            
                            setLoadingOverlay(false)
                        })
                    
                    })
                },
                (e) => {
                    let errorMsg = '';
                    if(e.bankProof !== undefined){
                        setErrorDialogMessage(e.bankProof[0])
                    }else if(e.IDFront !== undefined){
                        setErrorDialogMessage(e.IDFront[0])
                    }
                    else if(e.IDBack !== undefined){
                        setErrorDialogMessage(e.IDBack[0])
                    }else if(e.bankAccountNumber){
                        setErrorDialogMessage(e.bankAccountNumber[0])
                    }else{
                        setErrorDialogMessage(e.detail)
                    }
                    setOpenErrorDialog(true)
                    setLoadingOverlay(false)
                    console.log(e);
                }
                
            )
        },
        [
            files.Bankproof,
            bankCode,
            files.IDBack,
            files.IDFront,
            bankAccountNumber
        ],
    )

    const resendEmailVerif = () => {
        setLoadingOverlay(true)
        if(user){
            Auth.resendVerifEmail(
                user.email,
                (resp) => {
                    console.log(resp)
                    setOpenDialog(true)
                    setLoadingOverlay(false)
                    setDialogMessage("A verification email has been sent.");
                },
                (e) => {
                    console.log(e)
                    setLoadingOverlay(false)
                    setErrorDialogMessage(e.new_password2 ? e.new_password2 : e.new_password1 ? e.new_password1 : "We have encountered an error. Please try again in a few minutes")
                    setOpenErrorDialog(true)
                }
            )
        }
        
    }

    const postRegisterLogin = () => {
        setLoadingOverlay(true)
        resetErrors()
        if(Auth.isAuthenticated()){
            
        }else{

        
        Auth.login(
            values.login_email !== '' ? values.login_email : values.register_email,
            values.login_password !==  '' ? values.login_password : values.register_password,
            (response) => {
                setLoadingOverlay(false)
                //history.push('/app/my-account')
                User.get(
                    (resp) => {
                        setUser(resp)
                        emailSubscribe(resp.id)
                        setLoadingOverlay(false)
                    }
                )
                changePage('email-verif-page');
                /*User.get(
                    (resp) => {
                        console.log(resp)
                        if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                            //history.push('/app/my-account')
                            setLoadingOverlay(false)
                            //changePage('fork-page')
                            if(receiverType === "BUY"){
                                console.log("BUYER SIGNUP")
                                changePage('add-card-page')
                                if(resp.buyAuthorized){
                                    console.log("Buy View")
                                    window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                }else if(resp.sellAuthorized){
                                    console.log("Sell View")
                                    window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                }else{
                                    console.log("Fork screen")
                                }
                            }
                            else if(receiverType === "SELL"){
                                console.log("MERCHANT SIGNUP")
                                changePage('apply-merchant-page')
                                if(resp.buyAuthorized){
                                    console.log("Buy View")
                                    window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                }else if(resp.sellAuthorized){
                                    console.log("Sell View")
                                    window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                }else{
                                    console.log("Fork screen")
                                }
                            }
                           
                           
                        }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }else if(!resp.contact_number && !resp.sms_verified){
                            setLoadingOverlay(false)
                            changePage('contact-page')
                        }
                        
                        else{
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }
                    }
                )*/
            },
            (e) => {
                setLoadingOverlay(false)
                setRegErrorMSG(e);
            }
        )
        }
    }

    const verifyEmail = () => {
        if(Auth.isAuthenticated()) {
            Auth.authVerifyEmail(
                null,
                emailCode,
                (resp) => {
                    console.log(resp)
                    setVerifSuccess(true)
                    setUser(resp)
                    ReactPixel.track('Email Verified')
                    setIsEmailConfirmed(true)
                    setVerifSuccessMessage('Email successfully validated!');
                },
                (e) => {
                    console.log(e.error)
                    setVerifErrorMessage(e.error ? e.error : e.msg ? e.msg : 'There was an error validating your email. Please try again.');
                    setVerifError(true)
                }
            )
        }else{
            Auth.verifyEmail(
                null,
                emailCode,
                (resp) => {
                    console.log(resp)
                    setVerifSuccess(true)
                    setUser(resp)
                    setVerifSuccessMessage('Email successfully validated!');
                },
                (e) => {
                    console.log(e.error)
                    setVerifErrorMessage(e.error ? e.error : e.msg ? e.msg : 'There was an error validating your email. Please try again.');
                    setVerifError(true)
                }
            )
        }
    }

    const submitLogin = useCallback(() => {
        setLoadingOverlay(true)
        resetErrors()
        Auth.login(
            values.login_email,
            values.login_password,
            (response) => {
                console.log('moving to my account....');
                
                User.get(
                    (resp) => {
                        console.log(resp)
                        User.getSource((cardData) => {
                            setSource(cardData.data.results)
                            setUser(resp);
                            
                            /**
                             * 
                             * if(location.pathname.includes('buyer') && !userData.buyAuthorized){
                                history.push('/app/seller/vault-cards?no_card=true');
                            }else if(location.pathname.includes('seller') && !userData.sellAuthorized){
                                history.push('/app/buyer/account-upgrade?send_documents=true')
                            }
                             */
                            console.log("START")
                            setIsEmailConfirmed(resp.emailConfirmed)
                            if( proposalData && (proposalData.buyer === resp.id || proposalData.merchant === resp.id)){
                                changePage('error-proposal-page')
                                setLoadingOverlay(false);
                            }else{
                                if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                    console.log("HAS DETAILS AND SMS")
                                    //history.push('/app/my-account')
                                    setLoadingOverlay(false)
                                    //changePage('fork-page')
                                    if(receiverType){
                                        if(receiverType === "BUY"){
                                            console.log("BUYER LOGIN")
                                            if(!resp.emailConfirmed){
                                                changePage('email-verif-page');
                                            }
                                            else if(resp.buyAuthorized){
                                                window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}`
                                            }
                                            else{
                                                changePage('add-card-page')
                                            }
                                        }
                                        else if(receiverType === "SELL"){
                                            console.log("MERCHANT LOGIN")
                                            if(!resp.emailConfirmed){
                                                changePage('email-verif-page');
                                            }
                                            else if(resp.sellAuthorized){
                                                window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}`
                                            }
                                            else{
                                                if(resp.Bankproof && (resp.IDBack && resp.IDFront)){
                                                    changePage('pending-page')
                                                }else{
                                                    changePage('apply-merchant-page')
                                                }
                                                
                                            }
                                        }
                                    }else{
                                        if(resp.sellAuthorized){
                                            console.log("Sell View")
                                            setBuyOrSellMode("sell")
                                            window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                            
                                        }else if(!resp.emailConfirmed){
                                            changePage('email-verif-page');
                                            emailSubscribe(resp.id);
                                            setLoadingOverlay(false)
                                            
                                        }
                                        else if(resp.buyAuthorized){
                                            console.log("Buy View")
                                            setBuyOrSellMode("buy")
                                            window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                        }else{
                                            console.log("Fork screen")
                                            changePage('fork-page')
                                        }
                                    }
                                    
                                    
                                
                                
                                }else if(!resp.emailConfirmed){
                                    emailSubscribe(resp.id);
                                    setLoadingOverlay(false)
                                    changePage('email-verif-page');
                                }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                    
                                    console.log("HAS NO DETAILS")
                                    setLoadingOverlay(false)
                                    changePage('profile1-page')
                                }else if(!resp.contact_number && !resp.sms_verified){
                                    console.log("HAS NO SMS")
                                    setLoadingOverlay(false)
                                    changePage('contact-page')
                                }
                                
                                else{
                                    setLoadingOverlay(false)
                                    changePage('profile1-page')
                                }
                                setLoadingOverlay(false)
                            }
                            
                        })
                        
                    }
                )
               
                
            },
            (e) => {
                setLoadingOverlay(false)
                setLogErrorMSG(e);
            }
        )
    }, [
        values.login_email, 
        values.login_password, 
    ]);

    const forgotPassword = useCallback(() => {
        setLoadingOverlay(true)
        Auth.forgotPassword(
            values.forgot_password_email,
            (response) => {
                setLoadingOverlay(false)
                setOpenDialog(true)
                setDialogMessage("Your request for a password change has been sent! Please check your email.")
            },
            (e) => {
                setLoadingOverlay(false)
                setErrorForgotPassword(e.email[0])
                setLogErrorMSG(e)
            }
        )
    },[
        values.forgot_password_email
    ])

    const onKeyPress = (e: any) => {
        if (e.keyCode === 13 && page === 'login-page') {
         
          submitLogin();
          
        }else if (e.keyCode === 13 && page === 'contact-page') {
         
            validateNumber();
            
        }else if (e.keyCode === 13 && page === 'sms-verify') {
         
            smsVerify();
            
        }else if (e.keyCode === 13 && page === 'email-verif-page') {
         
            verifyEmail();
            
        }else if (e.keyCode === 13 && page === 'profile2-page'){

            validatePage2(e)

        }else if(e.keyCode === 13 && page === 'forgot-password'){

            forgotPassword()

        }else if(e.keyCode === 13 && page === 'add-card-page'){

            addCard()

        }else if (e.keyCode === 13 && page === 'pay-as-guest-page'){
            payNowAsGuest()
        }
        else if(e.keyCode === 13 && page === 'apply-merchant-page'){
            if(user?.IDFront){
                sendUpdatedRequest(e)
            }else{
                upgradeAccount(e)
            }
            

        }
    }

    const {reference} = useParams<{reference: any}>();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userSources, setUserSources] = useState<any>([]);
    const [paymentTypeList, setPaymentTypeList] = useState<any | null>(null);
    const [transactData, setTransactData] = useState<any>(null);

    
  
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
  

  
    const [terms, setTerms] = useState('3');
    const [paymentType, setPaymentType] = useState<any>('');
    const [sourceID, setSourceID] = useState<any>('');
    const [cardActivated, setCardActivated] = useState(null);
    
    const [merchantName, setMerchantName] = useState<string>('');
    const [totalAmount, setTotalAmount] = useState<any>(0);
  
    const [loggedEmail, setLoggedEmail] = useState('');
    const [sourceIDSelected, setSourceIDSelected] = useState(false);
  
    const [paymentForm, setPaymentForm] = useState(false);
    const [payAsGuest, setPayAsGuest] = useState(false);
  
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expMonth, setExpMonth] = useState('');
    const [expYear, setExpYear] = useState('');
    const [cvc, setCVC] = useState('');


    const [proposalData, setProposalData] = useState<any>(null)

    const [receiverType, setReceiverType] = useState<any>(null)
  
    const handleCategoryChange = (val: any) => {
      if (val === 1) {
        setTerms('1');
        setPaymentType(val);
      } else {
        setTerms('3');
        setPaymentType(val);
      }
    };
  
    function numberWithCommas(x: any) {
      return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  
    const checkIfActivated = (results: any) => {
      const isInstall = results.map((data: any) => {
        if (data.installmentAuthorized) {
          return true;
        } else {
          return false;
        }
      });
      console.log(isInstall);
      const test = isInstall.some((val: any) => {
        if (val === true) {
          return true;
        }
      });
      console.log(test);
      setCardActivated(test);
    };

    const getProposalDetails = () => {
        setLoadingOverlay(true)
        
        if(reference){
          
          axios.post(MyURL.PROPOSALS.REFERENCE_GENERATE, {reference}).then(
              ({data}) => {
                  if(data.Link.includes('link')){
                      axios.get(data.Link).then(
                          (responseLink) => {
                              console.log("LINK RESPONSE", responseLink)
                              setProposalData(responseLink.data.proposal)
                          }
                      ).catch((e) => {
                        setErrorMsg(e.response.data.error)
                        setIsError(true)
                        setLoadingOverlay(false)
                      })
                      if(data.proposal){
                          if(data.proposal.proposal_type === 'BUYING'){
                              data('SELL')
                          }else if(data.proposal.proposal_type === 'SELLING'){
                            setReceiverType('BUY')
                          }
                            setLoadingOverlay(false)
                        }
                       
                    }else{
                        let link = data.Link.substring(data.Link.lastIndexOf('/')+1)
                        console.log(link)
                        if(data.proposal){
                          setProposalData(data.proposal)
                          if(data.proposal.proposal_type === 'BUYING'){
                            setReceiverType('SELL')
                          }else if(data.proposal.proposal_type === 'SELLING'){
                            setReceiverType('BUY')
                          }
                          setLoadingOverlay(false)
                        }
                         
              }
        })
          .catch((e) => {
              //handleCloseProposalKey()
              setErrorMsg(e.error)
              setIsError(true)
              //setOpenErrorDialog(true)
              setLoadingOverlay(false)
          })
          
            
        }else{
            setErrorDialogMessage('Please input a reference code.')
            setIsError(true)
            //handleCloseProposalKey()
            setOpenErrorDialog(true)
            setLoadingOverlay(false)
        }
    }

    useEffect(() => {
        if(!proposalData){
            getProposalDetails()
          }
    }, [])


    useEffect(() => {
      if(Auth.isAuthenticated() && !user){
        User.get(
          (resp) => {
              User.getSource(
                (sourceResp) => {
                  setUser(resp)
                  setSource(sourceResp)
                }
              )
              
          }
        )
      }

      if(proposalData && user && receiverType){
        setLoadingOverlay(true);
        if(proposalData.buyer === user.id || proposalData.merchant === user.id){
            changePage('error-proposal-page')
            setLoadingOverlay(false);
        }else{
            if(receiverType){
                setIsEmailConfirmed(user.emailConfirmed)
              if(receiverType === "BUY"){
                  if(!user.gender || !user.city || !user.gender || !user.region || !user.street ){
                    setLoadingOverlay(false)
                    changePage('profile1-page')
                    }else if(!user.contact_number && !user.sms_verified){
                        setLoadingOverlay(false)
                        changePage('contact-page')
                    }else if(!user.emailConfirmed && page === 'email-verif-page'){
                        emailSubscribe(user.id);
                        setLoadingOverlay(false)
                        changePage('email-verif-page');
                    }
                 
                  else if(user.buyAuthorized){
                    window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}`
                  }
                  else{
                    setLoadingOverlay(false);
                      changePage('add-card-page')
                  }
              }
              else if(receiverType === "SELL"){
                  if(!user.gender || !user.city || !user.gender || !user.region || !user.street ){
                    setLoadingOverlay(false)
                    changePage('profile1-page')
                    }else if(!user.contact_number && !user.sms_verified){
                        setLoadingOverlay(false)
                        changePage('contact-page')
                    }else if(!user.emailConfirmed && page === 'email-verif-page'){
                        emailSubscribe(user.id);
                        setLoadingOverlay(false)
                        changePage('email-verif-page');
                    }
                  else if(user.sellAuthorized){
                    window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}`
                  }
                  else{
                      if(user.Bankproof && (user.IDBack && user.IDFront)){
                        setLoadingOverlay(false);
                          changePage('pending-page')
                      }else{
                        setLoadingOverlay(false);
                          changePage('apply-merchant-page')
                      }
                      
                  }
              }
            }else{
                setIsEmailConfirmed(user.emailConfirmed)
              if(user.sellAuthorized){
                  setBuyOrSellMode("sell")
                  window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                  
              }else if(!user.emailConfirmed){
                  changePage('email-verif-page');
              }
              else if(user.buyAuthorized){
                  setBuyOrSellMode("buy")
                  window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
              }else{
                  changePage('fork-page')
              }
            }
        }
        
      }
      
    }, [user, source, proposalData, receiverType])
  
    /**useEffect(() => {
          if(Auth.isAuthenticated() && userSources.length === 0 && !paymentTypeList){
              console.log("Run sources")
              User.get(
                  (resp) => {
                      setLoggedEmail(resp.email)
                  }
              )
              getSources()    
          }
              
      },[userSources, paymentTypeList])*/
  
    const getSources = () => {
      Auth.getSources(
        (resp) => {
          if (resp.results.length > 0) {
            setUserSources(resp.results);
            checkIfActivated(resp.results);
            Auth.getServiceTypes(
              (resp) => {
                setPaymentTypeList(resp.results);
              },
              (e) => {
                console.log(e);
                setErrorDialogMessage(e.error);
                setOpenErrorDialog(true);
              }
            );
          } else {
            setErrorDialogMessage(
              'You have no payment method registered in the system.'
            );
            setOpenErrorDialog(true);
          }
        },
        (e) => {
          console.log(e);
          setErrorDialogMessage(e.error);
          setOpenErrorDialog(true);
        }
      );
    };
  
    useEffect(() => {
      if (terms !== '' && transactData) {
        Auth.installmentCompute(
          transactData.amount,
          terms,
          (resp) => {
            setTotalAmount(resp.data);
          },
          (e) => {
            setLoadingOverlay(false);
            setErrorDialogMessage(e.error);
            setOpenErrorDialog(true);
          }
        );
      }
    }, [terms, transactData]);
  /*
    useEffect(() => {
      if (!transactData) {
        if (query.has('id')) {
          setLoadingOverlay(true);
          Auth.getTransactionCreateDetails(
            `?id=${query.get('id')}`,
            (resp) => {
              setTransactData(resp.data);
              setLoadingOverlay(false);
            },
            (e) => {
              console.log(e);
              setLoadingOverlay(false);
              setIsError(true);
              setErrorMsg(e.error);
            }
          );
        } else {
          setIsError(true);
          setErrorMsg('Transaction not found.');
        }
      }
    }, [transactData]);*/
  
    useEffect(() => {
      if (cardActivated === false && userSources.length > 0) {
        console.log(cardActivated);
      }
    }, [cardActivated, userSources]);
  
    /*
    const submitLogin = useCallback(() => {
      setLoadingOverlay(true);
      Auth.login(
        email,
        password,
        (response) => {
          console.log('moving to my account....');
          console.log(response);
          User.get((resp) => {
            setLoggedEmail(resp.email);
            getSources();
            setPaymentForm(true);
            setLoadingOverlay(false);
          });
        },
        (e) => {
          setLoadingOverlay(false);
          setErrorDialogMessage(
            e.error
              ? e.error
              : e.non_field_errors
              ? e.non_field_errors
              : e.password
              ? 'Error in Password: ' + e.password
              : 'An error has been encountered.'
          );
          setOpenErrorDialog(true);
        }
      );
    }, [email, password]);*/
  
    const changeUser = () => {
      Auth.logout();
      setEmail('');
      setPassword('');
      setPaymentForm(false);
    };
  
    const payNowAsGuest = () => {
      setLoadingOverlay(true);
      Auth.tokenize_card(
        cardNumber,
        expMonth,
        '20'+expYear,
        cvc,
        (resp) => {
          Auth.proposalPayAsGuestSubmit(
            firstName,
            lastName,
            proposalData.id,
            resp.paymentTokenId,
            (payResp) => {
              window.location.href = payResp.verification_url;
            },
            (e) => {
              setLoadingOverlay(false);
              setErrorDialogMessage(e.detail);
              setOpenErrorDialog(true);
            }
          );
        },
        (e) => {
          setOpenErrorDialog(true);
          setErrorDialogMessage(e);
        }
      );
    };
  
    const payNow = () => {
      setLoadingOverlay(true);
      Auth.appPaySubmit(
        transactData.name,
        transactData.amount,
        terms,
        paymentType,
        transactData.merchant_id,
        sourceID,
        transactData.reference,
        (resp) => {
          window.location.href = transactData.success_redirect_url;
        },
        (e) => {
          setLoadingOverlay(false);
          setErrorDialogMessage(e.error);
          setOpenErrorDialog(true);
          //window.location.href = transactData.failure_redirect_url;
        }
      );
    };
  
  
    return (
      <>
        {loadingOverlay ? (
          <Backdrop className={classes.backdrop} open={loadingOverlay}>
            <BounceLoader color={'#FF873A'} />
          </Backdrop>
        ) : null}
        <div className="app-pay">
          <div style={{height: '-webkit-fill-available'}}>
            <Grid container  style={{width: '100%', height: '100%', margin: 'auto'}} direction="row" justify="space-around" spacing={3}>
              <Grid container alignItems="center" item xs={12} sm={isError ? 12 : 6} style={{width: matches ? '100vw' : '100vw', margin: 'auto'}} >
                <div className="app-pay-details-container" style={{border: isError ? '1px solid #d2d2d2' : 'none', padding: isError ? '30px 45px' : '0', width: isError ? '500px' : '100vw'}}>
          
                    <div className="app-pay-details-title">
                      <img src={logo} alt="" className="main_logo" />
                    </div>
                    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                      <Divider />
                    </div>
                    {proposalData && (
                      <div className="app-pay-details">
                        <Grid
                          container
                          item
                          direction="column"
                          spacing={2}
                          alignItems="center"
                          justify="center"
                        >
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Transaction Name:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                              {proposalData.name}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Reference:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                              {proposalData.reference}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Proposed By:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                              {proposalData.proposal_type === "BUYING" ? proposalData.buyer_name : proposalData.merchant_name}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={6}>
                              <span style={{ color: '#FF873A', fontWeight: 'bold' }}>
                                Amount:
                              </span>
                            </Grid>
                            <Grid item xs={6}>
                              {numberWithCommas(proposalData.amount)}
                            </Grid>
                          </Grid>
  
                          {paymentType === 2 && sourceIDSelected && (
                            <>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Terms:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {terms}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Interest rate:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {`${totalAmount.interest}%`}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Interest charge per month:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {totalAmount.monthly_interest_charge}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <span
                                    style={{ color: '#FF873A', fontWeight: 'bold' }}
                                  >
                                    Monthly:
                                  </span>
                                </Grid>
                                <Grid item xs={6}>
                                  {numberWithCommas(totalAmount.monthly)}
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </div>
                    )}
  
                    {isError && (
                      <>
                        <div className="app-pay-details">
                          <Grid
                            container
                            direction="column"
                            spacing={2}
                            alignItems="center"
                            justify="center"
                          >
                            <Grid item>
                              <img
                                src={error}
                                style={{ width: '100px', height: '100px' }}
                              />
                            </Grid>
                            <Grid item>{errorMsg}</Grid>
                          </Grid>
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                          <Button
                            color="login-primary"
                            onClick={() =>
                              transactData
                                ? window.location.replace(
                                    transactData.failure_redirect_url
                                  )
                                : window.location.replace(
                                    'https://4gives.me'
                                  )
                            }
                            className="create-sale-button"
                          >
                            Go to 4Gives
                          </Button>
                        </div>
                      </>
                    )}
                </div>
                
              </Grid>
              {/** 
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <Divider />
              </div>
              */}
              {!isError && (
              <Grid container item xs={12} sm={6} style={{backgroundColor: '#FF873A'}}>
            {proposalData && (
              <div className="app-pay-container-proposal">
                {paymentForm ? (
                  <div className="app-pay-form">
                    <div className="app-pay-title">
                      <h4>Payment</h4>
                      <p style={{ margin: 0 }}>Logged in as {loggedEmail}</p>
                      <p
                        onClick={changeUser}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Change User
                      </p>
                      <p>
                        Please select your payment source and desired payment type
                      </p>
                    </div>
                    <div style={{ padding: '20px' }}>
                      <Divider />
                    </div>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>Payment Source</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Select
                            id="demo-simple-select-autowidth"
                            name="category"
                            value={sourceID}
                            className={classes.select}
                            inputProps={{
                              classes: {
                                icon: classes.selectIcon,
                                root: classes.labelForm,
                              },
                              className: classes.multilineColor,
                            }}
                            style={{ width: '200px' }}
                            onChange={(e: any) => {
                              setSourceID(e.target.value);
                            }}
                          >
                            <MenuItem value="" disabled>
                              <em>Choose payment source...</em>
                            </MenuItem>
                            {userSources &&
                              userSources.map((card: any) => (
                                <MenuItem
                                  value={card.id}
                                  onClick={() =>
                                    setSourceIDSelected(
                                      card.installmentAuthorized
                                    )
                                  }
                                >
                                  {card.type + ' - ' + card.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>Payment Type</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Select
                            id="demo-simple-select-autowidth"
                            name="category"
                            value={paymentType}
                            className={classes.select}
                            inputProps={{
                              classes: {
                                icon: classes.selectIcon,
                                root: classes.labelForm,
                              },
                              className: classes.multilineColor,
                            }}
                            style={{ width: '200px' }}
                            onChange={(e: any) => {
                              handleCategoryChange(e.target.value);
                            }}
                          >
                            <MenuItem value="" disabled>
                              <em>Choose transaction type...</em>
                            </MenuItem>
                            {paymentTypeList &&
                              paymentTypeList.map((type: any) => (
                                <MenuItem value={type.id}>{type.name}</MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      </Grid>
                      {paymentType === 2 && (
                        <Grid
                          container
                          direction="row"
                          item
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={4}>
                            <div style={{ color: 'white' }}>Terms</div>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Select
                              id="demo-simple-select-autowidth"
                              name="category"
                              value={terms}
                              className={classes.select}
                              inputProps={{
                                classes: {
                                  icon: classes.selectIcon,
                                  root: classes.labelForm,
                                },
                                className: classes.multilineColor,
                              }}
                              style={{ width: '200px' }}
                              onChange={(e: any) => {
                                setTerms(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>Choose transaction type...</em>
                              </MenuItem>
                              <MenuItem value={'3'}>3</MenuItem>
                              <MenuItem value={'6'}>6</MenuItem>
                              <MenuItem value={'9'}>9</MenuItem>
                              <MenuItem value={'12'}>12</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      )}
  
                      <Grid item xs={12}>
                        <div style={{ color: 'white' }}>
                          {!sourceIDSelected && sourceID
                            ? "Your card doesn't support installments"
                            : ''}
                        </div>
                      </Grid>
  
                      <Grid item xs={12}>
                        <Button
                          disabled={paymentType === '' || sourceID === ''}
                          onClick={payNow}
                          color={'login-primary'}
                          className="login-button fg-button-block form-button"
                        >
                          Pay Now
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ) : payAsGuest ? (
                  <div className="app-pay-form">
                    <div className="app-pay-title">
                      <h4>Pay as Guest</h4>
                      <p
                        onClick={() => setPayAsGuest(false)}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Login instead?
                      </p>
                      <p>Please enter your card credentials in the form below.</p>
                    </div>
                    <div style={{ padding: '20px' }}>
                      <Divider />
                    </div>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>First Name:</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextLoginInput
                            name="firstName"
                            onChange={(e: any) => {
                              setFirstName(e.target.value);
                            }}
                            type="text"
                            value={firstName}
                            placeholder="Enter First Name"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>Last Name:</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextLoginInput
                            name="lastName"
                            onChange={(e: any) => {
                              setLastName(e.target.value);
                            }}
                            type="text"
                            value={lastName}
                            placeholder="Enter Last Name"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>Card Number:</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextLoginInput
                            name="cardNumber"
                            onChange={(e: any) => {
                              if (e.target.value.length < 17) {
                                setCardNumber(e.target.value);
                              }
                            }}
                            type="number"
                            value={cardNumber}
                            placeholder="Enter Card Number"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>Expiry Month</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Select
                            id="demo-simple-select-autowidth"
                            name="expMonth"
                            value={expMonth || ''}
                            style={{ width: '100%', color: 'white' }}
                            onChange={(e: any) => setExpMonth(e.target.value)}
                            displayEmpty
                            autoWidth
                            defaultValue=""
                          >
                            <MenuItem value="" disabled>
                              <em>Choose Expiry Month...</em>
                            </MenuItem>
  
                            <MenuItem value={'01'}>01</MenuItem>
                            <MenuItem value={'02'}>02</MenuItem>
                            <MenuItem value={'03'}>03</MenuItem>
                            <MenuItem value={'04'}>04</MenuItem>
                            <MenuItem value={'05'}>05</MenuItem>
                            <MenuItem value={'06'}>06</MenuItem>
                            <MenuItem value={'07'}>07</MenuItem>
                            <MenuItem value={'08'}>08</MenuItem>
                            <MenuItem value={'09'}>09</MenuItem>
                            <MenuItem value={'10'}>10</MenuItem>
                            <MenuItem value={'11'}>11</MenuItem>
                            <MenuItem value={'12'}>12</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>Expiry Year</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Select
                            id="demo-simple-select-autowidth"
                            name="expYear"
                            value={expYear || ''}
                            style={{ width: '100%', color: 'white' }}
                            onChange={(e: any) => setExpYear(e.target.value)}
                            displayEmpty
                            autoWidth
                            defaultValue=""
                          >
                            <MenuItem value="" disabled>
                              <em>Choose Expiry Year...</em>
                            </MenuItem>
  
                            <MenuItem value={'2021'}>2021</MenuItem>
                            <MenuItem value={'2022'}>2022</MenuItem>
                            <MenuItem value={'2023'}>2023</MenuItem>
                            <MenuItem value={'2024'}>2024</MenuItem>
                            <MenuItem value={'2025'}>2025</MenuItem>
                            <MenuItem value={'2026'}>2026</MenuItem>
                            <MenuItem value={'2027'}>2027</MenuItem>
                            <MenuItem value={'2028'}>2028</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <div style={{ color: 'white' }}>CVC:</div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextLoginInput
                            name="cvc"
                            onChange={(e: any) => {
                              if (e.target.value.length < 4) {
                                setCVC(e.target.value);
                              }
                            }}
                            type="number"
                            value={cvc}
                            placeholder="Enter CVC"
                          />
                        </Grid>
                      </Grid>
  
                      <Grid item xs={12}>
                        <Button
                          disabled={
                            firstName === '' ||
                            lastName === '' ||
                            cardNumber === '' ||
                            cvc === '' ||
                            expMonth === '' ||
                            expYear === ''
                          }
                          onClick={payNowAsGuest}
                          color={'login-primary'}
                          className="login-button fg-button-block form-button"
                        >
                          Pay Now
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <>
                  <Grid container item xs={12} md={12} style={{padding: matchesBelow ? '50px 170px' : matches ? '50px 120px' :'20px', height:'100%', backgroundColor: '#FF873A', border: '1px solid #FF873A'}} direction="row" justify="center" alignItems="center">

                    <Grid item xs={12}>
                        <div style={{margin: matches ? '20px' : '20px', textAlign: 'left'}}>
                        <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: matches ? '100%' : '250px',  borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: 'auto'}} isOpen={!isEmailConfirmed && ((page === 'fork-page' || page === 'pending-page') || (page === 'add-card-page' || page === 'apply-merchant-page'))}>
                            <div onClick={() => changePage('email-verif-page')}> <p style={{fontWeight: 'bold'}}>Email Not Verified:</p> Please verify your email.</div>
                        </Alert>
                        <Alert color="warning"  style={{cursor: 'pointer', border: '2px solid #FF873A', width: matches ? '100%' : '250px', borderRadius: '20px', marginBottom: '5px', paddingRight: '20px', margin: 'auto'}} isOpen={page === 'add-card-page'}>
                            <div > <p style={{fontWeight: 'bold'}}>Note:</p> 4Gives will validate your card by charging PHP 10. Once it is validated, it will be immediately refunded to your card.</div>
                            <br />
                            <div > 4Gives will never take PHP 10 from your account.</div>
                        </Alert>
                        </div>
                        {/* LOGIN */}
                        {((query.has('page') && query.get('page') === 'login-page' ))&& (
                        <div className="form login-form">
                            <div className="login-title">
                                
                                {/**
                                <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}} src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                 */}
                                <h4>{receiverType ? receiverType === 'BUY' ? 'Login as a Buyer' : receiverType === 'SELL' ? 'Login as a Merchant' : 'Login' : 'Login'}</h4>
                            </div>
                          
                        {/**                                 {receiverType && (

                                <Row>
                                  
                                    <Col xs={12} sm={12}>
                                        <p 
                                            onClick={() => history.push(receiverType ? receiverType === 'BUY' ? '/login?page=registration-page&applying_for=merchant' : receiverType === 'SELL' ? '/login?page=registration-page&applying_for=buyer' : '/login?page=registration-page': '/login?page=registration-page')}
                                            style={{cursor: 'pointer', textDecoration: 'underline', padding: '0 0 0 0'}}
                                        >
                                            Are you a {receiverType ? receiverType === 'BUY' ? 'seller' : receiverType === 'SELL' ? 'buyer' : '' : ''}?
                                        </p>
                                    </Col>
                                </Row>
                            )}
                            */}
                            <form>
                            <div className="form-container">
                                <TextLoginInput 
                                    name='login_email'
                                    type='email'
                                    value={values.login_email}
                                    onChange={handleChange}
                                    placeholder='E-mail'
                                    icon={{ prefix: 'far', name: 'envelope'}}
                                    onKeyDown={handleEnter}
                                />
                            </div>
                            {getLogErrorMsg('email') ? (
                                <p className='email' style={{fontSize: '12px', margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { getLogErrorMsg('email')}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <TextLoginInput 
                                    name='login_password'
                                    type='password'
                                    value={values.login_password}
                                    onChange={handleChange}
                                    onKeyDown={onKeyPress}
                                    placeholder='Password'
                                    icon={{ prefix: 'fas', name: 'key'}}
                                />
                            </div>
                            {getLogErrorMsg('password') ? (
                                <p className='password' style={{fontSize: '12px', margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { getLogErrorMsg('password')}
                                </p>
                            ) : null}
                            {getLogErrorMsg('non_field_errors') ? (
                                <p className='none_field_error' style={{fontSize: '12px', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '10px'}}>
                                { getLogErrorMsg('non_field_errors') ? `Error: ${getLogErrorMsg('non_field_errors')}` : null}
                                </p>
                            ) : null}
                            </form>
                            <div className="form-container">
                                <Row>
                                    <Col>

                                          <Button 
                                              onClick={() => {
                                                  // history.push(`/login${receiverType ? receiverType === 'BUY' ? '?page=registration-page&applying_for=buyer' : receiverType === 'SELL' ? '?page=registration-page&applying_for=merchant' : '?page=registration-page' : '?page=registration-page' }`)
                                                  changePage('registration-page')
                                              }}
                                              color={'login-secondary'}
                                              className='goto-register-button fg-button-block'
                                          >  
                                              Register
                                          </Button>
                                      
                                    </Col>
                                    <Col>
                                        
                                        <Button 
                                            onClick={() => {
                                                submitLogin();
                                            }}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Sign In
                                        </Button>
                                    </Col>
                                </Row>
                                

                            </div>
                           
                            <div className="form-container page-linker-container">
                            
                            </div>
                            {receiverType === "BUY" && (

                            
                            <div className="form-container page-linker-container">
                        
                                <Button 
                                    onClick={() => {
                                        changePage('pay-as-guest-page');
                                    }}
                                    color={'login-secondary'}
                                    className='goto-register-button fg-button-block'
                                >  
                                    Pay as Guest
                                </Button>
                          
                            </div>
                            )}
                            {/** 
                            <div className="form-container page-linker-container">
                                
                                <Button 
                                    onClick={() => {
                                      
                                            history.push(`${receiverType ? receiverType === 'BUY' ? '/buyer' : receiverType === 'SELL' ? '/merchant' : '/' : '/' }`)
                                        
                                        
                                        
                                    }}
                                    color={'login-secondary'}
                                    className='goto-register-button fg-button-block'
                                >  
                                    Go Back
                                </Button>
                                
                            </div>*/}
                        </div>
                        )}
                        
                        {/**PAY AS GUEST PAGE 
                         * 
                        */}
                        {((query.has('page') && query.get('page') === 'pay-as-guest-page' ))&& (
                            <div className="form add-card-form">
                                <div className="registration-title">
                                  {/*
                                    <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                  */}
                                    <h4>Pay as Guest</h4>
                                    <p>Please complete the form below to proceed.</p>
                                    
                                </div>
                                <form>
                                <div className="form-container">
                                  <TextLoginInput 
                                      name='firstName'
                                      type='text'
                                      value={firstName}
                                      onKeyDown={handleEnter}
                                      onChange={(e: any) => setFirstName(e.target.value)}
                                      placeholder='First Name'
                                  />
                                </div>
                                <div className="form-container">
                                  <TextLoginInput 
                                      name='lastName'
                                      type='text'
                                      value={lastName}
                                      onKeyDown={handleEnter}
                                      onChange={(e: any) => setLastName(e.target.value)}
                                      placeholder='Last Name'
                                  />
                                </div>
                                <div className="form-container">
                                    <TextLoginCardNumberInput 
                                        name='cardNumber'
                                        id='cardNumber'
                                        type='text'
                                        value={cardNumber}
                                        onKeyDown={handleEnter}
                                        onChange={(e) => {
                                            if(e.target.value.length < 17){
                                                setCardNumber(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card Number'
                                    />
                                
                                </div>
                                
                                <div className="form-container">
                                    {/**
                                    * <TextLoginCardNumberInput 
                                        name='addCardExpiry'
                                        type='text'
                                        value={addCardExpiry}
                                        className={"expMonthYear"}
                                        onChange={(e) => {
                                            if(e.target.value.length < 17){
                                                setAddCardExpiry(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card Expiry'
                                    />
                                    */}
                                    

                                    <TextLoginCardExpiryInput 
                                        expName1='expMonth'
                                        expName2='expYear'
                                        expValue1={expMonth}
                                        expValue2={expYear}
                                        expOnChange1={(e) => setExpMonth(e.target.value)}
                                        expOnChange2={(e) => setExpYear(e.target.value)}
                                        expOnKeyDown1={handleEnter}
                                        expOnKeyDown2={handleEnter}
                                        name="addCardExpiry"
                                        type='text'
                                        value={addCard}
                                        className={"expMonthYear"}
                                        onChange={(e) => {
                                            if(e.target.value.length < 17){
                                                setAddCardExpiry(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card Expiry'
                                    />
                                
                                </div>
                                <div className="form-container">
                                
                                    <TextLoginCardNumberInput 
                                        name='cvc'
                                        type='text'
                                        value={cvc}
                                        onKeyDown={onKeyPress}
                                        className={"cvc"}
                                        onChange={(e) => {
                                            if(e.target.value.length < 5){
                                                setCVC(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card CVC'
                                    />
                                
                                </div>
                                </form>
                                <div className="form-container">
                                    <Button 
                                        onClick={payNowAsGuest}
                                        disabled={cvc === '' || expMonth === '' || expYear === '' || cardNumber === ''}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Purchase
                                    </Button>

                                </div>
                               
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Login Instead?
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                
                            </div>
                            
                        )}
                        {/* FORGOT PASSWORD */}
                        {((query.has('page') && query.get('page') === 'forgot-password' ))&&  (
                            <div className="form registration-form">
                                <div className="registration-title">
                                    {/*
                                    <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}} src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                     */}
                                    <h4>Forgot Password</h4>
                                    <p>All fields are required</p>
                                
                                </div>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='forgot_password_email'
                                        type='text'
                                        value={values.forgot_password_email}
                                        onChange={handleChange}
                                        onKeyDown={onKeyPress}
                                        placeholder='Email'
                                        icon={{ prefix: 'far', name: 'envelope'}}
                                        errorMessage={getRegErrorMsg('forgot_password_email')}
                                    />
                                </div>
                                {errorForgotPassword ? (
                                    <p className='errorForgotPassword' style={{fontSize: '12px', margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorForgotPassword}
                                    </p>
                                ) : null}
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            forgotPassword()
                                        }}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Submit
                                    </Button>

                                </div>
                                <div className="form-container">
                                <Button 
                                    onClick={() => {
                                        //history.push(`/login${receiverType ? receiverType === 'BUY' ? '?applying_for=buyer' : receiverType === 'SELL' ? '?applying_for=merchant' : '' : '' }`)
                                        changePage('login-page')
                                    }}
                                    color={'login-secondary'}
                                    className='goto-register-button fg-button-block'
                                >  
                                    Back
                                </Button>
                                </div>
                            </div>
                        )}

                        {/* FORK SCREEN */}
                        {((query.has('page') && query.get('page') === 'fork-page' ))&& (
                            <div className="form fork-form">
                                <div className="registration-title">
                                    {/*
                                    <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                     */}
                                    <h4>What would you like to do?</h4>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color:'white', fontSize:'12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                    <p>Select an option to proceed</p>
                                </div>
                                <div className="form-container" >
                                    
                                    <div className="imgBtn-login" onClick={() => {
                                        User.get(
                                            (resp) => {
                                                if(resp.buyAuthorized){
                                                    window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` 
                                                }else{
                                                    changePage('add-card-page')
                                                }
                                            }
                                        )
                                        
                                    }}>
                                        <img src={purchaseIcon}  />
                                        <div className="imgBtn-text" >
                                            I would like to purchase an item in installments.
                                        </div>
                                    </div>
                                        
                                    
                                    
                                </div>
                                <div className="form-container">
                                    <div className="imgBtn-login" onClick={() => {
                                        User.get(
                                            (resp) => {
                                                if(resp.sellAuthorized){
                                                    window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` 
                                                }else{
                                                    if(user && user.IDFront && user.Bankproof && user.IDBack){
                                                        changePage('pending-page')
                                                    }else{
                                                        changePage('apply-merchant-page')
                                                    }
                                                }
                                            }
                                        )
                                    }}>
                                        <img src={merchantIcon}  />
                                        <div className="imgBtn-text" >
                                            I want to be a merchant that can offer installment payments.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                                
                            </div>
                        )}

                        {/* FORK SCREEN */}
                        {((query.has('page') && query.get('page') === 'pending-page' ))&& (
                            <div className="form pending-form">
                                <div className="registration-title">
                                  {/*
                                    <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                     */}
                                    {isMerchantRejected ? 
                                        <>
                                            <h4>Your application has been rejected.</h4>
                                            <h4>Reason: {rejectNote}</h4>
                                        </>
                                    :
                                        <h4>You have a pending application, please wait for up to 48 hours for verification.</h4>
                                    }
                                  
                                </div>
                                {isMerchantRejected && (
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                          
                                            changePage('apply-merchant-page');
                                        }}
                                        color={'login-primary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        Resubmit Application
                                    </Button>
                                </div>
                                )}
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            setPreview({
                                                IDFront: '',
                                                IDBack: '',
                                                Bankproof: '',
                                            })
                                            setBankAccountNumber(0)
                                            setBankCode('')
                                            changePage('fork-page');
                                        }}
                                        color={'login-primary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        I would like to purchase instead.
                                    </Button>
                                </div>

                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                              
                                
                            </div>
                        )}

                        {/* FORK SCREEN */}
                        {((query.has('page') && query.get('page') === 'error-proposal-page' ))&& (
                            <div className="form pending-form">
                                <div className="registration-title">
                                
                                    <h4>Error: You cannot use your own proposal link.</h4>
                                       
                                  
                                </div>
                              
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            Auth.logout()
                                            window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                            //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                            //changePage('login-page')
                                        }}
                                        color={'login-primary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        Logout
                                    </Button>
                                </div>

                              
                                
                            </div>
                        )}
                        
                        {/* EMAIL VERIF */}
                        {((query.has('page') && query.get('page') === 'email-verif-page' ))&&  (
                            <div className="form fork-form">
                                <div className="registration-title">
                                  {/*
                                    <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                     */}
                                    <h4>Email Verification</h4>
                                    
                                    <p>Please enter the code that was sent to your email</p>
                                    
                                </div>
                                <div className="form-container">
                                    <TextLoginInput 
                                        name='emailCode'
                                        type='text'
                                        value={emailCode}
                                        onChange={(e) => {
                                            setEmailCode(e.target.value)
                                            
                                            
                                        }}
                                        onKeyDown={onKeyPress}
                                        placeholder='Enter your code'
                                    />
                                
                                </div>
                                <div className="form-container">

                                    <Button 
                                        onClick={() => {
                                            verifyEmail()
                                        }}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Submit
                                    </Button>
                                </div>
                                <div className="form-container">

                                    <Button 
                                        onClick={() => {
                                            User.get(
                                                (resp) => {
                                                    if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                                                        //history.push('/app/my-account')
                                                        setLoadingOverlay(false)
                                                        //changePage('fork-page')*/
                                                        if(query.get('applying_for') === "buyer"){
                                                            changePage('add-card-page')
                                                            if(resp.buyAuthorized){
                                                                history.push('/app/buyer/my-account');
                                                            }else if(resp.sellAuthorized){
                                                                history.push('/app/seller/my-account');
                                                            }
                                                        }
                                                        else if(query.get('applying_for') === "merchant"){
                                                            changePage('apply-merchant-page')
                                                            if(resp.buyAuthorized){
                                                                history.push('/app/buyer/my-account');
                                                            }else if(resp.sellAuthorized){
                                                                history.push('/app/seller/my-account');
                                                            }else if (!resp.sellAuthorized && ((resp.IDFront && resp.IDBack)  && resp.Bankproof)){
                                                                changePage('pending-page')
                                                            }
                                                        }else{
                                                            changePage('fork-page')
                                                        }
                                                    
                                                    
                                                    }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                                                        setLoadingOverlay(false)
                                                        changePage('profile1-page')
                                                    }else if(!resp.contact_number && !resp.sms_verified){
                                                        setLoadingOverlay(false)
                                                        changePage('contact-page')
                                                    }
                                                    
                                                    else{
                                                        setLoadingOverlay(false)
                                                        changePage('profile1-page')
                                                    }
                                                }
                                            )
                                        }}
                                        color={'login-secondary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Skip for Now
                                    </Button>
                                </div>
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    resendEmailVerif()
                                                    //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Didn't get your email? Click here to resend.
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                        )}

                        {/** APPLY FOR MERCHANT */}
                        {((query.has('page') && query.get('page') === 'apply-merchant-page' ))&& (
                                <div className="form apply-merchant-form">
                                    <div className="registration-title">
                  
                                        <h4>Application for Merchant</h4>
                                    
                                        <p>Please upload the required documents to proceed as a merchant.</p>
                                        
                                    </div>
                                    {user && !((user.IDFront && user.IDBack)  && user.Bankproof) ?
                                        <>
                                        
                                    <Row>
                                        <Col md={12} xl={6}>
                                        <div className="form-container">
                                            <h6>Government ID Front</h6>
                                            
                                        
                                            <div className="image-container">
                                                            {/**      
                                                    <img 
                                                        className="image" 
                                                        src={memoIDFront !== '' ? URL.createObjectURL(memoIDFront) : ''}
                                                        style={{
                                                            border: '2px solid white', 
                                                            width: matches ? '150px' : '150px',
                                                            height: matches ? '150px' : '150px',
                                                            paddingTop: '10px'
                                                        }}
                                                    />*/}
                                                    {ImageIDFront}
                                                    
                                                    
                                                </div>
                                            <div className="fileupload">
                                                <FileUploadLogin
                                                    name='IDFront'
                                                    onChange={(e: any) => { previewHandler(e); handleFiles(e);} }
                                                    placeholder='Front ID'
                                                />
                                            </div>

                                            
                                        </div>
                                        </Col>
                                        <Col md={12} xl={6}>
                                            <div className="form-container">
                                                <h6>Government ID Back</h6>
                                                
                                           
                                                <div className="image-container">
                                                                
                                                    {ImageIDBack}
                                                    
                                                    
                                                </div>
                                         
                                                <div className="fileupload">
                                                    <FileUploadLogin
                                                        name='IDBack'
                                                        onChange={(e: any) => { previewHandler(e); handleFiles(e);} }
                                                        placeholder='Back ID'
                                                    />
                                                </div>

                                                
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} xl={12}>
                                            <div className="form-container">
                                                <h6>Bank Statement</h6>
                                                
                                                {/*preview.Bankproof !== '' &&(
                                                    <div className="image-container">
                                                                    
                                                        {ImageBankProof}
                                                        <CircularProgress />
                                                    </div>
                                                )
                                                    
                                                */}
                                                <div className="image-container">
                                                                    
                                                    {ImageBankProof}
                                                </div>
                                                <div className="fileupload">
                                                    <FileUploadLogin
                                                        name='Bankproof'
                                                        onChange={(e: any) => { previewHandler(e); handleFiles(e);} }
                                                        placeholder='Bankproof'
                                                    />
                                                </div>

                                                
                                            </div>
                                        </Col>
                                        <Col md={12} xl={12}>
                                            <form>
                                            <div className="form-container">
                                                <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                                <NativeSelect
                                                    id="demo-simple-select-autowidth"
                                                    name="bankCode"
                                                    value={bankCode}
                                                    className={classes.select}
                                                    style={{width: '100%', color: 'white'}}
                                                    onKeyDown={handleEnter}
                                                    onChange={(e: any)=>{setBankCode(e.target.value)}}
                                                >
                                                    <option value="" disabled>
                                                        Choose your bank...
                                                    </option>
                                                    {banks.map((bank : any) => (
                                                        <option key={bank.id} value={bank.value}>{bank.label}</option>
                                                    ))
                                                    }
                                                    
                                                </NativeSelect>
                                            </div>
                                            
                                            <div className="form-container">
                                                <h6>Account Number:</h6>
                                                <TextLoginInput 
                                                    name='bankAccountNumber'
                                                    onChange={(e: any) => 
                                                        setBankAccountNumber(e.target.value)
                                                    }
                                                    onKeyDown={onKeyPress}
                                                    type='text'
                                                    value={memoBankAcctNum}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                            </form>
                                        </Col>
                                    </Row>
                                    <div className="form-container">
                                        <Button 
                                            disabled={files.IDFront === '' || files.IDBack === '' || files.Bankproof === '' || bankCode === '' || bankAccountNumber === 0}
                                            onClick={upgradeAccount}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                setPreview({
                                                    IDFront: '',
                                                    IDBack: '',
                                                    Bankproof: '',
                                                })
                                                setBankAccountNumber(0)
                                                setBankCode('')
                                                changePage('fork-page');
                                            }}
                                            color={'login-secondary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            I changed my mind
                                        </Button>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `?page=${page}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                    </>
                                    :

                                    <>
                                       <Row>
                                        <Col md={12} xl={6}>
                                        <div className="form-container">
                                            <h6>Government ID Front</h6>
                                            
                                           
                                                <div className="image-container">
                                                                
                                                    {ImageIDFront}

                                                    
                                                    
                                                </div>
                                                <div onClick={openIDFrontRef} className='fg-uploadbutton'>
                                                    UPLOAD
                                                </div>
                                            <div className="fileupload-hidden">
                                                    {/***
                                                     *   <FileUpload
                                                        name='IDBack'
                                                        onChange={handleFiles}
                                                        ref={idBackRef}
                                                        placeholder='Back ID'
                                                    />
                                                     */}
                                                  
                                                    <input 
                                                        type="file" 
                                                        ref={idFrontRef} 
                                                        name="IDFront" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    setEditIDFront(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }
                                                        } 
                                                    />
                                            </div>

                                            
                                        </div>
                                        </Col>
                                        <Col md={12} xl={6}>
                                            <div className="form-container">
                                                <h6>Government ID Back</h6>
                                                
                                               
                                                    <div className="image-container">
                                                                    
                                                        {ImageIDBack}

                                                        
                                                    </div>
                                                    <div onClick={openIDBackRef} className='fg-uploadbutton'>
                                                            UPLOAD
                                                        </div>
                                                        
                                                <div className="fileupload-hidden">
                                                    {/***
                                                     *   <FileUpload
                                                        name='IDBack'
                                                        onChange={handleFiles}
                                                        ref={idBackRef}
                                                        placeholder='Back ID'
                                                    />
                                                     */}
                                                  
                                                    <input 
                                                        type="file" 
                                                        ref={idBackRef} 
                                                        name="IDBack" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    setEditIDBack(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }
                                                        } 
                                                    />
                                                </div>

                                                
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} xl={12}>
                                            <div className="form-container">
                                                <h6>Bank Statement</h6>
                                                
                                                
                                                    <div className="image-container">
                                                                    
                                                        {ImageBankProof}

                                                        
                                                        
                                                    </div>
                                                    <div onClick={openBankproofRef} className='fg-uploadbutton'>
                                                        UPLOAD
                                                    </div>
                                               
                                                
                                                
                                                <div className="fileupload-hidden">
                                                    {/***
                                                     * <FileUpload
                                                        name='Bankproof'
                                                        onChange={handleFiles}
                                                        ref={bankproofRef}
                                                        placeholder='Account Number'
                                                    />
                                                     */}
                                                    
                                                    <input 
                                                        type="file" 
                                                        ref={bankproofRef} 
                                                        name="Bankproof" 
                                                        accept="application/pdf, image/*"
                                                        onChange={(e) => {
                                                            handleFiles(e); 
                                                            if(e.target.files !== null){
                                                                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                                                                var i = Math.floor(Math.log(e.target.files[0].size) / Math.log(1024));
                                                                const imageSize = Math.round(e.target.files[0].size / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
                                                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                                                if(FileSize > 2){
                                                                    setOpenErrorDialog(true)
                                                                    setErrorDialogMessage("File must be below 2MB.")
                                                                }else{
                                                                    setEditBankproof(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </Col>
                                        <Col md={12} xl={12}>

                                            <form>
                                            <div className="form-container">
                                                <h6 id="demo-simple-select-autowidth-label">Bank:</h6>
                                                <NativeSelect
                                                    id="demo-simple-select-autowidth"
                                                    name="bankCode"
                                                    value={editBankCode}
                                                    style={{width: '100%', color: 'white'}}
                                                    onKeyDown={handleEnter}
                                                    className={classes.select}
                                                    onChange={(e: any)=>{setEditBankCode(e.target.value)}}
                                                >
                                                    <option value="" disabled>
                                                        Choose your bank...
                                                    </option>
                                                    {banks.map((bank : any) => (
                                                        <option key={bank.id} value={bank.value}>{bank.label}</option>
                                                    ))
                                                    }
                                                    
                                                </NativeSelect>
                                            </div>
                                            
                                            <div className="form-container">
                                                <h6>Account Number:</h6>
                                                <TextLoginInput 
                                                    name='bankAccountNumber'
                                                    onChange={(e: any) => setEditBankAccountNumber(e.target.value)}
                                                    type='number'
                                                    onKeyDown={onKeyPress}
                                                    value={editBankAccountNumber}
                                                    placeholder='Account Number'
                                                />
                                            </div>
                                            </form>
                                        </Col>
                                    </Row>
                                    <div className="form-container">
                                        <Button 
                                            disabled={editIDFront === '' || editIDBack === '' || editBankproof === '' || editBankCode === '' || editBankAccountNumber === 0}
                                            onClick={sendUpdatedRequest}
                                            color={'login-primary'}
                                            className='login-button fg-button-block form-button' 
                                        >  
                                            Submit
                                        </Button>

                                    </div>
                                    <div className="form-container">
                                        <Button 
                                            onClick={() => {
                                                setPreview({
                                                    IDFront: '',
                                                    IDBack: '',
                                                    Bankproof: '',
                                                })
                                                setEditBankproof('')
                                                setEditIDFront('')
                                                setEditIDBack('')
                                                setEditBankAccountNumber(0)
                                                setEditBankCode('')
                                                changePage('fork-page');
                                            }}
                                            color={'login-secondary'}
                                            className='goto-register-button fg-button-block'
                                        >  
                                            I changed my mind
                                        </Button>
                                    </div>
                                    <div className="form-container">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <span 
                                                    onClick={() => {
                                                        Auth.logout()
                                                        window.location.href = window.location.origin + `?page=${page}`
                                                        //history.push(query.has('applying_for') ? query.get('applying_for') === 'buyer' ? '/login?page=login-page&applying_for=buyer' : query.get('applying_for') === 'merchant' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                        //changePage('login-page')
                                                    }}
                                                    style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                                >
                                                    Logout? 
                                                </span>
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                    </>
                                    }
                                </div>
                            )}

                        {/** ADD CARD */}
                        {((query.has('page') && query.get('page') === 'add-card-page' ))&& (
                            <div className="form add-card-form">
                                <div className="registration-title">
                                  {/*
                                    <img  style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                  */}
                                    <h4>Add a Card</h4>
                                    <p>Please add a card to proceed as a buyer</p>
                                    
                                </div>
                                <form>
                                <div className="form-container">
                                    <TextLoginCardNumberInput 
                                        name='addCardNumber'
                                        type='text'
                                        onKeyDown={handleEnter}
                                        value={addCardNumber}
                                        onChange={(e) => {
                                            if(e.target.value.length < 17){
                                                setAddCardNumber(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card Number'
                                    />
                                
                                </div>
                                <div className="form-container">
                                    {/**
                                    * <TextLoginCardNumberInput 
                                        name='addCardExpiry'
                                        type='text'
                                        value={addCardExpiry}
                                        className={"expMonthYear"}
                                        onChange={(e) => {
                                            if(e.target.value.length < 17){
                                                setAddCardExpiry(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card Expiry'
                                    />
                                    */}
                                    

                                    <TextLoginCardExpiryInput 
                                        expName1='addCardExpiryMonth'
                                        expName2='addCardExpiryYear'
                                        expValue1={addCardExpiryMonth}
                                        expValue2={addCardExpiryYear}
                                        expOnChange1={(e) => setAddCardExpiryMonth(e.target.value)}
                                        expOnChange2={(e) => setAddCardExpiryYear(e.target.value)}
                                        expOnKeyDown1={handleEnter}
                                        expOnKeyDown2={handleEnter}
                                        name="addCardExpiry"
                                        type='text'
                                        value={addCard}
                                        className={"expMonthYear"}
                                        onChange={(e) => {
                                            if(e.target.value.length < 17){
                                                setAddCardExpiry(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card Expiry'
                                    />
                                
                                </div>
                                <div className="form-container">
                                
                                    <TextLoginCardNumberInput 
                                        name='addCardCVC'
                                        type='text'
                                        value={addCardCVC}
                                        onKeyDown={onKeyPress}
                                        className={"expMonthYear"}
                                        onChange={(e) => {
                                            if(e.target.value.length < 5){
                                                setAddCardCVC(e.target.value)
                                            }
                                            
                                        }}
                                        placeholder='Card CVC'
                                    />
                                
                                </div>
                                </form>
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            addCard()
                                        }}
                                        disabled={addCardCVC === '' || addCardExpiryMonth === '' || addCardExpiryYear === '' || addCardNumber === ''}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Add Card
                                    </Button>

                                </div>
                                {/** 
                                <div className="form-container">
                                    <Button 
                                        onClick={() => {
                                            changePage('fork-page');
                                        }}
                                        color={'login-secondary'}
                                        className='goto-register-button fg-button-block'
                                    >  
                                        I changed my mind
                                    </Button>
                                </div>
                                */}
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                        )}
                                    
                        {/* REGISTRATION */}
                        {((query.has('page') && query.get('page') === 'registration-page' ))&& (
                        <div className="form registration-form">
                        <div className="registration-title">
                          {/*
                            <img style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                             */}
                                <h4>{receiverType ? receiverType === 'BUY' ? 'Register as a Buyer' : receiverType === 'SELL' ? 'Register as a Merchant' : 'User Registration' : 'User Registration'}</h4>
                                <p>All fields are required</p>
                                
                            </div>
                            {receiverType && (

                                <Row>
                                    <Col xs={12} sm={12}>
                                        <p 
                                            onClick={() => {
                                                //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                changePage('login-page')
                                            }}
                                            style={{cursor: 'pointer', textDecoration: 'underline', padding: '0 0 0 0'}}
                                        >
                                            Already Registered? 
                                        </p>
                                    </Col>
                                   
                                </Row>
                            )}
                            <form>
                            
                            <div className="form-container">
                                <TextLoginInput 
                                    name='register_email'
                                    type='email'
                                    id='register_email'
                                    onKeyDown={handleEnter}
                                    value={values.register_email}
                                    onChange={handleChange}
                                    placeholder='Email'
                                    icon={{ prefix: 'far', name: 'envelope'}}
                                />
                            </div>
                            {getRegErrorMsg('email') ? (
                                <p className='email' style={{margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { getRegErrorMsg('email')}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <TextLoginInput 
                                    name='register_password'
                                    type='password'
                                    id='register_password'
                                    onKeyDown={handleEnter}
                                    value={values.register_password}
                                    onChange={handleChange}
                                    placeholder='Password'
                                    icon={{ prefix: 'fas', name: 'key'}}

                                />
                            </div>
                            {getRegErrorMsg('password') ? (
                                <p className='password' style={{margin:'-15px 0',backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { getRegErrorMsg('password')}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <TextLoginInput 
                                    name='register_passwordConfirm'
                                    type='password'
                                    id='register_passwordConfirm'
                                    onKeyDown={handleEnter}
                                    value={values.register_passwordConfirm}
                                    onChange={handleChange}
                                    placeholder='Confirm Password'
                                    icon={{ prefix: 'fas', name: 'key'}}

                                />
                            </div>
                            {getRegErrorMsg('password2') ? (
                                <p className='password2' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { getRegErrorMsg('password2')}
                                </p>
                            ) : null}

                            {signUpPassword2 !== '' ? (
                                <p className='signUpPassword2' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    {signUpPassword2}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <Row>
                                    <Col xs={1}>
                                
                                        <Checkbox
                                            checked={agreements.terms}
                                            onChange={() => setAgreements({...agreements, terms: !agreements.terms})}
                                            name="checkedB"
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" style={{fill: 'white'}} />}
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" style={{fill: 'white'}} />
                                            }
                                        />
                                    </Col>
                                    <Col xs={11} style={{color: 'white', textAlign:'left', margin: 'auto'}}>
                                
                                      
                                            I have read and accept the 
                                            <Link 
                                            style={{
                                                color: '#0072ff', 
                                                textDecoration: 'underline'
                                            }} 
                                            to={`/${receiverType ? receiverType === 'BUY' ? 'buyer' : receiverType === 'SELL' ? 'merchant' : '': ''}/terms-conditions`}>
                                                terms and condtions
                                            </Link>
                                            {' '}and{' '} 
                                            <Link style={{color: '#0072ff', textDecoration: 'underline'}} to={`/${receiverType ? receiverType === 'BUY' ? 'buyer' : receiverType === 'SELL' ? 'merchant' : '': ''}/privacy-policy`}>
                                                privacy policy
                                            </Link>
                                            .
                                        
                                    </Col>
                                </Row>
                           
                            </div>
                            </form>
                            <div className="form-container">
                                <Button 
                                    onClick={() => {
                                        submitRegistration()
                                    }}
                                    disabled={!agreements.terms}
                                    id='submit_signup'
                                    color={'login-primary'}
                                    className='login-button fg-button-block form-button' 
                                >  
                                    Sign Up
                                </Button>

                            </div>
                            <div className="form-container page-linker-container">
                                
                                <Button 
                                    onClick={() => {
                                        history.push(`/${receiverType ? receiverType === 'BUY' ? 'buyer' : receiverType === 'SELL' ? 'merchant' : '/' : '/' }`)
                                        changePage('login-page')
                                    }}
                                    color={'login-secondary'}
                                    className='goto-register-button fg-button-block'
                                >  
                                    Back
                                </Button>
                                
                            </div>
                        </div>
                        )}
                        
                        {((query.has('page') && query.get('page') === 'contact-page' ))&&  (
                            <div className="form contact-form">
                                <div className="profile-title">
                                  {/*
                                    <img style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                  */}
                                    <p>Please enter your contact number. After submitting, a code will be sent to you.</p>
                                </div>

                                <div className="form-container">
                                    <TextContactLoginInput 
                                        name='contact_number'
                                        type='text'
                                        value={values.contact_number}
                                        onKeyDown={onKeyPress}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errorNumber !== '' && (
                                    <p className='errorNumber' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                        { errorNumber}
                                    </p>
                                )}
                                
                                <div className="form-container">
                                    <Button 
                                        
                                        onClick={validateNumber}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Submit
                                    </Button>

                                </div>
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                        )}

                        {/** PROFILE DETAILS PART 1*/}
                        {((query.has('page') && query.get('page') === 'profile1-page' ))&&  (
                        <div className="form profile1-form">
                        <div className="profile-title">
                          {/*
                        <img style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                          */}   
                                <p>Please fill the form below.</p>
                              
                            </div>
                            <form>
                            <div className="form-container">
                                <TextLoginInput 
                                    name='first_name'
                                    type='text'
                                    value={values.first_name}
                                    onKeyDown={handleEnter}
                                    onChange={handleChange}
                                    placeholder='First Name'
                                />
                            </div>
                            {errorProfile1.errors.first_name !== ''? (
                                <p className='errorProfile1.errors.first_name' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { errorProfile1.errors.first_name}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <TextLoginInput 
                                    name='middle_name'
                                    type='text'
                                    value={values.middle_name}
                                    onKeyDown={handleEnter}
                                    onChange={handleChange}
                                    placeholder='Middle Name (Optional)'
                                />
                            </div>
                            <div className="form-container">
                                <TextLoginInput 
                                    name='last_name'
                                    type='text'
                                    value={values.last_name}
                                    onKeyDown={handleEnter}
                                    onChange={handleChange}
                                    placeholder='Last Name'
                                />
                            </div>
                            {errorProfile1.errors.last_name !== ''? (
                                <p className='errorProfile1.errors.last_name' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { errorProfile1.errors.last_name}
                                </p>
                            ) : null}
                            <div className="form-container">
                                
                                    <RadioGroup aria-label="gender" name="gender1" onKeyDown={handleEnter} value={gender} onChange={(e) => setGender(e.target.value)}>
                                        <div style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center'}}>
                                            <FormControlLabel style={{color: 'white'}} value="M" control={<Radio checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} />} label="Male" />
                                            <FormControlLabel style={{color: 'white'}} value="F" control={<Radio checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />}/>} label="Female" />
                                        </div>
                                    </RadioGroup>

                                
                            </div>

                            
                            
                            <div className="form-container">
                                <Button 
                                    
                                    onClick={validatePage1}
                                    color={'login-primary'}
                                    className='login-button fg-button-block form-button' 
                                >  
                                    Next
                                </Button>

                            </div>
                            </form>
                        </div>
                        )}

                        {((query.has('page') && query.get('page') === 'sms-verify' ))&& (
                            <div className="form sms-form">
                                <div className="profile-title" style={{paddingBottom: '0px'}}>
                                  {/*
                                    <img style={{display: 'inline-block'}} src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                                  */}
                                    <p>A code has been sent to +63{values.contact_number}</p>
                                </div>
                                <div className="form-container">
                                    {otp.map((data, index) => {
                                        return (
                                            <input
                                                className={`otp-field${index === 3 ? '-last' : ''}`}
                                                type="text"
                                                name="otp"
                                                maxLength={1}
                                                key={index}
                                                value={data}
                                                placeholder="."
                                                onChange={e => handleOTPChange(e.target, index)}
                                                onKeyDown={e => {
                                                    if(index === 3 && e.keyCode === 13){
                                                        smsVerify();
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                                <div className="form-container">
                                    <Button 
                                        onClick={smsVerify}
                                        disabled={otp[3] === ''}
                                        color={'login-primary'}
                                        className='login-button fg-button-block form-button' 
                                    >  
                                        Submit
                                    </Button>
                                    {canResend ? (
                                        <div onClick={sendOTP} style={{color: 'white', fontSize: '12px', cursor: 'pointer', paddingTop: '20px', textDecoration: 'underline', textAlign: 'center'}}>
                                            Didn't get the code? Resend code.
                                        </div>
                                        
                                    ): (
                                        <div  style={{color: 'white', fontSize: '12px', paddingTop: '20px',  textAlign: 'center'}}>
                                            Type your code in {seconds} seconds.
                                        </div>
                                    )}

                                    <div  style={{color: 'white', fontSize: '12px', paddingTop: '20px', textDecoration: 'underline', textAlign: 'center'}}>
                                        <a onClick={() => {
                                        changePage('contact-page');
                                        setCompletedContact(false)
                                        }}
                                        style={{color: 'white', fontSize: '12px', cursor: 'pointer', paddingTop: '20px', textDecoration: 'underline', textAlign: 'center'}}>
                                            Change Number?</a>
                                    </div>
                                </div>
                                <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                        )}
                        

                        {/**PROFILE DETAILS PART 2 */}
                        {((query.has('page') && query.get('page') === 'profile2-page' ))&& (
                            <div className="form profile2-form">
                        <div className="profile-title">
                          {/*
                        <img style={{display: receiverType === 'SELL' ? 'inline-block' : !matches ? 'inline-block' : 'none'}}src={receiverType ? receiverType === 'BUY' ? buyerLogoWhite : receiverType === 'SELL' ? sellerLogoWhite : logo : logo} alt="" className='main_logo'/> 
                          */}
                                <p>Please fill the form below.</p>
                              
                            </div>
                            <form>
                            <div className="form-container">
                                <TextLoginInput 
                                    name='street'
                                    type='text'
                                    value={values.street}
                                    onKeyDown={handleEnter}
                                    onChange={handleChange}
                                    placeholder='Street Address'

                                />
                            </div>
                            {errorProfile2.errors.street !== ''? (
                                <p className='errorProfile2.errors.street' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { errorProfile2.errors.street}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <TextLoginInput 
                                    name='street2'
                                    type='text'
                                    value={values.street2}
                                    onKeyDown={handleEnter}
                                    onChange={handleChange}
                                    placeholder='Street Address 2'
                                />
                            </div>
                            <div className="form-container">
                                <Select
                                    id="demo-simple-select-autowidth"
                                    name="region"
                                    value={values.region}
                                    style={{width: '100%'}}
                                    className={classes.select}
                                    onKeyDown={handleEnter}
                                    inputProps={{
                                        classes: {
                                            icon: classes.selectIcon,
                                            root: classes.labelForm
                                        },
                                        className: classes.multilineColor,
                                        id: 'name-native-error',
                                    }}
                                    
                                    displayEmpty
                                    onChange={(e)=>{handleChange(e)}}
                                >
                                    <MenuItem value="" disabled>
                                        <i>Select Province...</i>
                                    </MenuItem>
                                    {provinces.map((prov : any) => (
                                        <MenuItem key={prov.name} value={prov.name}>{prov.name}</MenuItem>
                                    ))
                                    }
                                    
                                </Select>
                                {errorProfile2.errors.region !== ''? (
                                        <p className='errorProfile2.errors.region' style={{backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                        { errorProfile2.errors.region}
                                        </p>
                                    ) : null}
                            </div>
                            
                            <div className="form-container">
                            
                                <Select
                                    id="demo-simple-select-autowidth"
                                    name="city"
                                    value={values.city}
                                    style={{width: '100%'}}
                                    className={classes.select}
                                    onKeyDown={handleEnter}
                                    inputProps={{
                                        classes: {
                                            icon: classes.selectIcon,
                                            root: classes.labelForm
                                        },
                                        className: classes.multilineColor
                                    }}
                                    displayEmpty
                                    onChange={(e)=>{handleChange(e)}}
                                >
                                    <MenuItem value="" disabled>
                                        <i>Select City...</i>
                                    </MenuItem>
                                    {filter(cities, {province: values.region}).map((city : any) => (
                                        <MenuItem key={city.name} value={city.name}>{city.name}</MenuItem>
                                    ))
                                    }
                                    
                                </Select>
                                {errorProfile2.errors.city !== ''? (
                                    <p className='errorProfile2.errors.city' style={{ backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                    { errorProfile2.errors.city}
                                    </p>
                                ) : null}
                            </div>
                            <div className="form-container">
                                <TextLoginInput 
                                    name='barangay'
                                    type='text'
                                    onKeyDown={handleEnter}
                                    value={values.barangay}
                                    onChange={handleChange}
                                    placeholder='Barangay'
                                />
                            </div>
                            {errorProfile2.errors.barangay !== ''? (
                                <p className='errorProfile2.errors.barangay' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { errorProfile2.errors.barangay}
                                </p>
                            ) : null}
                            
                            <div className="form-container">
                                <TextLoginInput 
                                    name='zip_code'
                                    type='text'
                                    onKeyDown={onKeyPress}
                                    value={values.zip_code}
                                    onChange={(e) => {
                                          
                                        if(e.target.value.match(/^\d+$/) && e.target.value.length < 6){
                                            handleChange(e)
                                        }else if(e.target.value === ''){
                                            handleChange(e)
                                        }
                                    }}
                                    placeholder='Zip Code'
                                />
                            </div>
                            {errorProfile2.errors.zip_code !== ''? (
                                <p className='errorProfile2.errors.zip_code' style={{margin:'-15px 0', backgroundColor: '#ff4c4c', color: 'white', fontWeight: 'bold', border: '1px solid red', borderRadius: '10px', padding: '5px'}}>
                                { errorProfile2.errors.zip_code}
                                </p>
                            ) : null}
                            <div className="form-container">
                                <Button 
                                    onClick={validatePage2}
                                    color={'login-primary'}
                                    className='login-button fg-button-block form-button' 
                                >  
                                    Submit
                                </Button>

                            </div>
                            </form>
                            <div className="form-container">
                                <Button 
                                    onClick={() => {
                                        setCompletedProf1(false);
                                        changePage('profile1-page')
                                    }}
                                    color={'login-primary'}
                                    className='login-button fg-button-block form-button' 
                                >  
                                    Back
                                </Button>

                            </div>
                            <div className="form-container">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <span 
                                                onClick={() => {
                                                    Auth.logout()
                                                    window.location.href = window.location.origin + window.location.pathname+'?page=login-page'
                                                    //history.push(receiverType ? receiverType === 'BUY' ? '/login?page=login-page&applying_for=buyer' : receiverType === 'SELL' ? '/login?page=login-page&applying_for=merchant' : '/login': '/login')
                                                    //changePage('login-page')
                                                }}
                                                style={{cursor: 'pointer', color: 'white', fontSize: '12px', textDecoration: 'underline', padding: '0 0 0 0'}}
                                            >
                                                Logout? 
                                            </span>
                                        </Col>
                                        
                                    </Row>
                                </div>
                        </div>  
                        )}
                    </Grid>
                    </Grid>
                  {/** 
                  <div className="app-pay-login-form">
                    <div className="app-pay-login-title">
                      <h4>Login</h4>
                      <p>Please login to proceed with your purchase</p>
                    </div>
  
                    <div className="form-container">
                      <TextLoginInput
                        name="login_email"
                        type="email"
                        value={values.login_email}
                        onChange={handleChange}
                        placeholder="E-mail"
                        icon={{ prefix: 'far', name: 'envelope' }}
                      />
                    </div>
                    <div className="form-container">
                      <TextLoginInput
                        name="login_password"
                        type="password"
                        value={values.login_password}
                        onChange={handleChange}
                        placeholder="Password"
                        icon={{ prefix: 'fas', name: 'key' }}
                      />
                    </div>
                    <div className="form-container">
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              submitLogin();
                            }}
                            color={'login-primary'}
                            className="login-button fg-button-block form-button"
                          >
                            Log In
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography
                            style={{
                              textDecoration: 'underline',
                              textAlign: 'center',
                              paddingTop: '20px',
                            }}
                          >
                            <a
                              style={{ cursor: 'pointer', color: 'white' }}
                              href="https://dev.thetechsetter.com/login?page=registration-page"
                              target="_blank"
                            >
                              Don't have an account? Click here!{' '}
                            </a>
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography
                            style={{
                              fontSize: '15px',
                              textAlign: 'center',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                            }}
                          >
                            OR{' '}
                          </Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              setPayAsGuest(true);
                            }}
                            color={'login-secondary'}
                            className="login-button fg-button-block form-button"
                          >
                            Pay as Guest
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  */}
                  </>
                )}
              </div>
            )}
            </Grid>
            )}
            </Grid>
            </div>
          
        </div>
        <SuccessDialog 
        openDialog={openDialog} 
        setOpenDialog={setOpenDialog} 
        onClose={() => {
            if(user){
                if(query.get('applying_for') === "buyer"){
                    changePage('add-card-page')
                    if(user.buyAuthorized){
                        console.log("Buy View")
                        history.push('/app/buyer/my-account');
                    }else if(user.sellAuthorized){
                        history.push('/app/seller/my-account');
                    }
                }
                else if(query.get('applying_for') === "merchant"){
                    changePage('apply-merchant-page')
                    if(user.buyAuthorized){
                        history.push('/app/buyer/my-account');
                    }else if(user.sellAuthorized){
                        history.push('/app/seller/my-account');
                    }
                }else{
                    changePage('fork-page');
                }
            }
        }}
        dialogMessage={dialogMessage} />
        <SuccessDialog openDialog={merchantOpenDialog} setOpenDialog={setMerchantOpenDialog} onClose={() => query.get('applying_for') === 'merchant' && Auth.isAuthenticated() ? changePage('pending-page') : history.push('/login?page=login-page&applying_for=merchant') } dialogMessage={merchantDialogMessage} />
        <SuccessDialog 
            openDialog={verifySuccess} 
            setOpenDialog={setVerifSuccess} 
            onClose={() => 
                User.get(
                    (resp) => {
                        if(resp.contact_number, resp.city, resp.gender, resp.region, resp.street, resp.sms_verified){
                            //history.push('/app/my-account')
                            setLoadingOverlay(false)
                            //changePage('fork-page')
                            if(receiverType === "BUY"){
                                changePage('add-card-page')
                                if(resp.buyAuthorized){
                                    window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                }else if(resp.sellAuthorized){
                                    window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                }
                            }
                            else if(receiverType === "SELL"){
                                changePage('apply-merchant-page')
                                if(resp.buyAuthorized){
                                    window.location.href = window.location.origin + `/app/buyer/proposal/${proposalData.id}` ;
                                }else if(resp.sellAuthorized){
                                    window.location.href = window.location.origin + `/app/seller/proposal/${proposalData.id}` ;
                                }
                            }else{
                                changePage('fork-page');
                            }
                           
                           
                        }else if(!resp.gender || !resp.city || !resp.gender || !resp.region || !resp.street ){
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }else if(!resp.contact_number && !resp.sms_verified){
                            setLoadingOverlay(false)
                            changePage('contact-page')
                        }
                        
                        else{
                            setLoadingOverlay(false)
                            changePage('profile1-page')
                        }
                    }
                )
            } 
            dialogMessage={verifSuccessMessage} />
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorDialogMessage} />
        <ErrorDialog openDialog={verifError} setOpenDialog={setVerifError} dialogMessage={verifErrorMessage} />
        <IdleLogoutDialog reference={reference} isProposal={true} openDialog={openIdle} />
      </>
    );
  };
  
  export default ProposalDetailsLogin;
