import React, {useEffect, useState} from 'react';
import { Col, Row } from 'react-grid-system';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, TextField, Theme, Toolbar, Typography, InputAdornment, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import createBrowserHistory from 'history/createBrowserHistory';
import { Button as CustomButton } from '../../components/Button';
import homebg from '../../assets/images/home_icons/home_sell.jpg';
import interestGraphic from '../../assets/images/home_icons/interest-graphic.png';
import fast from '../../assets/images/home_icons/fast.png';
import moneyCards from '../../assets/images/home_icons/mobile-money-graphic.png';
import hiddenFees from '../../assets/images/home_icons/no hidden fees.png';
import comingSoon from '../../assets/images/home_icons/coming soon-graphic.png';
import easySection from '../../assets/images/home_icons/Untitled-8.jpg';
import easySection1 from '../../assets/images/home_icons/Untitled-9.jpg';
import visa from '../../assets/images/home_icons/visa-icon.png';
import mastercard from '../../assets/images/home_icons/mastercard-icon.png';
import jcb from '../../assets/images/home_icons/JCB_logo.svg.png';
import bsp from '../../assets/images/home_icons/bsp-logonew.png';
import npc from '../../assets/images/home_icons/npc.png';
import pci from '../../assets/images/home_icons/pci-dss.svg';
import merchantPhoto from '../../assets/images/merch.jpg';
import merchantPhoto1 from '../../assets/images/merch1.jpg';
import iosBadge from '../../assets/images/ios-badge.png';
import googleBadge from '../../assets/images/google-play-badge.png';
import Auth from '../../auth';
import CheckIcon from '@material-ui/icons/Check';


const logo = require('../../assets/images/4giveslogo.png');
const logoWhite = require('../../assets/images/4giveslogo_white.png');
const pic = require('../../assets/images/pic2-min.png');
const offer = require('../../assets/images/create_offer.png');
const sale = require('../../assets/images/create_sale.png');
const gives = require('../../assets/images/4giveslogoorange.png');
const purchase = require('../../assets/images/purchase.png')


const useStyles = makeStyles((theme) => ({
  selectInput: {
      minWidth: 300,
      maxWidth: 300,
  },
  multilineColor: {
    color: 'white',
    fontSize: '20px',
    width: '130px'
  },
  multilineColorMobile: {
    color: 'white',
    fontSize: '16px',
    width: '70px'
  },
  select: {
    '&:before': {
        borderColor: 'rgba(255, 133, 51, 0.42)',
    },
    '&:after': {
        borderColor: '#FF873A',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `1px solid #FF873A`
    },
    
  },
  selectIcon: {
      fill: '#ececec',
  },
  labelForm: {
    color: '#FF873A',
    margin: '0 auto',
  },
  btnBorderColor: {
    border:"none",
    outline:"none",
    borderColor: '#FF873A',
    borderWidth: '0px',
    padding: '10px',
    color: '#FF873A',
    backgroundColor: '#fee2b3',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    '&:active': {
      outline: 'none',
    },
  },
  btnBorderColorSelected: {
    border:"none",
    outline:"none",
    borderColor: '#FF873A',
    borderWidth: '0px',
    padding: '10px',
    color: 'white',
    backgroundColor: '#FFA500',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    '&:active': {
      outline: 'none',
    },
  },
  labelColor: {
    color: 'white',
  },
  cssFocused: {
    color: 'white',
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid white'
    },
    '&:after': {
        borderBottom: `2px solid white`
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid white`
    }
  },
  largeIcon: {
    width: 70,
    height: 70,
    fill: '#FF873A'
  },
}));

export const Content: React.FC<{
  howRef: any;
  calcRef: any;
  reqRef: any;
}> = ({howRef, calcRef, reqRef}) => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const contentMatches = useMediaQuery('(min-width: 1025px)');
    const tabletMatches = useMediaQuery('(max-width: 1024px) and (min-width: 768px)')
    const phoneMatches = useMediaQuery('(max-width: 500px)')
    const [amount, setAmount] = useState<string | null>('2000')
    const [month, setMonth] = useState(3);
    const [perMonth, setPerMonth] = useState<any | null>(null)
    const [isError, setIsError] = useState(false)
    const [interest, setInterest] = useState(0);
    const foldMatches = useMediaQuery('(max-width: 280px)')
    const handleAmountChange = (str: string) => {
      if(/^[0-9]*$/.test(str)){
        setAmount(str)
        setIsError(false)
      }else{
        setIsError(true)
      }
    }

    useEffect(() => {
      if((amount !== '' && amount !== '0') && month > 0){
        computeInstallment(amount, month)
      }
    },[amount, month])

    const computeInstallment = (amount: string | null, month: number) => {
      Auth.installmentCompute(
        amount,
        month,
        (resp) => {
          console.log(resp)
          setPerMonth(resp.data.monthly)
          setInterest(resp.data.interest)
        },
        (e) => {
          console.log(e)
        }
      )
    }
    
      return(
        <div className="content">
          <div className="merchant-content-main-container">
            <div className='content-pic'>
              <Grid container item direction="row" 
               
                alignItems="center"  justify="center">
                  {!contentMatches && (
                    <Grid xs={12} container item style={{background: `url(${homebg})`}}>
                      <img src={homebg} style={{height: '50%', width: '100vw', position: 'relative'}} />
                    </Grid>
                  )}
                <Container>
                  
                <Grid item sm={12} style={{height: tabletMatches ? '0px' : !contentMatches ? '0px' :'600px', width: '600px'}}>
                  {contentMatches ? (

                
                    <div className="content-main-desc" style={{ textAlign: 'left', padding: matches ?'20px 0px' : '20px 0px 50px'}}>
                      <Typography variant={!matches ? "h2" : "h2"} style={{color: 'white', fontWeight: 'bold'}}>
                        Sell with <br/> 4Gives
                      </Typography>
                      <Typography variant={"h6"} style={{color: "white", padding:'10px 0px 60px'}}>
                        The easiest way to start selling in installments.
                        <br />
                        Let's grow your business, together.
                      </Typography>
                      <CustomButton 
                          color='home'
                          onClick={() => history.push('/login?page=registration-page&applying_for=merchant')}
                          className='fg-home'
                      >
                          Start Now!
                      </CustomButton>
                      {/** 
                      <Typography variant={"body1"} style={{color: "black", padding:'30px 30px 10px 30px'}}>
                        Or download our mobile app:
                      </Typography>
                      <img src={iosBadge} style={{height: '100%', width: '200.00px'}}/>
                      <img src={googleBadge} style={{height: '100%', width: '200.00px'}}/>
                      */}
                    </div>
                  ): (
                    <div style={{ textAlign: 'left', padding: matches ?'20px 0px' : '20px 0px 50px', position: 'absolute', top: tabletMatches ? '450px' : foldMatches ? '220px':'250px', left: tabletMatches ?'300px' : '120px',  msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)'}}>
                      <Typography className="content-main-headline"  variant={tabletMatches ? "h2" : 'h6'} style={{color: 'white', fontWeight: 'bold'}}>
                        Sell with 4Gives
                      </Typography>
                      <Typography className="content-main-body"  variant={ tabletMatches ? "h5" : "body1"} style={{color: "white", padding: '10px 0px 80px'}}>
                        The easiest way to start <br /> selling in installments.
                        <br />
                        Let's grow your business, <br />together.
                      </Typography>
                      <CustomButton 
                          color='home'
                          onClick={() => history.push('/login?page=registration-page&applying_for=merchant')}
                          className='fg-home'
                      >
                          Start Now!
                      </CustomButton>
                      {/** 
                      <Typography variant={"body1"} style={{color: "black", padding:'30px 30px 10px 30px'}}>
                        Or download our mobile app:
                      </Typography>
                      <img src={iosBadge} style={{height: '100%', width: '200.00px'}}/>
                      <img src={googleBadge} style={{height: '100%', width: '200.00px'}}/>
                      */}
                    </div>
                  )}
                </Grid>
          
          
                </Container>
              </Grid>
            </div>
          </div>
          <div style={{padding: '50px 20px'}}  id="easy">
              <Container>
                  <Typography className="content-main-headline"  variant={!matches ? "h5" : "h4"} style={{ color: 'black', fontWeight: 'bold', paddingBottom: '20px'}}>
                    The easiest way anyone can start selling in installments.
                  </Typography>
                <Grid container direction="row" spacing={4} alignItems="flex-start" justify="center">
                  <Grid container xs={12} spacing={2} sm={7} item direction="column" justify="center">
                
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={fast} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px' , wordWrap:"break-word"}}>
                          Easy to integrate with your online store
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={moneyCards} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          No online store? No problem, send payment links directly through chat, viber or messenger!
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={hiddenFees} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          No hidden fees, no set-up fees, no monthly
                          fees. Only 3.5% + 20php per transaction
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container  direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={comingSoon} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          Pay through salary deductions by Debit Card, soon!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={5} item direction="column">
                      
                      <img src={easySection} style={{height: phoneMatches ? '300px' :'400px', width: 'auto', objectFit: 'contain'}}/>
                  </Grid>
                </Grid>
              </Container>
          </div>
          <div style={{padding: '30px 20px 50px', background: '#FF873A'}}  id="howRef" ref={howRef}>
            <Container>
              <Typography variant={!matches ? "h6" : "h4"} style={{color: 'white', paddingTop: 20, fontWeight: 500, textAlign: 'center', paddingBottom: '20px'}}>
                How it Works
              </Typography>
              <Grid container item direction="column" alignItems="center" justify="center" >
                <Grid container direction="row" item justify="center" alignItems="center" spacing={1}>
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body"  variant={"h6"} style={{textAlign:'center', color: '#FF873A', fontWeight: 'bold'}}>
                        1
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body"  variant={"h6"} style={{ color: 'white'}}>
                    Create a 4Gives account with your e-mail
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body"  variant={"h6"} style={{textAlign:'center', color: '#FF873A', fontWeight: 'bold'}}>
                        2
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body"  variant={"h6"} style={{ color: 'white'}}>
                    Submit your ID, connect a bank account to get approved in 48 hours.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body"  variant={"h6"} style={{textAlign:'center', color: '#FF873A', fontWeight: 'bold'}}>
                        3
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body"  variant={"h6"} style={{ color: 'white'}}>
                    Integrate with your online store or send payment links through viber, chat and messenger.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}/>
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body"  variant={"h6"} style={{textAlign:'center', color: '#FF873A', fontWeight: 'bold'}}>
                        4
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body"  variant={"h6"} style={{ color: 'white'}}>
                    Your customers pay in installments and you get paid upfront!

                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: '20px'}}/>
                  <Grid item xs={12}>
                    <Typography className="content-main-body" variant={"body1"} style={{ color: 'white', textDecoration: 'underline', textAlign: 'center' }}>
                      <span onClick={() => history.push('/merchant/faqs')} className="content-main-body" style={{cursor:'pointer', color: 'white', fontSize: '16px'}}>Have more questions? Visit our FAQs.
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: '20px'}}/>
                  <CustomButton 
                      color='home'
                      onClick={() => history.push('/login?page=registration-page&applying_for=merchant')}
                      className='fg-home'
                  >
                      Start Now!
                  </CustomButton>
                </Grid>
              </Grid>
              
            </Container>
          </div>
          <div style={{padding: '50px 20px'}}  id="easy">
              <Container>
                  <Typography className="content-main-headline"  variant={!matches ? "h5" : "h4"} style={{ color: 'black', fontWeight: 'bold', paddingBottom: '20px'}}>
                  Grow your sales by giving your customers flexible payment options
                  </Typography>
                <Grid container direction="row" spacing={4} alignItems="center" justify="center">
                  <Grid container xs={12} spacing={2} sm={7} item direction="column" alignItems="flex-start" justify="center">
                
     
                    
                      <Grid item xs={12}>
                        <ul>
                          <li>
                        <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', wordWrap:"break-word"}}>
                          
                        With 4Gives you can start receiving payments with any
                        Credit Card, while allowing your customers to pay in up to 6
                        monthly installments with a low interest rate, starting at 2%.

                            
                          
                        </Typography>
                        </li>
                          </ul>
                   
                      </Grid>
                   
                      <Grid item xs={12}>
                        <ul>
                            <li>
                          <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', wordWrap:"break-word"}}>
                            
                          We don't charge any set-up fees, monthly fees or hidden
                          fees and only 3.5% + 20 php per transaction.
                              
                            
                          </Typography>
                          </li>
                        </ul>
                      </Grid>
                
                   
                      <Grid item xs={12}>
                        <ul>
                            <li>
                          <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', wordWrap:"break-word"}}>
                            
                          Your customers pay in installments, you get paid upfront, we
                          assume all the risk if any of them fail to pay.
                            
                          </Typography>
                          </li>
                        </ul>
                      </Grid>
                  
                  
                      <Grid item xs={12}>
                        <ul>
                            <li>
                          <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', wordWrap:"break-word"}}>
                            
                          Connect 4Gives to your online store or use our app to send
                          payment requests directly through chat, messenger or Viber.
                              
                            
                          </Typography>
                          </li>
                        </ul>
                      </Grid>
               
                  </Grid>
                  <Grid container xs={12} sm={5} item direction="column">
                      
                      <img src={easySection1} style={{height: phoneMatches ? '300px' :'400px', width: 'auto', objectFit: 'contain'}}/>
                  </Grid>
                </Grid>
              </Container>
          </div>
          <div style={{padding: '30px', background: '#FF873A'}}  id="howRef" ref={howRef}>
            <Container>
              <Typography className="content-main-headline"  variant={!matches ? "h6" : "h4"} style={{color: 'white', paddingTop: 20, fontWeight: 500, textAlign: 'center', paddingBottom: '20px'}}>
                Start accepting payments in up to 12 installments with any Credit Card
              </Typography>
              <Typography className="content-main-body"  variant={"h6"} style={{textAlign:'center', color: 'white'}}>
                Enable your customers to pay with any Visa, Mastercard or JCB Credit
                Card, and pay only 3.5% +20php per transaction.
              </Typography>
              <div style={{padding: '20px'}}/>
              <Grid container direction="row" spacing={3} alignItems="center" justify="center">
                <Grid item>
                  <img src={visa} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                </Grid>
                <Grid item>
                  <img src={mastercard} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                </Grid>
                <Grid item>
                  <img src={jcb} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                </Grid>
              </Grid>
              <Grid container item direction="column" alignItems="center" justify="center" spacing={matches ? 9 : 2}>
                
                  <Grid item xs={12} style={{margin: '30px 0px 20px'}}>
                    <CustomButton 
                        color='home'
                        onClick={() => history.push('/login?page=registration-page&applying_for=merchant')}
                        className='fg-home'
                    >
                        Start Now!
                    </CustomButton>
                  </Grid>
              </Grid>
              
            </Container>
          </div>
        
         
          
          
          <div style={{background: 'white', padding: '50px'}}>
            <Container>
              <Grid container item direction="column" alignItems="center" spacing={2} justify="center" >
                <Grid item>
                  <Typography className="content-main-headline"  variant={!matches ? "h6" : "h4"} style={{color: 'black', fontWeight: 500}}>
                    Why 4Gives
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{color: 'black', lineHeight: 2}}>
                    Our mission is to financially empower filipinos to buy anything they
                    need, while helping merchants and sellers grow, through inclusive,
                    fair and safe credit for everyone.
                  </Typography>
                </Grid>
                <Grid container direction="row" spacing={5} alignItems="center" justify="center" item>
                  <Grid item>
                    <img src={bsp} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
{/*                   <Grid item>
                    <img src={npc} style={{width: '130px', height: 'auto', objectFit:'contain'}} />
                  </Grid> */}
                  <Grid item>
                    <img src={pci} style={{width: '130px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
                </Grid>
           
              </Grid>
            </Container>
          </div>
        </div>
      )
}

export default Content;