import { ApiRequest, HttpRequest } from '../services/http';
import URL from '../services/url';
import { User }  from '../models';
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js';
import axios from 'axios';
// AUTH localstorage
// isAuthenticated: boolean
//localStorage.getItem('key')

 
//const {encryptString, decryptString} = new StringCrypto(options);
const cryptoPassword = "testinggggg"



const Auth = {
    key: () => {
        var newKey;
        var decrypted;
        if(Cookies.get('key') && Cookies.get('key') !== ''){
            newKey = Cookies.get('key');
            if(newKey !== undefined){
                decrypted = CryptoJS.AES.decrypt(newKey, cryptoPassword).toString(CryptoJS.enc.Utf8)
                //console.log(decrypted)
                return decrypted;
            }
            
            
           
        }else{
            return null;
        }
    },
    isAuthenticated: () => {
        const isAuthenticated = Cookies.get('key');
        if(isAuthenticated !== undefined){
            return true;
        }else{
            console.log(isAuthenticated);
        }
        
    },

    login:  (email: string, password: string,
        success: (e?: any) => void, failed: (e?: any) => void ) => {
      
        HttpRequest.post(URL.LOGIN, {
            email,
            password,
        })
        .then(  (response) => {
            
            
            let encryptedData =  CryptoJS.AES.encrypt(response.data.key, cryptoPassword).toString();
            //console.log(encryptedData)
            localStorage.setItem('key', encryptedData);
            Cookies.set('key', encryptedData);
            if(encryptedData){
                success(response);
            }
            
        })
        .catch(e => {
            failed(e.response.data)
        });
    },

    register: (username: string, email: string, password1: string, password2: string,
        success: (e?: any) => void, failed: (e?: any) => void ) => {
            HttpRequest.post(URL.REGISTRATION, {
                username,
                email,
                password: password1,
                password2,
            })
            .then(response => {
            
                let encryptedData =  CryptoJS.AES.encrypt(response.data.key, cryptoPassword).toString();
                //console.log(encryptedData)
                localStorage.setItem('key', encryptedData);
                Cookies.set('key', encryptedData);
                if(encryptedData){
                    success(response);
                }
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    forgotPassword: (email: string, success: (e?: any) => void, failed: (e?: any) => void ) => {
        HttpRequest.post(URL.FORGOT_PASSWORD, {
            email
        })
            .then(resp=> {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    newPassword: (token: string, uid: any, password: string, success: (e?: any) => void, failed: (e?: any) => void ) => {
        HttpRequest.post(URL.NEW_PASSWORD, {
            token,
            password,
            uid
        })
        .then(resp => {
            success(resp.data);
        })
        .catch(e => {
            failed(e.response.data)
        })
    },

    authDevice: (registration_id: string | null, success: (e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.post(URL.NOTIFICATIONS, {
            registration_id,
            type: 'web'
        })
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    verifyEmail:(id: any | null, code: string | null, success: (e?: any) => void, 
        failed: (e?: any) => void) => {
            HttpRequest.put(URL.USER.PROFILE+URL.USER.VERIFY, {
                uid: id,
                confirmation_key: code
            })
                .then(response => {
                    console.log(response.data)
                    success(response.data)
                })
                .catch(e => {
                    console.log(e.response.data);
                    failed(e.response.data)
                }) 
    },

    authVerifyEmail:(id: any | null, code: string | null, success: (e?: any) => void, 
        failed: (e?: any) => void) => {
            if(id){
                HttpRequest.put(URL.USER.PROFILE+URL.USER.VERIFY, {
                    uid: id,
                    confirmation_key: code
                })
                    .then(response => {
                        console.log(response.data)
                        success(response.data)
                    })
                    .catch(e => {
                        console.log(e.response.data);
                        failed(e.response.data)
                    }) 
            }else{
                ApiRequest.put(URL.USER.PROFILE+URL.USER.VERIFY, {
                    uid: id,
                    confirmation_key: code
                })
                    .then(response => {
                        console.log(response.data)
                        success(response.data)
                    })
                    .catch(e => {
                        console.log(e.response.data);
                        failed(e.response.data)
                    }) 
            }
            
    },

    getIDViaEmail: (email: string | null, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.get(URL.USER.PROFILE+`${email}`)
            .then(response => {
                success(response.data.id)
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data)
            })
    },

    getUser: (id: string | null, success:(e?: any) => void, failed:(e?: any) => void)=> {
        ApiRequest.get(URL.USER.PROFILE+`${id}/`)
            .then(response => {
                success(response.data)
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data)
            })
    },

    postProposalSellLink: (name: string | null, description: string | null, category: any | null, terms: string | null, payment_type: any | null,
        TotalAmount: any | null, is_interest_shouldered_by_merchant: any | null, is_link: any, limit: any, method: any, success:(e?: any) => void, failed:(e?: any) => void) => {
        
        ApiRequest.post(URL.PROPOSALS.PROPOSAL_SELL, {
            name,
            description,
            total_amount: parseInt(TotalAmount),
            payment_type,
            terms,
            category,
            is_interest_shouldered_by_merchant,
            is_link,
            limit: parseInt(limit),
            method
        })
        .then(response => {
            console.log(response.data);
            success(response.data);
        })
        .catch(e => {
            console.log(e)
            if(e.response !== undefined){
                console.log(e.response.data ? e.response.data : e.response);
                failed(e.response.data ? e.response.data : e.response)
            }
        })
    },

    generateKeys: (type: string, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.post(URL.USER.API_KEYS,{
            type
        })
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    getMerchantKeys: (success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.get(URL.USER.API_KEYS)
            .then((response) => {
                success(response.data)
            })
            .catch((e) => {
                failed(e.response.data)
            })
    },

    deleteApiKey: (id: any, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.delete(URL.USER.API_KEYS+id)
        .then((response) => {
            success(response.data)
        })
        .catch((e) => {
            failed(e.response.data)
        })
    },

    getMerchantProfile: (success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.get(URL.USER.MERCHANT_PROFILE)
            .then((response) => {
                success(response.data)
            })
            .catch((e) => {
                failed(e.response.data)
            })
    },

    updateWebHook: (webhook_url: string, transaction_name: string, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.patch(URL.USER.MERCHANT_PROFILE,{
            webhook_url,
            transaction_name
        })
            .then((response) => {
                success(response.data)
            })
            .catch((e) => {
                failed(e.response.data)
            })
    },

    getServiceTypes: (success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.SERVICE_TYPES)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    getCategory: (success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.CATEGORY)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    getProposalsSell:(args: string | null, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSALS_SELL+args)
            .then(response => {
                console.log(response)
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data);
            })
    },
    
    getProposalsSellAccepted:(args: string | null, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSALS_SELL_ACCEPTED+args)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            }) 
    },

    getProposalsSellWaiting:(args: string | null, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSALS_SELL_WAITING+args)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            }) 
    },

    getProposalsBuyAccepted:(args: string | null, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSALS_BUY_ACCEPTED+args)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            }) 
    },

    getProposalsBuyWaiting:(args: string | null, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSALS_BUY_WAITING+args)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            }) 
    },

    patchProposal: (proposalID: string, buyer: string | null, merchant: string | null, success:(e?:any) => void, 
        failed:(e?: any) => void) =>{
        ApiRequest.patch(URL.PROPOSALS.PROPOSAL+`${proposalID}/`, {
            buyer,
            merchant
        })
        .then(response => {
            console.log(response.data)
            success(response.data)
        })
        .catch(e => {
            console.log(e.response.data);
            failed(e.response.data)
        })
    },

    patchProposalPaymentType: (id: any, payment_type: any, success:(e?:any) => void, 
        failed:(e?: any) => void) => {
            ApiRequest.patch(URL.PROPOSALS.PROPOSAL+`${id}/`, {
                payment_type
            })
                .then(response => {
                    success(response.data)
                })
                .catch(e => {
                    failed(e.response.data)
                })
    },

    postProposalBuyLink: (name: string | null, description: string| null, terms: string | null,
        TotalAmount: any | null, category: any, payment_type: any, method: any, success:(e?: any) => void, failed: (e?: any) => void) => {
        
        TotalAmount = parseFloat(TotalAmount);
        
        ApiRequest.post(URL.PROPOSALS.PROPOSAL_BUY, {
            name,
            category,
            payment_type,
            terms,
            description,
            total_amount: parseInt(TotalAmount),
            method
        })
        .then(response =>  {
            console.log(response.data);
            success(response.data);
        })
        .catch(e => {
            console.log(e.response.data);
            failed(e.response.data);
        })
    },

    getProposalLink: (id: any, success:(e?: any) => void, failed:(e?:any) => void) => {
        HttpRequest.get(URL.PROPOSALS.GET_PROPOSAL_LINK+id)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    getProposalsBuy:(args: string | null, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSALS_BUY+args)
            .then(response => {
                console.log(response)
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data);
            })
    },

    getTransactions:(args: string | undefined, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.TRANSACTIONS_BASE+args)
            .then(response => {
                console.log(response)
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data);
            })
    },

    getTransaction:(id: any | undefined, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.TRANSACTIONS_BASE+"/"+id+"/")
            .then(response => {
                console.log(response)
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data);
            })
    },

    getTransactionsBuy:(args: string | undefined, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.TRANSACTIONS_BUY+args)
            .then(response => {
                console.log(response)
                success(response.data);
            })
            .catch(e => {
                failed(e.response.data);
            })
    },

    getTransactionsSell:(args: string | undefined, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.TRANSACTIONS_SELL+args)
            .then(response => {
                console.log(response)
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data);
            })
    },

    getRecentActivities:(args: string | undefined, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.TRANSACTIONS_ACTIVITY+args)
            .then(response => {
                console.log(response.data)
                success(response.data)
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data)
            })
    },

    updateProposal:(id: any, terms: any, payment_type: any, success:(e?: any) => void, 
        failed: (e?: any) => void)=>{
            ApiRequest.patch(URL.PROPOSALS.PROPOSAL+id+'/', {
                terms,
                payment_type
            })
            .then(response => {
                console.log(response.data)
                success(response.data)
            })
            .catch(e => {
                console.log(e)
                failed(e)
            })
    },

    getProposal: (id: any, success:(e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.PROPOSALS.PROPOSAL+id+'/')
            .then(response => {
                console.log(response.data)
                success(response.data)
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data);
            })
    },

    acceptProposal: (pid: any, sid: any, success:(e?: any) => void, 
        failed: (e?: any) => void) => {
            ApiRequest.post(URL.PROPOSALS.PROPOSAL_ACCEPT, {
                pid,
                sid,
            })
                .then(response => {
                    success(response.data)
                })
                .catch(e => {
                    if(e.response){
                        failed(e.response)
                    }
                    
                })
    },

    acceptSellerProposal: (id: any, merchant: any | null, buyer: any | null, payment_type: any | null, success:(e?: any) => void, 
        failed: (e?: any) => void) => {
            ApiRequest.patch(URL.PROPOSALS.PROPOSAL+`${id}/`,{
                id,
                merchant,
                buyer,
                payment_type,
                status: "ACCEPTED"
            })
                .then(response => {
                    console.log(response.data)
                    success(response.data)
                })
                .catch(e => {
                    console.log(e.response.data)
                    failed(e.response.data)
                })
    },

    postTransaction: (id: any | null, name: any | null, description: any | null, merchant: any | null,
        tag: any | null, buyer: any | null, amount: any | null, terms: any | null, transaction_fee: any | null,
        monthly: any | null, interest_charge: any | null, interest: any | null, status: any | null, category: any | null,
        success:(e?: any) => void, failed: (e?: any) => void) => {
            ApiRequest.post(URL.TRANSACTIONS.TRANSACTIONS_BASE+"/", {
                id,
                name,
                description,
                merchant,
                tag,
                buyer,
                amount,
                terms,
                transaction_fee,
                monthly,
                interest_charge,
                interest,
                status,
                category,
            })
            .then(response => {
                console.log(response.data)
                success(response.data)
            })
            .catch(e => {
                console.log(e.response.data)
                failed(e.response.data)
            })
    },
    
    addIDFront: (IDFront: any | null, success: (e?: any)=> void, failed: (e?:any) => void) => {
        ApiRequest.patch(URL.USER.PROFILE, {
            IDFront
        })
            .then(response => {
                success(response.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    upgradeAcc: (IDFront: any | null, IDBack: any | null, Bankproof: any | null, bankCode: string , bankAccountNumber: any ,
        success: (e?: any) => void, failed: (e?: any) => void) => {
            console.log({IDFront})
            let formData = new FormData();
            formData.append('IDFront', IDFront)
            formData.append('IDBack', IDBack)
            formData.append('Bankproof', Bankproof)
            formData.append('bankCode', bankCode)
            formData.append('bankAccountNumber', bankAccountNumber)
            formData.append('submit_merchant_application', 'true')
            formData.append('_method', 'PATCH')
            ApiRequest.patch(URL.USER.PROFILE, formData , true)
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data);
            })
    },


    accUpgrade: {
        uploadIDFront: (IDFront: any | null, success: (e?: any) => void, failed: (e?: any) => void) => {
            let formData = new FormData();
            formData.append('IDFront', IDFront)
            formData.append('_method', 'PATCH')
            ApiRequest.patch(URL.USER.PROFILE, formData, true)
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data);
            })
        },
    
        uploadIDBack: (IDBack: any | null, success: (e?: any) => void, failed: (e?: any) => void) => {
            let formData = new FormData();
            formData.append('IDBack', IDBack)
            formData.append('_method', 'PATCH')
            ApiRequest.patch(URL.USER.PROFILE, formData, true)
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data);
            })
        },
    
        uploadBankproof: (Bankproof: any | null, success: (e?: any) => void, failed: (e?: any) => void) => {
            let formData = new FormData();
            formData.append('Bankproof', Bankproof)
            formData.append('_method', 'PATCH')
            ApiRequest.patch(URL.USER.PROFILE, formData, true)
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data);
            })
        },
        
        updateBankDetails: (bankCode: any | null, bankAccountNumber: any | null, success: (e?: any) => void, failed: (e?: any) => void) => {
            ApiRequest.patch(URL.USER.PROFILE, {
                bankCode,
                bankAccountNumber,
                submit_merchant_application: true
            })
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data);
            })
        },
    },
    
    
    submitCode: (reference: any | null, success: (e?: any) => void, failed: (e?: any) => void) => {
        HttpRequest.post(URL.PROPOSALS.REFERENCE_GENERATE,{
            reference
        })
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    activateAcc: (id: string, reference_id: string, Cardstatement: string | null, 
        cardCutoff: string, cardLimit: string, success: (e?: any) => void, 
        failed: (e?: any) => void) => {
        ApiRequest.patch(URL.TRANSACTIONS.PAYMENT_SOURCE+"/"+id+'/', {
            id,
            reference_id,
            Cardstatement,
            cardCutoff,
            cardLimit,
            submit_installment_authorize_application: true
        })
        .then(response => {
            console.log(response.data)
            success(response.data)
        })
        .catch(e => {
            console.log(e.response.data)
            failed(e.response.data)
        })
    },

    paymayaInit: () =>{
        ApiRequest.post(URL.PAYMAYA.PAYMAYA_ACCOUNT)
        .then(response => {
            console.log(response.data)
        })
        .catch(e => {
            console.log(e.response.data);
        })
    },

    installmentCompute: (amount: any | null, terms: any | null, success:(e?: any) => void, failed:(e?: any) => void) => { 
        HttpRequest.post(URL.TRANSACTIONS.COMPUTATION, {
            amount,
            terms
        })
            .then((response) => {
                success(response.data)
            })
            .catch((e) => {
                failed(e.response.data)
            })
    },

    updateProfile: (first_name: string, middle_name: string,  last_name: string, gender: string,
        street: string, street2: string, region: string, city: string,
        barangay: string, zip_code: string, success: (e?: any) => void,
        failed: (e?: any) => void) =>{
            ApiRequest.patch(URL.USER.PROFILE, {
                first_name,
                middle_name,
                last_name,
                gender,
                street,
                street2,
                region,
                city,
                barangay,
                zip_code
            })
            .then((response) => {
                console.log(response.data)
                success(response.data)
            })
            .catch((e) => {
                console.log(e.response.data);
                failed(e.response.data)
            })
    },
    
    

    updateProfilePage: (first_name: string, middle_name: string, last_name: string, gender: string,
        street: string, street2: string, region: string, city: string,
        barangay: string, zip_code: string, success: (e?: any) => void,
        failed: (e?: any) => void) =>{
            ApiRequest.patch(URL.USER.PROFILE, {
                first_name,
                middle_name,
                last_name,
                gender,
                street,
                street2,
                region,
                city,
                barangay,
                zip_code
            })
            .then((response) => {
                console.log(response.data)
                success(response.data)
            })
            .catch((e) => {
                console.log(e.response.data);
                failed(e.response.data)
            })
    },

    updateAvatar: (profile_picture: any, success: (e?: any) => void,
        failed: (e?: any) => void) => {
        ApiRequest.patch(URL.USER.PROFILE,{
            profile_picture
        })
        .then((response) => {
            console.log(response.data)
            success(response.data)
        })
        .catch((e) => {
            console.log(e.response.data)
            failed(e.response.data)
        })
    },

    addCard: (payment_token: string, proposal_id: any | null,
        success: (e?: any) => void, failed: (e?: any) => void) => {


            ApiRequest.post(URL.PAYMAYA.PAYMAYA_CARDS, {
                payment_token,
                proposal_id
            })
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data)
            })

    },

    initSMSVerification: (number: string | null, success: (e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.post(URL.USER.NUMBER, {
            number
        })
            .then(resp => {
                success(resp.data)
            })
            .catch((e) => {
                failed(e.response.data)
            })
    },

    smsVerification: (uid: string | null, code: string | null,
        success:(e?:any) => void, failed:(e?: any) => void) => {
        ApiRequest.post(URL.USER.SMS_VERIFY, {
            uid,
            code
        })
            .then(response => {
                success(response.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    tokenize_card: (number: string, expMonth: string, expYear: string, cvc: string, 
        success: (e?: any) => void, failed: (e?: any) => void) => {

            ApiRequest.paymayaPost('https://pg.paymaya.com/payments/v1/payment-tokens', {
                card: {
                    number,
                    expMonth,
                    expYear,
                    cvc,
                }
            })
            .then(response => {
                console.log(response.data);
                success(response.data);
            })
            .catch(e => {
                console.log(e.response.data);
                failed(e.response.data)
            })

},

    paymonggo_tokenize_card: (card_number: string, exp_month: any, exp_year: any, cvc: string, line1: string,
        line2: string, city: string, state: string, postal_code: string, name: string,
        email: string, phone: string,
            success: (e?: any) => void, failed: (e?: any) => void) => {
                ApiRequest.paymonggoPost('https://api.paymongo.com/v1/payment_methods', {
                    data: {
                    attributes: {
                        type: "card",
                        
                    details: {
                        card_number,
                        exp_month: parseInt(exp_month),
                        exp_year: parseInt(exp_year),
                        cvc,
                    }, 
                    billing: {
                        address: {
                            line1,
                            line2,
                            city,
                            state,
                            postal_code,
                            country: 'PH',
                        },
                        name,
                        email,
                        phone,
                    }
                
            }
                    }
                })
                .then(response => {
                    console.log(response.data.data)
                    success(response.data.data);
                })
                .catch(e => {
                    console.log(e.response.data.errors[0].detail, 'error test')
                   
                   failed(e.response.data.errors[0].detail); 
                })
    
    },

    onDemandPay: (payment_id: any, payment_token: any, success: (e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.post(URL.TRANSACTIONS.ON_DEMAND_PAYMENT, {
            payment_id,
            payment_token
        })
            .then(response => {
                success(response.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },
        
    getCards: (success: (e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.PAYMENT_SOURCE)
            .then(response => {
                success(response.data.results);
            })
            .catch(e => {
                failed(e.response.data);
            })
    },

    deleteCard: ( id: any | null, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.delete(URL.TRANSACTIONS.PAYMENT_SOURCE+`/${id}`)
            .then(response => {
                success(response.data)
            })
            .catch(e => {
                failed(e.response.data)
            }) 
    },

    getTransactionCreateDetails: (args: any | null, success:(e?:any) => void, failed:(e?: any) => void) => {
        HttpRequest.get(URL.TRANSACTIONS.CREATE+args)
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {   
                console.log(e)
                failed(e.response.data)
            })
    },
    getTransactionGcashDetails: (name: any, guest_name: any, buyer: any, address: any, amount: any, sub_merchant_id: any, sub_merchant_name: any,
        reference: any, success_redirect_url: any, failure_redirect_url: any, api_key: any, pay_as_guest: boolean,
        success:(e?: any) => void, failed:(e?: any) => void) => {
        const headers = {
            'Content-Type': 'application/json',
            'Api-key': api_key,
        }
        const payload = {
            name,
            guest_name,
            buyer,
            address,
            amount,
            terms: 1,
            sub_merchant_id,
            sub_merchant_name,
            reference,
            success_redirect_url,
            failure_redirect_url,
            pay_as_guest
        }

        HttpRequest.post(URL.TRANSACTIONS.GCASH_CREATE, payload , {headers})
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {   
                failed(e.response.data)
            })
    },

    appPaySubmit: (name: any, amount: any, terms: any, payment_type_id: any,
        merchant_id: any, payment_source_id: any, reference: any,
        success:(e?: any) => void, 
        failed:(e?: any) => void) => {
        ApiRequest.post(URL.TRANSACTIONS.CREATE, {
            name,
            amount,
            terms,
            payment_type_id,
            merchant_id,
            payment_source_id,
            reference,
            pay_as_guest: false,
        })
            .then((resp) => {
                success(resp.data)
            })
            .catch(e=>{
                failed(e.response.data.error[0].detail)
            })
    },

    appPayAsGuestSubmit: (name: any,  first_name: any, last_name: any, buyer: any, address: any, amount: any, merchant_id: any, 
        reference: any, payment_token: any, success_redirect_url: any, failure_redirect_url: any,
        success:(e?: any) => void, failed:(e?: any) => void) => {

        HttpRequest.post(URL.TRANSACTIONS.CREATE, {
            name,
            guest_name: first_name+" "+last_name,
            buyer,
            address,
            amount,
            terms: 1,
            payment_type_id: 1,
            merchant_id,
            payment_token,
            reference,
            pay_as_guest: true,
            success_redirect_url,
            failure_redirect_url
        })
            .then((resp) => {
                success(resp.data)
            })
            .catch(e=>{
                failed(e.response.data.error[0].detail)
            })
    },

    proposalPayAsGuestSubmit: (first_name: any, last_name: any, proposal_id: any, payment_token: any,
        success:(e?: any) => void, failed:(e?: any) => void) => {
        HttpRequest.post(URL.TRANSACTIONS.CREATE, {
            guest_name: first_name+" "+last_name,
            terms: 1,
            payment_type_id: 1,
            proposal_id,
            payment_token,
            pay_as_guest: true,
        })
            .then((resp) => {
                success(resp.data)
            })
            .catch(e=>{
                failed(e.response.data)
            })
    },

    sendOTP: (number: any | null, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.post(URL.OTP.SEND, {
            number
        })
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            }) 
    },

    otpSend: (success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.post(URL.TRANSACTIONS.OTP_SEND)
        .then(resp => {
            success(resp.data)
        })
        .catch(e => {
            failed(e.response.data)
        }) 
    },

    resendVerifEmail: (email: any, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.post(URL.USER.RESEND_VERIF, {
            email
        })
            .then((resp) => {
                success(resp.data)
            })
            .catch((e)=> {
                failed(e.response.data)
            })
    },

    updateNumber: (contact_number: any, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.patch(URL.USER.PROFILE,{
            contact_number: '+63'+contact_number
        })
            .then((resp) => {
                success(resp.data)
            })
            .catch((e) => {
                failed(e.response.data)
            })
    },

    verifyOTP: (uid: string | null, code: any | null, success:(e?: any) => void, failed:(e?: any) => void) => {
        ApiRequest.post(URL.OTP.VERIFY, {
            uid,
            code
        })
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },

    
    changePassword: (new_password1: string, new_password2: string, success: (e?: any) => void, failed: (e?: any) => void) => {
        ApiRequest.post(URL.CHANGE_PASSWORD, {
            new_password1,
            new_password2
        })
            .then(resp => {
                success(resp.data)
            })
            .catch(e => {
                failed(e.response.data)
            })
    },
    
    getSources: (success: (e: any) => void, failed: (e: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.PAYMENT_SOURCE)
            .then(cardResp => {
                console.log(cardResp.data)
                success(cardResp.data)
            })
            .catch(e => {
                console.log(e)
                failed(e.response.data)
            }) 
    },

    getSource: (id: any, success: (e: any) => void, failed: (e: any) => void) => {
        ApiRequest.get(URL.TRANSACTIONS.PAYMENT_SOURCE+`/${id}`)
            .then(cardResp => {
                console.log(cardResp.data)
                success(cardResp.data)
            })
            .catch(e => {
                console.log(e)
                failed(e.response.data)
            }) 
    },

    logout: () => {
        localStorage.removeItem('key');
        localStorage.removeItem('token');
        Cookies.remove('key')
        Cookies.remove('key');
    },

    

}

export default Auth;