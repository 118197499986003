export const banks = [
    {
        label: 'Banco De Oro',
        value: 'BDO',
    },
    {
        label: 'Bank Of the Phillipine Island',
        value: 'BPI',
    },
    {
        label: 'BPI Family Savings',
        value: 'BFB',
    },
    {
        label: 'Metrobank',
        value: 'MBTC',
    },
    {
        label: 'Rizal Commercial Banking Corporation',
        value: 'RCBC',
    },
    {
        label: 'United Coconut Planters Bank',
        value: 'UCPB',
    },
    {
        label: 'UnionBank',
        value: 'UBP',
    },
    {
        label: 'Chinabank',
        value: 'CBC',
    },
    {
        label: 'Eastwest Bank',
        value: 'EWB',
    },
    {
        label: 'Security Bank',
        value: 'SBC',
    },
    {
        label: 'Landbank',
        value: 'LBP',
    },
    {
        label: 'Philippine National Bank',
        value: 'PNB',
    },
    {
        label: 'Asia United Bank',
        value: 'AUB',
    },
    {
        label: 'Robinsons Bank',
        value: 'RSB',
    },
    {
        label: 'Bank of Commerce',
        value: 'BOC',
    },
    {
        label: 'Chinabank Savings',
        value: 'CBCS',
    },
    {
        label: 'Chinatrust Bank',
        value: 'CTBC',
    },
    {
        label: 'Development Bank of the Philippines',
        value: 'DBP',
    },
    {
        label: 'Maybank',
        value: 'MAY',
    },
    {
        label: 'PBCom',
        value: 'PBCM',
    },
    {
        label: 'PSBank',
        value: 'PSB',
    },
    {
        label: 'Philippine Veterans Bank',
        value: 'PVB',
    },
    {
        label: 'Sterling Bank of Asia',
        value: 'SBA',
      }
]