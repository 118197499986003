import { Grid } from '@material-ui/core';
import React, {useEffect, useState, useContext} from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Auth from '../../auth';
import { User } from '../../models';
import './style.scss';

export const NavBar: React.FC = () => {

    const { user, source } = useContext(User.Context)
    const [cardActivated, setCardActivated] = useState(false);
    
    const history = useHistory();
    let location = useLocation();
    useEffect(() => {
        if(source){
            const isInstall = source.map((data:any) => {
                if(data.installmentAuthorized){
                  return true
                }else{
                  return false
                }
              })
            console.log(isInstall)
            const test =  isInstall.some((val: any) => {
                if(val === true){
                    return true
                }
            })
            console.log(test)
            setCardActivated(test);
        }
    },[source])

    useEffect(() => {
        console.log("HAAAAA ", cardActivated)
    },[cardActivated])

    return (
        <div style={{paddingLeft: '50px'}}>
            <Grid container direction="row" alignItems="center" justify="center" spacing={3}>

                <Grid item>
                    <Link to={location.pathname.includes("buyer") ? '/app/buyer/my-account' : '/app/seller/my-account'}>
                
                        
                        <div className={'action-bar-item-label' + (location.pathname === "/app/buyer/my-account" || location.pathname === "/app/seller/my-account"  ? "-current" : "")}>Home</div>
                        
                    
                    </Link>
                </Grid>

                
                {location.pathname.includes('buyer') && (
                    <Grid item>
                        <Link to={user && (user.buyAuthorized && source.length > 0) ? (user.emailConfirmed) ? '/app/buyer/create-offer' : '/verify?require_code=true' : '/app/buyer/vault-cards?no_card=true'}>
                        
                                
                                <div className={'action-bar-item-label' + (location.pathname === "/app/buyer/create-offer" ? "-current" : "")}>Transactions</div>
                            
                            
                        </Link>
                    </Grid>
                )}
                
                {location.pathname.includes('seller') && (
                    <Grid item>
                        <Link to={user && user.sellAuthorized ? (user.emailConfirmed) ? '/app/seller/create-sale' : '/verify?require_code=true' : '/app/seller/account-upgrade?send_documents=true'}>
                        
                                
                                <div className={'action-bar-item-label' + (location.pathname === "/app/seller/create-sale" ? "-current" : "")}>Transactions</div>
                            
                        </Link>
                    </Grid>
                )}
                

                
                {location.pathname.includes('buyer') && (
                    <Grid item>
                        <Link to="/app/buyer/vault-cards">
                            
                                
                                <div className={'action-bar-item-label' + (location.pathname === "/app/buyer/vault-cards" ? "-current" : "")}>Payment Methods</div>
                            
                        </Link>
                    </Grid>
                )}
               

                
                {/*location.pathname.includes('seller') && (
                    <Grid item>
                        <Link to="/app/seller/account-upgrade">
                            
                                    
                                        <div className={'action-bar-item-label' + (location.pathname === "/app/seller/account-upgrade" ? "-current" : "")}>Upgrade</div>
                                
                        </Link>
                    </Grid>
                )*/}
           

               
                {/*location.pathname.includes('seller') ?(
                        <Grid item>
                            <Link to={location.pathname.includes('seller') && (user && !user.buyAuthorized) ? '/app/seller/vault-cards' :  '/app/buyer/my-account'}>
                            
                                    
                                        <div className={'action-bar-item-label' + (location.pathname === "/app/seller/vault-cards" ? "-current" : "")}>Buyer Mode</div>
                                
                            </Link>
                        </Grid>
                ): location.pathname.includes('buyer') ? (
                    <Grid item>
                        <Link to={
                            location.pathname.includes('buyer') && (user && !user.sellAuthorized) ? 
                            
                            '/app/buyer/account-upgrade'
                            : 
                            '/app/seller/my-account' 
                        }>
                        
                                    <div className={'action-bar-item-label' + (location.pathname === "/app/buyer/account-upgrade" ? "-current" : "")}>Seller Mode</div>
                            
                        </Link>
                    </Grid>
                ): null
                
                    */}
              
                {user && (user.sellAuthorized && location.pathname.includes('seller')) && (
                    <Grid item>
                        <Link to="/app/seller/api-keys">
                        
                                
                                    <div className={'action-bar-item-label' + (location.pathname === "/app/seller/api-keys" ? "-current" : "")}>API Documentation</div>
                        
                        </Link>
                    </Grid>
                )}
               
            </Grid>
          
            
            
            
            
            
           
            
            
            
            {/**
             * 
             * 
            
            <div className="action-bar-item-big">
                <div style={{paddingLeft: '20px', alignItems: 'center'}}>
                    {location.pathname.includes('buyer') && (
                        <>
                        <Row align="normal" justify="start">
                            <label className="container-check">Buyer Authorized
                                <input type="checkbox" disabled checked={user && source.length > 0 ? user.buyAuthorized : false} />
                                <span className="checkmark"></span>
                            </label>
                        </Row>
                        <Row align="normal" justify="start">
                            <label className="container-check">Installment Ready
                                <input type="checkbox" disabled checked={cardActivated} />
                                <span className="checkmark"></span>
                            </label>
                        </Row>
                        </>
                    )}
                    
                    {location.pathname.includes('seller') && (
                        <Row align="normal" justify="start">
                            <label className="container-check">Seller Authorized
                                <input type="checkbox" disabled checked={user ? user.sellAuthorized : false} />
                                <span className="checkmark"></span>
                            </label>
                        </Row>
                    )}
                    
                </div>
            </div>
             */}
        </div>
    )
}