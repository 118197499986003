import React from 'react';
import { useHistory } from 'react-router-dom';
import  { ApiRequest }  from '../services/http';
import URL from '../services/url';
import Cookies from 'js-cookie';
import { IContextUser } from '../interface';

// import { createCtx } from '../hooks/createCtx';


export const User = {
    Context: React.createContext<IContextUser>({
        user: null,
        source: [],
        buyOrSellMode: null,
        setUser: () => {},
        setSource: () => {},
        setBuyOrSellMode: () => {}
    }),
    get: (success: (e: any) => void) => 
        ApiRequest.get(URL.USER.PROFILE)
        .then(response => {
            success(response.data);
            Object.assign(User.userData, response.data);
        })
        .catch(e => {
            Cookies.remove('key');
            localStorage.removeItem('key');
            console.log(e);
            //
        }),
    getSource: (success: (e: any) => void) => 
        ApiRequest.get(URL.TRANSACTIONS.PAYMENT_SOURCE)
        .then(cardResp => {
            success(cardResp)
            Object.assign(User.userSource, cardResp.data.cards)
        })
        .catch(e => {
            console.log(e)
        }),
    userData:{
        id: null,
        email: "",
        IDFront: null,
        IDBack: null,
        Bankproof: null,
        Cardstatement: null,
        username: "",
        first_name: "",
        last_name: "",
        gender: null,
        date_joined: "",
        barangay: "",
        street2: "",
        emailConfirmed: false,
        sellAuthorized: false,
        buyAuthorized: false,
        installmentAuthorized: false,
        sms_verified: false,
        bankCode: null,
        bankAccountNumber: null,
        cardCutoff: null,
        cardLimit: null,
        spendLimit: null,
        contact_number: null,
        particulars: null,
        profile_picture: null,
        street: null,
        city: null,
        region: null,
        zip_code: null
    },
    userSource: {

    }
    /*testData: {
        id: 8,
        email: "ericreforma@gmail.com",
        IDFront: null,
        IDBack: null,
        Bankproof: null,
        Cardstatement: null,
        username: "testeric",
        first_name: "",
        last_name: "",
        gender: null,
        date_joined: "2020-07-08T10:53:48.904877Z",
        emailConfirmed: false,
        sellAuthorized: false,
        buyAuthorized: false,
        installmentAuthorized: false,
        bankCode: null,
        bankAccountNumber: null,
        cardCutoff: null,
        cardLimit: null,
        spendLimit: null,
        contact_number: null,
        particulars: null,
        street: null,
        city: null,
        region: null,
        zip_code: null
    }*/
}
