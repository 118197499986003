import React, {useState} from 'react';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, Theme, Toolbar, Typography, Divider, ListItemIcon, MenuItem, MenuList, SwipeableDrawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { 
  withRouter, 
  Link,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import Footer from '../../layouts/Footer';
import { HomeNavBar } from '../../layouts/HomeNavBar';


const logo = require('../../assets/images/4giveslogo.png');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    menuButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      flexGrow: 1,
    },
    toolbarBtns: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    toolbarBtnMobile: {
      marginLeft: 'auto',
    },
    content: {
      flex: 1,
      marginTop: '0px',
  marginBottom: '0px',
  marginRight: '0px',
  marginLeft: '0px',
      overflowY: 'auto'
    },
    btns: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      background: '#fff',
    },
    menuLabel: {
      color: '#FF873A'
  },
  }),
);

export const InstallmentAgreement: React.FC = () => {
    //var html = require('../../assets/html/4gives-faq.html')
    const [htmlFile, setHTMLFile] = useState<string>('');

    axios.get('https://4gives-pages.s3.ap-southeast-1.amazonaws.com/installment.html', 
        {
        headers: {
           'Content-Type': 'application/json'
        } 
    })
    .then(function (response: any) {
      setHTMLFile(response.data)
    })
    .catch(function(error: any) {
      console.log(error)

    })

    const theme = useTheme();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();

    return (
        <div className="page">
            <HomeNavBar />

            <Container style={{ padding: matches ? 50 : 0 }}>
                <div style={{backgroundColor: '#fee2b3', padding: matches ? '50px' : '50px 20px' , borderRadius: '5px'}} dangerouslySetInnerHTML={{__html: htmlFile}} ></div>
                {/*
                <Typography variant="h4" color="primary" align="center">{FAQsCopy.title}</Typography>

                { FAQsCopy.content.map(
                    (qna, index) => (
                    <>
                        <Typography variant="h6" color="secondary" style={{ marginTop: 20, fontWeight: 600 }}>
                        {qna.question}
                        </Typography>
                        
                        { qna.arr ? qna.ans.map(
                        ans => (
                            <Typography variant="body1" style={{ marginLeft: 20 }}>&#8226; {ans}</Typography>
                        )
                        ) : (
                        <Typography variant="body1" style={{ marginLeft: 20, whiteSpace: "pre-wrap" }}>{qna.ans}</Typography>
                        ) }
                    </>
                    )
                ) }
                        */}
            </Container>

            <Footer />
        </div>
    )
}