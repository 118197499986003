import React, {useEffect, useState} from 'react';
import { Col, Row } from 'react-grid-system';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, TextField, Theme, Toolbar, Typography, InputAdornment, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import createBrowserHistory from 'history/createBrowserHistory';
import { Button as CustomButton } from '../../components/Button';
import Select from '@material-ui/core/Select';

import homebg from '../../assets/images/image 3(1).jpg';
import interestGraphic from '../../assets/images/home_icons/interest-graphic.png';
import fast from '../../assets/images/home_icons/fast.png';
import moneyCards from '../../assets/images/home_icons/money-cards.png';
import hiddenFees from '../../assets/images/home_icons/no hidden fees.png';
import comingSoon from '../../assets/images/home_icons/coming soon-graphic.png';
import easySection from '../../assets/images/home_icons/image-4.jpg';
import visa from '../../assets/images/home_icons/visa-icon.png';
import mastercard from '../../assets/images/home_icons/mastercard-icon.png';
import jcb from '../../assets/images/home_icons/JCB_logo.svg.png';
import bsp from '../../assets/images/home_icons/bsp-logonew.png';
import npc from '../../assets/images/home_icons/npc.png';
import pci from '../../assets/images/home_icons/pci-dss.svg';
import merchantPhoto from '../../assets/images/seller.jpg';

import Auth from '../../auth';
import CheckIcon from '@material-ui/icons/Check';

const logo = require('../../assets/images/4giveslogo.png');
const logoWhite = require('../../assets/images/4giveslogo_white.png');
const pic = require('../../assets/images/pic2-min.png');
const offer = require('../../assets/images/create_offer.png');
const sale = require('../../assets/images/create_sale.png');
const gives = require('../../assets/images/4giveslogoorange.png');
const purchase = require('../../assets/images/purchase.png')


const useStyles = makeStyles((theme) => ({
  selectInput: {
      minWidth: 300,
      maxWidth: 300,
  },
  multilineColor: {
    color: 'white',
    fontSize: '20px',
    width: '130px'
  },
  multilineColorMobile: {
    color: 'white',
    fontSize: '16px',
    width: '70px'
  },
  select: {
    '&:before': {
        borderColor: 'rgba(255, 133, 51, 0.42)',
    },
    '&:after': {
        borderColor: '#FF873A',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `1px solid #FF873A`
    },
    
  },
  selectIcon: {
      fill: '#ececec',
  },
  labelForm: {
    color: '#FF873A',
    margin: '0 auto',
  },
  btnBorderColor: {
    border:"none",
    outline:"none",
    borderColor: '#FF873A',
    borderWidth: '0px',
    padding: '10px',
    color: '#FF873A',
    backgroundColor: '#fee2b3',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    '&:active': {
      outline: 'none',
    },
  },
  btnBorderColorSelected: {
    border:"none",
    outline:"none",
    borderColor: '#FF873A',
    borderWidth: '0px',
    padding: '10px',
    color: 'white',
    backgroundColor: '#FFA500',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    '&:active': {
      outline: 'none',
    },
  },
  labelColor: {
    color: 'white',
  },
  cssFocused: {
    color: 'white',
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid white'
    },
    '&:after': {
        borderBottom: `2px solid white`
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid white`
    }
  },
  largeIcon: {
    width: 70,
    height: 70,
    fill: '#FF873A'
  },
}));

export const Content: React.FC<{
  howRef: any;
  calcRef: any;
  reqRef: any;
}> = ({howRef, calcRef, reqRef}) => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const contentMatches = useMediaQuery('(min-width: 1025px)');
    const tabletMatches = useMediaQuery('(max-width: 1024px) and (min-width: 768px)')
    const phoneMatches = useMediaQuery('(max-width: 500px)')
    const lgMatches = useMediaQuery(theme.breakpoints.up('lg')) 
    const [amount, setAmount] = useState<string | null>('2000')
    const [month, setMonth] = useState(3);
    const [convenience_fee, setConvenienceFee] = useState(0);
    const [perMonth, setPerMonth] = useState<any | null>(null)
    const [isError, setIsError] = useState(false)
    const [interest, setInterest] = useState(0);
    const foldMatches = useMediaQuery('(max-width: 280px)')
    const handleAmountChange = (str: string) => {
      if(/^[0-9]*$/.test(str)){
        setAmount(str)
        setIsError(false)
      }else{
        setIsError(true)
      }
    }

    useEffect(() => {
      if((amount !== '' && amount !== '0') && month > 0){
        computeInstallment(amount, month)
      }
    },[amount, month])

    const computeInstallment = (amount: string | null, month: number) => {
      Auth.installmentCompute(
        amount,
        month,
        (resp) => {
          console.log(resp)
          setPerMonth(resp.data.monthly)
          setConvenienceFee(resp.data.convenience_fee)
          setInterest(resp.data.interest)
        },
        (e) => {
          console.log(e)
        }
      )
    }
    
      return(
        <div className="content">
          <div className="content-main-container">
            <div className='buyer-content-pic'>
              <Grid container item direction="row" 
                style={{
                  background: `url(${homebg})`
                }}
                alignItems="center"  justify="center">
                  {!contentMatches && (
                    <Grid xs={12} container item style={{background: `url(${homebg})`}}>
                      <img src={homebg} style={{height: '50%', width: '100vw', position: 'relative'}} />
                    </Grid>
                  )}
                <Container>
                  
                <Grid item sm={12} style={{height: tabletMatches ? '0px' : !contentMatches ? '0px' :'600px', width: '600px'}}>
                  {contentMatches ? (

                
                    <div className="content-main-desc" style={{ textAlign: 'left', padding: matches ?'20px 0px' : '20px 0px 50px'}}>
                      <Typography className="content-main-headline" variant={!matches ? "h2" : "h2"} style={{color: 'white', fontWeight: 'bold'}}>
                        Your dreams, <br/> unlocked.
                      </Typography>
                      <Typography className="content-main-body" variant={"h6"} style={{color: "white", padding:'10px 0px 60px'}}>
                        Buy now, pay later in easy and fair installments.
                      </Typography>
                      <CustomButton 
                          color='home'
                          onClick={() => history.push('/login?page=registration-page&applying_for=buyer')}
                          className='fg-home'
                      >
                          Apply Now!
                      </CustomButton>
                      {/** 
                      <Typography variant={"body1"} style={{color: "black", padding:'30px 30px 10px 30px'}}>
                        Or download our mobile app:
                      </Typography>
                      <img src={iosBadge} style={{height: '100%', width: '200.00px'}}/>
                      <img src={googleBadge} style={{height: '100%', width: '200.00px'}}/>
                      */}
                    </div>
                  ): (
                    <div style={{ textAlign: 'left', padding: matches ?'20px 0px' : '20px 0px 50px', position: 'absolute', top: tabletMatches ? '450px' :foldMatches ? '220px':'250px', left: tabletMatches ?'250px' : '120px',  msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)'}}>
                      <Typography className="content-main-headline" variant={tabletMatches ? "h2" : 'h6'} style={{color: 'white', fontWeight: 'bold'}}>
                        Your dreams, <br/>unlocked.
                      </Typography>
                      <Typography className="content-main-body" variant={ tabletMatches ? "h5" : "body1"} style={{color: "white", padding:'10px 0px 80px'}}>
                        Buy now, pay later in easy <br />and fair installments.
                      </Typography>
                      <CustomButton 
                          color='home'
                          onClick={() => history.push('/login?page=registration-page&applying_for=buyer')}
                          className='fg-home'
                      >
                          Apply Now!
                      </CustomButton>
                      {/** 
                      <Typography variant={"body1"} style={{color: "black", padding:'30px 30px 10px 30px'}}>
                        Or download our mobile app:
                      </Typography>
                      <img src={iosBadge} style={{height: '100%', width: '200.00px'}}/>
                      <img src={googleBadge} style={{height: '100%', width: '200.00px'}}/>
                      */}
                    </div>
                  )}
                </Grid>
          
          
                </Container>
              </Grid>
            </div>
          </div>
          
          <div style={{background: '#FF873A', padding:'60px 20px'}} id="calc" ref={calcRef}>
            <Container style={{textAlign: 'center'}}>
                <div style={{padding: '0px 0px 0px'}}>
                  <Typography  className="content-main-headline" variant={!matches ? "h5" : "h4"} style={{color: 'white', fontWeight: 'bold'}}>
                    Up to 12 installments with any Credit Card
                  </Typography>
                  <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{color: 'white', fontWeight: 'bold', padding: '20px'}}>
                  Shop online in installments with any Visa, Mastercard or JCB
                  Credit Card, and pay as low as 2% interest.
                  </Typography>
                </div>
                <Grid container item direction="row" alignItems="center" spacing={1} justify="space-around">
                  <Grid className="content-main-body" container direction="column" alignItems="center" justify="center" item xs={12} sm={3} style={{padding: '20px'}}>
                    <Grid item>
                      <div  className="card-label-small">
                        Input Order Value
                      </div>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-error"
                        value={amount}
                        error={isError}
                        type="number"
                        helperText={isError ? "Please input only numbers" : ''}
                        onChange={e =>  handleAmountChange(e.target.value)}
                        InputLabelProps={{
                          className: classes.labelColor
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div className="card-label">
                                P
                              </div>
                            </InputAdornment>
                          ),
                          classes:{
                            input: matches ? classes.multilineColor : classes.multilineColorMobile,
                            focused: classes.cssFocused,
                            underline: classes.underline,
                          }
                        }}
                      />
                    </Grid>
                    
                  </Grid>
                  <Grid container className="content-main-body" direction="column" alignItems="center" justify="center" item xs={12} sm={4} style={{padding: '20px'}}>
                    <Grid item>
                      <div className="card-label-small">
                        Installment Term(months)
                      </div>
                    </Grid>
                    <Grid item container alignContent="center" spacing={1} xs={12} justify="center">
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 3 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(3)}
                        >
                          <div className={`card-label`+ ( month === 3 ? '-white' : '')}>
                            3
                          </div>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 6 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(6)}
                        >
                          <div className={`card-label`+ ( month === 6 ? '-white' : '')}>
                            6
                          </div>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 9 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(9)}
                        >
                          <div className={`card-label`+ ( month === 9 ? '-white' : '')}>
                            9
                          </div>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 12 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(12)}
                        >
                          <div className={`card-label`+ ( month === 12 ? '-white' : '')}>
                            12
                          </div>
                        </Button>
                      </Grid>
                      
                    </Grid>
                    {/*matches ? 
                    <Grid item container alignContent="center" spacing={1} xs={6} justify="center">
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 3 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(3)}
                        >
                          <div className={`card-label`+ ( month === 3 ? '-white' : '')}>
                            3
                          </div>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 6 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(6)}
                        >
                          <div className={`card-label`+ ( month === 6 ? '-white' : '')}>
                            6
                          </div>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 9 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(9)}
                        >
                          <div className={`card-label`+ ( month === 9 ? '-white' : '')}>
                            9
                          </div>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={ month === 12 ? classes.btnBorderColorSelected : classes.btnBorderColor}
                          onClick={() => setMonth(12)}
                        >
                          <div className={`card-label`+ ( month === 12 ? '-white' : '')}>
                            12
                          </div>
                        </Button>
                      </Grid>
                      
                    </Grid>
                    :
                    <Grid item alignItems="center" justify="center">
                      <Select
                            id="demo-simple-select-autowidth"
                            name="region"
                            value={month}
                            style={{width: '50%'}}
                            className={classes.select}
                            inputProps={{
                                classes: {
                                    icon: classes.selectIcon,
                                    root: classes.labelForm
                                },
                                className: classes.multilineColor
                            }}
                            
                            displayEmpty
                            onChange={(e:any)=>{console.log(e.target.value); setMonth(e.target.value)}}
                        >
                          <MenuItem value="3" >
                            3
                          </MenuItem>
                          <MenuItem value="6" >
                            6
                          </MenuItem>
                          <MenuItem value="9" >
                            9
                          </MenuItem>
                          <MenuItem value="12" >
                            12
                          </MenuItem>
                      </Select>
                    </Grid>
                          */}
                  
                  </Grid>
                  <Grid container item direction="column" alignItems="center" justify="flex-start" style={{padding: '30px 0px'}} xs={12} sm={5}>

                  <div style={{background: 'white', border: '1px solid white', width: '80%', borderRadius: '30px', padding: '30px'}}>
                    <Grid container  direction="row" spacing={2} alignItems="center" justify="center">
                      <Grid className="content-main-body" container xs={12} lg={6} item direction="column" alignItems="center" justify="center">
                        <Grid item>
                          <div  className="card-label-black">
                            Installment Amount
                          </div>
                        </Grid>
                        <Grid item style={{padding: '10px'}}>
                          <div className="card-label-important">
                            PHP {perMonth}
                          </div>
                        </Grid>
                        <Grid item>
                          <div className="card-label-black">
                            for {month} months with {interest}% interest + PHP {convenience_fee} per month
                          </div>
                        </Grid>
                        <Grid item>
                          <div className="card-label-black">
                            
                          </div>
                        </Grid>
                        
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <div className="activate-account-button" style={{marginTop: '0px'}}>
                          <CustomButton 
                              color='home'
                              onClick={() => history.push('/login?page=registration-page&applying_for=buyer')}
                              className='fg-home'
                          >
                              Apply Now!
                          </CustomButton>
                        </div>
                      </Grid>
                    </Grid>
                    
                  </div>
                  </Grid>
                </Grid>
          
                <Grid container direction="row" spacing={3} alignItems="center" justify="center">
                  <Grid item>
                    <img src={visa} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
                  <Grid item>
                    <img src={mastercard} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
                  <Grid item>
                    <img src={jcb} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
                </Grid>

            </Container>
          </div>
          <div style={{padding: '50px 20px'}}  id="easy">
              <Container>
                  <Typography className="content-main-headline" variant={!matches ? "h5" : "h4"} style={{ color: 'black', fontWeight: 'bold', paddingBottom: '20px'}}>
                    Easy and fair installments with any credit card.
                  </Typography>
                <Grid container direction="row" spacing={4} alignItems="center" justify="center">
                  <Grid container xs={12} spacing={2} sm={7} item direction="column" justify="center">
                
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={interestGraphic} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body" variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          Pay as low as 2% interest
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={fast} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body" variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px' , wordWrap:"break-word"}}>
                          Fast-approval
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={moneyCards} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body" variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          Flexible credit line with up to 30% of your credit card limit
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={hiddenFees} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body" variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          No down-payment or hidden fees
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" item xs={12}>
                      <Grid item xs={1}>
                        <img src={comingSoon} style={{width: '60px', height: 'auto', objectFit: 'contain', float: 'left'}} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="content-main-body" variant={phoneMatches ? "body1" : "h6"} style={{ color: 'black', paddingLeft: '100px', wordWrap:"break-word"}}>
                          Pay through salary deductions by Debit Card, soon!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={5} item direction="column">
                      
                      <img src={easySection} style={{height: phoneMatches ? '300px' :'450px', width: 'auto', objectFit: 'contain'}}/>
                  </Grid>
                </Grid>
              </Container>
          </div>
          <div style={{padding: '30px 20px 50px', background: '#FF873A'}}  id="howRef" ref={howRef}>
            <Container>
              <Typography className="content-main-headline" variant={!matches ? "h5" : "h4"} style={{color: 'white', paddingTop: 20, fontWeight: 500, textAlign: 'center', paddingBottom: '20px'}}>
                How it Works
              </Typography>
              <Grid container className="content-main-body" item direction="column" alignItems="center" justify="center" >
                <Grid container direction="row" spacing={1} item justify="center" alignItems="center">
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body"  variant={"h6"} style={{textAlign:'center', color: '#FF873A', fontWeight: 'bold'}}>
                        1
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body" variant={"h6"} style={{ color: 'white'}}>
                      Sign-up with just your e-mail
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body" variant={"h6"} style={{textAlign:'center', fontWeight: 'bold'}}>
                        2
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body" variant={"h6"} style={{ color: 'white'}}>
                      Connect your Visa, Mastercard or JCB Credit Card
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={1}>
                    <div style={{ border: '3px solid white', background:'white', borderRadius: '50%', width: '40px'}}>
                      <Typography className="content-main-body" variant={"h6"} style={{textAlign:'center', fontWeight: 'bold'}}>
                        3
                      </Typography>
                    </div>
                  </Grid>
                  {phoneMatches && (
                    <Grid item xs={1} />
                  )}
                  <Grid item xs={10} sm={6}>
                    <Typography className="content-main-body" variant={"h6"} style={{ color: 'white'}}>
                      Get approved in 48 hours and start shopping in installments!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: '20px'}}/>
                  <Grid item xs={12}>
                    <Typography className="content-main-body" variant={"body1"} style={{ color: 'white', textDecoration: 'underline', textAlign: 'center' }}>
                      <span onClick={() => history.push('/faqs')} className="content-main-body" style={{cursor:'pointer', color: 'white', fontSize: '16px'}}>Have more questions? Visit our FAQs.
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: '20px'}}/>
                  <CustomButton 
                      color='home'
                      onClick={() => history.push('/login?page=registration-page&applying_for=buyer')}
                      className='fg-home'
                  >
                      Apply Now!
                  </CustomButton>
                </Grid>
              </Grid>
              
            </Container>
          </div>
         
          
          
          <div style={{background: 'white', padding: '50px'}}>
            <Container>
              <Grid container item direction="column" alignItems="center" spacing={2} justify="center" >
                <Grid item>
                  <Typography className="content-main-headline" variant={!matches ? "h6" : "h4"} style={{color: 'black', fontWeight: 500}}>
                    Why 4Gives
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="content-main-body"  variant={phoneMatches ? "body1" : "h6"} style={{color: 'black', textAlign:'center', lineHeight: 2}}>
                    Our mission is to financially empower filipinos to buy anything they
                    need, while helping merchants and sellers grow, through inclusive,
                    fair and safe credit for everyone.
                  </Typography>
                </Grid>
                <Grid container direction="row" spacing={5} alignItems="center" justify="center" item>
                  <Grid item>
                    <img src={bsp} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
{/*                   <Grid item>
                    <img src={npc} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid> */}
                  <Grid item>
                    <img src={pci} style={{width: '100px', height: 'auto', objectFit:'contain'}} />
                  </Grid>
                </Grid>
               
              </Grid>
            </Container>
          </div>
        </div>
      )
}

export default Content;