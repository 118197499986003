import React, { useState } from 'react';

export const useForm = <T>(initialValue: T) => {
    const [values, setValues] = useState<T>(initialValue);
//React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | {value: unknown}>
    const handleChange = (event: any) => {
        
        if(event.persist !== undefined){
            event.persist();
        }
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    return {
        values,
        handleChange
    };
}

