import { Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, MenuItem, Select } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField/TextField';
import React, {useState, useEffect, useContext} from 'react'
import Auth from '../../auth';
import { TextInput } from '../Input';
import { Button, ErrorDialog, SuccessDialog } from '..';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory, useLocation } from 'react-router-dom';
import { User } from '../../models';
import emailIcon from '../../assets/images/fork_screen icons/SendbyEmail.png';
import linkIcon from '../../assets/images/fork_screen icons/SendLink.png';
import close from '../../assets/images/close.png';

export const ProposalViaEmailLink: React.FC<{
    setLoadingOverlay: (val: boolean) => void,
    setOpenEmailLinkPropose: (val: boolean) => void,
    openEmailLinkPropose: any,
    user: any,
    classes: any,
    proposeType?: string | null,
    type?: string | null
}> = ({
    setLoadingOverlay,
    user, 
    classes, 
    setOpenEmailLinkPropose, 
    openEmailLinkPropose, 
    proposeType,
    type
}) => {
    const history= useHistory();
    const [buyOrSell, setBuyOrSell] = useState(type ? type :null);
    const [proposalType, setProposalType] = useState(proposeType ? proposeType : null);
    const {source} = useContext<any>(User.Context)
    const [receiver, setReceiver] = useState<any | null>(null);
    const [receiverEmail, setReceiverEmail] = useState<string | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [link, setLink] = useState(null);
    const [is_link, setIsLink] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [desc, setDesc] = useState<string | null>(null);
    const [category, setCategory] = useState<any | null>(null);
    const [terms, setTerms] = useState<any | null>(null);
    const [amount, setAmount] = useState<string | null>(null);
    const [showForm, setShowForm] = useState(false);
    const [buyer, setBuyer] = useState<any | null>(null)
    const [merchant, setMerchant] = useState<any | null>(null)
    const [shouldered_by_merchant, setShoulderedByMerchant] = useState(false);
    const [categoryTypes, setCategoryTypes] = useState<any| null>(null);
    const [payment_type, setPaymentType] = useState<any | null> (null);
    const [limit, setLimit] = useState<any>(0);
    useEffect(() => {
        if(categoryTypes === null){
            Auth.getCategory(
                (resp) => {
                    setCategoryTypes(resp)
                },
                (e) =>{
                    setErrorMessage(e.error)
                }
            )
        }
        
    },[categoryTypes])

   

    useEffect(() => {
        if(user){
            if(type && proposalType === "Link" && user.id){
                if(type === "BUY"){
                    setMerchant(null)
                    setBuyer(user.id)
                }else if(type === "SELL"){
                    setBuyer(null)
                    setMerchant(user.id)
                }
                setBuyOrSell(type)
               
                setShowForm(true)
            }else if(type){
                if(type === "BUY"){
                    setBuyer(user.id)
                    setMerchant(null)
                }else if(type === "SELL"){
                    setBuyer(null)
                    setMerchant(user.id)
                }
                setBuyOrSell(type)
                setShowForm(false)
            }
        }
    },[user, type, openEmailLinkPropose])
    
    useEffect(() => {
        if(user && buyOrSell){
            if((buyOrSell === "SELL" && type==="SELL") && !user.sellAuthorized){
                history.push('/app/seller/account-upgrade?send_documents=true');
            }
            if((buyOrSell === "BUY" && type === "BUY") && (!user.buyAuthorized && !(source.length > 0))){
                history.push('/app/buyer/vault-cards?no_card=true')
            }
        }
    }, [user, buyOrSell, type])

    const handleClose = () => {
        setTerms(null)
        setName(null)
        setReceiver(null)
        if(type === "SELL"){
            setBuyer(null)
        }else if(type === "BUY"){
            setMerchant(null)
        }
        setIsLink(false)
        setShoulderedByMerchant(false)
        setLimit(0)
        setDesc(null)
        setCategory(null)
        setLink(null);
        setAmount(null)
        setBuyOrSell(type ? type : null)
        setReceiverEmail(null)
        setProposalType(null)
        setCategoryTypes(null)
        setPaymentType(null)
        setOpenEmailLinkPropose(false)
        setShowForm(false)
        setIsLink(false)
        setLimit(0)
    }

    useEffect(() => {
        
        if(type && proposeType === "Link"){
            
            setShowForm(true)
        }else if(type && proposalType === "Link"){
            setShowForm(true)
        }
        else{
            
            setShowForm(false)
        }
    },[proposeType, proposalType])

    const getReceiver = () => {
        setLoadingOverlay(true)
        if(user.email !== receiverEmail){
            Auth.getIDViaEmail(
                receiverEmail,
                (response) => {
                    if(buyOrSell === "BUY"){
                       
                        setReceiver(response)
                        setMerchant(response)
                    }else{
                        setReceiver(response)
                        setBuyer(response)
                    }
                },
                (e) => {
                    setLoadingOverlay(false)
                    setEmailError(e.detail)
                    console.log(e)
                }
            )
        }else{
            setLoadingOverlay(false)
            setOpenErrorDialog(true)
            setErrorMessage("You cannot use your own email")
        }
        
    }

    const handleCategoryChange = (val: any) => {
        if(val === 2){
            setTerms(3)
            setCategory(val)
            setPaymentType(2)
        }
        else{
            setTerms('1')
            setCategory(val);
            setPaymentType(null)
        }
    }

    const emailProposal = () => {
        setLoadingOverlay(true)
        
            if(buyOrSell === "BUY") {
                Auth.postProposalBuyLink(
                    name,
                    desc,
                    terms,
                    amount,
                    category,
                    payment_type,
                    "EMAIL",
                    (response) => {
                        //let link = response.Link.match(/\/proposal\/(\d+)\//)
                        console.log(response)
                        let link = response.id
                        if(link) {
                            console.log(link)
                            let id = link
                            Auth.patchProposal(
                                id,
                                buyer,
                                merchant,
                                (response) => {
                                    handleClose();
                                    console.log(response)
                                    setOpenDialog(true);
                                    setSuccessMessage("Proposal has been sent to "+receiverEmail)
                                    setLoadingOverlay(false)
                                },
                                (e)=>{
                                    console.log(e)
                                    setLoadingOverlay(false)
                                    setOpenErrorDialog(true)
                                    setErrorMessage(e.error)
                                }
                            )
                        }
                    },
                    (e) => {
                        console.log(e)
                        console.log(e)
                        setLoadingOverlay(false)
                        setOpenErrorDialog(true)
                        setErrorMessage(e.error)
                    }
                )
            }else if(buyOrSell === "SELL"){
                Auth.postProposalSellLink(
                    name,
                    desc,
                    category,
                    terms,
                    shouldered_by_merchant ? 2 : payment_type,
                    amount,
                    shouldered_by_merchant,
                    false,
                    null,
                    "EMAIL",
                    (response) => {
                        //let link = response.Link.match(/\/proposal\/(\d+)\//)
                        console.log(response)
                        let link = response.id
                        if(link) {
                            
                            Auth.patchProposal(
                                link,
                                buyer,
                                merchant,
                                (response) => {
                                    console.log(response)
                                    handleClose();
                                    setOpenDialog(true);
                                    setSuccessMessage("Proposal has been sent to "+receiverEmail)
                                    setLoadingOverlay(false)
                                    /*Auth.patchProposalPaymentType(
                                        link,
                                        payment_type,
                                        (resp) => {
                                            console.log(response)
                                            handleClose();
                                            setOpenDialog(true);
                                            setSuccessMessage("Proposal has been sent to "+receiverEmail)
                                            setLoadingOverlay(false)
                                        },
                                        (e) => {
                                            console.log(e)
                                            setLoadingOverlay(false)
                                            setOpenErrorDialog(true)
                                            setErrorMessage(e.error)
                                        }
                                    )*/
                                    
                                },
                                (e)=>{
                                    console.log(e)
                                    setLoadingOverlay(false)
                                    setOpenErrorDialog(true)
                                    setErrorMessage(e.error)
                                }
                            )
                        }
                    },
                    (e) => {
                        console.log(e)
                        setLoadingOverlay(false)
                        setOpenErrorDialog(true)
                        setErrorMessage(e.error)
                    }
                )
            }
        
        
    }

    const generateLink = () => {
        setLoadingOverlay(true)
        if(buyOrSell === "BUY"){
            Auth.postProposalBuyLink(
                name,
                desc,
                terms,
                amount,
                category,
                payment_type,
                "LINK",
                (response) => {
                    handleClose();
                    console.log(response)
                    setOpenDialog(true);
                    setLink(response.reference)
                    setSuccessMessage(`Code generated! Please share this code to a merchant.`)
                    setLoadingOverlay(false)
                },
                (e) => {
                    setLoadingOverlay(false)
                    setOpenErrorDialog(true)
                    setErrorMessage(e.error)
                    console.log(e.error)
                }
            )
        }else if(buyOrSell === "SELL"){
            Auth.postProposalSellLink(
                name,
                desc,
                category,
                terms,
                shouldered_by_merchant ? 2 : payment_type,
                amount,
                shouldered_by_merchant,
                is_link,
                is_link ? limit : null,
                "LINK",
                (response) => {
                    console.log("SELLING")
                    console.log(response)
                        if(is_link){
                            //let test = response.Link;
                            //console.log() test.substring(test.lastIndexOf('/') + 1)
                            //console.log(test.substring(test.lastIndexOf('/') -1))
                            handleClose();
                            setOpenDialog(true);
                            setLink(response.reference)
                            setSuccessMessage(`Code generated! Please share this code to your customers.`)
                            setLoadingOverlay(false)
                            /*
                            Auth.getProposalLink(
                                test.substring(test.lastIndexOf('/') + 1),
                                (resp) => {
                                    let link = resp.link.match(/\/proposal\/(\d+)\//)
                                    let id = link[1]
                                    Auth.patchProposalPaymentType(
                                        id,
                                        payment_type,
                                        (patchresp) => {
                                            
                                        },
                                        (e) => {
                                            console.log(e)
                                            setLoadingOverlay(false)
                                            setOpenErrorDialog(true)
                                            setErrorMessage(e.error)
                                        }
                                    )
                                },
                                (e) => {
                                    console.log(e)
                                    setLoadingOverlay(false)
                                    setOpenErrorDialog(true)
                                    setErrorMessage(e.error)
                                }
                            )*/
                        }else{
                            //let link = response.Link.match(/\/proposal\/(\d+)\//)
                            
                            let id = response.id
                            console.log(id)
                            Auth.patchProposalPaymentType(
                                id,
                                shouldered_by_merchant ? 2 : payment_type,
                                (patchresp) => {
                                    console.log(patchresp)
                                    handleClose();
                                    setOpenDialog(true);
                                    setLink(response.reference)
                                    setSuccessMessage(`Code generated! Please share this code to your customer.`)
                                    setLoadingOverlay(false)
                                },
                                (e) => {
                                    console.log(e)
                                    setLoadingOverlay(false)
                                    setOpenErrorDialog(true)
                                    setErrorMessage(e.error)
                                }
                            )
                        }
                        
                        
                    
                    
                },
                (e) => {
                    console.log(e)
                    setLoadingOverlay(false)
                    setOpenErrorDialog(true)
                    setErrorMessage(e.error)
                }
            )
        }
        
    }

    useEffect(() => {
        if(shouldered_by_merchant){
            setTerms('3')
        }else{
            setTerms('')
        }
    },[shouldered_by_merchant])

    const handleProposeTypeChange = (e: any ) => {
        setProposalType(e.target.value)
    }

    useEffect(() => {
        if(receiver) {
            
            setLoadingOverlay(false)
            setShowForm(true)
        }
    },[receiver])

    const handleTypeChange = (e:any) => {
        setMerchant(null)
        setBuyer(null)
        console.log()
        if(e.target.value === "BUY"){
            setBuyOrSell(e.target.value)
            console.log("BUYINGGG")
            if(!(source.length > 0)) {
                history.push('/app/buyer/vault-cards?no_card=true');
            }
            setBuyer(user.id)
        }else{
            setBuyOrSell(e.target.value)
            if(!user.sellAuthorized) {
                history.push('/app/seller/account-upgrade?send_documents=true');
            }
            setMerchant(user.id)
        }
        if(proposalType === "Link"){
            setShowForm(true)
        }
        
    }

    const location = useLocation();

    return (
        <>
            <Dialog 
                className={classes.dialogPaper} 
                fullWidth={true} 
                maxWidth={'sm'} 
                onClose={handleClose} 
                open={openEmailLinkPropose}
                PaperProps={{style: {
                    backgroundColor: '#fee2b3',
                    border: "1px solid #fee2b3",
                    borderRadius: '10px'
                }}}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <span style={{color: '#FF873A', fontWeight: 'bold'}}>{location.pathname.includes('buyer') ? 'Create a Payment' : location.pathname.includes('seller') ? 'Create a Sale' : 'Proposal Creation' }</span>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={handleClose}/>
                </DialogTitle>
                <div style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                    <div style={{padding: 25}}>
                        {/**
                         * 
                         * 
                         
                        <Grid container item direction="row" alignItems="center" justify="space-evenly">
                            <Grid item xs={4}>
                                I am a:
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    id="demo-simple-select-autowidth"
                                    name="category"
                                    value={buyOrSell}
                                    style={{width: '100%'}}
                                    className={classes.select}
                                    disabled={type ? true : false}
                                    inputProps={{
                                        classes: {
                                            icon: classes.selectIcon,
                                            root: classes.labelForm
                                        },
                                        className: classes.multilineColor
                                    }}
                                    onChange={handleTypeChange}
                                    displayEmpty
                                    defaultValue=""
                                >
                                    <MenuItem value="" disabled>
                                        <em>Choose transaction type...</em>
                                    </MenuItem>
                                    
                                    <MenuItem value={'BUY'}>BUYER</MenuItem>
                                    <MenuItem value={'SELL'}>SELLER</MenuItem>
                                </Select>
                            </Grid>
                           
                        </Grid>
                        */}
                        {(proposeType === undefined && (proposalType === undefined || proposalType === null)) && (
                            <div>
                                {/***
                                 * 
                                 * 
                             
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={4}>
                                        Send via: 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            id="demo-simple-select-autowidth"
                                            name="category"
                                            value={proposalType}
                                            style={{width: '100%'}}
                                            className={classes.select}
                                            inputProps={{
                                                classes: {
                                                    icon: classes.selectIcon,
                                                    root: classes.labelForm
                                                },
                                                className: classes.multilineColor
                                            }}
                                            onChange={handleProposeTypeChange}
                                            displayEmpty
                                            defaultValue=""
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Choose proposal type...</em>
                                            </MenuItem>
                                            
                                            <MenuItem value={'Email'}>Email</MenuItem>
                                            <MenuItem value={'Link'}>Link</MenuItem>
                                        </Select>
                                    </Grid>
                                
                                </Grid>
                                    */}
                                    <Grid container item direction="row" spacing={3} alignItems="center" justify="center">
                                        <Grid item>
                                            <div className="imgBtn" onClick={() => {
                                                setProposalType('Email')
                                            }}>
                                                <img src={emailIcon} style={{width: '100%'}} />
                                                <div style={{padding: '10px', color: '#FF873A'}}>
                                                    <span style={{fontWeight: 'bold', color: '#FF873A'}}>Request via Email</span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className="imgBtn" onClick={() => {
                                                setProposalType('Link')
                                            }}>
                                                <img src={linkIcon} style={{width: '100%'}} />
                                                <div style={{padding: '10px', color: '#FF873A'}}>
                                                    <span style={{fontWeight: 'bold', color: '#FF873A'}}>Request via Link</span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                
                                
                            </div>
                        )}
                        {buyOrSell && proposeType === "Email" ? (
                            <Grid style={{paddingTop: 25}} container item direction="column" spacing={2} alignItems="center" justify="center">
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={12} sm={4}>
                                        Email:
                                    </Grid>
                                    <Grid xs={12} sm={6} item alignItems="center" justify="center">
                                        <TextInput 
                                            name="email"
                                            value={receiverEmail}
                                            type='email'
                                            placeholder="Enter an existing email"
                                            onChange={(e:any) => {setReceiverEmail(e.target.value); setEmailError(null)}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                   
                                    {emailError && (
                                        <Alert severity="error" style={{fill: "green"}}>{emailError}</Alert>
                                    )}
                                </Grid>
                                {!receiver && (
                                    <>
                                <Grid item>
                                    <Button
                                        color='primary'
                                        onClick={() => {
                                            getReceiver();
                                        }}
                                    >
                                        {!receiver ? "Submit": "Update"}
                                        
                                    </Button>
                                </Grid>
                              
                                    {!receiver && (
                                        <Grid item>
                                            <Button
                                                color='primary'
                                                className="fg-button-login-primary"
                                                onClick={() => {
                                                    setProposalType(null)
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    )}
                              
                                    </>
                                )}
                                
                                
                                
                            </Grid>
                        ):(<>
                        
                            
                        </>
                        )}
                        {showForm && (
                            <Grid style={{paddingTop: 25}} container item direction="column" spacing={2} alignItems="center" justify="center">
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={4}>
                                        Name:
                                    </Grid>
                                    <Grid xs={6} item alignItems="center" justify="center">
                                        <TextInput 
                                            name="name"
                                            value={name || ''}
                                            type='text'
                                            placeholder="Name"
                                            onChange={(e:any) => setName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={4}>
                                        Description:
                                    </Grid>
                                    <Grid item xs={6} alignItems="center" justify="center">
                                        <TextInput 
                                            name="desc"
                                            value={desc || ''}
                                            type='text'
                                            placeholder="Description"
                                            onChange={(e:any) => setDesc(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                              
                                    <>
                                    <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                        <Grid item xs={4}>
                                            Transaction Type:
                                        </Grid>
                                        <Grid xs={6} item alignItems="center" justify="center">
                                            <Select
                                                id="demo-simple-select-autowidth"
                                                name="category"
                                                value={category || ''}
                                                style={{width: '100%'}}
                                                
                                                onChange={(e) => handleCategoryChange(e.target.value)}
                                                displayEmpty
                                                autoWidth
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Choose transaction type...</em>
                                                </MenuItem>
                                                {categoryTypes && categoryTypes.results.map((cateType: any) => {
                                                    return (
                                                        <MenuItem value={cateType.id}>{cateType.name}</MenuItem>
                                                    )
                                                })}
                                                    
                                                
                                                
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    {(category === 2 || (category === 1 && shouldered_by_merchant)) && (
                                        <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                            <Grid item xs={4}>
                                                Monthly Terms:
                                            </Grid>
                                            <Grid xs={6} item alignItems="center" justify="center">
                                                <Select
                                                    id="demo-simple-select-autowidth"
                                                    name="terms"
                                                    value={terms || ''}
                                                    style={{width: '100%'}}
                                                    
                                                    onChange={(e: any)=>{setTerms(e.target.value);}}
                                                    displayEmpty
                                                    autoWidth
                                                    defaultValue=""
                                                >
                                                    <MenuItem value="" disabled>
                                                        <em>Choose monthly terms...</em>
                                                    </MenuItem>
                                            
                                                    <MenuItem value={'3'}>3</MenuItem>
                                                    <MenuItem value={'6'}>6</MenuItem>
                                                    <MenuItem value={'9'}>9</MenuItem>
                                                    <MenuItem value={'12'}>12</MenuItem>
                                                    
                                                    
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    )}
                                    </>
                                 
                                <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                    <Grid item xs={4}>
                                        Amount:
                                    </Grid>
                                    <Grid item xs={6} alignItems="center" justify="center">
                                        <TextInput 
                                            name="desc"
                                            value={amount || ''}
                                            type='number'
                                            placeholder="Amount"
                                            onChange={(e:any) => setAmount(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                {(category === 1 && buyOrSell === "SELL") && (
                                    <Grid container item direction="row" alignItems="center" justify="center">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    name="checkedI"
                                                    value={shouldered_by_merchant}
                                                    onChange={()=>setShoulderedByMerchant(!shouldered_by_merchant)}
                                                />
                                                }
                                                label="Interest Shouldered by Merchant"
                                            />
                                        </Grid>
                                    </Grid>
                                    )}
                                {proposalType === "Link" && buyOrSell === "SELL" && (
                                    <>
                                    
                                    
                                    <Grid container item direction="row" alignItems="center" justify="center">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    name="checkedI"
                                                    value={is_link}
                                                    onChange={()=>setIsLink(!is_link)}
                                                />
                                                }
                                                label="Use as reusable link"
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    {is_link && (
                                    <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                        <Grid item xs={4}>
                                            Limit:
                                        </Grid>
                                        <Grid item xs={6} alignItems="center" justify="center">
                                            <TextInput 
                                                name="limit"
                                                value={limit || ''}
                                                type='number'
                                                placeholder="Limit"
                                                onChange={(e:any) => setLimit(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    )}
                                    
                                   
                                    
                                    </>
                                )}
                               
                                
                                <Grid container item direction="row" alignItems="center" justify="center">
                                    
                                    <Grid item alignItems="center" justify="center">
                                        <Button
                                            color='primary'
                                            disabled= {buyOrSell === "SELL" ? 
                                                !(category &&
                                                name !== '' && name &&
                                                desc !== '' && desc &&
                                                amount !== '' && 
                                                amount && parseInt(amount) >= 500) 
                                                ||
                                                !(is_link ? (limit !== '' && limit) : true)
                                            : 
                                                !((amount !== null && category !== null) && (desc !== null && name !== null))}
                                            onClick={() => {
                                                if(proposalType === "Email"){
                                                    emailProposal();
                                                }else{
                                                    generateLink();
                                                }
                                            }}
                                        >
                                            {(proposalType === "Email") ?
                                                "Send Proposal"
                                            :
                                                "Generate Link"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid container item direction="row" alignItems="center" justify="center">
                                    
                                    <Grid item alignItems="center" justify="center">
                                        <Button
                                            color='primary'
                                            className="fg-button-login-primary"
                                            onClick={() => {
                                                setProposalType(null)
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        
                        
                    </div>
                </div>
            </Dialog>
            <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={successMessage} link={link}/>
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorMessage} />
        </>
    )
}

export default ProposalViaEmailLink;