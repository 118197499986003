import {
    IconLookup,
    IconDefinition,
    findIconDefinition,
    IconPrefix,
    IconName
} from '@fortawesome/fontawesome-svg-core';
   
const Icon = (prefix: IconPrefix, name: IconName): IconDefinition => {
    const iconLookup: IconLookup = { prefix: prefix, iconName: name }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup);
    return iconDefinition;
}

export default Icon;