import React, {useEffect, useState} from 'react';
import { Col, Row } from 'react-grid-system';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, Theme, Toolbar, Typography, Divider, ListItemIcon, MenuItem, MenuList, SwipeableDrawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { 
  withRouter, 
  Link,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import createBrowserHistory from 'history/createBrowserHistory';
import { Button as CustomButton } from '../../components/Button';
import Content from './Content';
import Auth from '../../auth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Footer from '../../layouts/Footer';
import { SuccessDialog, ErrorDialog } from '../../components';

const logo = require('../../assets/images/4giveslogo.png');
const logoWhite = require('../../assets/images/4giveslogo_white.png');
const pic = require('../../assets/images/pic2.png');
const offer = require('../../assets/images/create_offer.png');
const sale = require('../../assets/images/create_sale.png');
const gives = require('../../assets/images/4giveslogoorange.png');
const purchase = require('../../assets/images/purchase.png')



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    menuButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      flexGrow: 1,
    },
    toolbarBtns: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    toolbarBtnMobile: {
      marginLeft: 'auto',
    },
    content: {
      flex: 1,
      marginTop: '0px',
  marginBottom: '0px',
  marginRight: '0px',
  marginLeft: '0px',
      overflowY: 'auto'
    },
    btns: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      background: '#fff',
    },
    menuLabel: {
      color: '#FF873A'
  },
  }),
);


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Home: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    let query = useQuery();

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openError, setOpenError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
      if(query.has('paymaya_status')){
        if(query.get('paymaya_status') === 'SUCCESS'){
          setOpenDialog(true)
          setDialogMsg('Payment is successful! Thank your for using 4Gives!')
        }else if(query.get('paymaya_status') === 'FAILED'){
          setOpenError(true)
          setErrorMsg('Payment failed.');
        }
      }
    },[])

    return (
      <div className="page">
        <div>
            <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none'}}>
                <Toolbar>
                  
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                      <img src={logo} style={{maxWidth: matches ? '200px' : '150px', fontSize: 40}} />
                  </IconButton>
                  {/**
                   * {!matches ? (
                    <span className={classes.toolbarBtnMobile}>
                      <IconButton  edge="end" style={{left: "-50"}} onClick={() => setOpenDrawer(true)} color="inherit" aria-label="menu">
                        <MenuIcon style={{fill: '#FF873A'}} />
                      </IconButton>
                    </span>
                 
                  ):(
                  <span className={classes.toolbarBtns}>
                    <Button variant="text" className={classes.btns} style={{color: '#FF873A', fontSize: 16}}><span style={{color: '#FF873A'}}>Features</span></Button>
                    <Button variant="text" className={classes.btns} style={{color: '#FF873A', fontSize: 16}}><span style={{color: '#FF873A'}}>Pricing</span></Button>
                    <Fab variant="extended" href="/login" onClick={() => history.push('/login')} className={classes.btns} style={{color: '#FF873A', fontSize: 16}}>
                      <span style={{color: '#FF873A'}}>Login</span>
                    </Fab>
                  </span>
                  )}
                   * 
                   */}
                  
                 
                </Toolbar>
            </AppBar>

            <SwipeableDrawer 
                    anchor={'right'} 
                    classes={{paper: classes.paper}} 
                    open={openDrawer} onOpen={() => setOpenDrawer(true)} onClose={() => {setOpenDrawer(false)}}>
                    <div className="drawernav">
                        <Grid container justify="center" spacing={2}>
                            
                            
                            <Grid xs={12} item style={{ textAlign: 'center' }}>
                                <img src={logo} style={{maxWidth: '150px', margin: 'auto'}} />
                            </Grid>
                            <Divider />
                            <Grid xs={12}  item>
                                <MenuList>
                                    <MenuItem onClick={() => history.push('/buyer')}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <StarsIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Buyer Mode</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => history.push('/merchant')}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <LocalAtmIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Seller Mode</Typography>
                                    </MenuItem>
                                    {/**
                                     * 
                                     * <MenuItem href="/login" onClick={() => {
                                        
                                        history.push('/login')
                                    }}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <AccountCircleIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Login</Typography>
                                    </MenuItem>
                                     */}
                                    
                                    
                                </MenuList>
                                <span style={{ fontWeight: 'bold', fontSize: '16px', color: "#FF873A" }}></span>
                            </Grid>
                        </Grid>
                    </div>
                </SwipeableDrawer>


          
            
        </div>
      
        {query.get('page') === 'features'? 
          <Content /> 
        : 
          <Content />
        }
     
        <Footer />
        <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={dialogMsg} />
        <ErrorDialog openDialog={openError} setOpenDialog={setOpenError} dialogMessage={errorMsg} />
      </div>
    )
    
}
  
export default withRouter(Home);