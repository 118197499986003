import React, {useEffect, useRef, useState} from 'react';
import { Col, Row } from 'react-grid-system';
import { Container, Grid, AppBar, Button, createStyles, IconButton, Fab, makeStyles, Theme, Toolbar, Typography, Divider, ListItemIcon, MenuItem, MenuList, SwipeableDrawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { 
  withRouter, 
  Link,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import createBrowserHistory from 'history/createBrowserHistory';
import { Button as CustomButton } from '../../components/Button';
import Content from './Content';
import Auth from '../../auth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Footer from '../../layouts/Footer';
import { HomeNavBar } from '../../layouts/HomeNavBar';
import ReactGA from 'react-ga'

const logo = require('../../assets/images/4giveslogo_seller.svg');
const logoWhite = require('../../assets/images/4giveslogo_white.png');
const pic = require('../../assets/images/pic2.png');
const offer = require('../../assets/images/create_offer.png');
const sale = require('../../assets/images/create_sale.png');
const gives = require('../../assets/images/4giveslogoorange.png');
const purchase = require('../../assets/images/purchase.png')



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    menuButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      flexGrow: 1,
    },
    toolbarBtns: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    toolbarBtnMobile: {
      marginLeft: 'auto',
    },
    content: {
      flex: 1,
      marginTop: '0px',
  marginBottom: '0px',
  marginRight: '0px',
  marginLeft: '0px',
      overflowY: 'auto'
    },
    btns: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      background: '#fff',
    },
    menuLabel: {
      color: '#FF873A'
  },
  }),
);


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const MerchantHome: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);


    const calcRef = useRef(null)
    const howRef = useRef(null)
    const reqRef = useRef(null)
    const [execScroll, executeScroll] = useState<any>(null) 
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    let query = useQuery();

    useEffect(() => {
     

      if(execScroll){
        console.log(execScroll); 
        const scrollToRef = (ref: any) => {
          console.log("SHOULD SCROLL ",execScroll); 
          execScroll.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'}); 
          executeScroll(null)
        };
        scrollToRef(execScroll)
        /*
        console.log(execScroll)
        const executeScroll = (ref: any) => {scrollToRef(ref); console.log(ref)}
        executeScroll(execScroll)
*/
      }

    },[calcRef, howRef, reqRef, execScroll])
    return (
      <div className="page">
        <div>
          <HomeNavBar executeScroll={executeScroll} howRef={howRef} calcRef={calcRef} reqRef={reqRef}/>
          {/**
            <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none'}}>
                <Toolbar>
                  
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                      <img src={logo} style={{maxWidth: matches ? '200px' : '150px', fontSize: 40}} />
                  </IconButton>
                  {!matches ? (
                    <span style={{marginLeft: '-20px'}}>
                      <IconButton  edge="end" style={{left: "-50"}} onClick={() => setOpenDrawer(true)} color="inherit" aria-label="menu">
                        <MenuIcon style={{fill: '#FF873A'}} />
                      </IconButton>
                    </span>
                 
                  ):(
                  <span className={classes.toolbarBtns}>
                    <Button variant="text" className={classes.btns} onClick={() => history.push('/buyer')} style={{color: '#a9a9a9', fontSize: 16}}><span style={{color: '#a9a9a9'}}>Go to 4Gives Buyer</span></Button>
                    <Fab variant="extended" href={"/login?page=registration-page&applying_for=merchant"} onClick={() => history.push('/login?page=registration-page&applying_for=merchant')} className={classes.btns} style={{color: '#FF873A', fontSize: 16}}>
                      <span style={{color: '#FF873A'}}>Sign Up</span>
                    </Fab>
                  </span>
                  )}
                 
                </Toolbar>
            </AppBar>

            <SwipeableDrawer 
                    anchor={'right'} 
                    classes={{paper: classes.paper}} 
                    open={openDrawer} onOpen={() => setOpenDrawer(true)} onClose={() => {setOpenDrawer(false)}}>
                    <div className="drawernav">
                        <Grid container justify="center" spacing={2}>
                            
                            
                            <Grid xs={12} item style={{ textAlign: 'center' }}>
                                <img src={logo} style={{maxWidth: '150px', margin: 'auto', paddingTop: '10px'}} />
                            </Grid>
                            <Divider />
                            <Grid xs={12}  item>
                                <MenuList>
                                    <MenuItem >
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <StarsIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Buyer Mode</Typography>
                                    </MenuItem>
                                    <Divider />
                            
                                    <MenuItem href="/login" onClick={() => {
                                        
                                        history.push('/login')
                                    }}>
                                        <ListItemIcon style={{minWidth: '28px'}}>
                                            <div className="menuIcon">
                                                <AccountCircleIcon fontSize="small" style={{fill:'#FF873A'}}/>
                                            </div>
                                        </ListItemIcon>
                                        <Typography className={classes.menuLabel} variant="body1">Login</Typography>
                                    </MenuItem>
                                    
                                </MenuList>
                                <span style={{ fontWeight: 'bold', fontSize: '16px', color: "#FF873A" }}></span>
                            </Grid>
                        </Grid>
                    </div>
                </SwipeableDrawer>

 */}
          
            
        </div>
      
        {query.get('page') === 'features'? 
          <Content howRef={howRef} calcRef={calcRef} reqRef={reqRef}/> 
        : 
          <Content howRef={howRef} calcRef={calcRef} reqRef={reqRef}/>
        }
     
        <Footer />
      </div>
    )
    
}
  
export default withRouter(MerchantHome);