import { Container, Grid, TableRow, TableBody, TableCell, TableHead, Table, TableContainer, Dialog, DialogTitle, Divider, MenuItem, Select } from "@material-ui/core"
import React, {useState, useEffect} from "react"
import Auth from "../../auth"
import { Button, ErrorDialog, TextInput, SuccessDialog } from "../../components";
import './style.scss';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CodeBlock } from "react-code-blocks";
import axios from "axios";

export const ApiKeysPage : React.FC<{
    user: any;
    setLoadingOverlay: (val: boolean) => void;
}> = ({user, setLoadingOverlay}) => {
    const close = require('../../assets/images/close.png');
    const [data, setData] = useState<any | null>(null)
    const [public_key, setPublicKey] = useState<any | null>(null);
    const [secret_key, setSecretKey] = useState<any | null>(null);
    const [copied, setCopied] = useState<any | null>({
        public_copied: false,
        secret_copied: false,
    })
    const [webhook, setWebHook] = useState('');
    const [transactName, setTransactName] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const [openOneTimeModal, setOpenOneTimeModal] = useState(false); 
    const [seconds, setSeconds] = useState(10);
    const [canClose, setCanClose] = useState(false);

    const [openGenerate, setOpenGenerate] = useState(false);
    const [type, setType] = useState('public');

    const [keyCopied, setKeyCopied] = useState('');

    const [htmlFile, setHTMLFile] = useState<string>('');

    axios.get('https://4gives-pages.s3.ap-southeast-1.amazonaws.com/4gives-api.html', 
        {
        headers: {
           'Content-Type': 'application/json'
        } 
    })
    .then(function (response: any) {
      setHTMLFile(response.data)
    })
    .catch(function(error: any) {
      console.log(error)

    })
    
    useEffect(() => {
        if(data === null) {
            setLoadingOverlay(true)
            getProfile()
            getKeys()
        }
    })

    useEffect(() => {
        if(openOneTimeModal){
            if(seconds > 0){
                setTimeout(() => {
                    setSeconds(seconds - 1)
                },1000)
            } else {
                setCanClose(true)
            }
        }
    },[openOneTimeModal, seconds])

    const generateKeys =  () => {
        setLoadingOverlay(true)
        Auth.generateKeys(
            type,
            (resp) => {
                setPublicKey(resp.public_key)
                setSecretKey(resp.secret_key)
                setWebHook(resp.webhook_url)
                setOpenErrorDialog(false)
                if(resp.type === "secret"){
                    setSecretKey(resp.key)
                    setOpenOneTimeModal(true)
                    setSeconds(10)
                    setCanClose(false)
                }
                getKeys()
                setOpenGenerate(false)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setOpenErrorDialog(true)
                setErrorMessage(e.error)
                setLoadingOverlay(false)
            }
        )
    }

    const getProfile = () => {
        Auth.getMerchantProfile(
            (resp) => {
                setWebHook(resp.webhook_url)
                setTransactName(resp.transaction_name)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setOpenErrorDialog(true)
                setErrorMessage(e.error)
                setLoadingOverlay(false)
            }
        )
    }

    const getKeys = () => {
        Auth.getMerchantKeys(
            (response) => {
                setData(response)
                
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setOpenErrorDialog(true)
                setErrorMessage(e.error)
                setLoadingOverlay(false)
            }
        )
    }

    const updateWebhook = () => {
        setLoadingOverlay(true)
        Auth.updateWebHook(
            webhook,
            transactName,
            (resp) => {
                console.log(resp)
                setSuccessMessage("Webhook Url has been updated.")
                setOpenDialog(true)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setOpenErrorDialog(true)
                setErrorMessage(e.error)
                setLoadingOverlay(false)
            }
        )
    }

    const deleteApiKey = (id: any) => {
        setLoadingOverlay(true)
        Auth.deleteApiKey(
            id,
            (resp) => {
                console.log(resp)
                getKeys()
            },
            (e) => {
                setOpenErrorDialog(true)
                setErrorMessage(e.error)
                setLoadingOverlay(false)
            }   
        )
    }

    const censorChars = (string: any) => {
        const limit = 4;
        const head = string.slice(0, -limit);
        const tail = string.slice(-limit);
        return head.replace(/./g, '*') + tail;
    }

    return(
        <>
            <div className="page-content apikeys-page">
                <Container>
                    <div className="apikeys-page-container fg-container">
                        {/** 
                        <Grid container item direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <h1 className="page-title">
                                    API Keys
                                </h1>
                            </Grid>
                            
                        </Grid>*/}
                        <Grid container direction="row" alignItems="flex-start" justify="space-around">
                            <Grid item xs={12} md={6}>
                            <Container style={{ padding: '10px 30px' }}>
                                <div style={{ padding: '0px', borderRadius: '10px'}} dangerouslySetInnerHTML={{__html: htmlFile}} ></div>
                                {/*
                                <Typography variant="h4" color="primary" align="center">{FAQsCopy.title}</Typography>

                                { FAQsCopy.content.map(
                                    (qna, index) => (
                                    <>
                                        <Typography variant="h6" color="secondary" style={{ marginTop: 20, fontWeight: 600 }}>
                                        {qna.question}
                                        </Typography>
                                        
                                        { qna.arr ? qna.ans.map(
                                        ans => (
                                            <Typography variant="body1" style={{ marginLeft: 20 }}>&#8226; {ans}</Typography>
                                        )
                                        ) : (
                                        <Typography variant="body1" style={{ marginLeft: 20, whiteSpace: "pre-wrap" }}>{qna.ans}</Typography>
                                        ) }
                                    </>
                                    )
                                ) }
                                        */}
                                       
                            </Container>
                            <Divider />
                            </Grid>

                            
                            <Grid item xs={12} md={6}>
                            <Container style={{ padding: '0px 50px' }}>
                                <Grid container item direction="row" justify="center" alignItems="center">
                                    <Grid item>
                                        <h1 className="page-title">
                                            API Keys
                                        </h1>
                                    </Grid>
                                    
                                </Grid>
                           
                                <Grid container item direction="row" spacing={2} alignItems="center" justify="center">
                                    <Grid item xs={12}>
                                        Webhook URL:
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" justify="center">
                                        <TextInput 
                                            name="webhook"
                                            value={webhook}
                                            type='text'
                                            placeholder="Webhook URL e.g. https://www.<YOURSITE>.com"
                                            onChange={(e:any) => setWebHook(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        Webhook Transaction Name:
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" justify="center">
                                        <TextInput 
                                            name="transactName"
                                            value={transactName}
                                            type='text'
                                            placeholder="Webhook Transaction Name"
                                            onChange={(e:any) => setTransactName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            color='primary'
                                            disabled={webhook === '' || transactName === ''}
                                            onClick={updateWebhook}
                                        >
                                            Save Webhook Settings
                                        </Button>
                                    </Grid>
                                </Grid>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Key
                                                </TableCell>
                                                <TableCell>
                                                    Policy
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                                <TableCell>
                                            
                                                    <Button
                                                        color='primary'
                                                        onClick={() => setOpenGenerate(true)}
                                                    >
                                                        Generate Key
                                                    </Button>
                                    
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        
                                            {data && data.results.map((api: any) => (
                                                <TableRow key={api.id}>
                                                    <TableCell>
                                                        {censorChars(api.key)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {api.type}
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <CopyToClipboard text={api.key} onCopy={() => setKeyCopied(api.key)}>
                                                            <Button
                                                                color='primary'
                                                                onClick={() => console.log('test')}
                                                            >
                                                                {keyCopied === api.key ? 'Copied!' : 'Copy'}
                                                            </Button>
                                                        </CopyToClipboard>
                                                        
                                                        <Button
                                                            color='delete'
                                                            onClick={() => deleteApiKey(api.id)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Container>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <Dialog 
                open={openGenerate} 
                fullWidth={true} 
                maxWidth={'sm'} 
                PaperProps={{
                    style: {
                        backgroundColor: '#fee2b3', 
                        border: "1px solid #FF873A",
                        borderRadius: '20px'
                    },
                }}
            >
                <DialogTitle style={{color: 'green', textAlign: "center"}}>
                    <img
                        src={close}
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={() =>{setOpenGenerate(false); 
                            
                        }} 
                    />
                    
                    <div style={{paddingTop: 10, color: "#FF873A"}}>
                        Generate Key
                    </div>
                        
                    
                </DialogTitle>
                <Container>
                    <div style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                        <div style={{padding: 20}}>
                            <Grid container item direction="row" alignItems="center" justify="space-evenly">
                                <Grid item xs={4}>
                                    Type:
                                </Grid>
                                <Grid xs={6} item alignItems="center" justify="center">
                                    <Select
                                        id="demo-simple-select-autowidth"
                                        name="terms"
                                        value={type || ''}
                                        style={{width: '100%'}}
                                        
                                        onChange={(e: any)=>{setType(e.target.value);}}
                                        displayEmpty
                                        autoWidth
                                        defaultValue=""
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Choose API key type...</em>
                                        </MenuItem>
                                
                                        <MenuItem value={'public'}>Public</MenuItem>
                                        <MenuItem value={'secret'}>Secret</MenuItem>
                                        
                                        
                                    </Select>
                                </Grid>
                            </Grid>
                            <div style={{padding: 20}}></div>
                            <Grid container item direction="row" alignItems="center" justify="center">
                                
                                <Grid item alignItems="center" justify="center">
                                    <Button
                                        color='primary'
                                        onClick={generateKeys}
                                    >
                                        {
                                            "Generate Key"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                          
                        </div>
                    </div>
                    
                </Container>
            </Dialog>
            <Dialog 
                open={openOneTimeModal} 
                fullWidth={true} 
                maxWidth={'sm'} 
                PaperProps={{
                    style: {
                        backgroundColor: '#fee2b3', 
                        border: "1px solid #FF873A",
                        borderRadius: '20px'
                    },
                }}
            >
                <DialogTitle style={{color: 'green', textAlign: "center"}}>
                    
                    
                    <div style={{paddingTop: 10, color: "#FF873A"}}>
                        API Keys Generated
                    </div>
                        
                    
                </DialogTitle>
                <Container>
                    <div style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                        <div style={{padding: 20}}>
                            
                            <Grid container item direction="row" alignContent="center" justify="center">
                                <Grid item>
                                    The system has generated your Secret API Key. Please copy or write down from a piece of paper your key below before closing as this is only shown to you once.
                                </Grid>
                            </Grid>
                            <div style={{padding: 20}}>
                                <Divider />
                            </div>
                            {secret_key &&(
                                <div className="link-copy">
                                    <CopyToClipboard text={secret_key} onCopy={() => setCopied({public_copied: false, secret_copied: true})}>
                                        <Grid container item direction="row" alignContent="center" justify="center">
                                            <Grid item>
                                                <div className="text">
                                                    {secret_key}{!copied.secret_copied ? (<FileCopyIcon fontSize="small"/>) : (<DoneIcon fontSize="small" />)}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CopyToClipboard>
                                </div>
                        
                            )}
                            <div style={{padding: 20}}>
                                <Grid container item direction="row" alignItems="center" justify="center">
                                    <Grid item>
                                        <Button
                                            disabled={!canClose}
                                            color='primary'
                                            onClick={() => {setOpenOneTimeModal(false); setOpenGenerate(false)}}
                                        >
                                            {canClose ? 'Close Now' : `Can close in ${seconds}`}
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </div>
                    </div>
                    
                </Container>
            </Dialog>
            <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} dialogMessage={successMessage}/>
            <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={errorMessage} />
        </>
    )
}

